import ENVIRONMENT from "./ENVIRONMENT";
import Props from "./Props";
import {AsyncStorageKeys} from "../Types";

let ENV_CONFIG: Props;

export const hostUrl = window.location.host;

export let DEFAULT_ENV: ENVIRONMENT;
export let DEFAULT_ENV_STRING: string;

// set env variable here
if (hostUrl.includes('localhost')) {
	DEFAULT_ENV = ENVIRONMENT.LOCALHOST;
	DEFAULT_ENV_STRING = 'development';
} else if (hostUrl.includes('staging')) {
	DEFAULT_ENV = ENVIRONMENT.STAGING;
	DEFAULT_ENV_STRING = 'staging';
} else if (hostUrl.includes('uat')) {
	DEFAULT_ENV = ENVIRONMENT.UAT;
	DEFAULT_ENV_STRING = 'uat';
} else {
	DEFAULT_ENV = ENVIRONMENT.PRODUCTION;
	DEFAULT_ENV_STRING = 'production';
}

let envOverride: string | null = localStorage.getItem(AsyncStorageKeys.EnvironmentOverride);
DEFAULT_ENV = envOverride ? (<any>ENVIRONMENT)[envOverride] : DEFAULT_ENV;

switch (DEFAULT_ENV) {
	case ENVIRONMENT.LOCALHOST:
		ENV_CONFIG = require('./staging').default;
		break;
	case ENVIRONMENT.STAGING:
		ENV_CONFIG = require('./staging').default;
		break;
	case ENVIRONMENT.UAT:
		ENV_CONFIG = require('./uat').default;
		break;
	case ENVIRONMENT.PRODUCTION:
		ENV_CONFIG = require('./prod').default;
		break;
}


export default ENV_CONFIG;