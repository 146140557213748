import styled, { css } from "styled-components/native";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { black, separator_gray } from "../../StyleHelpers";

interface Props {
	type: ComponentTypeEnum;
}

const StyledContainer = styled.View<Props>`
	height: 2px;

	${(props) => {
		switch (props.type) {
			case ComponentTypeEnum.Primary:
				return css`
					width: 100%;
					background-image: linear-gradient(to left, ${black}, #008bef 50%, ${black});
				`;
			case ComponentTypeEnum.Secondary:
				return css`
					width: 90%;
					background: ${separator_gray};
				`;
		}
	}}
`;

const Separator = (props: Props) => {
	return <StyledContainer type={props.type} />;
};

export default Separator;
