import { white_smoke } from "../../StyleHelpers";
import styled from "styled-components/native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import { formatDate, formatHoursAndMinutesFromToday, globalAny, padTo2Digits } from "../../utils/Utils";
import { View, Text } from "react-native";
import useStyleHelpers from "../../hooks/useStyleHelpers";

interface Props {
	rating?: string;
	genres?: any;
	isRent?: boolean;
	expiryDate?: any;
}

const StyledGenres = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 0.5rem;
	align-self: stretch;
`;

const StyledGenresContainer = styled.Text<any>`
	display: flex;
	padding: 0.375rem 0.5rem;
	align-items: flex-start;
	gap: 0.625rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	background: rgba(255, 255, 255, 0.08);
	color: #FFF;
	text-align: center;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 500;
	line-height: ${(props) => props.ratings.lineHeight};
`;

const StyledText = styled.Text`
	font-family: ${FONT_FAMILY_DEFAULT};
	color: ${white_smoke};
	text-shadow: rgba(0,0,0,.3) 2px 2px 2px;
`;

const StyledRatingContainer = styled.Text<any>`
	display: flex;
	padding: 0.375rem 0.5rem;
	align-items: flex-start;
	gap: 0.625rem;
	border: 1px solid #F2F2F2;
	border-radius: 0.25rem;
	background: rgba(0, 0, 0, 0.60);
	color: #FFF;
	text-align: center;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 700;
	line-height: ${(props) => props.ratings.lineHeight};
	text-transform: uppercase;
`;

const StyledProfileContainer = styled.Text<any>`
	display: flex;
	padding: 0.375rem 1rem;
	align-items: flex-start;
	gap: 0.625rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	background: rgba(0, 0, 0, 0.60);
	color: #FFF;
	text-align: center;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 700;
	line-height: ${(props) => props.ratings.lineHeight};
	text-transform: uppercase;
`;

const StyledExpiryContainer = styled.View`
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	gap: 8px;
	align-self: stretch;
	flex-wrap: wrap;
	margin-bottom: 16px;
`;

const StyledExpiryText = styled.Text`
	background: rgba(72, 28, 40, 0.5);
	border-radius: 4px;
	color: white;
	font-family: "Nunito";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	display: flex;
	padding: 6px 8px;
	align-items: flex-start;
	gap: 10px;
`;

const GenresComponent = (props: Props) => {
	const styleHelper = useStyleHelpers();
	const wording = {
		rentExpires: globalAny.language.rent_expires,
		viewingExpires: globalAny.language.viewing_expires,
	};

	const onViewingExpires = (expiryDate: any) => {
		let date = new Date(new Date().toUTCString());
		const convertToDate = new Date(expiryDate.utcExpiryDate);
		let viewingExpiry = false;
		if (date.getMonth() === convertToDate.getMonth()) {
			viewingExpiry = 0 === date.getDate() - convertToDate.getDate();
			return { viewingExpiry: viewingExpiry, newDateFormat: formatDate(convertToDate) };
		}
		return { viewingExpiry: viewingExpiry, newDateFormat: formatDate(convertToDate) };
	};

	const formatExpiry = (expiryDate: any) => {
		const expiry = onViewingExpires(expiryDate);

		if (expiryDate?.ownershipExpiryHoursRemaining && expiryDate?.ownershipExpiryMinutesRemaining) {
			return `${wording.viewingExpires} ${padTo2Digits(Number(expiryDate?.ownershipExpiryHoursRemaining))}:${padTo2Digits(
				Number(expiryDate?.ownershipExpiryMinutesRemaining)
			)}`;
		} else if (!expiryDate?.ownershipExpiryHoursRemaining && !expiryDate?.ownershipExpiryMinutesRemaining && expiry.viewingExpiry) {
			return `${wording.rentExpires} ${formatHoursAndMinutesFromToday(expiryDate.utcExpiryDate)}`;
		} else {
			return `${wording.rentExpires} ${expiry.newDateFormat === "NaN/NaN/NaN" ? "" : expiry.newDateFormat}`;
		}
	};

	return (
		<>
			{/* @ts-ignore */}
			<StyledGenres>
				{/* @ts-ignore */}
				{props?.rating && <StyledRatingContainer ratings={styleHelper?.tdp.ratings}>{props?.rating}</StyledRatingContainer>}
				{/* @ts-ignore */}
				{props?.rating && <StyledProfileContainer ratings={styleHelper?.tdp.ratings}>HD</StyledProfileContainer>}
				{props?.genres?.map((item: any) => {
					return (
						//@ts-ignores
						<StyledGenresContainer ratings={styleHelper?.tdp.ratings} key={`genre-component-${item}`}>
							<View>
								<Text>
									{/* @ts-ignores */}
									<StyledText>{item}</StyledText>
								</Text>
							</View>
						</StyledGenresContainer>
					);
				})}
			</StyledGenres>

			{props?.isRent ? (
				// @ts-ignore
				<StyledExpiryContainer>
					{/* @ts-ignore */}
					<StyledExpiryText>{formatExpiry(props?.expiryDate)}</StyledExpiryText>
				</StyledExpiryContainer>
			) : (
				<></>
			)}
		</>
	);
};

export default GenresComponent;
