import { TouchableOpacity } from "react-native";
import Image from "../Image/Image";
import { StyledBreakWord, StyledClickableContainer, StyledFooterContainer, StyledHeaderFont, StyledLabelContainer, StyledText } from "./styled";

interface Props {
	header?: string;
	subHeader?: string;
	body?: string;
	footer?: string;
	onClickableHeader?: boolean;
	onClickableBody?: boolean;
	onClickableFooter?: boolean;
	addMarginChild?: boolean;
	onPressSubHeader?: () => void;
	onPressHeader?: () => void;
	onPressFooter?: () => void;
	onPressBody?: () => void;
	imageSource?: string;
	icon?: boolean;
	isFooterIcon?: boolean;
	disableIcon?: boolean;
	headerIcon?: boolean;
	iconSize?: number;
	iconWidth?: number;
	iconHeight?: number;
	iconGap?: string;
	styledSettings: any;
}

// @ts-ignore
const LabelContainer = (props: Props) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledLabelContainer>
				{props.onClickableHeader || props.headerIcon ? (
					<TouchableOpacity onPress={props.onPressHeader} disabled={props.headerIcon}>
						{/* @ts-ignore */}
						<StyledClickableContainer {...props}>
							<StyledHeaderFont {...props}>
								<StyledBreakWord>{props.header}</StyledBreakWord>
							</StyledHeaderFont>
							<Image
								source={{ uri: props.icon }}
								width={props.iconSize ?? props.iconWidth}
								height={props.iconSize ?? props.iconHeight}
							/>
						</StyledClickableContainer>
					</TouchableOpacity>
				) : (
					<></>
				)}

				{!props.onClickableHeader && !props.headerIcon ? (
					<StyledHeaderFont {...props}>
						<StyledBreakWord>{props.header}</StyledBreakWord>
					</StyledHeaderFont>
				) : (
					<></>
				)}

				{props.body && props.onClickableBody ? (
					// @ts-ignore
					<TouchableOpacity onPress={props.onPressBody}>
						<StyledText {...props}>
							<StyledBreakWord>{props.body}</StyledBreakWord>
						</StyledText>
					</TouchableOpacity>
				) : (
					<StyledText {...props}>
						<StyledBreakWord>{props.body}</StyledBreakWord>
					</StyledText>
				)}

				{props.footer && props.onClickableFooter ? (
					// @ts-ignore
					<StyledFooterContainer>
						<TouchableOpacity onPress={props.onPressFooter}>
							<StyledText {...props}>
								<StyledBreakWord>{props.footer}</StyledBreakWord>
							</StyledText>
						</TouchableOpacity>
					</StyledFooterContainer>
				) : (
					<StyledText {...props}>
						<StyledBreakWord>{props.footer}</StyledBreakWord>
					</StyledText>
				)}
			</StyledLabelContainer>
		</>
	);
};

export default LabelContainer;
