import React from "react";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";
import { StyledBody, StyledMain, StyledTitle } from "./styled";

const Typography = (props: any) => {
	const styleHelper = useStyleHelpers();
	return (
		<>
			{/* @ts-ignore */}
			<StyledMain isNarrow={styleHelper?.layout === ScreenDimensions.narrow} gap={styleHelper?.typographyTitle.gap}>
				{/* @ts-ignore */}
				<StyledTitle
					isNarrow={styleHelper?.layout === ScreenDimensions.narrow}
					fontSize={styleHelper?.typographyTitle?.fontSize}
					lineHeight={styleHelper?.typographyTitle?.lineHeight}
				>
					{props.title}
				</StyledTitle>
				{/* @ts-ignore */}
				<StyledBody
					isNarrow={styleHelper?.layout === ScreenDimensions.narrow}
					fontSize={styleHelper?.typographyBody?.fontSize}
					lineHeight={styleHelper?.typographyBody?.lineHeight}
				>
					{props.description}
				</StyledBody>
			</StyledMain>
		</>
	);
};

export default Typography;
