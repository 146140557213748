import styled from "styled-components/native";

const StyledWrapper = styled.View`
	z-index: -1;
	margin: 10% 14.1% 0 14.1%;
`;

// @ts-ignore
const Wrapper = ({ children }) => {
	return (
		// @ts-ignores
		<StyledWrapper>{children}</StyledWrapper>
	);
};

export default Wrapper;
