import styled from "styled-components/native";
import useGenericContentStore from "../../store/genericContent.store";

export const StyledScreenContainerView = styled.View<any>`
	width: 100%;
	border-radius: 16px;
	display: flex;
	padding: ${(props) => props?.styledSettings.container};
	flex-direction: column;
	align-items: flex-start;
	gap: ${(props) => (props?.removeGap ? 0 : props?.styledSettings.gap)};
	background-color: ${(props) => props?.themeManager.baseBackgroundColor};
	overflow: hidden;
`;

// @ts-ignore
const SettingsContainer = ({ children, styledSettings, removeGap }: any) => {
	const themeManager = useGenericContentStore((state: any) => state.themeManager);

	return (
		// @ts-ignores
		<StyledScreenContainerView themeManager={themeManager} styledSettings={styledSettings} removeGap={removeGap}>
			{children}
		</StyledScreenContainerView>
	);
};

export default SettingsContainer;
