import { getTransactionFilters } from "../utils/Utils";
import { CONTENT_BASEURL_V7, CONTENT_BASEURL_V6, CONTENT_BASEURL_V8, CONTENT_BASEURL_V9 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const getPlaylist = (playlistUId: string, isCustomList: boolean, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			(isCustomList ? CONTENT_BASEURL_V7 + "customlist/" : CONTENT_BASEURL_V9 + "playlist/") +
				playlistUId +
				`/content?transactionalFilters=${getTransactionFilters()}&svodCountryId=0&streamType=2&supportedCodecs=1%2C2`,
			{
				headers: getHeaders(),
				signal,
			}
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});

	return promise;
};

export const getPlaylistWithPage = (playlistUId: string, isCustomList: boolean, pageSize?: number, pageNumber?: number) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			(isCustomList ? CONTENT_BASEURL_V7 + "customlist/" : CONTENT_BASEURL_V9 + "playlist/") +
				playlistUId +
				"/content" +
				`?PageSize=${pageSize}&PageNumber=${pageNumber}&transactionalFilters=${getTransactionFilters()}&svodCountryId=0&streamType=2`,
			{
				headers: getHeaders(),
			}
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});

	return promise;
};

export const getCustomerPlaylist = (customListId: string, transactionalFilters: any, pageSize?: number, pageNumber?: number, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V9 +
				`consumerplaylist/${customListId}/content?PageSize=${pageSize}&PageNumber=${pageNumber}&transactionalFilters=${transactionalFilters}&svodCountryId=0&streamType=2`,
			{
				headers: getHeaders(true),
				signal,
			}
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});

	return promise;
};
/**
 * Service to fetch vam playlist
 * @param customListId {string}
 * @returns Return the list of content in the given custom list
 */
export const getVamPlaylist = (customListId: string, pageSize?: number, pageNumber?: number, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V7 +
				"customlist/" +
				customListId +
				`/content?PageSize=${pageSize}&PageNumber=${pageNumber}&imax=false&kids=false&svodCountryId=0&streamType=2&SupportedCodecs=1%2C2`,
			{
				headers: getHeaders(false),
				signal,
			}
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});
	return promise;
};
