import styled, { css } from "styled-components/native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";

interface Props {
	isNarrow?: any;
	fontSize?: any;
	lineHeight?: any;
}

export const StyledWideMain = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

//Narrow
export const StyledNarrowMain = styled.View`
	display: flex;
	padding: 1.5rem 1.5rem;
	flex-direction: row;
	gap: 1rem;
`;
export const StyledText = styled.Text<Props>`
	${(props) =>
		css`
			font-size: ${props?.fontSize};
			line-height: ${props?.lineHeight};
			font-family: ${FONT_FAMILY_DEFAULT};
			color: white;
			margin-bottom: 100px;
		`}
`;
