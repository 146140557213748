
// @ts-ignore
import { inject, injectable } from "inversify";
import type IMqttClient from "../../models/IMqttClient";
import "reflect-metadata";
import IMqttDeviceBroadcast from "../../models/IMqttDeviceBroadcast";
import { AppLogger } from "../../utils/AppLogger";
import IPresignedUrl from "../../models/IPresignedUrl";


interface ConnectProps {
	onConnect: (mqttClient: IMqttClient, mqttDeviceBroadcast: IMqttDeviceBroadcast, topic: string) => void
	onFailed?: () => void
}


@injectable()
export default class MqttConductorService {
	@inject("IMqttClient") private _mqttClient: IMqttClient | undefined;

	constructor() {
	}

	/**
	 * Used to initiate the connection to mqtt broker. Previously named as connect() function
	 */
	public async connectToMqttBroker(presignedUrl: IPresignedUrl) {
		try {
			// @ts-ignore
			this._mqttClient.connect(presignedUrl);
		} catch (e) {

		}
	}

	public onConnect(fn: (mqttClient: IMqttClient, topic: string) => void) {
		this._mqttClient?.onConnect((clientTopic: string) => {
			if (!this._mqttClient) {
				return;
			}

			AppLogger.log('[MQTT] device connected!!! topic generated: ', clientTopic);
			fn(this._mqttClient, clientTopic);
		});
	}

	public onDisconnect(fn: () => void) {
		this._mqttClient?.onDisconnect(fn);
		this._mqttClient?.onConnectionLost(fn);
		this._mqttClient?.onClose(fn);
	}


}

