import { Image } from "react-native";
import { useEffect, useState } from "react";

export default (props: any) => {
	const [width, setWidth] = useState(props.width);
	const [height, setHeight] = useState(props.height);

	/**
	 * Dynamic Resizing Function - Updates the dimensions of items when the screen size changes without having to reload
	 */
	useEffect(() => {
		setWidth(props.width);
		setHeight(props.height);
	}, [props.windowDimensions]);

	const defaultStyle = { width: width, height: height };
	const incomingStyle = Array.isArray(props.style) ? props.style : [props.style];
	return <Image {...props} style={[defaultStyle, ...incomingStyle]} source={props.source} />;
};
