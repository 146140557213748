import "./App.css";
import Navigation from "./navigation/MainNavigator";
import CarActivityWrapper from "./components/Layout/CarActivityWrapper";
import SessionWrapper from "./components/Layout/SessionWrapper";
import MQTTWrapper from "./components/Layout/MQTTWrapper";

function App() {
	return (
		<SessionWrapper>
			<MQTTWrapper>
				<CarActivityWrapper>
					<Navigation/>
				</CarActivityWrapper>
			</MQTTWrapper>
		</SessionWrapper>
	);
}

export default App;
