import { globalAny, metadataTextSeparator } from "../../utils/Utils";
import { Image, TouchableOpacity } from "react-native";
import GenresComponent from "../../components/GenresComponent";
import Text from "../../components/Text/Text";
import { useEffect, useState } from "react";
import {
	StyledDetails,
	StyledDetailsContainer,
	StyledMargin,
	StyledPackshotContainer,
	StyledPurchaseContainer,
	StyledText,
	StyledTextContainer,
	StyledTitle,
	StyledTitleContainer,
} from "./styled";

interface Props {
	metadata?: any;
	price?: any;
	isRent?: any;
	onPressTermsAndService?: () => void;
}

const PurchaseOptions = (props: Props) => {
	const wording = {
		purchasePrice: props.isRent ? globalAny.language.rent_price : globalAny.language.purchase_price,
		tax: props?.price?.taxType === 1 ? globalAny.language.tax : globalAny.language.vat,
		total: globalAny.language.total,
		termOfService: globalAny.language.purchase_agree,
	};

	const purchaseDetails = [
		{
			id: 1,
			title: wording.purchasePrice,
			price: props?.price?.price,
			bold: true,
		},
		{
			id: 2,
			title: `${wording.tax}`,
			price: props?.price?.taxAmount,
			bold: false,
			fontWeight: 400,
		},
		{
			id: 3,
			title: wording.total,
			price: props?.price?.totalAmount,
			bold: true,
		},
	];

	const [image, setImage] = useState();

	useEffect(() => {
		let getImage: any;
		if (props?.metadata?.packshots) {
			getImage = props?.metadata?.packshots?.filter((val: any) => val.width === 600 && val.height === 900)[0]?.url;
			setImage(getImage);
			return;
		}
		getImage = props?.metadata?.images?.filter((val: any) => val.width === 600 && val.height === 900)[0]?.url;
		setImage(getImage);
	}, []);

	const renderPurchaseDetails = (item: any) => (
		// @ts-ignores
		<StyledTextContainer key={`purchase-details-${item.id}`}>
			{/* @ts-ignore */}
			<StyledText bold={item.bold} fontWeight={item.fontWeight}>
				{item.title}
			</StyledText>
			{/* @ts-ignore */}
			<StyledText bold={item.bold} fontWeight={item.fontWeight}>
				{item.price}
			</StyledText>
		</StyledTextContainer>
	);

	return (
		//@ts-ignore
		<StyledPurchaseContainer>
			{/* @ts-ignore */}
			<StyledDetailsContainer>
				{/* @ts-ignore */}
				<StyledText>
					{/* @ts-ignore */}
					<StyledTitleContainer>
						{/* @ts-ignore */}
						<StyledTitle>
							<Text style={{ fontSize: 50, lineHeight: 60, width: 500 }} numberOfLines={1} ellipsizeMode="tail">
								{props?.metadata?.title}
							</Text>
						</StyledTitle>

						{/* @ts-ignore */}
						<StyledDetails>
							<GenresComponent rating={props?.metadata?.rating} genres={props?.metadata?.genres} />
							{metadataTextSeparator(props?.metadata)}
						</StyledDetails>
					</StyledTitleContainer>
					{/* @ts-ignore */}
					<StyledMargin>
						{/* @ts-ignore */}
						{purchaseDetails.map((item) => {
							return renderPurchaseDetails({
								...item,
							});
						})}
					</StyledMargin>
					{/* @ts-ignore */}
					<StyledMargin>
						{/* @ts-ignore */}
						<StyledDetails>
							{/* @ts-ignore */}
							<StyledText fontWeight={400}>{`${wording.termOfService} `}</StyledText>
							<TouchableOpacity onPress={props.onPressTermsAndService}>
								{/* @ts-ignore */}
								<StyledText fontWeight={400} underline={true}>
									RideVu {globalAny.language.terms_of_service}.
								</StyledText>
							</TouchableOpacity>
						</StyledDetails>
					</StyledMargin>
				</StyledText>
			</StyledDetailsContainer>
			{/* @ts-ignore */}
			<StyledPackshotContainer>
				<Image
					source={{
						uri: `${image}?width=600&disablewebp=false`,
					}}
					style={{ width: 270, height: "100%", borderRadius: 16, borderColor: "#555454", borderWidth: 1 }}
					resizeMode="cover"
				/>
			</StyledPackshotContainer>
		</StyledPurchaseContainer>
	);
};

export default PurchaseOptions;
