import { useEffect } from "react";
import { create } from "zustand";
import { globalAny } from "../../utils/Utils";
import OtherData from "./OtherData";
import useDimensions from "../../hooks/useDimensions";
interface Props {
	onSubmit?: (value: boolean) => void;
	onChangeGoogleData?: (value: boolean) => void;
	onChangeVideoAnalyticsData?: (value: boolean) => void;
	onPressPrimaryButton?: () => void;
	onPressSecondaryButton?: () => void;
	onPressVideoAnalytics?: () => void;
	onPressGoogle?: () => void;
	onPressPrivacy?: () => void;
	showPrivacyLink?: boolean;
	isSettings?: boolean;
	title?: string;
	description?: string;
}

const useOtherDataToggle = create((set: any) => ({
	otherData: false,
	googleData: false,
	videoAnalyticsData: false,
	setOtherData: (state: boolean) => set(() => ({ otherData: state })),
	setGoogleData: (state: boolean) => set(() => ({ googleData: state })),
	setVideoAnalyticsData: (state: boolean) => set(() => ({ videoAnalyticsData: state })),
}));

const OtherDataComponent = (props: Props) => {
	const screenDimensions = useDimensions();
	const { otherData, googleData, videoAnalyticsData, setOtherData, setGoogleData, setVideoAnalyticsData } = useOtherDataToggle();
	const createAccountButtonData = [
		{
			title: globalAny.language.google_analytics,
			isToggle: googleData,
			toggleTitle: googleData,
			onPress: props.onPressGoogle,
			onPressToggle: (state: boolean) => setGoogleData(state),
		},
		{
			title: globalAny.language.video_analytics,
			isToggle: videoAnalyticsData,
			toggleTitle: videoAnalyticsData,
			onPress: props.onPressVideoAnalytics,
			onPressToggle: (state: boolean) => setVideoAnalyticsData(state),
		},
	];

	useEffect(() => {
		if (googleData || videoAnalyticsData) {
			setOtherData(true);
		} else {
			setOtherData(false);
		}

		if (props.onSubmit) {
			props.onSubmit(googleData && videoAnalyticsData);
		}
	}, [googleData, videoAnalyticsData]);

	useEffect(() => {
		if (!props.onChangeGoogleData) {
			return;
		}

		// @ts-ignore
		props.onChangeGoogleData(googleData);
	}, [googleData]);

	useEffect(() => {
		if (!props.onChangeVideoAnalyticsData) {
			return;
		}

		// @ts-ignore
		props.onChangeVideoAnalyticsData(videoAnalyticsData);
	}, [videoAnalyticsData]);

	const handleOtherDataToggle = (state: boolean) => {
		setOtherData(state);
		if (state) {
			setGoogleData(true);
			setVideoAnalyticsData(true);
		} else {
			setGoogleData(false);
			setVideoAnalyticsData(false);
		}
	};

	return (
		<OtherData
			title={props.title ?? globalAny.language.other_data}
			screenDimensions={screenDimensions}
			onBackPress={props.onPressSecondaryButton}
			onPressNext={props.onPressPrimaryButton}
			defaultValue={otherData}
			description={props.description ?? globalAny.language.other_data_body}
			showPrivacyLink={props.showPrivacyLink}
			onPress={(state: any) => handleOtherDataToggle(state)}
			onPressPrivacy={props.onPressPrivacy}
			sectionButtons={createAccountButtonData}
		/>
	);
};

export default OtherDataComponent;
