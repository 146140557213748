import { generateDeviceInfo, globalAny, isStaging } from "../../utils/Utils";
import { light_black } from "../../StyleHelpers";
import { useEffect, useState } from "react";
import { HomeScreenNavigationProp, routeHome, routeLogin, routeNewSettings, routeSignIn, routeCodeSignIn } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { AsyncStorageKeys, DeviceSelectionPages, Pages, ProfileName } from "../../Types";
import { removeCacheItem } from "../../utils/CacheStorageUtils";
import { View } from "react-native";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import Toast from "../../components/Toast";
import styled from "styled-components/native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import DeviceNameContainer from "../../components/Layout/DeviceNameContainer";
import TextInput from "../../components/TextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import DemoOptions from "./DemoOptions";
import ScreenSelection from "./ScreenSelection";
import useGenericContentStore from "../../store/genericContent.store";
import useLoginStore from "../../store/useLogin.store";
import useMqttStore from "../../store/useMqtt.store";
import MqttSenderService from "../../services/mqtt/MqttSenderService";
import OtherDevicesLoader from "../../components/Loaders/OtherDevicesLoader";
import DemoOptionsLoader from "../../components/Loaders/DemoOptionsLoader";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100%;
`;

const DeviceName = (props: any) => {
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const { location } = props.route.params;
	const navigation = useNavigation<any>();
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [deviceError, setDeviceError] = useState(false);
	const [deviceMessage, setDeviceMessage] = useState("");
	const [devices, setDevices] = useState<any>([]);
	const [isFrontAvailable, setIsFrontAvailable] = useState(true);
	const [isLeftAvailable, setIsLeftAvailable] = useState(true);
	const [isRightAvailable, setIsRightAvailable] = useState(true);
	const [isRearAvailable, setIsRearAvailable] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [isLoaded, setIsLoaded] = useState(false);
	const [deviceChangeModal, setDeviceChangeModal] = useState(false);
	const setNumberOfScreens = useGenericContentStore((state: any) => state.setNumberOfScreens);
	const [selectedView, setSelectedView] = useState(DeviceSelectionPages.DemoOptions);
	const resetAvailability = () => {
		setIsFrontAvailable(true);
		setIsLeftAvailable(true);
		setIsRightAvailable(true);
	};
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const mqttSender: MqttSenderService = useMqttStore((state: any) => state.mqttSender);
	const mqttDevices = useMqttStore((state: any) => state.mqttDevices);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	const setDeviceName = useMqttStore((state: any) => state.setDeviceName);
	const deviceName = useMqttStore((state: any) => state.deviceName);

	// Validate from MQTT devices if device already exists.
	useEffect(() => {
		const currentDevices = mqttDevices;

		if (!mqttDevices.length) return;

		resetAvailability();

		currentDevices?.forEach((device: any) => {
			if (device.screenName === ProfileName.FrontScreen) {
				setIsFrontAvailable(false);
			} else if (device.screenName === ProfileName.LeftScreen) {
				setIsLeftAvailable(false);
			} else if (device.screenName === ProfileName.RightScreen) {
				setIsRightAvailable(false);
			} else if (device.screenName === ProfileName.RearScreen) {
				setIsRearAvailable(false);
			}
		});

		setDevices(currentDevices);
	}, [mqttDevices]);

	useEffect(() => {
		if (mqttSender) {
			setIsLoaded(true);
			mqttSender.discoverAccountDevices();
		}
	}, [mqttSender]);

	useEffect(() => {
		const oldProfileName = localStorage.getItem(AsyncStorageKeys.profileName);
		if (oldProfileName) {
			setDeviceName(oldProfileName);
		}
	}, []);

	const wording = {
		deviceHeader: globalAny.language.device_name_change,
		success: globalAny.language.device_change_success,
		confirm: globalAny.language.confirm,
	};

	const onDismissSnackBar = () => setVisible(false);

	const onBackPress = () => {
		setNumberOfScreens(3);
		if (location === Pages.settings) {
			settingsNavigate();
			return;
		}

		if (navigateEvent === Pages.codeSignIn) {
			codeSignInNavigate();
			return;
		}
		
		removeCache();
		// @ts-ignore
		navigation.reset({
			index: 0,
			routes: [
				{
					name: routeSignIn,
				},
			],
		});
	};

	const onClose = () => {
		setNumberOfScreens(3);
		if (location === Pages.settings) {
			// @ts-ignore
			navigation.navigate(routeHome);
			return;
		}

		removeCache();
		// @ts-ignore
		navigation.navigate(routeLogin);
	};

	const settingsNavigate = () => {
		navigation.navigate(routeNewSettings);
	};
	const codeSignInNavigate = () => {
		navigation.navigate(routeCodeSignIn);
	};

	const removeCache = () => {
		removeCacheItem(AsyncStorageKeys.email);
		removeCacheItem(AsyncStorageKeys.password);
		removeCacheItem(AsyncStorageKeys.consumerId);
		removeCacheItem(AsyncStorageKeys.sessionExpiry);
		removeCacheItem(AsyncStorageKeys.session);
		removeCacheItem(AsyncStorageKeys.refreshToken);
		removeCacheItem(AsyncStorageKeys.deviceId);
	};

	const onEnterDeviceName = (val: any) => {
		setDeviceName(val);
		if (val?.replace(/\s/g, "").length) {
			setDisabled(false);
			setDeviceError(false);
			setDeviceMessage("");
			return;
		}
		setDisabled(true);
	};

	const onAssignName = async () => {
		if (deviceName) {
			const exist = devices.find((device: any) => device.screenName === deviceName);
			if (exist) {
				setDeviceError(true);
				setToastMessage(globalAny.language.device_name_exist);
				setVisible(true);

				return;
			}
			onProceed(deviceName);
			return;
		}
		setDeviceError(true);
		setDeviceMessage(globalAny.language.device_name_required);
	};

	const onDeviceSelected = async (profileName: any) => {
		if (profileName) {
			onProceed(profileName);
		}
	};

	const redirectToHome = (profileName: any) => {
		if ((isLoggedIn && location !== Pages.settings && globalAny.profileName) || (location !== Pages.settings && profileName)) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [{
					name: routeHome
				}]
			});
		}
	};

	const updateDevice = (profileName: any, deviceId?: any) => {
		const deviceInfo = generateDeviceInfo(profileName, deviceId);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
		setDeviceName(profileName);
		location !== Pages.settings ? mqttSender.setDeviceBroadcast(deviceInfo) : mqttSender.setDeviceBroadcastAsSendOnly(deviceInfo);
		if (isStaging()) mqttSender.broadcastDevice();
	};

	const onProceed = async (profileName: any) => {
		if (location === Pages.settings) {
			const currentDeviceId = localStorage.getItem(AsyncStorageKeys.deviceId);
			updateDevice(profileName, currentDeviceId);
			setDeviceChangeModal(true);
			return;
		}

		setIsLoggedIn(true);
		updateDevice(profileName);
		redirectToHome(profileName);
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.profileName, deviceInfo.deviceName);
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const isInCarScreen = () => {
		const profileName = localStorage.getItem(AsyncStorageKeys.profileName);

		switch (profileName) {
			case ProfileName.FrontScreen:
			case ProfileName.LeftScreen:
			case ProfileName.RightScreen:
			case ProfileName.RearScreen:
				return true;
			default:
				return false;
		}
	};

	const isRenderLoader = () => {
		return (
			(location !== Pages.settings && !isLoaded) ||
			(location !== Pages.settings && selectedView === DeviceSelectionPages.ScreenSelectionLoader) ||
			!mqttSender
		);
	};

	const renderLoader = () => {
		if (
			(location !== Pages.settings && !isLoaded) ||
			(location !== Pages.settings && selectedView === DeviceSelectionPages.ScreenSelectionLoader) ||
			(location === Pages.settings && isInCarScreen())
		) {
			return <DemoOptionsLoader />;
		}

		return <OtherDevicesLoader />;
	};

	const isRenderSelection = () => {
		return (
			(location !== Pages.settings && selectedView === DeviceSelectionPages.DemoOptions) ||
			(location !== Pages.settings && selectedView === DeviceSelectionPages.ScreenSelection) ||
			(location === Pages.settings && isInCarScreen())
		);
	};

	const renderSelection = () => {
		if (location !== Pages.settings && selectedView === DeviceSelectionPages.DemoOptions)
			return <DemoOptions onPressLeftTitleIcon={onBackPress} onPressLeftIcon={onClose} setSelectedView={setSelectedView} />;

		if (location !== Pages.settings && selectedView === DeviceSelectionPages.ScreenSelection)
			return (
				<ScreenSelection
					onPressLeftTitleIcon={() => onBackPress()}
					onPressLeftIcon={onClose}
					isFrontAvailable={isFrontAvailable}
					isLeftAvailable={isLeftAvailable}
					isRightAvailable={isRightAvailable}
					isRearAvailable={isRearAvailable}
					setSelectedView={setSelectedView}
					onDeviceSelected={onDeviceSelected}
					isInCarScreen={isInCarScreen}
				/>
			);

		if (location === Pages.settings && isInCarScreen())
			return (
				<ScreenSelection
					onPressLeftTitleIcon={() => onBackPress()}
					isFrontAvailable={isFrontAvailable}
					isLeftAvailable={isLeftAvailable}
					isRightAvailable={isRightAvailable}
					isRearAvailable={isRearAvailable}
					setSelectedView={setSelectedView}
					onDeviceSelected={onDeviceSelected}
					isInCarScreen={isInCarScreen}
				/>
			);

		return <></>;
	};

	const renderScreen = () => {
		if (isRenderLoader()) return renderLoader();

		if (isRenderSelection()) return renderSelection();

		return (
			//@ts-ignore
			<StyledContainer>
				<TopAppbar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={globalAny.language.device_name}
					rightLogo={ridevueLogo}
					onPressLeftTitleIcon={() => onBackPress()}
					onPressLeftIcon={onClose}
					isSingleLogo={true}
				/>
				<DeviceNameContainer>
					{(location === Pages.settings && !isInCarScreen()) || location === Pages.main ? (
						<View style={{ marginBottom: "20px", marginTop: "60px" }}>
							<TextInput
								onChangeText={(event) => onEnterDeviceName(event)}
								componentTheme={ComponentThemeType.VinFast}
								label={location === Pages.main ? globalAny.language.device_name : globalAny.language.edit_device}
								error={deviceError}
								errorMessage={deviceMessage}
								autoFocus={true}
								isCounterLabel={true}
								maxLength={25}
							/>
						</View>
					) : (
						<></>
					)}

					{(location === Pages.settings && !isInCarScreen()) || location === Pages.main ? (
						<TouchableButton
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => onAssignName()}
							disabled={disabled}
						>
							{location === Pages.main ? globalAny.language.assign_name : globalAny.language.update}
						</TouchableButton>
					) : (
						<></>
					)}
				</DeviceNameContainer>
			</StyledContainer>
		);
	};

	const renderToastAndModal = () => {
		return (
			<>
				<Toast visible={visible} text={toastMessage} label={""} onDismissSnackBar={onDismissSnackBar}></Toast>
				{deviceChangeModal && (
					<CustomizeDialogBox
						Header={wording.deviceHeader}
						Body={wording.success}
						ButtonTextRight={wording.confirm}
						SingleButton={true}
						Width={680}
						Height={"auto"}
						TypeButtonColorRight={ComponentTypeEnum.Primary}
						onPressRight={settingsNavigate}
					/>
				)}
			</>
		);
	};

	return (
		<>
			{renderScreen()}
			{renderToastAndModal()}
		</>
	);
};

export default DeviceName;
