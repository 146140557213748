import * as React from "react";
import { TouchableHighlight } from "react-native";
import { PRESSED_BUTTON_COLOR } from "../../StyleHelpers";
import { useEffect, useState } from "react";
import { calculateDynamicHeight, calculateDynamicWidth } from "../../utils/Utils";

export default (props: any) => {
	const [width, setWidth] = useState(calculateDynamicWidth(props.width));
	const [height, setHeight] = useState(calculateDynamicHeight(props.height, props.width));
	const [showUnderLay, setShowUnderLay] = useState(false);
	/**
	 * Dynamic Resizing Function - Updates the dimensions of items when the screen size changes without having to reload
	 */
	useEffect(() => {
		setWidth(calculateDynamicWidth(props.width));
		setHeight(calculateDynamicHeight(props.height, props.width));
	}, [props.windowDimensions]);

	const defaultStyle = { width: width, height: height };
	const incomingStyle = Array.isArray(props.style) ? props.style : [props.style];
	if (props.subComponent)
		return (
			<TouchableHighlight
				onShowUnderlay={() => setShowUnderLay(true)}
				onHideUnderlay={() => setShowUnderLay(false)}
				{...props}
				style={[defaultStyle, ...incomingStyle]}
				underlayColor={PRESSED_BUTTON_COLOR}
				activeOpacity={0.6}
			>
				{props.subComponent(showUnderLay)}
			</TouchableHighlight>
		);
	else return <TouchableHighlight {...props} style={[defaultStyle, ...incomingStyle]} underlayColor={PRESSED_BUTTON_COLOR} activeOpacity={0.6} />;
};
