import { CyberSourceSetupPayerAuthentication } from "../models/CyberSourceSetupPayerAuthentication";
import { TaxAddress } from "../models/TaxAddress";
import { PAYMENT_BASEURL_V6, PAYMENT_LOCAL_V6, PAYMENT_BASEURL_V8 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const purchaseHistory = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "purchase/history", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const productPrice = async (parentProductId: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "product/" + parentProductId + "?SupportedCodecs=1%2C2", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const countryList = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/countries", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const regionListByCountry = async (countryCode: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/regions?countryCode=" + countryCode, { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

/**
 * Tax calculation
 * @param transactionId - string transaction Id
 * @param taxRequest - model tax Address request body
 * @returns - return calculated tax
 */
export const tax = async (transactionId: string, taxRequest: TaxAddress) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/" + transactionId + "/tax", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(taxRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Local environment tax calculation
 * @param transactionId - string transaction id
 * @param taxRequest - model tax Address request body
 * @returns - return calculated tax
 */
export const taxLocal = async (transactionId: string, taxRequest: TaxAddress) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_LOCAL_V6 + "avalara/" + transactionId + "/tax", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(taxRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Validation of address using Avalara
 * @param address - model address information request body
 * @returns - return valid address
 */
export const resolveAddress = async (address: TaxAddress) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/resolveaddress", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(address),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Local validation of address using Avalara
 * @param address - model address information request body
 * @returns - return valid address
 */
export const resolveAddressLocal = async (address: TaxAddress) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_LOCAL_V6 + "avalara/resolveaddress", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(address),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Service use to setup the 3ds
 * @param transactionId
 * @param payerAuthSetup
 * @returns
 */
export const cyberSourcePayerAuthSetup = (transactionId: string, payerAuthSetup: CyberSourceSetupPayerAuthentication) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "cybersource/auth/" + transactionId + "/setup", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(payerAuthSetup),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Service use to check enrollment the 3ds
 * @param transactionId
 * @param payerAuthCheck
 * @returns
 */
export const cyberSourcePayerAuthCheck = (transactionId: string, payerAuthCheck: CyberSourceSetupPayerAuthentication) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "cybersource/auth/" + transactionId + "/check", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(payerAuthCheck),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};
