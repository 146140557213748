import {
	StyledNarrowMain,
	StyledNarrowButtonMain,
	StyledNarrowButtonContainer,
	StyledNarrowFormContainer,
	StyledButton,
	StyledPrivacy,
	StyledTextInput,
	StyledFormContainer,
	StyledButtonMain,
	StyledRowButtonContainer,
	StyledNarrowButton,
} from "./styled";

export const SignInNarrowLoader = (props: any) => {
	return (
		/* @ts-ignore */
		<StyledNarrowMain>
			{/* @ts-ignore */}
			<StyledNarrowButtonMain>
				{/* @ts-ignore */}
				<StyledNarrowButtonContainer>
					<StyledNarrowButton />
					{!props?.isCodeSignIn ? <StyledNarrowButton /> : <></>}
					<StyledPrivacy />
				</StyledNarrowButtonContainer>
			</StyledNarrowButtonMain>

			{/* @ts-ignore */}
			<StyledNarrowFormContainer>
				<StyledTextInput />
				{!props?.isCodeSignIn ? <StyledTextInput /> : <></>}
			</StyledNarrowFormContainer>
		</StyledNarrowMain>
	);
};

export const SignInWideLoader = (props: any) => {
	return (
		<>
			{!props.privacyPolicyScreen && (
				<>
					{/* @ts-ignore */}
					<StyledFormContainer>
						<StyledTextInput />
						{!props?.isCodeSignIn ? <StyledTextInput /> : <></>}
					</StyledFormContainer>
					{/* @ts-ignore */}
					<StyledButtonMain>
						{/* @ts-ignore */}
						<StyledRowButtonContainer>
							<StyledButton />
							{!props?.isCodeSignIn ? <StyledButton /> : <></>}
						</StyledRowButtonContainer>
						<StyledPrivacy />
					</StyledButtonMain>
				</>
			)}
		</>
	);
};
