import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import TextInput from "../../components/TextInput";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import { globalAny } from "../../utils/Utils";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import { StyledRowButtonContainer } from "./styled";
import { CreateAccountNarrowScreen, CreateAccountWideScreen } from "./CreateAccountFormScreen";

export const RenderTextInput = (props: any) => (
	<>
		<TextInput
			componentTheme={ComponentThemeType.VinFast}
			placeholder={"your@email.com"}
			label={globalAny.language.enter_email_ca}
			onChangeText={props.emailOnChangeText}
			error={props.emailError}
			errorMessage={props.emailErrorMessage}
			autoFocus={true}
		/>
		<TextInput
			componentTheme={ComponentThemeType.VinFast}
			label={globalAny.language.enter_password}
			onChangeText={props.passowrdOnChangeText}
			onPressInputIcon={props.onPressInputIcon}
			inputIcon={props.inputIcon}
			passwordField={props.secureTextEntry}
			error={props.passwordError}
			errorMessage={props.passwordErrorMessage}
			
		/>
		<TextInput
			value={props.birthdayVal}
			keyboardType={props.keyboardType}
			componentTheme={ComponentThemeType.VinFast}
			label={globalAny.language.date_of_birth.replace("(MM/YYYY)", "")}
			placeholder={props.placeholder}
			error={props.birthdayError}
			errorMessage={props.birthdayErrorMessage}
			onChangeText={props.birthdayOnChangeText}
			onChange={props.birthdayOnchange}
			onSubmitEditing={props.birthdayOnSubmitEditing}
			onEndEditing={props.birthdayOnEndEditing}
			onBlur={props.onBlur}
		/>
	</>
);

export const RenderButton = (props: any) => (
	<>
		{props.screenDimensions === ScreenDimensions.narrow ? (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => props.onPressSubmit()}
					style={{ width: "100%" }}
					disabled={props.disabled}
					loadingIndicator={props.loadingIndicator}
				>
					{globalAny.language.submit}
				</TouchableButton>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Secondary}
					onPress={() => props.onBackPress()}
					style={{ width: "100%" }}
				>
					{globalAny.language.back}
				</TouchableButton>
			</StyledRowButtonContainer>
		) : (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Secondary}
					onPress={() => props.onBackPress()}
					style={{ width: "100%" }}
				>
					{globalAny.language.back}
				</TouchableButton>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => props.onPressSubmit()}
					style={{ width: "100%" }}
					disabled={props.disabled}
					loadingIndicator={props.loadingIndicator}
				>
					{globalAny.language.submit}
				</TouchableButton>
			</StyledRowButtonContainer>
		)}
	</>
);

const CreateAccount = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<CreateAccountNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.wide) {
			return (
				<MainWideWrapper>
					<CreateAccountWideScreen {...props} />
				</MainWideWrapper>
			);
		}

		return (
			<MainDefaultWrapper>
				<CreateAccountWideScreen {...props} />
			</MainDefaultWrapper>
		);
	};

	if(!props.screenDimensions) return <></>;

	return renderScreenDimensions();
};
export default CreateAccount;
