import { black, spacing_l } from "../../StyleHelpers";
import styled from "styled-components/native";
import { View } from "react-native";
import CardGridPlaylist from "../../components/CardGridPlaylist";
import AggregatePageLoader from "../../components/Loaders/AggregatePageLoader";

const StyledContainer = styled.View<any>`
	padding: ${spacing_l} ${(props) => props.styledTDP.paddingLeft} 8% ${(props) => props.styledTDP.paddingLeft};
	height: 100%;
	z-index: -1;
`;

const Extras = (props: any) => {
	return (
		<View style={{ flex: 1, backgroundColor: black }}>
			{/* @ts-ignore */}
			<StyledContainer styledTDP={props.styledTDP}>
				<CardGridPlaylist
					data={props?.data}
					hideTitle={true}
					isClips={true}
					isAssignToScreen={props.id === 1}
					parentProductId={props.parentProductId}
					isRedeem={props.redeem}
					isBorderClips={true}
					isExtras={true}
				/>

				{props.extraLoader && <AggregatePageLoader isVam={true} slice={props.gridSize} />}
			</StyledContainer>
		</View>
	);
};

export default Extras;
