import { white_smoke, dark_gray } from "../../StyleHelpers";
import styled from "styled-components/native";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/autoplay/autoplay.scss"; // Autoplay module
import { Pagination, Autoplay } from "swiper";
import { StyleSheet } from "react-native";
import { PageScreenNavigationProp, routePage, routeTitleDetails } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import Image from "../../components/Image/Image";
import { useState } from "react";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { calculateImageWidth, isFord } from "../../utils/Utils";
import useGenericContentStore from "../../store/genericContent.store";
import { Pages } from "../../Types";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";
interface Props {
	heroBanner?: any;
	isPreview?: boolean;
}

const StyledMain = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FeatureText = styled.View<any>`
	position: absolute;
	top: 1;
	left: 1;
	display: flex;
	width: 50%;
	height: calc(100% - 2px);
	aspect-ratio: 4/1;
	padding: 2.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1.5rem;
	flex-shrink: 0;
	border-radius: 16px;
	border-color: transparent;
	background-image: linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.00) 100%);
	z-index: 9;
`;

const FeatureLineOne = styled.Text<any>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${(props) => props?.clamp};
	align-self: stretch;
	overflow: hidden;
    text-overflow: ellipsis;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30);
	font-family: 'Nunito';
	font-size: ${(props) => props?.fontSize};
	font-weight: 500;
	font-style: normal;
	line-height: ${(props) => props?.lineHeight};
	color: ${(props) => props?.hexCode};
`;

const FeatureLineTwo = styled.Text<any>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${(props) => props?.clamp};
	align-self: stretch;
	overflow: hidden;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30);
	font-family: 'Nunito';
	font-size: ${(props) => props?.fontSize};
	font-weight: 500;
	font-style: normal;
	line-height: ${(props) => props?.lineHeight};
	color: ${(props) => props?.hexCode};
`;

const TextContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

const HeroBanner = (props: Props) => {
	const delay = 8000;
	const styleHelper = useStyleHelpers();
	const screenDimension = useDimensions();
	const navigationTitleScreen = useNavigation<any>();
	const navigationPageScreen = useNavigation<PageScreenNavigationProp>();
	const [borderEvent, setBorderEvent] = useState(false);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const dimensions = useGenericContentStore((state: any) => state.dimensions);
	const isNarrow = () => screenDimension === ScreenDimensions.narrow;

	const calculateItemsByWidth = () => {

		if (!styleHelper?.banner.widthInPixel) return 1.83;

		return (dimensions.window.width / styleHelper?.banner.widthInPixel);
	}
	
	const onPress = (navigationLinkType: string, navigationLink: any) => {
		if (props?.isPreview) setNavigateEvent(Pages.preview);
		setSelectedTabs(0);
		switch(navigationLinkType) {
			case 'ParentProduct':
				navigationTitleScreen.push(routeTitleDetails, props?.isPreview ? { parentProductId: navigationLink, preview: true } : { parentProductId: navigationLink });
				break;
			case 'Page':
				navigationPageScreen.push(routePage, { uniqueId: navigationLink });
				break;
		}
	};

	const convertARGBtoRGBA = (code: any) => {
		const hashtagLessCode = code.replace('#', '');
		const rgb = hashtagLessCode.substring(2);
		const alpha = hashtagLessCode.substring(0, 2);

		return `#${rgb}${alpha}`;
	};

	const renderFeatureText = (obj: any) => {
		const featureText = obj.featureText.filter((feature: any) => feature.text);
		const stt = obj?.sttUrl;
		let lineOne: any;
		let lineTwo: any;

		if (!featureText.length && !stt) return <></>;
		
		lineOne = featureText.find((feature: any) => feature.name === 'Line1');
		lineTwo = featureText.find((feature: any) => feature.name === 'Line2');

		return (
			<FeatureText height={styleHelper?.banner.height}>
				{/* @ts-ignore */}
				<TextContainer>
					{stt
						? <Image
								source={{
									uri: `${stt}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
								}}
								resizeMode="contain"
								style={styles.stt}
							/>
						: 	<FeatureLineOne 
								clamp={styleHelper?.banner.lineOne.clamp}
								fontSize={styleHelper?.banner.lineOne.fontSize}
								lineHeight={styleHelper?.banner.lineOne.lineHeight}
								hexCode={convertARGBtoRGBA(lineOne.hexCode)}
							>
								{lineOne.text}
							</FeatureLineOne>
					}
					{lineTwo 
						? <FeatureLineTwo 
							clamp={styleHelper?.banner.lineTwo.clamp}
							fontSize={styleHelper?.banner.lineTwo.fontSize}
							lineHeight={styleHelper?.banner.lineTwo.lineHeight}
							hexCode={convertARGBtoRGBA(lineTwo.hexCode)}
						>
							{lineTwo.text}
						</FeatureLineTwo> 
					: <></>}
				</TextContainer>
			</FeatureText>
		);
	};

	const getHeight = () => isNarrow() ? styleHelper?.banner.height : 'auto';

	return (
		//@ts-ignore
		<StyledMain>
			<Swiper
				style={{ width: isFord() ? '175vw' : '100vw'}}
				slidesPerView={isNarrow() ? calculateItemsByWidth() : 1}
				centeredSlides={true}
				loop={isNarrow()}
				spaceBetween={isNarrow() ? 16 : 1}
				pagination={{
					clickable: true,
				}}
				initialSlide={isNarrow() && props?.heroBanner?.length > 1 ? 2 : 1}
				allowTouchMove={true}
				autoplay={{
					delay: delay,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Pagination]}
			>
				{props?.heroBanner?.map((obj: any, index: number) => {
					return (
						<SwiperSlide key={`hero-banner${obj.featureId}`} 
							style={{paddingLeft: index === 0 && !isNarrow() ? styleHelper?.paddingStart : ''}} tabIndex={index}
						>
							<TouchableOpacity
								activeOpacity={1}
								onPress={() => onPress(obj?.navigationLinkType, obj?.navigationLink)}
								onMouseEnter={() => setBorderEvent(true)}
								onMouseLeave={() => setBorderEvent(false)}
								style={{width: styleHelper?.banner.width, height: 'auto', aspectRatio: '4/1'}}
							>
								{renderFeatureText(obj)}
								<Image
									source={{
										uri: `${obj?.featureImageUrl}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
									}}
									resizeMode="cover"
									style={[{width: styleHelper?.banner.width, height: getHeight(), aspectRatio: '4/1'}, borderEvent ? styles.onHover : styles.onLeave]}
								/>
							</TouchableOpacity>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</StyledMain>
	);
};

export default HeroBanner;

const styles = StyleSheet.create({
	stt: {
		width: '31.0625rem',
		height: '5.0625rem',
		flexShrink: 0
	},
	onHover: {
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray
	},
	onLeave: {
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray
	},
});
