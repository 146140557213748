import styled from "rn-css";
import { TouchableWithoutFeedback } from "react-native";

interface Props {
	onPress?: () => void;
}

const StyledNerdButton = styled.View`
	position: absolute;
	background-color: transparent;
	width: 60px;
	height: 60px;
	bottom: 0;
	right: 0;
`;

const NerdButton = (props: Props) => {
	return (
		<TouchableWithoutFeedback onPress={props.onPress}>
			<StyledNerdButton></StyledNerdButton>
		</TouchableWithoutFeedback>
	);
};

export default NerdButton;
