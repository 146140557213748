import { StyledMainWide, StyledMainWrapper, StyledMainContainer } from "./Styled";

const MainWideWrapper = ({ children }: any) => {
	return (
		//@ts-ignore
		<StyledMainWrapper>
			{/* @ts-ignore */}
			<StyledMainContainer>
				{/* @ts-ignore */}
				<StyledMainWide>{children}</StyledMainWide>
			</StyledMainContainer>
		</StyledMainWrapper>
	);
};

export default MainWideWrapper;
