import {
	StyledButtonContainer,
	StyledButtonNarrowContainer,
	StyledMainNarrow,
	StyledTextContainer,
	StyledTextNarrowContainer,
} from "./styled";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";
import { RenderButton, RenderTextInput } from "./CodeSignIn";

export const CodeSignInScreen = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledTextContainer>
				<RenderTextInput {...props} />
			</StyledTextContainer>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				<RenderButton {...props} />
				<PrivacyPolicyButton removeFixedPosition={true} />
			</StyledButtonContainer>
		</>
	);
};

export const CodeSignInNarrowScreen = (props: any) => {
	return (
		//@ts-ignore
		<StyledMainNarrow>
			{/* @ts-ignore */}
			<StyledButtonNarrowContainer>
				<RenderButton {...props} />
				<PrivacyPolicyButton removeFixedPosition={true} />
			</StyledButtonNarrowContainer>
			{/* @ts-ignore */}
			<StyledTextNarrowContainer>
				<RenderTextInput {...props} />
			</StyledTextNarrowContainer>
		</StyledMainNarrow>
	);
};


