import styled, { css } from "styled-components/native";
import { royal_blue_hex } from "../../StyleHelpers";

export const StyledContainer = styled.View`
	display: flex;
	width: 21.5rem;
	height: 7.5rem;
	padding: 1.5rem;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	background: ${royal_blue_hex};
`;

export const StyledText = styled.Text<any>`
	color: #fff;
	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
		`}

	font-size: 32px;
	font-family: "Nunito";
	font-style: normal;
	line-height: 28px;
`;

export const StyledCodeWrapper = styled.View`
	display: flex;
	width: 21.5rem;
	height: 7.5rem;
	padding: 1.5rem;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	flex-shrink: 0;
	border-radius: 16px;
	background: rgba(255, 255, 255, 0.2);
`;

export const StyledCodeText = styled.Text`
	color: #fff;
	font-family: "Nunito";
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 56% */
	letter-spacing: 2px;
`;

export const StyledSpinnerWrapper = styled.View`
	margin-bottom: 15px;
`;