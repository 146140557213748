import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import { PrivacyNarrowScreen, PrivacyWideScreen } from "./PrivacyPolicyScreen";

const PrivacyText = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<PrivacyNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.wide) {
			return (
				<MainWideWrapper>
					<PrivacyWideScreen {...props} />
				</MainWideWrapper>
			);
		}

		return (
			<MainDefaultWrapper>
				<PrivacyWideScreen {...props} />
			</MainDefaultWrapper>
		);
	};

	return renderScreenDimensions();
};
export default PrivacyText;
