import useGenericContentStore from "../../store/genericContent.store";
import useDimensions from "../../hooks/useDimensions";
import { TabsViewDefault, TabsViewNarrow } from "./TabsLayout";
import { ScreenDimensions } from "../../utils/enum";
import useStyleHelpers from "../../hooks/useStyleHelpers";

interface Props {
	children: any;
	tabsToRender: any;
	paddingLeft?: any;
	disabled?: any;
	isSettings?: boolean;
	isTDP?: boolean;
}

const TabsView = (props: Props) => {
	const selectedTabs = useGenericContentStore((state: any) => state.selectedTabs);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const styledTabsView = styleHelper?.tabsView;

	return (
		<>
			{screenDimensions === ScreenDimensions.narrow ? (
				<TabsViewNarrow
					{...props}
					selectedTabs={selectedTabs}
					setSelectedTabs={setSelectedTabs}
					screenDimensions={screenDimensions}
					styledTabsView={styledTabsView}
				/>
			) : (
				<TabsViewDefault
					{...props}
					selectedTabs={selectedTabs}
					setSelectedTabs={setSelectedTabs}
					screenDimensions={screenDimensions}
					styledTabsView={styledTabsView}
				/>
			)}
		</>
	);
};

export default TabsView;
