import { ActivityIndicator, ProgressBar } from "react-native-paper";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import { StyledCodeText, StyledCodeWrapper, StyledContainer, StyledSpinnerWrapper, StyledText } from "./styled";
interface Props {
	title: string;
	hideButton?: boolean;
	counter?: number;
	disabled: boolean;
	onPress: () => void;
}

const GradientCodeComponent = (props: Props) => {
	return (
		<>
			{!props?.hideButton ? (
				<TouchableOpacity onPress={props.onPress} disabled={props.disabled}>
					{/* @ts-ignores */}
					<StyledContainer>
						{/* @ts-ignores */}
						<StyledText disabled={props.disabled}>{props.title}</StyledText>
					</StyledContainer>
				</TouchableOpacity>
			) : (
				// @ts-ignores
				<StyledCodeWrapper>
					{!props.title ? (
						// @ts-ignores
						<StyledSpinnerWrapper>
							<ActivityIndicator size={"large"} color={"white"} />
						</StyledSpinnerWrapper>
					) : (
						<></>
					)}
					{/* @ts-ignores */}
					{props.title ? <StyledCodeText>{props.title}</StyledCodeText> : <></>}
					<ProgressBar
						progress={props.counter ?? 0}
						color={"#008131"}
						style={{ borderRadius: 4, height: 16, width: 300, backgroundColor: "#002E11" }}
					/>
				</StyledCodeWrapper>
			)}
		</>
	);
};

export default GradientCodeComponent;
