import styled, { css } from "styled-components/native";

interface Props {
	children: any;
	spaceBetween?: boolean;
	contentCenter?: boolean;
	justifyContentSpaceBetween?: boolean;
}

const Styled = styled.View<Props>`
	flex-direction: row;
	width: 100%;

	/* ${(prop) =>
		prop.justifyContentSpaceBetween &&
		css`
			justify-content: space-between;
		`} */
	${(props) =>
		props.contentCenter &&
		css`
			justify-content: center;
		`}
`;

const FlexRow = (props: Props) => {
	return (
		// @ts-ignore
		<Styled {...props}>{props.children}</Styled>
	);
};

export default FlexRow;
