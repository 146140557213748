export const DARK = {
    baseBackgroundColor: '#000000',
    baseSubBackgroundColor: '#141414',
    baseBorderColor: 'rgb(85, 84, 84)',
    baseSubBorderColor: 'rgb(85, 84, 84)',
    baseTextColor: '#ffffff',
    baseSubTextColor: '#f2f2f2',
    baseInvertedTextColor: '#000000',
    baseInvertedSubTextColor: '#808080',
    baseLeftGradientColor: '#000000',
    baseRightGradientColor: '#000000',
    baseTopGradientColor: '#000000',
    baseBottomGradientColor: '#000000',
    navBar: {
        icons: {},
    }, // Specific Styling for navBar
    sideBar: {
        icons: {},
    }, // Specific Styling for sideBar
    button: {
        primary: {
            icons: {},
            textColor: {},
            subTextColor: {},
        }
    }, // Specific Styling for button
    title: {}, // Specific Styling for title
    packshot: {}, // Specific Styling for packshot
};