import { globalAny } from "../../utils/Utils";
import LegalTerms from "./LegalTerms";
import useDimensions from "../../hooks/useDimensions";

interface Props {
	showButton?: boolean;
	bodyText?: string;
	onPressNext?: () => void;
	onPressBack?: () => void;
	// onPressPromotionalTerms?: () => void;
	onPressTermsOfUse?: () => void;
	onPressTermsOfService?: () => void;
	onPressPrivacy?: () => void;
	isSettings?: boolean;
	isSkipDataManagement?:any;
}

const LegalTermsConditions = (props: Props) => {
	const screenDimensions = useDimensions();
	const createAccountButtonData = [
		// {
		// 	title: globalAny.language.promotional_terms,
		// 	onPress: props.onPressPromotionalTerms,
		// },
		{ title: globalAny.language.terms_of_use, onPress: props.onPressTermsOfUse },
		{ title: globalAny.language.terms_of_service, onPress: props.onPressTermsOfService },
	];

	return (
		<LegalTerms
			title={globalAny.language.legal_terms}
			description={props.bodyText}
			onPressPrivacy={props.onPressPrivacy}
			onPressNext={props.onPressNext}
			onBackPress={props.onPressBack}
			sectionButtons={createAccountButtonData}
			screenDimensions={screenDimensions}
			isSettings={props?.isSettings}
			isSkipDataManagement={props.isSkipDataManagement}
		/>
	);
};

export default LegalTermsConditions;
