import { RegisterModel } from "../models/RegisterModel";
import { PasswordReset, PasswordChange } from "../models/PasswordReset";
import VoucherModel from "../models/VoucherModel";
import { Code_OK, TEMPORARY_PASSWORD_OK } from "../networking/networking";
import { USER_BASEURL_V6, USER_BASEURL_V8, USER_BASEURL_V9 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders, getVoucherHeaders, getHeadersWithApplicationJSON } from "../utils/endpoint/header";
import { globalAny, hexValueConverter, getDateTimePlusHour } from "../utils/Utils";
import { AsyncStorageKeys } from "../Types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AppLogger } from "../utils/AppLogger";

const loginStorage = async (result: any) => {
	setTimeout(async () => await renewSession(), 7200000);
	AppLogger.log("Session renewal timer started in background");

	globalAny.SESSIONKEY = result.session;
	globalAny.CONSUMERID = result.consumerId;
	localStorage.setItem(AsyncStorageKeys.foreverData, 'heretostay');
	localStorage.setItem(AsyncStorageKeys.session, result.session);
	localStorage.setItem(AsyncStorageKeys.sessionExpiry, result.sessionExpiry);
	localStorage.setItem(AsyncStorageKeys.refreshToken, result.refreshToken);
	localStorage.setItem(AsyncStorageKeys.consumerId, result.consumerId);
	localStorage.setItem(AsyncStorageKeys.consumerDeviceId, result.consumerDeviceId);
	if (result.activeSubscription) localStorage.setItem(AsyncStorageKeys.subscription, JSON.stringify(result.activeSubscription));
};

export const login = async (email: string | null, password: string | null) => {
	const hardwareId = localStorage.getItem(AsyncStorageKeys.hwid) ?? "";
	const deviceIdentifier = localStorage.getItem(AsyncStorageKeys.deviceIdentifier) ?? "WEB-MERCEDES";
	let postData = {
		oAuthToken: "",
		deviceIdentifier,
		deviceModel: hardwareId ? 'mercedez-benz' : 'WEB',
		hardwareId,
		email: email,
		password: password,
		isHwidEncrypted: localStorage.getItem(AsyncStorageKeys.hwid) !== null
	};

	let data = {
		method: "POST",
		body: JSON.stringify(postData),
		headers: {
			ApiKey: globalAny.APIKEY,
			"content-type": "application/json",
			Token: "",
		},
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V8 + "login", data)
			.then((resJson) => {
				if (resJson.responseCode === Code_OK || resJson.responseCode === TEMPORARY_PASSWORD_OK) {
					// Start session renewal timer in background, executes renewal after 2 hours
					loginStorage(resJson);
					resolve(resJson);
				} else {
					reject(new Error(resJson.responseCode));
				}
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const codeLogin = async (shortCode: string | null) => {
	const hardwareId = localStorage.getItem(AsyncStorageKeys.hwid) ?? "";
	const deviceIdentifier = localStorage.getItem(AsyncStorageKeys.deviceIdentifier) ?? "WEB-MERCEDES";
	let postData = {
		deviceIdentifier,
		deviceModel: hardwareId ? 'mercedez-benz' : 'WEB',
		hardwareId,
		shortCode,
		isHwidEncrypted: localStorage.getItem(AsyncStorageKeys.hwid) !== null
	};

	let data = {
		method: "POST",
		body: JSON.stringify(postData),
		headers: {
			ApiKey: globalAny.APIKEY,
			"content-type": "application/json",
			Token: "",
		},
	};

	let promise = new Promise(async (resolve, reject) => {
		fetchWrapper(USER_BASEURL_V8 + "login/shortcode", data)
			.then((resJson) => {
				if (resJson.responseCode === Code_OK) {
					// Start session renewal timer in background, executes renewal after 2 hours
					loginStorage(resJson);
					resolve(resJson);
				} else {
					reject(resJson.responseCode);
				}
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const linkedDevice = async () => {
	const hardwareId = localStorage.getItem(AsyncStorageKeys.hwid) ?? "";
	const deviceIdentifier = localStorage.getItem(AsyncStorageKeys.deviceIdentifier) ?? "WEB-MERCEDES";
	let postData = {
		deviceIdentifier,
		deviceModel: hardwareId ? 'mercedez-benz' : 'WEB',
		hardwareId,
		isHwidEncrypted: localStorage.getItem(AsyncStorageKeys.hwid) !== null
	};

	let data = {
		method: "POST",
		body: JSON.stringify(postData),
		headers: {
			ApiKey: globalAny.APIKEY,
			"content-type": "application/json",
			Token: "",
		},
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V8 + "login/linkeddevice", data)
			.then((resJson) => {
				if (resJson.responseCode === Code_OK) {
					// Start session renewal timer in background, executes renewal after 2 hours
					loginStorage(resJson);
					resolve(resJson);
				} else {
					resolve(resJson.responseCode);
				}
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const renewSession = async () => {
	const currentRefreshToken = await AsyncStorage.getItem(AsyncStorageKeys.refreshToken);
	return new Promise((resolve, reject) => {
		if (currentRefreshToken == null) {
			reject("Cannot renew an empty session");
		}
		fetchWrapper(USER_BASEURL_V6 + "session/refresh", {
			method: "POST",
			headers: getHeadersWithApplicationJSON(),
			body: JSON.stringify({ refreshToken: currentRefreshToken }),
		})
			.then((responseJson) => {
				if (responseJson.session) {
					globalAny.SESSIONKEY = responseJson.session; // Retained for backwards compatibility

					AsyncStorage.setItem(AsyncStorageKeys.session, responseJson.session);
					AsyncStorage.setItem(AsyncStorageKeys.sessionExpiry, responseJson.sessionExpiry);
					AsyncStorage.setItem(AsyncStorageKeys.refreshToken, responseJson.refreshToken);
					AsyncStorage.setItem(AsyncStorageKeys.idleExpiry, getDateTimePlusHour().toString());

					// Start session renewal timer in background, executes renewal after 2 hours
					setTimeout(async () => await renewSession(), 7200000);
					AppLogger.log("Session renewal timer started in background");

					resolve(responseJson);
				} else {
					reject("failed to renew session");
				}
			})
			.catch((err) => reject(err));
	});
};

export const registerAccount: any = async (model: RegisterModel, voucherCode: string) => {
	AppLogger.log(model);
	let data = {
		method: "POST",
		body: JSON.stringify(model),
		headers: {
			ApiKey: globalAny.APIKEY,
			"content-type": "application/json",
		},
	};

	const promise = new Promise(async (resolve, reject) => {
		try {
			const result = await fetchWrapper(USER_BASEURL_V8 + "register", data);
			if (result.responseCode === Code_OK) {
				loginStorage(result);
			}

			if (result.responseCode !== Code_OK) {
				return resolve(result);
			}

			const voucher = await fetchWrapper(USER_BASEURL_V9 + "voucher/" + voucherCode, {
				method: "POST",
				headers: getVoucherHeaders(result.session),
			});

			if (voucher.responseCode !== Code_OK) {
				return resolve({ errorVoucher: true });
			}

			return resolve(result);
		} catch (ex) {
			AppLogger.error("an error has occurred while creating account: ", ex);
			reject(ex);
		}
	});

	return promise;
};

export const registerAccountNoVoucherNeeded: any = async (model: RegisterModel, voucherCode: string) => {
	let data = {
		method: "POST",
		body: JSON.stringify(model),
		headers: {
			ApiKey: globalAny.APIKEY,
			"content-type": "application/json",
		},
	};

	const promise = new Promise(async (resolve, reject) => {
		try {
			const result = await fetchWrapper(USER_BASEURL_V8 + "register", data);
			if (result.responseCode === Code_OK) {
				loginStorage(result);
			}

			if (result.responseCode !== Code_OK) {
				return resolve(result);
			}

			return resolve(result);
		} catch (ex) {
			AppLogger.error("an error has occurred while creating account: ", ex);
			reject(ex);
		}
	});

	return promise;
};

export const validateVoucher = async (voucher: string) => {
	let promise = new Promise((resolve, reject) => {
		//TODO update deviceModel
		fetchWrapper(USER_BASEURL_V6 + "voucher/" + voucher + `?deviceModel=${localStorage.getItem(AsyncStorageKeys.hwid) ? "mercedez-benz" : "WEB"}`, { headers: getHeaders() })
			.then((resJson) => {
				let response = resJson.response.split("");
				response = response.filter((a: string) => a !== "-");
				let values = response.map((x: string) => hexValueConverter(x));
				let hexValue = values.reduce((a: number, b: number) => a + b);
				const result: VoucherModel = {
					response: hexValue % 2 == 0,
					responseCode: resJson.errorCode,
				};
				resolve(result);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const redeemVoucherCode = async (voucherCode: string, session: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V9 + "voucher/" + voucherCode, { method: "POST", headers: getVoucherHeaders(session) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const sendTemporaryPassword = async (email: string) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "password?email=" + email, { method: "POST", headers: getHeaders() })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const verifyPassword = async (tempPassword: string) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "password/verify/" + tempPassword, { method: "POST", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const resetTemporaryPassword = async (PasswordReset: PasswordReset) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "temporarypasswordcode", { method: "PATCH", headers: getHeaders(true), body: JSON.stringify(PasswordReset) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const resetCurrentPassword = async (PasswordChange: PasswordChange) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "password", { method: "PATCH", headers: getHeaders(true), body: JSON.stringify(PasswordChange) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const logoutDevice = async () => {
	const consumerDeviceId = await AsyncStorage.getItem(AsyncStorageKeys.consumerDeviceId);

	let data = {
		method: "PUT",
		headers: getHeaders(true),
	};

	let promise = new Promise(async (resolve, reject) => {
		fetchWrapper(USER_BASEURL_V8 + `devices/${consumerDeviceId}/logout`, data)
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};

export const renewVoucher = async (session: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V9 + "voucher/", { method: "PATCH", headers: getVoucherHeaders(session) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err.message)));
	});
	return promise;
};