import styled from "styled-components/native";
import { spacing_l } from "../../StyleHelpers";

export const StyledScreenContainerView = styled.View`
	margin: ${spacing_l} 0 0 0;
	border: none;
`;

// @ts-ignore
const ScreenManagerContainer = ({ children }) => {
	return (
		// @ts-ignores
		<StyledScreenContainerView>{children}</StyledScreenContainerView>
	);
};

export default ScreenManagerContainer;
