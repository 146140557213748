import SettingsContainer from "../../components/Layout/SettingsContainer";
import styled from "styled-components/native";
import { globalAny } from "../../utils/Utils";
import { View } from "react-native";
import GradientCodeComponent from "../../components/GenerateCodeComponent";
import { useEffect, useState } from "react";
import { signInCode } from "../../services/accountService";
import LabelContainer from "../../components/SettingsComponent/SettingsLabelContainer";


const StyledTextContainer = styled.View<any>`
	width: 45%;
	margin-bottom: 1rem;
`;

const StyledContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
`;

const SettingsAddScreen = (props: any) => {
	const [isGenerateCode, setIsGenerateCode] = useState(false);
	const [generateCode, setGenerateCode] = useState<any>();
	const [counter, setCounter] = useState(1);
	const [disabled, setDisabled] = useState(false);
	const [timer, setTimer] = useState(false);

	const onGenerateCode = async () => {
		setDisabled(true);
		setIsGenerateCode(true);
		const setSignInCode: any = await signInCode();
		if (setSignInCode.code) {
			setTimer(true);
			setGenerateCode(setSignInCode.code);
			setDisabled(false);
			return;
		}
		setDisabled(false);
	};

	useEffect(() => {
		if (counter > 0 && timer) {
			setTimeout(() => setCounter(counter - 0.017), 1000);
		} else {
			setCounter(1);
			setTimer(false);
			setIsGenerateCode(false);
			setGenerateCode("");
		}
	}, [counter, timer]);

	const renderTitle = (title: string, desc: string, subDesc?: string) => {
		return (
			// @ts-ignores 
			<StyledTextContainer {...props}>
				<LabelContainer header={title} body={desc} footer={subDesc} onClickableFooter={false} isFooterIcon={false} {...props} />
			</StyledTextContainer>
		);
	};

	return (
		<>
			{/* @ts-ignores */}
			<SettingsContainer styledSettings={props.styledSettings}>
				{/* @ts-ignores */}
				<StyledContainer>
					{renderTitle(globalAny.language.sign_in_with_code, globalAny.language.sign_in_with_code_desc)}
					<View>
						<GradientCodeComponent
							title={isGenerateCode ? generateCode : globalAny.language.generate_code ?? ""}
							onPress={() => onGenerateCode()}
							hideButton={isGenerateCode}
							counter={counter}
							disabled={disabled}
						/>
					</View>
				</StyledContainer>
			</SettingsContainer>
			{/* @ts-ignores */}
			{/* <SettingsContainer> */}
			{/* @ts-ignores */}
			{/* <StyledContainer>
					{renderTitle(globalAny.language.download_app, globalAny.language.download_app_desc, " www.companionapp.ridevu.com")}
					<View style={{ paddingBottom: 30 }}>
						<Image source={{ uri: IosQr }} width={160} height={160} resizeMode="contain" />
					</View>
				</StyledContainer> */}
			{/* </SettingsContainer> */}

			{/* <SettingsContainer> */}
			{/* @ts-ignores */}
			{/* <StyledContainer>
					{renderTitle(globalAny.language.download_app_android, globalAny.language.download_app_desc, " www.companionapp.ridevu.com")}
					<View style={{ paddingBottom: 30 }}>
						<Image source={{ uri: AndroidQr }} width={160} height={160} resizeMode="contain" />
					</View>
				</StyledContainer> */}
			{/* </SettingsContainer> */}
		</>
	);
};

export default SettingsAddScreen;
