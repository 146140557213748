import StyledText from "./StyledText";
import HeaderTextProps from "./HeaderTextProps";

const H3 = (props: HeaderTextProps) => {
	return (
		// @ts-ignores
		<StyledText fontSize={30} fontWeight={props.fontWeight} fontColor={props.fontColor}>
			{props.children}
		</StyledText>
	);
};

export default H3;
