import styled, { css } from "styled-components/native";
import styledRN from "rn-css";
import { Modal as ModalPaper } from "react-native-paper";
import { sm, md, lg, xl, x2l, white_smoke } from "../../StyleHelpers";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";

interface StyledProps {
	isTermAndService: boolean;
}

interface Props {
	show?: boolean;
	step?: number;
}

interface TextProps {
	bold?: boolean;
	fontWeight?: number;
	underline?: boolean;
}

export const StyledContainer = styled.View<StyledProps>`
	background-color: rgb(20, 20, 20);

	${(props) =>
		!props.isTermAndService &&
		css`
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
		`}
`;

export const StyledFormContainer = styledRN.View<any>`
	display: ${(props) => (props.isShow ? "block" : "none")};
	padding-top: 200px;
	padding-bottom: 60px;
	width: 56vw;
	z-index: 999;
	
	@media (min-width: 100) {
		width: 98vw;
	}

	@media (min-width: ${sm}) {
		width: 98vw;
	}

	@media (min-width: ${md}) {
		width: 86vw;
	}

	@media (min-width: ${lg}) {
		width: 76vw;
	}

	@media (min-width: ${xl}) {
		width: 66vw;
	}

	@media (min-width: ${x2l}) {
		width: 56vw;
	}
`;

export const StyledButtonContainer = styledRN.View`
	margin-bottom: 20px;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 100) {
		width: 98vw;
	}

	@media (min-width: ${sm}) {
		width: 96vw;
		bottom:5%;
	}

	@media (min-width: ${md}) {
		width: 86vw;
	}

	@media (min-width: ${lg}) {
		width: 76vw;
	}

	@media (min-width: ${xl}) {
		width: 66vw;
	}

	@media (min-width: ${x2l}) {
		width: 56vw;
	}
	@media (min-width: 1728) {
		width: 56vw;
		margin-top: 100px;
	}
`;

export const StyledButton = styled.View`
	width: 49%;
`;

export const StyledModal = styled(ModalPaper)`
	background-color: rgba(0, 0, 0, 0.9);
	height: 100%;
	width: 100%;
	align-items: center;
	position: fixed;
	z-index: 1005;
`;

export const StyledSubmitButton = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
`;

//Card Details
export const StyledCardContainer = styled.View`
	margin-top: 40px;
	gap: 1rem;
`;

export const StyledNameContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const StyleHalfInput = styled.View`
	width: 49%;
`;

//Billing Details
export const StyledBillingContainer = styled.View<Props>`
	margin-top: 40px;

	${(props) =>
		props.show &&
		css`
			display: block;
		`}

	${(props) =>
		!props.show &&
		css`
			display: none;
		`}
`;

export const StyledDropdownContainerFirst = styled.View`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
`;

export const StyledDropdownContainerSecond = styled.View`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: -100;
`;

export const StyleTextInput = styled.View`
	width: 100%;
`;

export const StyleTextContainer = styled.View`
	gap: 1rem;
	z-index: 999;
`;

//Purchase Options
export const StyledPurchaseContainer = styled.View`
	margin-top: 40px;
	display: flex;
	flex-direction: row;
	height: 400;
`;

export const StyledDetailsContainer = styled.View`
	width: 75%;
	overflow-y: scroll;
`;

export const StyledTitleContainer = styled.View`
	display: flex;
	flex-direction: column;
`;

export const StyledPackshotContainer = styled.View`
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: end;
`;

export const StyledDetails = styled.View`
	width: 80%;
`;

export const StyledText = styled.Text<TextProps>`
	font-family: ${FONT_FAMILY_DEFAULT};
	color: ${white_smoke};

	${(props) =>
		props.bold &&
		css`
			font-weight: 700;
			font-size: 25px;
		`}

	${(props) =>
		props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
			font-size: 25px;
		`}

    ${(props) =>
		props.underline &&
		css`
			text-decoration: underline;
			text-decoration-color: ${white_smoke};
		`}
`;

export const StyledTitle = styledRN.Text`
	font-size: 50px;
	line-height: 60px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (min-width:  ${sm}) {
		width: 420px;
	}

	@media (min-width:  ${md}) {
		width: 500px;
	}

`;

export const StyledTextContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const StyledMargin = styledRN.View`
	margin-top: 20px;

	@media (min-width: 0) {
		width: 70%;
	}

	@media (min-width: ${sm}) {
		width: 80%;
	}
`;

export const StyledNarrowMain = styled.View`
	display: flex;
	width: 100%;
	height: 100%;
	padding: 1.5rem 1.5rem;
	align-items: flex-start;
	gap: 1rem;
	flex-shrink: 0;
	align-self: stretch;
`;
