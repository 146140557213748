import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import TextInput from "../../components/TextInput";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import { capitalize, globalAny } from "../../utils/Utils";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { SignInNarrowScreen, SignInWideScreen } from "./SignInLayout";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import { StyledRowButtonContainer } from "./styled";

export const RenderTextInput = (props: any) => (
	<>
		<TextInput
			addLeftSpacing={true}
			componentTheme={ComponentThemeType.VinFast}
			placeholder={"your@email.com"}
			label={globalAny.language.enter_email_ca}
			onChangeText={(event) => props.onEnterEmail(event)}
			error={props.emailError}
			errorMessage={props.emailErrorMessage}
			autoFocus={true}
			value={props.emailValue}
			disabled={props.disabled}
		/>
		<TextInput
			addLeftSpacing={true}
			componentTheme={ComponentThemeType.VinFast}
			label={globalAny.language.enter_password}
			onChangeText={(event) => props.onEnterPassword(event)}
			onPressInputIcon={props.onPressInputIcon}
			inputIcon={props.inputIcon}
			passwordField={props.passwordField}
			error={props.passwordError}
			errorMessage={props.passwordErrorMessage}
			autoFocus={props.autoFocus}
			value={props.passwordValue}
			onKeyPress={props.onKeyPress}
			disabled={props.disabled}
		/>
	</>
);

export const RenderButton = (props: any) => (
	<>
		{props.screenDimensions === ScreenDimensions.narrow ? (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				<TouchableButton
					disabled={props.disableSignInBtn || props.disabled}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={props.onPressLogin}
					style={{ width: "100%" }}
				>
					{capitalize(globalAny.language.sign_in)}
				</TouchableButton>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Secondary}
					onPress={props.onPressRecoverPassword}
					disabled={props.disabled}
					style={{ width: "100%" }}
				>
					{capitalize(globalAny.language.recover_password)}
				</TouchableButton>
			</StyledRowButtonContainer>
		) : (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Secondary}
					onPress={props.onPressRecoverPassword}
					disabled={props.disabled}
					style={{ width: "100%" }}
				>
					{capitalize(globalAny.language.recover_password)}
				</TouchableButton>
				<TouchableButton
					disabled={props.disableSignInBtn || props.disabled}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={props.onPressLogin}
					style={{ width: "100%" }}
				>
					{capitalize(globalAny.language.sign_in)}
				</TouchableButton>
			</StyledRowButtonContainer>
		)}
	</>
);

const SignIn = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<SignInNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.wide) {
			return (
				<MainWideWrapper>
					<SignInWideScreen {...props} />
				</MainWideWrapper>
			);
		}

		return (
			<>
				<MainDefaultWrapper>
					<SignInWideScreen {...props} />
				</MainDefaultWrapper>
			</>
		);
	};

	return renderScreenDimensions();
};
export default SignIn;
