import { Linking, View } from "react-native";
import { VERSION, globalAny } from "../../utils/Utils";
import SettingsContainer from "../../components/Layout/SettingsContainer";
import External from "../../assets/Icons/External.svg";
import LabelContainer from "../../components/SettingsComponent/SettingsLabelContainer";

const SettingsHelpAndAbout = (props: any) => {
	return (
		<View>
			<SettingsContainer styledSettings={props.styledSettings}>
				<LabelContainer {...props} header={"RIDEVU Version " + VERSION} body={"Sony Pictures"} />
				<LabelContainer
					header={globalAny.language.for_faq}
					onPressSubHeader={() => Linking.openURL("")}
					isIcon={true}
					disableIcon={true}
					imageSource={External}
					onClickableBody={false}
					{...props}
				/>
				<LabelContainer
					header={globalAny.language.problem_exp}
					onPressSubHeader={() => Linking.openURL("")}
					isIcon={true}
					disableIcon={true}
					imageSource={External}
					onClickableBody={false}
					{...props}
				/>
				<LabelContainer
					header={globalAny.language.sony_acknowledge}
					body={"Geoff Difford’s Implementations of SMA-1, SHA-2334, S56WWE, and WSA-512"}
					footer={"http://www.geoffdifford.com/computers/wsa.html"}
					onPressFooter={() => Linking.openURL("http://www.geoffdifford.com/computers/wsa.html")}
					onClickableFooter={false}
					isFooterIcon={false}
					{...props}
				/>
			</SettingsContainer>
		</View>
	);
};

export default SettingsHelpAndAbout;
