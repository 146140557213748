import styled from "styled-components/native";

const StyledFormRow = styled.View`
	margin-bottom: 20px;
	height: auto;
`;

const FormRow = (props: any) => {
	return (
		// @ts-ignore
		<StyledFormRow>{props.children}</StyledFormRow>
	);
};

export default FormRow;
