import SettingsContainer from "../../components/Layout/SettingsContainer";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import TouchableButton from "../../components/TouchableButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { StyleSheet, View } from "react-native";
import H4 from "../../components/Typography/H4";
import { Dropdown } from "react-native-element-dropdown";
import localStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys, CarActivity } from "../../Types";
import ButtonToggle from "../../components/ButtonToggle";
import iconChevron from "../../assets/Icons/icon_chevron-down.svg";
import Image from "../../components/Image/Image";
import Toast from "../../components/Toast";
import { checkApiKey } from "../../services/apiKeyService";
import { removeCacheItem } from "../../utils/CacheStorageUtils";
import useGenericContentStore from "../../store/genericContent.store";
import ENVIRONMENT from "../../config/ENVIRONMENT";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";
import useDrivingStore from "../../store/useDriving.store";
import languagesList from "../../assets/Language/languages.json";
import localeByCountry from "../../assets/Language/localeByCountry.json";
import { globalAny } from "../../utils/Utils";
import LabelContainer from "../../components/SettingsComponent/SettingsLabelContainer";
import SettingsButton from "../../components/SettingsComponent/SettingsButton";
import { StyledBreakWord } from "./styled";

const StyledMain = styled.View`
	gap: 1.5rem;
`;

const StyledContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	gap: 1.5rem;
`;

const StyledCenteredContainer = styled.View`
	flex-direction: row;
	justify-content: center;
`;
const PaddedContainer = styled.View`
	flex-direction: row;
	justify-content: start;
	gap: 1.5rem;
`;

const StyledPartialSettingsContainer = styled.View`
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	display: flex;
`;

const StyledPartialSettings = styled.View`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap !important;
`;

const StyledSwitchWrapper = styled.View`
	flex-wrap: wrap;
	display: flex;
	flex-direction: row;
	gap: 1.5rem;
	width: 100%;
`;

const SettingsAdvance = (props: any) => {
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setCarActivity = useDrivingStore((state: any) => state.setCarActivity);
	const isEnabledFloatingButton = useGenericContentStore((state: any) => state.isEnabledFloatingButton);
	const setIsEnabledFloatingButton = useGenericContentStore((state: any) => state.setIsEnabledFloatingButton);
	const [selectedRegion, setSelectedRegion] = useState("Unset");
	const [selectedLanguage, setSelectedLanguage] = useState("Unset");
	const [selectedEnvironment, setSelectedEnvironment] = useState("Unset");
	const [selectedCarActivity, setSelectedCarActivity] = useState<any>(carActivity);
	const [disableMqtt, setDisableMqtt] = React.useState(true);
	const [disableReload, setDisableReload] = React.useState(false);
	const [enableFloatingButton, setEnableFloatingButton] = React.useState<any>(isEnabledFloatingButton);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState("");
	const [countryData, setCountryData] = useState<any>([]);
	const [languages, setLanguages] = useState<any>([]);
	const onDismissSnackBar = () => setVisible(false);

	const getCountries = () => {
		const data = localeByCountry.map((item: any) => {
			let languageCode = item.locales.toUpperCase();
			let countryName = `${item.en_name} - ${item.country.toUpperCase()}`;
			let countryCode = item.country.toUpperCase();
			return {
				id: languageCode,
				label: countryName,
				value: countryCode,
			};
		});

		setCountryData(data);
	};

	const getLanguages = () => {
		const data = languagesList.map((item: any) => {
			let languageCode = item.code;
			return {
				id: languageCode,
				label: item.language,
				value: languageCode,
			};
		});

		setLanguages(data);
	};

	const getLocaleByCountry = (countryCode: string) => {
		if (countryCode.includes(",")) {
			const multipleCountryCode = countryCode.split(",");
			const multipleLanguages = multipleCountryCode.map((code) => filteredLanguages(code));
			setLanguages(multipleLanguages);
			return;
		}

		setLanguages([filteredLanguages(countryCode)]);
	};

	const filteredLanguages = (countryCode: string) => {
		const [locale] = languagesList.filter((val) => val.code.trim() === countryCode.trim());
		let languageCode = locale.code;
		return {
			id: languageCode,
			label: locale.language,
			value: languageCode,
		};
	};

	const initializeCountries = async () => {
		const regionCode: any = await localStorage.getItem(AsyncStorageKeys.RegionOverride);
		setSelectedRegion(regionCode);
		const langCode: any = await localStorage.getItem(AsyncStorageKeys.LanguageOverride);
		setSelectedLanguage(langCode);

		localStorage.getItem(AsyncStorageKeys.EnvironmentOverride).then((value) => {
			setSelectedEnvironment(value ?? "Unset");
		});

		configureMqttSettings();
	};

	const configureMqttSettings = async () => {
		const disableMqttStore = sessionStorage.getItem("disableMqtt");

		setDisableMqtt(disableMqttStore === "true");
	};

	const setLanguageOverride = (lang: string) => {
		setSelectedLanguage(lang);
	};

	const setRegionOverride = (region: string, id: string) => {
		getLocaleByCountry(id);
		setSelectedRegion(region);
	};

	function setEnvironmentOverride(env: string) {
		setSelectedEnvironment(env);
		localStorage.setItem(AsyncStorageKeys.EnvironmentOverride, env);
	}
	function unsetLanguageOverride() {
		setSelectedLanguage("Unset");
	}

	function unsetRegionOverride() {
		setSelectedRegion("Unset");
	}

	function unsetEnvironmentOverride() {
		setSelectedEnvironment("Unset");
		localStorage.removeItem(AsyncStorageKeys.EnvironmentOverride);
	}

	function setDisableMqttOverride(disable: boolean) {
		// @ts-ignore
		sessionStorage.setItem("disableMqtt", disable);
		setDisableMqtt(disable);
	}

	const onReload = async () => {
		const result: any = await checkApiKey(selectedRegion);

		if (result?.apiKey && result?.responseCode) {
			removeCacheItem(AsyncStorageKeys.languageJson);
			localStorage.setItem(AsyncStorageKeys.RegionOverride, selectedRegion);
			localStorage.setItem(AsyncStorageKeys.LanguageOverride, selectedLanguage);
			localStorage.setItem(AsyncStorageKeys.isOverride, "true");
			window.location.replace("/landing");

			return;
		}

		setToastMessage(globalAny.language.territory_lang_not_supported);
		setLabelText("");
		setVisible(true);
	};

	useEffect(() => {
		if (selectedLanguage === "Unset" || selectedRegion === "Unset") {
			return setDisableReload(true);
		}
		setDisableReload(false);
	}, [selectedRegion, selectedLanguage]);

	useEffect(() => {
		const initCarActivity = async () => {
			setSelectedCarActivity(carActivity);
			setEnableFloatingButton(isEnabledFloatingButton);
		};
		initCarActivity();
	}, []);

	useEffect(() => {
		localStorage.setItem(AsyncStorageKeys.carActivity, selectedCarActivity);
		setCarActivity(selectedCarActivity);
	}, [selectedCarActivity]);

	useEffect(() => {
		localStorage.setItem(AsyncStorageKeys.carActivity, carActivity);
	}, [carActivity]);

	useEffect(() => {
		localStorage.setItem(AsyncStorageKeys.floatingButton, enableFloatingButton);
		setIsEnabledFloatingButton(enableFloatingButton);
	}, [enableFloatingButton]);

	//@ts-ignore
	useEffect(() => {
		getCountries();
		getLanguages();
	}, []);

	useEffect(() => {
		initializeCountries();
	}, []);

	return (
		<View>
			{/* @ts-ignore */}
			<StyledMain>
				<SettingsContainer styledSettings={props.styledSettings}>
					<LabelContainer {...props} header={"Clicking UNSET will revert to the default/detected setting."} />
					{/* @ts-ignore */}
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						/* @ts-ignore */
						<StyledPartialSettings>
							<LabelContainer {...props} header={"Environment"} />

							{/* @ts-ignore */}
							<StyledContainer>
								<SettingsButton
									{...props}
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "Unset" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => unsetEnvironmentOverride()}
									title={"UNSET"}
								/>
								<SettingsButton
									{...props}
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "STAGING" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => setEnvironmentOverride("STAGING")}
									title={"STAGING"}
								/>
								<SettingsButton
									{...props}
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "UAT" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => setEnvironmentOverride("UAT")}
									title={"UAT"}
								/>
								<SettingsButton
									{...props}
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "PRODUCTION" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => setEnvironmentOverride("PRODUCTION")}
									title={"PRODUCTION"}
								/>
							</StyledContainer>
						</StyledPartialSettings>
					) : (
						<></>
					)}
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<LabelContainer {...props} header={"Car Activity"} />

						{/* @ts-ignore */}
						<PaddedContainer>
							<SettingsButton
								{...props}
								componentTheme={ComponentThemeType.VinFast}
								type={carActivity === CarActivity.INMOTION ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={() => setSelectedCarActivity(CarActivity.INMOTION)}
								title={"IN-MOTION"}
							/>

							<SettingsButton
								{...props}
								componentTheme={ComponentThemeType.VinFast}
								type={carActivity === CarActivity.PARKED ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={() => setSelectedCarActivity(CarActivity.PARKED)}
								title={"PARKED"}
							/>
						</PaddedContainer>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<View
							style={{
								alignItems: "center",
								flexBasis: "100%",
								width: "100%",
								flexDirection: "row",
								justifyContent: "flex-start",
								paddingVertical: 15,
							}}
						>
							{/* @ts-ignore */}
							<StyledSwitchWrapper>
								<ButtonToggle defaultValue={enableFloatingButton} onPress={(state) => setEnableFloatingButton(state)} />
								<View>
									<H4>
										<StyledBreakWord>Show this as a floating button</StyledBreakWord>
									</H4>
								</View>
							</StyledSwitchWrapper>
						</View>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<LabelContainer {...props} header={"Territory"} />
						{/* @ts-ignore */}
						<PaddedContainer>
							<SettingsButton
								{...props}
								componentTheme={ComponentThemeType.VinFast}
								type={selectedRegion == "Unset" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={() => unsetRegionOverride()}
								title={"UNSET"}
							/>

							<Dropdown
								style={[styles.dropdown]}
								placeholderStyle={styles.placeholderStyle}
								selectedTextStyle={styles.selectedTextStyle}
								inputSearchStyle={styles.inputSearchStyle}
								searchPlaceholder="Search..."
								data={countryData}
								labelField={"label"}
								valueField={"value"}
								dropdownPosition={"bottom"}
								search
								onChange={(item) => setRegionOverride(item.value, item.id)}
								value={selectedRegion}
								renderRightIcon={() => (
									<Image
										source={{
											uri: iconChevron,
										}}
										resizeMode="stretch"
										width={24}
										height={24}
									/>
								)}
							/>
						</PaddedContainer>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<LabelContainer {...props} header={"Language"} />

						{/* @ts-ignore */}
						<PaddedContainer>
							<SettingsButton
								{...props}
								componentTheme={ComponentThemeType.VinFast}
								type={selectedLanguage == "Unset" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={() => unsetLanguageOverride()}
								title={"UNSET"}
							/>
							<Dropdown
								style={[styles.dropdown]}
								placeholderStyle={styles.placeholderStyle}
								selectedTextStyle={styles.selectedTextStyle}
								inputSearchStyle={styles.inputSearchStyle}
								searchPlaceholder="Search..."
								data={languages}
								labelField={"label"}
								valueField={"value"}
								dropdownPosition={"bottom"}
								search
								onChange={(item) => setLanguageOverride(item.id)}
								value={selectedLanguage}
								renderRightIcon={() => (
									<Image
										source={{
											uri: iconChevron,
										}}
										resizeMode="stretch"
										width={24}
										height={24}
									/>
								)}
							/>
						</PaddedContainer>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						//@ts-ignore
						<StyledPartialSettingsContainer>
							<LabelContainer {...props} header={"Theme"} />
							{/* @ts-ignore */}
							<PaddedContainer>
								<SettingsButton
									{...props}
									title={"DARK"}
									componentTheme={ComponentThemeType.VinFast}
									type={ComponentTypeEnum.Primary}
								/>
								<SettingsButton
									{...props}
									title={"LIGHT"}
									componentTheme={ComponentThemeType.VinFast}
									type={ComponentTypeEnum.Secondary}
									disabled={true}
								/>
							</PaddedContainer>
						</StyledPartialSettingsContainer>
					) : (
						<></>
					)}
					{/* @ts-ignore */}
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						//@ts-ignore
						<StyledPartialSettingsContainer>
							<View
								style={{
									alignItems: "center",
									flexBasis: "100%",
									width: "100%",
									flexDirection: "row",
									justifyContent: "flex-start",
									paddingVertical: 15,
								}}
							>
								{/* @ts-ignore */}
								<StyledSwitchWrapper>
									<ButtonToggle defaultValue={!disableMqtt} onPress={(state) => setDisableMqttOverride(!state)} />
									<View>
										<H4>
											<StyledBreakWord>MQTT Functionality</StyledBreakWord>
										</H4>
									</View>
								</StyledSwitchWrapper>
							</View>
						</StyledPartialSettingsContainer>
					) : (
						<></>
					)}
				</SettingsContainer>

				{/* @ts-ignore */}
				<StyledCenteredContainer>
					<SettingsButton
						{...props}
						componentTheme={ComponentThemeType.VinFast}
						disabled={disableReload}
						type={ComponentTypeEnum.Secondary}
						onPress={() => onReload()}
						title={"Reload"}
					/>
				</StyledCenteredContainer>
			</StyledMain>
			<Toast visible={visible} text={toastMessage} label={labelText} onDismissSnackBar={onDismissSnackBar}></Toast>
		</View>
	);
};

export default SettingsAdvance;

const styles = StyleSheet.create({
	centeredContainer: {
		backgroundColor: "#111111",
	},
	container: {
		backgroundColor: "#111111",
	},
	dropdownContainer: {
		paddingHorizontal: 30,
		paddingBottom: 20,
	},
	dropdown: {
		height: "72px",
		width: "75%",
		borderColor: "#2362be",
		borderWidth: 2,
		fontSize: 18,
		borderRadius: 16,
		paddingHorizontal: 24,
		backgroundColor: "black",
	},
	icon: {
		marginRight: 5,
	},
	placeholderStyle: {
		fontSize: 16,
		color: "white",
	},
	selectedTextStyle: {
		fontSize: 24,
		color: "white",
	},
	inputSearchStyle: {
		padding: 10,
		height: 40,
		fontSize: 18,
		borderRadius: 8,
		color: "black",
		borderWidth: 0,
	},
});
