import { StyledMainSettingsDefault } from "./Styled";

const MainSettingsDefaultWrapper = ({ children, addTop }: any) => {
	return (
		//@ts-ignore
		<StyledMainSettingsDefault addTop={addTop}>{children}</StyledMainSettingsDefault>
	);
};

export default MainSettingsDefaultWrapper;
