import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { CarActivity } from "../Types";

const useDrivingStore = create(
	devtools((set: any) => ({
		carActivity: CarActivity.PARKED,
		setCarActivity: (carActivity: any) => set({ carActivity: carActivity }),
	}))
);

export default useDrivingStore;
