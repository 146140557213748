import { ActivityIndicator, useWindowDimensions } from "react-native";
import styled from "styled-components/native";
import ridevueLogo from "../../assets/Logo/logos.svg";
import Image from "../../components/Image/Image";
import AppBarWrapper from "../Layout/MainLayout/AppBarWrapper";
import { ScreenDimensions } from "../../utils/enum";
import { white } from "../../StyleHelpers";
import useDimensions from "../../hooks/useDimensions";
import useStyleHelpers from "../../hooks/useStyleHelpers";

export const MainLoadingWrapper = styled.View`
	display: flex;
	gap: 12.25rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const MainLoadingContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
`;

export const MainLoadingNarrowWrapper = styled.View`
	display: flex;
	padding: 0rem 6.5rem 0rem 6.5rem;
	align-items: flex-start;
`;

export const MainLoadingNarrowContainer = styled.View`
	display: flex;
	padding: 0rem 1.5rem;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	gap: 0.68rem;
`;

const StyledText = styled.Text`
	font-family: "Nunito";
	font-size: 1.625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	color: ${white};
`;

const LandingLoader = (props: any) => {
	const windowDimensions = useWindowDimensions();
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const styledLanding = styleHelper?.landing;

	const renderLoader = () => {
		return (
			<>
				<Image
					source={{ uri: ridevueLogo }}
					windowDimensions={windowDimensions}
					style={{ width: styledLanding?.logo?.width, height: styledLanding?.logo?.height }}
					resizeMode="contain"
				/>
				{/* @ts-ignore */}
				{props.title ? <StyledText>{props.title}</StyledText> : <ActivityIndicator size={80} color={"white"} />}
			</>
		);
	};

	const renderScreenDimensions = () => {
		if (screenDimensions === ScreenDimensions.narrow) {
			return (
				<>
					<AppBarWrapper dimensions={screenDimensions} />
					{/* @ts-ignore */}
					<MainLoadingNarrowWrapper>
						{/* @ts-ignore */}
						<MainLoadingNarrowContainer>{renderLoader(props.title)}</MainLoadingNarrowContainer>
					</MainLoadingNarrowWrapper>
				</>
			);
		}
		return (
			<>
				{/* @ts-ignore */}
				<MainLoadingWrapper>
					<AppBarWrapper />
					{/* @ts-ignore */}
					<MainLoadingContainer>{renderLoader()}</MainLoadingContainer>
				</MainLoadingWrapper>
			</>
		);
	};

	return <>{renderScreenDimensions()}</>;
};

export default LandingLoader;
