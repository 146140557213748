import styled from "styled-components/native";

interface Props {
	height?: any;
	isNarrow?: boolean;
}

const StyledContainer = styled.View<any>`
	width: ${(props) => props.isNarrow ? '25.683rem' : '56.25rem'};
	height: ${(props) => props.height};
	flex-shrink: 0;
	background-image: ${(props) => props.isNarrow 
		? 'linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.95) 10.43%, rgba(0, 0, 0, 0.60) 60.51%, rgba(0, 0, 0, 0.00) 100%)'
		 : 'linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.95) 20.43%, rgba(0, 0, 0, 0.60) 60.51%, rgba(0, 0, 0, 0.00) 100%)'};

`;

const GradientLeftSide = (props: Props) => {
	return (
		// @ts-ignores
		<StyledContainer isNarrow={props.isNarrow} height={props.height}></StyledContainer>
	);
};

export default GradientLeftSide;
