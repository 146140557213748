import AsyncStorage from "@react-native-async-storage/async-storage";
import { CONTENT_BASEURL_V6 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";
import { AsyncStorageKeys } from "../Types";
import { AppLogger } from "../utils/AppLogger";

/**
 * Get JSON strings for the current language of device
 * @return {Promise<LanguageStrings>}
 */
export const getLanguage = async () => {
	try {
		const resJson = await fetchWrapper(CONTENT_BASEURL_V6 + "appwording", { headers: getHeaders() });
		const existingLanguageData = await AsyncStorage.getItem(AsyncStorageKeys.existingLanguageData);
		if (existingLanguageData !== null) {
			const appWordingObject = JSON.parse(existingLanguageData);
			if (appWordingObject.lastUpdated == resJson.lastUpdated && appWordingObject.languageUsed == resJson.languageUsed) {
				const languageJson = await AsyncStorage.getItem(AsyncStorageKeys.languageJson);
				return JSON.parse(languageJson || "");
			}
		}
		if (resJson.url) {
			const responseJson = await fetchWrapper(resJson.url);
			// await AsyncStorage.setItem(AsyncStorageKeys.existingLanguageData, JSON.stringify(resJson));
			await AsyncStorage.setItem(AsyncStorageKeys.languageJson, JSON.stringify(responseJson));
			return responseJson;
		} else {
			throw new Error("failed to get language");
		}
	} catch (err) {
		AppLogger.error("failed to get language", err);
		throw err;
	}
};
