import styled, { css } from "styled-components/native";
import { light_black, spacing_l, white } from "../../StyleHelpers";

interface PropsCommonAttributes {
	shouldHighlight?: boolean;
}
//Default
export const StyledTextContent = styled.View<PropsCommonAttributes>`
	padding: 20px 25px;
	position: relative;
	z-index: 999;
`;

export const StyledTouchableOpacity = styled.TouchableOpacity<PropsCommonAttributes>`
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	background: ${(props) => (props.shouldHighlight ? "#182B47" : "transparent")};
	border-block-end: ${(props) => (props.shouldHighlight ? "2px solid #2362BE" : 0)};
`;

export const StyledContainer = styled.View<any>`
	position: ${(props) => (props.isSettings ? "fixed" : "sticky")};
	margin-top: ${(props) => (props.isSettings ? "7rem" : "0rem")};
	width: 100%;
	flex-direction: row;
	background-color: ${light_black};
	z-index: 1;
	padding-left: ${(props) => props.paddingLeft};
	top: ${(props) => props.styledTabsView.top};

	${(props) =>
		props.isSettings &&
		css`
			border-bottom-width: 1px;
			border-color: #5a5a5a;
			padding-left: ${spacing_l};
			top: 0;
		`}
`;

//Narrow
export const TabsNarrowMain = styled.View<any>`
	align-items: flex-start;
	flex: 1 0 0;
	z-index: 1000;
`;

export const TabsNarrowContainer = styled.View`
	display: flex;
	width: 18.75rem;
	flex-direction: column;
	align-items: flex-start;
	background-color: #0a0a0a;
	height: 100%;
	position: fixed;
`;

export const TabsNarrowContentArea = styled.View<any>`
	z-index: -1;
	padding-left: 18.8rem;
	width: 100%;
`;

export const StyledTouchableOpacityNarrow = styled.TouchableOpacity<PropsCommonAttributes>`
	display: flex;
	padding: 0.75rem 1rem;
	align-items: left;
	gap: 0.625rem;
	align-self: stretch;
	border-inline-end: ${(props) => (props.shouldHighlight ? "2px solid #2362BE" : 0)};
	background-color: ${(props) => (props.shouldHighlight ? "#182B47" : "transparent")};
`;

export const StyledFont = styled.Text<any>`
	color: ${white};
	font-family: ${(props) => props?.styledTabsView?.font?.fontFamily};
	font-size: ${(props) => props?.styledTabsView?.font?.size};
	font-style: normal;
	font-weight: 500;
	line-height: ${(props) => props?.styledTabsView?.font?.lineHeight};
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
