import { RenderButton } from ".";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";

import {
	StyledNarrowButtonContainer,
	StyledNarrowMain,
	StyledNarrowButtonMain,
	StyledButtonMain,
	StyledLegalContainer,
} from "./styled";
import Typography from "../../components/Typography";

export const LegalNarrowScreen = (props: any) => {
	return (
		/* @ts-ignore */
		<StyledNarrowMain>
			{/* @ts-ignore */}
			<StyledNarrowButtonMain>
				{/* @ts-ignore */}
				<StyledNarrowButtonContainer>
					<RenderButton {...props} />
					<PrivacyPolicyButton onPress={props.onPrivacy} removeFixedPosition={true} />
				</StyledNarrowButtonContainer>
			</StyledNarrowButtonMain>

			{/* @ts-ignore */}
			<StyledLegalContainer>
				{/* @ts-ignore */}
				<Typography title={props.legalTitle} description={props.legalDescription} />
			</StyledLegalContainer>
		</StyledNarrowMain>
	);
};

export const LegalWideScreen = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledLegalContainer>
				{/* @ts-ignore */}
				<Typography title={props.legalTitle} description={props.legalDescription} />
			</StyledLegalContainer>
			{/* @ts-ignore */}
			<StyledButtonMain>
				<RenderButton {...props} />
				<PrivacyPolicyButton onPress={props.onPrivacy} removeFixedPosition={true} />
			</StyledButtonMain>
		</>
	);
};
