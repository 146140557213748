import styled, { css } from "styled-components/native";
import { WheelPosition } from "../../Types";

export const StyledLoader = styled.View`
	height: 56px;
	width: 56px;
	border-radius: 16px;
	overflow: hidden;
	position: absolute;
`;

export const StyledMain = styled.View<any>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: rgba(0, 0, 0, 0.85);
	backdrop-filter: blur(5px);
	position: fixed;
	z-index: 1000;
	flex-shrink: 0;
	top: ${(props) => props?.styledSideBar?.top};
	width: ${(props) => props?.styledSideBar?.main?.width};
	height: 100%;

	${(props) =>
		props.wheelPosition === WheelPosition.RIGHT
			? css`
					right: 0;
			  `
			: css`
					left: 0;
			  `}
`;

export const StyledContainer = styled.TouchableOpacity<any>`
	display: flex;
	width: ${(props) => props?.styledSideBar?.container?.width};
	height: ${(props) => props?.styledSideBar?.container?.height};
	justify-content: center;
	align-items: center;
	gap: 0.625rem;

	${(props) =>
		props.highlight &&
		css`
			background-color: #2362be;
		`}
`;
