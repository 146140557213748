import { globalAny } from "../../utils/Utils";
import { light_black } from "../../StyleHelpers";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import styled from "styled-components/native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import DeviceButton from "../../components/DeviceButton";
import { useState } from "react";
import { DeviceSelectionPages, ProfileName } from "../../Types";
import useGenericContentStore from "../../store/genericContent.store";
import { ScrollView } from "react-native";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100%;
`;

const StyledOptionContainer = styled.View`
	top: 180px;
	height: 60vh;
	margin-left: 190px;
	margin-right: 190px;
	gap: 10px;
`;

const ScreenSelectionContainer = styled.View<any>`
	margin-bottom: 44px;
	height: 60vh;
	font-size: 25px;
	gap: 1.5rem;
`;

const ButtonContainer = styled.View`
    gap: 2rem;
    padding: 1.5rem 0 1.5rem 0;
`;

const HalfDeviceButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	gap: 1.5rem;
`;

const Separator = styled.View`
	height: 2px;
	margin-bottom: 20px;
	background-image: linear-gradient(270deg, rgba(19, 19, 19, 0) 0%, #2362be 49.92%, rgba(19, 19, 19, 0) 100%);
`;

const ScreenSelection = (props: any) => {
	const [selectedScreen, setSelectedScreen] = useState<any>(null);
	const numberOfScreens = useGenericContentStore((state: any) => state.numberOfScreens);

	return (
		//@ts-ignore
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={globalAny.language.select_your_screen}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={props?.onPressLeftTitleIcon}
				onPressLeftIcon={props?.onPressLeftIcon}
				isSingleLogo={true}
			/>
			<ScrollView style={{ zIndex: -1, marginBottom: 10, height: '100vh' }}>
				{/* @ts-ignore */}
				<StyledOptionContainer>
					{/* @ts-ignore */}
					<ScreenSelectionContainer>
						{/* @ts-ignore */}
						<HalfDeviceButtonContainer>
							{numberOfScreens === 1 || numberOfScreens === 3 ? (
								<DeviceButton
									text={"Front Screen (MHU)"}
									setSelectedScreen={() => setSelectedScreen(ProfileName.FrontScreen)}
									selected={selectedScreen === ProfileName.FrontScreen}
									value={ProfileName.FrontScreen}
									disabled={!props?.isFrontAvailable}
								/>
							) : (
								<></>
							)}
						</HalfDeviceButtonContainer>
						{/* @ts-ignore */}
						<HalfDeviceButtonContainer>
							{numberOfScreens === 2 ? (
								<>
									<DeviceButton
										text={"Front Screen (MHU)"}
										setSelectedScreen={() => setSelectedScreen(ProfileName.FrontScreen)}
										selected={selectedScreen === ProfileName.FrontScreen}
										value={ProfileName.FrontScreen}
										disabled={!props?.isFrontAvailable}
										width={"50%"}
									/>
									<DeviceButton
										text={"Rear Screen"}
										setSelectedScreen={() => setSelectedScreen(ProfileName.RearScreen)}
										selected={selectedScreen === ProfileName.RearScreen}
										value={ProfileName.RearScreen}
										width={"50%"}
										disabled={!props?.isRearAvailable}
									/>
								</>
							) : (
								<></>
							)}
							{numberOfScreens === 3 ? (
								<>
									<DeviceButton
										text={"Rear Left Screen"}
										setSelectedScreen={() => setSelectedScreen(ProfileName.LeftScreen)}
										selected={selectedScreen === ProfileName.LeftScreen}
										value={ProfileName.LeftScreen}
										width={"49%"}
										disabled={!props?.isLeftAvailable}
									/>
									<DeviceButton
										text={"Rear Right Screen"}
										setSelectedScreen={() => setSelectedScreen(ProfileName.RightScreen)}
										selected={selectedScreen === ProfileName.RightScreen}
										value={ProfileName.RightScreen}
										width={"49%"}
										disabled={!props?.isRightAvailable}
									/>
								</>
							) : (
								<></>
							)}
						</HalfDeviceButtonContainer>
						<Separator />
						{/* @ts-ignore */}
						{!props?.isInCarScreen() ? (
							<DeviceButton
								text={"Other Device..."}
								setSelectedScreen={() => setSelectedScreen(ProfileName.OtherDevice)}
								selected={selectedScreen === ProfileName.OtherDevice}
								value={ProfileName.OtherDevice}
								width={"50%"}
								borderStyle={true}
							/>
						) : (
							<></>
						)}

						{/* @ts-ignore */}
						<ButtonContainer>
							<TouchableButton
								componentTheme={ComponentThemeType.VinFast}
								type={ComponentTypeEnum.Primary}
								onPress={() => {
									if (selectedScreen === ProfileName.OtherDevice) {
										props?.setSelectedView(DeviceSelectionPages.OtherDevices);
										return;
									}

									props?.onDeviceSelected(selectedScreen);
								}}
								disabled={!selectedScreen}
							>
								{globalAny.language.continue}
							</TouchableButton>
						</ButtonContainer>
					</ScreenSelectionContainer>
				</StyledOptionContainer>
			</ScrollView>
		</StyledContainer>
	);
};

export default ScreenSelection;
