import AsyncStorage from "@react-native-async-storage/async-storage";
import { getDateTimePlusHour, logout } from "../Utils";
import { AsyncStorageKeys } from "../../Types";
export const CODE_SESSION_ERROR = 20008;
export const CODE_SESSION_EXPIRED = 40011;

export const fetchWrapper = async (url: any, requestOptions?: any) => {
	const fetchData = await fetch(url, requestOptions)
		.then(async (response: any) => {
			const resJson = response.json();
			AsyncStorage.setItem(AsyncStorageKeys.idleExpiry, getDateTimePlusHour().toString());

			return resJson;
		})
		.catch((e) => {
			return false;
		});
	
	if (url.indexOf('logout') === -1 && (fetchData.responseCode === CODE_SESSION_ERROR || fetchData.responseCode === CODE_SESSION_EXPIRED)) {
		localStorage.setItem(AsyncStorageKeys.sessionExpired, 'true');
		await logout();
	}

	return fetchData;
};
