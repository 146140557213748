import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import ComponentThemeType from "../../models/ComponentTheme";
import { StyledSettingsButton, StyledSettingsButtonText } from "./styled";

const SettingsButton = (props: any) => {
	return (
		<StyledSettingsButton
			{...props}
			componentTheme={ComponentThemeType.VinFast}
			onPress={() => props.onPress()}
			type={props.type ?? ComponentTypeEnum.Secondary}
		>
			<StyledSettingsButtonText {...props}>{props.title}</StyledSettingsButtonText>
		</StyledSettingsButton>
	);
};

export default SettingsButton;
