// @ts-nocheck
import styled, { css } from "rn-css";
import { TextInput, MD3DarkTheme as DefaultTheme, configureFonts } from "react-native-paper";
import { FONT_FAMILY_DEFAULT, TEXT_INPUT_FONTSIZE } from "../../utils/Typography";
import { FlatList, TouchableOpacity, View, Text } from "react-native";
import { white_smoke, light_black, highlightColor, mid_blue, salmon, separator_gray } from "../../StyleHelpers";
import { useRef, useEffect, useState } from "react";
import iconUp from "../../assets/Icons/icon_chevron-up.svg";
import iconDown from "../../assets/Icons/icon_chevron-down.svg";
import Image from "../../components/Image/Image";
interface Props {
	fontSize: number;
	isHighlight?: boolean;
}
interface StyledViewProps {
	addLeftSpacing?: boolean;
	addRightSpacing?: boolean;
}

const StyledInput = styled(TextInput)<Props>`
	position: relative;
	background-color: ${light_black};
	color: ${mid_blue};
	width: 100%;
	height: 60px;
	font-size: ${(props) => props.fontSize}px;
	font-family: ${FONT_FAMILY_DEFAULT};
	padding-left: 10px;
	flex-grow: 1;
	cursor: pointer;
`;

const StyledTextError = styled.Text`
	margin-left: 30px;
	font-size: 18px;
	font-family: ${FONT_FAMILY_DEFAULT};
	color: ${salmon};
`;

const StyledView = styled.View<StyledViewProps>`
	position: relative;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 999;
	${(props) =>
		props.addLeftSpacing &&
		css`
			margin-left: 20px;
		`}
	${(props) =>
		props.addRightSpacing &&
		css`
			margin-right: 20px;
		`}
`;

const StyledDropDownContainer = styled.View`
	position: absolute;
	margin-top: 69px;
	width: 100%;
	height: 260px;
	border: 2px white;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	background-color: rgb(20, 20, 20);
	overflow-y: scroll;
	z-index: 1000;
`;

const StyledListItemText = styled.View`
	width: 420px;
`;

const StyledListItem = styled.View<Props>`
	font-family: ${FONT_FAMILY_DEFAULT};
	z-index: 1000;
	width: 100%;
	height: 65px;
	padding: 22px 22px;

	&:hover {
		background-color: ${highlightColor};
	}
`;

const StyledRightIcon = styled.View`
	position: absolute;
	right: 20;
	top: 25px;
`;
const fontConfig = {
	web: {
		light: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 300,
		},
		regular: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 400,
		},
		medium: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 500,
		},
		bold: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 700,
		},
	},
	android: {
		light: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 300,
		},
		regular: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 400,
		},
		medium: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 500,
		},
		bold: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 700,
		},
	},
};

/**
 *
 * Current lib limitation: Change color of label when text input is focused. Read: https://stackoverflow.com/questions/58786281/change-label-color-textinput-react-native-paper-onfocus
 * Specs: https://callstack.github.io/react-native-paper/text-input.html
 */
const DropDown = (props: any) => {
	const dropDownRef = useRef();
	const [dropDownSelectedValue, setDropDownSelectedValue] = useState();
	const [dropdownData, setDropDownData] = useState(props.dropDownData);
	const [isFocused, setIsFocused] = useState(false);
	const [rightIcon, setRightIcon] = useState(iconDown);

	const onDropDownChangeText = (text: string) => {
		const inputText = text.toLowerCase();

		if (!inputText) {
			setDropDownSelectedValue(inputText);
			props.onItemSelect(inputText);
			return;
		}

		setDropDownData(
			props?.dropDownData?.filter(
				(item) => item.label.toLowerCase().indexOf(inputText) >= 0 || item.value.toLowerCase().indexOf(inputText) >= 0
			)
		);
		setDropDownSelectedValue(inputText);
	};

	const onDropDownItemPress = (item) => {
		setDropDownSelectedValue(item.value);
		props.onItemSelect(item);
		setDropDownData(props?.dropDownData);
		setIsFocused(false);
		setRightIcon(iconDown);
	};

	const onPressIcon = () => {
		if (props.disabled) return;

		if (rightIcon === iconUp) {
			setIsFocused(false);
			setRightIcon(iconDown);
			return;
		}

		setIsFocused(true);
		setRightIcon(iconUp);
	};

	useEffect(() => {
		if (props.autoFocus === true) {
			dropDownRef.current.focus();
		}
	}, [props.autoFocus]);

	useEffect(() => {
		setDropDownData(props.dropDownData);
	}, [props.dropDownData]);

	return (
		<StyledView addLeftSpacing={props.addLeftSpacing} addRightSpacing={props.addRightSpacing}>
			<TouchableOpacity
				onPress={() => onPressIcon()}
				onBlur={() =>
					setTimeout(() => {
						setIsFocused(false);
						setRightIcon(iconDown);
					}, 200)
				}
				style={{ cursor: "pointer" }}
			>
				{/* @ts-ignore */}
				<StyledInput
					mode="outlined"
					disabled={!props.value && props.disabled}
					activeOutlineColor={mid_blue}
					error={props.error}
					autoComplete={false}
					placeholder={props.dropDownPlaceholder}
					secureTextEntry={props.passwordField}
					value={dropDownSelectedValue ?? props.value}
					style={{ cursor: "pointer" }}
					outlineStyle={
						!props.value && props.disabled
							? { borderColor: isFocused ? mid_blue : separator_gray, borderRadius: 16, borderWidth: 2 }
							: {
									borderColor: isFocused ? mid_blue : "#938F99",
									borderRadius: 16,
									borderWidth: 2,
							  }
					}
					fontSize={TEXT_INPUT_FONTSIZE}
					label={
						<View style={{ left: -8, backgroundColor: light_black, cursor: "pointer" }}>
							<View
								style={{
									alignItems: "center",
								}}
							>
								<Text
									style={{
										fontSize: 25,
										lineHeight: 28,
										fontWeight: 500,
										marginLeft: 15,
										color: white_smoke,
										fontFamily: FONT_FAMILY_DEFAULT,
										backgroundColor: light_black,
										opacity: !props.value && props.disabled ? 0.5 : 1,
										cursor: "pointer",
									}}
								>
									{props.label}{" "}
								</Text>
							</View>
						</View>
					}
					onChangeText={onDropDownChangeText}
					textColor={white_smoke}
					onBlur={() =>
						setTimeout(() => {
							setIsFocused(false);
							setRightIcon(iconDown);
						}, 200)
					}
					showSoftInputOnFocus={false}
					theme={{
						...DefaultTheme,
						fonts: configureFonts({ config: fontConfig, isV3: true }),
						colors: {
							...DefaultTheme.colors,
							error: "#C3443A",
						},
					}}
					onFocus={() => {
						setIsFocused(true);
						setRightIcon(iconUp);
					}}
					ref={dropDownRef}
					maxLength={props.maxLength}
					editable={false}
				/>
				<StyledRightIcon>
					<Image source={{ uri: rightIcon }} width={30} height={30} resizeMode="contain" />
				</StyledRightIcon>
			</TouchableOpacity>
			{/* @ts-ignore */}
			{isFocused && (
				<StyledDropDownContainer>
					<FlatList
						data={dropdownData}
						renderItem={({ item }) => (
							<TouchableOpacity
								onPress={() => onDropDownItemPress(item)}
								style={item.label === dropDownSelectedValue ? { backgroundColor: highlightColor } : {}}
							>
								<StyledListItem>
									<StyledListItemText>
										<Text
											numberOfLines={1}
											ellipsizeMode="tail"
											style={{ fontSize: 25, lineHeight: 28, color: white_smoke, height: 75 }}
										>
											{item.label}
										</Text>
									</StyledListItemText>
								</StyledListItem>
							</TouchableOpacity>
						)}
						keyExtractor={(item) => item.id}
					/>
				</StyledDropDownContainer>
			)}

			{/* @ts-ignore */}
			<View style={{ marginTop: "10px", flexGrow: "0", flexBasis: "50%" }}>
				{props.error ? <StyledTextError>{props.errorMessage}</StyledTextError> : <></>}
			</View>
		</StyledView>
	);
};

export default DropDown;
