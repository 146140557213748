import { useState, useEffect } from "react";
import useGenericContentStore from "../store/genericContent.store";

function useDimensions() {
	const [screenDimensions, setScreenDimensions] = useState("");
	const currentLayout = useGenericContentStore((state: any) => state.currentLayout);
	
	useEffect(() => {
		setScreenDimensions(currentLayout ?? "wide");
	}, [currentLayout]);

	return screenDimensions;
}

export default useDimensions;
