import { create } from "zustand";
import { devtools } from "zustand/middleware";
import MqttConnectionStatus from "../models/MqttConnectionStatus";
import IPresignedUrl from "../models/IPresignedUrl";
import MqttConductorService from "../services/mqtt/MqttConductorService";
import MqttReceiverService from "../services/mqtt/MqttReceiverService";
import MqttSenderService from "../services/mqtt/MqttSenderService";
import { MqttPlayContent } from "../models/MqttModel";

const useMqttStore = create(
	devtools((set) => ({
		deviceName: '',
		setDeviceName: (deviceName: string) => set({ deviceName: deviceName }),
		deviceInfo: {},
		setDeviceInfo: (deviceInfo: any) => set({ deviceInfo: deviceInfo }),
        mqttDevices: [],
		setMqttDevices: (mqttDevices: any) => set({ mqttDevices: mqttDevices }),
        mqttConnectionStatus: MqttConnectionStatus.Offline,
        setMqttConnectionStatus: (mqttConnectionStatus: string) => set({ mqttConnectionStatus: mqttConnectionStatus }),
		presignedUrl: {},
		setPresignedUrl: (presignedUrl: IPresignedUrl) => set({presignedUrl: presignedUrl}),
		mqttTopic: "",
		setMqttTopic: (mqttTopic: string) => set({ mqttTopic: mqttTopic }),
		mqttConductor: null,
		setMqttConductor: (mqttConductor: MqttConductorService) => set({ mqttConductor: mqttConductor}),
		mqttReceiver: null,
		setMqttReceiver: (mqttReceiver: MqttReceiverService) => set({mqttReceiver: mqttReceiver}),
		mqttSender: null,
		setMqttSender: (mqttSender: MqttSenderService) => set({mqttSender: mqttSender}),
		mqttPlayContent: null,
		setMqttPlayContent: (mqttPlayContent: MqttPlayContent) => set({ mqttPlayContent: mqttPlayContent }),
		mqttGroupSync: [],
		setMqttGroupSync: (mqttGroupSync: any) => set({ mqttGroupSync: mqttGroupSync }),
		playSessionId: null,
		setPlaySessionId: (playSessionId: any) => set({ playSessionId: playSessionId }),
	}), {name: "mqttStore"})
);
export default useMqttStore;