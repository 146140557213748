export enum MqttCommand {
    Ping = "ping",
    PingBack = "ping-back",
    DisconnectDevice = "disconnect",
    RemoveScreen = "remove-screen",

    DiscoverLocal = "discover-local",
    DiscoverAccount = "discover-account",
    TestCommand = "test-command",
    SendFromTitleDetails = "send-title-details",

    DeviceBroadcast = "device-broadcast",
    SyncDevice = "device-sync",
    SyncDeviceDone = "device-sync-done",
    BroadcastUpdatedDeviceInfo = "broadcast-updated-device-info",

    PlayMovie = "play-movie",
    PlayVam = "play-vam",
    PlayTrailer = "play-trailer",
    MediaPosition = "media-position",
    MediaPlay = "media-play",
    MediaPause = "media-pause",
    MediaRestart = "media-restart",
    MediaStop = "media-stop",
    MediaSeek = "media-seek",
    MediaSync = "media-sync",
    MediaProgressBar = "media-progress-bar",
    MediaPlaybackOptions = "media-playback-options",
    MediaSubtitle = "media-subtitle",
    MediaAudio = "media-audio"
}
