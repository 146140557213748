import styled from "rn-css";
import viewLegalDocButton from "../../assets/Icons/read_more.png";
import { TouchableOpacity, Image } from "react-native";
import { globalAny } from "../../utils/Utils";

interface Props {
	onPress?: () => void;
	removeFixedPosition?: boolean;
}

const StyledPrivacyContainer = styled.View<Props>`
	margin-top: ${(props) => (props.removeFixedPosition ? 0 : "10px")};
	left: ${(props) => (props.removeFixedPosition ? 0 : "15%")};
	position: ${(props) => (props.removeFixedPosition ? "relative" : "fixed")};
	flex-direction: row;
	align-items: center;
	gap: 10px;


	@media (min-width: 1600px) and (max-width: 1600px) {
		bottom: 2% !important;
	}
`;

const StyledPrivacyText = styled.Text`
	font-size: 18px;
	color: white;
`;

const PrivacyPolicyButton = (props: Props) => {
	return (
		<TouchableOpacity onPress={props.onPress}>
			{/* @ts-ignore */}
			<StyledPrivacyContainer removeFixedPosition={props.removeFixedPosition}>
				{/* @ts-ignore */}
				<Image source={{ uri: viewLegalDocButton }} resizeMode={"contain"} style={{ width: 45, height: 45 }} />
				{/* @ts-ignore */}
				<StyledPrivacyText>{globalAny.language.privacy_policy}</StyledPrivacyText>
			</StyledPrivacyContainer>
		</TouchableOpacity>
	);
};

export default PrivacyPolicyButton;
