import { View } from "react-native";
import { StyledBreakWord, StyledContainer, StyledHeaderFont, StyledText, StyledTextContainer } from "./styled";
import SettingsButton from "./SettingsButton";

interface Props {
	header?: string;
	body?: string;
	buttonText?: string;
	hideButton?: boolean;
	onPress?: () => void;
	styledSettings: any;
}

// @ts-ignore
const GroupContainer = (props: Props) => {
	return (
		// @ts-ignores
		<StyledContainer>
			<View>
				<StyledHeaderFont styledSettings={props.styledSettings}>
					<StyledBreakWord>{props.header}</StyledBreakWord>
				</StyledHeaderFont>
				{/* @ts-ignores */}
				<StyledTextContainer>
					{/* @ts-ignores */}
					<StyledText styledSettings={props.styledSettings}>
						<StyledBreakWord>{props.body}</StyledBreakWord>
					</StyledText>
				</StyledTextContainer>
			</View>
			{!props.hideButton ? <SettingsButton {...props} title={props.buttonText} onPress={props.onPress} /> : <></>}
		</StyledContainer>
	);
};

export default GroupContainer;
