import styled, { css } from "styled-components/native";
import { IconButton } from "react-native-paper";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { mid_blue, navy_blue, peacock_blue, white_smoke } from "../../StyleHelpers";
import { useState } from "react";
import icon_car_parked from "../../assets/Icons/icon_car_parked.svg";
import icon_car_inmotion from "../../assets/Icons/icon_car_inmotion.svg";

interface FloatingBtnProps {
	type?: ComponentTypeEnum;
	isStreamProgress?: any;
	isHovered?: any;
	isActive?: any;
}

//Note: edit flex value to allow/disable occupying whole width of button in flex component
const StyledBtnPaper = styled(IconButton)<FloatingBtnProps>`
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 100px;
    width: 100px;
    border-radius: 1000px;
	/* align-items: center; */

	${(props) => {
        if (props?.isActive) {
            return css`
                background: ${props?.isHovered ? mid_blue : peacock_blue};
            `;
        }

        return css`
                background: ${props?.isHovered ? peacock_blue : mid_blue};
            `;
	}}

	${(props) => {
		return css`
            background: ${props?.isActive ? peacock_blue : navy_blue};
        `;
	}}
`;

// Specs: https://callstack.github.io/react-native-paper/button.html
const FloatingButton = (props: any) => {
	const [isHovered, setIsHovered] = useState(false);
	return (
		// @ts-ignore
		<StyledBtnPaper
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			isHovered={isHovered}
			id={props.nativeID}
			mode="contained-tonal"
			type={props.type}
			onPress={props.onPress}
			onLongPress={props.onLongPress}
			{...props}
			isActive={props?.isActive}
			labelStyle={{ height: "100px", width: "100px" }}
			contentStyle={{ height: "100px", width: "100px" }}
            icon={props?.isActive ? icon_car_inmotion : icon_car_parked}
			iconColor={white_smoke}
            size={props?.isActive ? 40 : 50}
		/>
	);
};

export default FloatingButton;
