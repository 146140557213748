import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import { globalAny } from "../../utils/Utils";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { StyledRowButtonContainer } from "./styled";
import { LegalNarrowScreen, LegalWideScreen } from "./LegalScreen";

export const RenderButton = (props: any) => (
	//@ts-ignore
	<StyledRowButtonContainer dimensions={props.screenDimensions}>
		<TouchableButton
			componentTheme={ComponentThemeType.VinFast}
			type={ComponentTypeEnum.Primary}
			onPress={props.onPressNext}
			style={{ width: "100%" }}
		>
			{globalAny.language.next}
		</TouchableButton>
	</StyledRowButtonContainer>
);

const LegalData = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<LegalNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.wide) {
			return (
				<MainWideWrapper>
					<LegalWideScreen {...props} />
				</MainWideWrapper>
			);
		}

		return (
			<MainDefaultWrapper>
				<LegalWideScreen {...props} />
			</MainDefaultWrapper>
		);
	};

	return renderScreenDimensions();
};
export default LegalData;
