import styled from "styled-components/native";
import { spacing_s } from "../../StyleHelpers";

export const StyledButtonContainer = styled.View`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	flex-shrink: 0;
`;
export const StyledButton = styled.View`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const StyledButtonLogout = styled.View`
	width: 21.5rem;
`;

//Account Settings
export const StyledContainer = styled.View`
	padding: 0px 0px ${spacing_s} 0px;
`;

export const StyledTransaction = styled.View`
	padding: 0px 0px ${spacing_s} 0px;
	flex-direction: row;
`;

export const StyledNoTransaction = styled.View`
	margin-bottom: 16;
`;

export const StyledBreakWord = styled.Text<any>`
	word-break: break-word;
`;