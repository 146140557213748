import styled from "styled-components/native";
import useStyleHelpers from "../../hooks/useStyleHelpers";

export const StyledMainRow = styled.View<any>`
    display: flex;
    align-items: flex-start;
    gap: ${(props) => props.isBanner ? 0.75 : 0.5}rem;
    margin-bottom: ${(props) => props.rowGap};
`;

//@ts-ignore
const MainRow = ({ children, isBanner }) => {
  const styleHelper = useStyleHelpers();

  return (
    //@ts-ignore
    <StyledMainRow isBanner={isBanner} rowGap={styleHelper?.rowGap}>{children}</StyledMainRow>
  )
}

export default MainRow;