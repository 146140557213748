import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";
import { StyledNarrowMain, StyledText, StyledWideMain } from "./styled";

export const PrivacyNarrowScreen = (props: any) => {
	const styleHelper = useStyleHelpers();
	return (
		/* @ts-ignore */
		<StyledNarrowMain>
			{/* @ts-ignore */}
			<StyledText
				isNarrow={styleHelper?.layout === ScreenDimensions.narrow}
				fontSize={styleHelper?.typographyBody?.fontSize}
				lineHeight={styleHelper?.typographyBody?.lineHeight}
			>
				{props.text}
			</StyledText>
		</StyledNarrowMain>
	);
};

export const PrivacyWideScreen = (props: any) => {
	const styleHelper = useStyleHelpers();
	return (
		/* @ts-ignore */
		<StyledWideMain>
			{/* @ts-ignore */}
			<StyledText
				isNarrow={styleHelper?.layout === ScreenDimensions.narrow}
				fontSize={styleHelper?.typographyBody?.fontSize}
				lineHeight={styleHelper?.typographyBody?.lineHeight}
			>
				{props.text}
			</StyledText>
		</StyledWideMain>
	);
};
