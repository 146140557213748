import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { capitalize, globalAny } from "../../utils/Utils";
import { StyledButton, StyledButtonContainer, StyledFormContainer, StyledNarrowMain } from "./styled";
import { Stepper } from "../../components/Stepper";
import Marker from "../../components/Stepper/Marker/Marker";

export const RenderButton = (props: any) => (
	<>
		{/* @ts-ignores */}
		<StyledButtonContainer>
			{/* @ts-ignores */}
			<StyledButton>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Secondary}
					onPress={() => (props.step > 0 ? props.onPressPrevious() : props.confirmExit("back"))}
					disabled={props.disabledPrevious}
				>
					{props.step > 0 ? globalAny.language.previous : globalAny.language.cancel}
				</TouchableButton>
			</StyledButton>
			{/* @ts-ignores */}
			<StyledButton>
				<TouchableButton
					nativeID={"payNext"}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => props.onPressNext()}
					disabled={props.onValidateStep}
				>
					{props.step < props.steps.length - 1
						? globalAny.language.next
						: capitalize(props.paymentType) === props.rent
						? globalAny.language.rent
						: globalAny.language.purchase_word}
				</TouchableButton>
			</StyledButton>
		</StyledButtonContainer>
	</>
);

export const PaymentLayout = (props: any) => (
	<StyledFormContainer isShow={props.isShow}>
		<Stepper
			orientation={"horizontal"}
			marker={<Marker onPress={(step) => props.onPress(step)} />}
			currentStep={props.step}
			steps={props.steps}
		/>
	</StyledFormContainer>
);