import styled, { css } from "styled-components/native";
import { light_black, spacing_l, white_smoke } from "../../StyleHelpers";
import { StyleSheet } from "react-native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import { WheelPosition } from "../../Types";

export const StyledContainer = styled.View<any>`
	flex-direction: column;
	backdrop-filter: blur(10px);
	justify-content: center;
	z-index: 1;
	width: 100%;
	top: 0;
	position: ${(props) => (props.removeFixedPosition ? "relative" : "fixed")};
	height: ${(props) => props.styledTopBar?.container?.height};
	padding-left: ${(props) => (props.hasSideBar && props.wheelPosition !== WheelPosition.RIGHT ? props.styledTopBar?.container?.paddingLeft : 0)};
	${(props) =>
		(!props.screenTitle && !props.titleLogo && !props.search && props?.demoOptions) || props.removeBackground
			? css`
					background-color: transparent;
			  `
			: css`
					background-color: rgba(0, 0, 0, 0.8);
			  `}
`;

export const StyledTextContainer = styled.View<any>`
	justify-content: center;
	align-items: left;
	flex: 1;
	${(props) =>
		props.addTitleMarginLeft &&
		css`
			margin-left: ${spacing_l};
		`}
`;

export const StyledText = styled.Text`
	font-family: ${FONT_FAMILY_DEFAULT};
	display: flex;
	flex-direction: column;
	width: 90%;
`;

export const StyledIconContainer = styled.View<any>`
	height: ${(props) => props.styledTopBar?.container?.iconHeight};
	align-items: center;
	justify-content: center;

	width: ${(props) => props.styledTopBar?.container?.iconWidth};
`;

export const StyledCloseContainer = styled.View<any>`
	height: ${(props) => props.styledTopBar?.container?.iconHeight};
	width: ${(props) => props.styledTopBar?.container?.iconWidth};
`;

export const StyledIconRenderer = styled.Image<any>`
	height: ${(props) => props.styledTopBar?.container?.iconRenderer};
	width: ${(props) => props.styledTopBar?.container?.iconRenderer};
`;

export const StyledLoading = styled.View<any>`
	width: 27.5rem;
	height: ${(props) => props.styledTopBar?.container?.iconRenderer};
	overflow: hidden;
	border-radius: 16px;
`;

export const StyledInnerContainer = styled.View`
	flex-direction: row;
`;

export const StyledCenterImage = styled.View<any>`
	text-align: center;
	justify-content: center;
	width: ${(props) => props.hasSideBar ? props.styledTopBar?.container?.iconWidth : '5.75rem'};
	align-items: center;
`;

export const styles = StyleSheet.create({
	backIcon: {
		resizeMode: "contain",
	},
	rightImage: {
		marginRight: 93,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: "white",
		borderStyle: "solid",
	},
	screenLabel: {
		borderColor: "white",
		textAlign: "left",
	},

	titleBody: {
		textAlign: "left",
		opacity: 0.8,
	},
	searchTextInput: {
		backgroundColor: light_black,
		fontFamily: FONT_FAMILY_DEFAULT,
		color: white_smoke,
		paddingLeft: 30,
	},
	outlineStyle: {
		borderRadius: 33,
	},
	stylesPreview: {
		height: 112,
		marginLeft: 40,
		justifyContent: "center",
	},
});
