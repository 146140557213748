import MainDefaultWrapper from "../../Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../Layout/MainLayout/MainNarrowWrapper";
import MainWideWrapper from "../../Layout/MainLayout/MainWideWrapper";
import { ScreenDimensions } from "../../../utils/enum";
import Main from "../../Layout/MainLayout/Main";
import { SignInNarrowLoader, SignInWideLoader } from "./SignInLoader";
import TopAppBar from "../../TopAppBar";
import AppBarWrapper from "../../Layout/MainLayout/AppBarWrapper";

const LoaderSignIn = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<>
					<AppBarWrapper dimensions={props.screenDimensions}>
						<TopAppBar removeBackground={true} />
					</AppBarWrapper>
					{/* @ts-ignore */}
					<MainNarrowWrapper>
						<SignInNarrowLoader isCodeSignIn={props?.isCodeSignIn} />
					</MainNarrowWrapper>
				</>
			);
		}

		if (props.screenDimensions === ScreenDimensions.wide) {
			return (
				<>
					<AppBarWrapper dimensions={props.screenDimensions}>
						<TopAppBar removeBackground={true} />
					</AppBarWrapper>
					<MainWideWrapper>
						<SignInWideLoader isCodeSignIn={props?.isCodeSignIn} />
					</MainWideWrapper>
				</>
			);
		}

		return (
			<>
				<AppBarWrapper dimensions={props.screenDimensions}>
					<TopAppBar removeBackground={true} />
				</AppBarWrapper>
				<MainDefaultWrapper>
					<SignInWideLoader isCodeSignIn={props?.isCodeSignIn} />
				</MainDefaultWrapper>
			</>
		);
	};

	return <Main>{renderScreenDimensions()}</Main>;
};
export default LoaderSignIn;
