import { Dimensions } from "react-native";
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
	routeHome,
	routePayment,
	routeScreenManager,
	PaymentScreenNavigationProp,
	routePlayer,
	routeTrailer,
	routeLogin,
	routePage,
	routeSearchScreen,
	routeDataManagementSettings,
	routeMyLibrary,
	routeAggregatePage,
	routeNewCuration,
	routePreview,
} from "../../Routes";
import { black, spacing_l } from "../../StyleHelpers";
import { Metadata } from "../../models/Metadata";
import styledRN from "rn-css";
import styled, { css } from "styled-components/native";
import TopAppBar from "../../components/TopAppBar/index";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import TabsView from "../../components/TabsView";
import ViewDetails from "./ViewDetails";
import Details from "./Details";
import GradientLeftSide from "../../components/GradientLayer/GradientLeftSide";
import Image from "../../components/Image/Image";
import TitleDetailsLoader from "../../components/Loaders/TitleDetailsLoader";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { redeemProductV8 } from "../../services/purchaseService";
import Toast from "../../components/Toast";
import { AsyncStorageKeys, Pages, direction, PurchaseTransactionType, CarActivity } from "../../Types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useGenericContentStore from "../../store/genericContent.store";
import Extras from "./Extras";
import { getProgressVideo } from "../../services/progressVideoService";
import {
	convertDuration,
	convertToLanguage,
	getVideoQualityByDeviceHeight,
	globalAny,
	historyFormat,
	isCoPassenger,
	isFord,
	isStaging,
	metadataTextSeparator,
	onCheckingNetwork,
} from "../../utils/Utils";
import { AppLogger } from "../../utils/AppLogger";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import { purchaseHistory, productPrice } from "../../services/paymentService";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
import { accountCredits, accountCreditsExpiration, accountSubscription } from "../../services/accountService";
import { TRANSACTION_TYPES } from "../../networking/networking";
import { getAvailableAudioLanguages, getConcurrency, getPlaybackWindow, postConcurrency } from "../../services/videoService";
import { getTrailer } from "../../services/trailerService";
import CarActivityDialog from "../../components/CarActivityDialog";
import { getAvailability } from "../../services/metadataService";
import { responseOk } from "../../utils/ValidateResponse";
import useMovieContentStore from "../../store/useMovieContent.store";
import useDrivingStore from "../../store/useDriving.store";
import useMqttStore from "../../store/useMqtt.store";
import ControllerContent from "../../components/ControllerContent";
import ScreenIcon from "../../assets/ControllerIcon/Screen-Icon.svg";
import ExitPreviewButton from "../../components/ExitPreviewButton";
import currencySymbols from "../../assets/CurrencySymbol/currencySymbol.json";
import { v4 as uuidv4 } from "uuid";
import useSearchDataStore from "../../store/useSearchData.store";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";
import useStyleHelpers from "../../hooks/useStyleHelpers";

interface Props {
	bgImage?: any;
	packshot?: any;
}

const StyledContainer = styled.View<any>`
	height: ${(props) => props.isFord ? '170vh' : '100vh' };
	 
	background-color: ${black};
`; // height: 200vh; enable when ford

const StyledViewContainer = styled.View<any>`
	${(props) =>
		!props.isNarrow &&
		css`
			display: flex;
			flex-direction: row;
			top: 0;
			padding: 2rem 0rem 0rem 6rem;
			align-items: flex-start;
			gap: ${props.gap};
			flex: 1 0 0;
			align-self: stretch;
		`}

	${(props) =>
		props.isNarrow &&
		css`
			display: flex;
			width: 81.25rem;
			flex-direction: row;
			padding: 2rem 1.5rem;
			justify-content: space-between;
			align-items: flex-start;
		`}
`;

const StyledTabsContainer = styled.View<any>`
	width: ${(props) => (props.isNarrow ? "auto" : "100%")};
	background-color: ${black};
`;

const StyledMain = styled.View`
	position: absolute;
	top: 0;
	right: 0;
	width: 90.75rem;
	height: 45.375rem;
	z-index: -1;
`;

const StyledMainNarrow = styled.View<any>`
	position: fixed;
	right: 0;
	bottom: 0;
	width: 40vw;
	height: ${(props) => props.height};
`;

const StyledMaskGroup = styled.View<any>`
	position: absolute;
	right: 0;
	width: 90.75rem;
	height: ${(props) => props.height};
	flex-shrink: 0;
	${(props) =>
		!props.bgImage &&
		css`
			background-color: black;
		`}

	${(props) =>
		props.bgImage &&
		css`
			mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.95) 80.43%, rgb(0, 0, 0, 0) 100%);
			background-image: url(${props.bgImage});
			background-position: top right;
			background-repeat: no-repeat;
			background-size: cover;
		`}
`;

const StyledMaskGroupNarrow = styled.View<any>`
	position: absolute;
	right: 0;
	bottom: 0;
	width: 40vw;
	height: ${(props) => props.height};
	flex-shrink: 0;
	${(props) =>
		!props.bgImage &&
		css`
			background-color: black;
		`}

	${(props) =>
		props.bgImage &&
		css`
			mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.95) 80.43%, rgb(0, 0, 0, 0) 100%);
			background-image: url(${props.bgImage});
			background-position: top right;
			background-repeat: no-repeat;
			background-size: cover;
		`}
`;

const TabsContainer = styled.View<any>`
	position: absolute;
	width: 100%;
	z-index: -1;
	background-color: black;

	${(props) =>
		props.screenDimensions === ScreenDimensions.narrow &&
		css`
			margin: 5rem 0 0 0;
		`}

	${(props) =>
		props.screenDimensions !== ScreenDimensions.narrow &&
		css`
			margin: 7rem 0 0 0;
		`}
`;

const StyledImage = styled.View`
	margin-top: ${spacing_l};
	display: flex;
`;

const StyledPackshot = styledRN.View`
	width: 420px;
	height: 630px;
	border-radius: 1rem;
	outline: 2px solid rgba(255, 255, 255, 0.30);
	overflow: hidden;
	position: absolute;

	@media (max-width: 640px) {
		margin-top:50px;
		width: 160px;
		height: 270px;
	}
	@media (min-width: 640px) {
		width: 300px;
		height: 480px;
	}
	@media (min-width: 768px) {
		margin-left:60px;
		width: 320px;
		height: 500px;
	}
	@media (min-width: 1536px) {
		margin-left:112px;
		width: 420px;
		height: 630px;
	}
`;

const StyledPackshotNarrow = styled.View`
	display: flex;
	width: 10.875rem;
	height: 16.3125rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	border-radius: 0.75rem;
	outline: 2px solid rgba(255, 255, 255, 0.3);
`;

const contentPerRow = 24;

const NewTitleDetails = (props: any) => {
	const navigation = useNavigation<any>();
	const navigationPayment = useNavigation<PaymentScreenNavigationProp>();
	const { route } = props;
	const { parentProductId, preview } = route.params;
	const [metadata, setMetadata] = useState<Metadata>();
	const [backgroundImage, setBackgroundImage] = useState("");
	const [ratingReason, setRatingReason] = useState("");
	const [progressBar, setProgressBar] = useState(0);
	const [streamProgress, setStreamProgress] = useState(0);
	const [director, setDirector] = useState([]);
	const [ownProduct, setOwnProduct] = useState(false);
	const [packshot, setPackshot] = useState();
	const [selectedProductRedeemed, setSelectedProductRedeemed] = useState<any>();
	const [showRedeemModal, setShowRedeemModal] = useState(false);
	const [showPurchaseModal, setShowPurchaseModal] = useState(false);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState("");
	const [type, setType] = useState(ComponentTypeEnum.Secondary);
	const [vamData, setVamData] = useState<any>({});
	const [channelData, setChannelData] = useState([]);
	const [isTransactionHistory, setIsTransactionHistory] = useState(false);
	const [transactionHistory, setTransactionHistory] = useState(false);
	const [hasCredit, setHasCredit] = useState(false);
	const [showCarToast, setShowCarToast] = useState(false);
	const [isRent, setIsRent] = useState<any>(null);
	const [expiryDate, setExpiryDate] = useState({});
	const [availabilityRedeem, setAvailabilityRedeem] = useState(false);
	const [availabilityPurchase, setAvailabilityPurchase] = useState(false);
	const [availabilityRent, setAvailabilityRent] = useState(false);
	const [noVamData, setNoVamData] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [movieAvailableLang, setMovieAvailableLang] = useState("");
	const [audioLang, setAudioLang] = useState("");
	const [subtitleLang, setSubtitleLang] = useState("");
	const [isTrailer, setIsTrailer] = useState(false);
	const [ownedTransaction, setOwnedTransaction] = useState<any>({});
	const NO_IMAGE_RETURNED = "";
	const onDismissSnackBar = () => setVisible(false);
	const movieStoreValue = useMovieContentStore((state: any) => state.data);
	const fetchTitleDetails = useMovieContentStore((state: any) => state.fetchTitleDetails);
	const fetchTitleDetailsPreview = useMovieContentStore((state: any) => state.fetchTitleDetailsPreview);
	const selectedTabs = useGenericContentStore((state: any) => state.selectedTabs);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const setVamDetails = useGenericContentStore((state: any) => state.setVamDetails);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const isSuccess = useGenericContentStore((state: any) => state.isSuccess);
	const dimensions = useGenericContentStore((state: any) => state.dimensions);
	const setIsSuccess = useGenericContentStore((state: any) => state.setIsSuccess);
	const hasRent = usePaymentTransactionStore((state: any) => state.hasRent);
	const setHasRent = usePaymentTransactionStore((state: any) => state.setHasRent);
	const setPurchasePrice = usePaymentTransactionStore((state: any) => state.setPurchasePrice);
	const setRentPrice = usePaymentTransactionStore((state: any) => state.setRentPrice);
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const mqttDevices = useMqttStore((state: any) => state.mqttDevices);
	const setPlaySessionId = useMqttStore((state: any) => state.setPlaySessionId);
	const currencySymbol = usePaymentTransactionStore((state: any) => state.currencySymbol);
	const setCurrencySymbol = usePaymentTransactionStore((state: any) => state.setCurrencySymbol);
	const lastSearchData = useSearchDataStore((state: any) => state.lastSearchData);
	const aggregatePageId = useGenericContentStore((state: any) => state.aggregatePageId);
	const uniqueId = useGenericContentStore((state: any) => state.uniqueId);
	const isRenderTDP = useGenericContentStore((state: any) => state.isRenderTDP);
	const [isLoadingButton, setIsLoadingButton] = useState(false);
	const [textRent, setTextRent] = useState("");
	const [textPurchase, setTextPurchase] = useState("");
	const [availableTransactionType, setAvailableTransactionType] = useState(0);
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const isNarrow = () => screenDimensions === ScreenDimensions.narrow;
	const [isPaymentsOptions, setIsPaymentsOptions] = useState(true);
	const styledTDP = styleHelper?.tdp;

	const controllerData = [
		{ id: 0, icon: ScreenIcon, buttonText: globalAny.language.assign_to_screens, textDescription: globalAny.language.assign_media_to_screen },
		{ id: 1, icon: "", buttonText: globalAny.language.cancel, textDescription: globalAny.language.select_media_to_assign },
	];
	const [controller, setController] = useState(controllerData[0]);
	const [id, setId] = useState(0);

	let menuList = [
		{ title: globalAny.language.view, disabled: false },
		{ title: globalAny.language.details, disabled: carActivity === CarActivity.INMOTION },
		{ title: globalAny.language.extras, disabled: noVamData ? true : carActivity === CarActivity.INMOTION },
	];

	let menuListPreview = [
		{ title: globalAny.language.view, disabled: false },
		{ title: globalAny.language.details, disabled: carActivity === CarActivity.INMOTION },
	];
	const [menuItem, setMenuItem] = useState(menuList);

	const wording = {
		redeemContent: globalAny.language.redeem_content,
		modalFooter: `${globalAny.language.available_audio_language} English, Spanish, French, German, Italian<br> ${globalAny.language.available_closed_caption_language} English, Spanish, French, German, Italian`,
		redeemCredit: globalAny.language.redeem_credit,
		redeemSuccess: globalAny.language.redeem_success,
		redeemLimit: globalAny.language.redeem_limit,
		purchaseRentContent: globalAny.language.purchase_rent_content,
		purchaseOnlyContent: globalAny.language.purchase_only_content,
		rent: globalAny.language.rent_for,
		rentExpiry: globalAny.language.rent_expiry,
		purchase: globalAny.language.purchase_for,
		movieNotAvailable: globalAny.language.movie_not_available_user,
		errorPurchase: globalAny.language.error_purchase,
		cancel: globalAny.language.cancel,
		concurrentLimit: globalAny.language.concurrent_limit,
		processing: globalAny.language.processing,
	};

	//#region Function
	/**
	 * Fetch price of current ProductId
	 * Note: currency symbol is not yet dynamic.
	 */
	const fetchPrice = async () => {
		const currentProductPrice: any = await productPrice(parentProductId);
		const [productPurchasePrice] =
			currentProductPrice?.productTierDetails?.filter((product: any) => product.transactionTypeId === PurchaseTransactionType.Purchase) ?? [];
		const [productRentPrice] =
			currentProductPrice?.productTierDetails?.filter((product: any) => product.transactionTypeId === PurchaseTransactionType.Rent) ?? [];
		const rentCurrencyCode = productRentPrice?.currency;
		const purchaseCurrencyCode = productPurchasePrice?.currency;
		setCurrencySymbol(getCurrencySymbol(rentCurrencyCode) ?? getCurrencySymbol(purchaseCurrencyCode));
		setPurchasePrice(productPurchasePrice?.price);
		setTextPurchase(`${wording.purchase}${currencySymbol}${productPurchasePrice?.price}`);

		if (productRentPrice) {
			setRentPrice(productRentPrice?.price);
			setTextRent(`${wording.rent}${currencySymbol}${productRentPrice?.price}`);
			setHasRent(true);
		}
	};

	const getVam = (result: any) => {
		if (result?.vam) {
			setVamData(result?.vam);
			setNoVamData(false);
			const newMenuItem = menuItem.map((item) => {
				if (item.title === "Extras") {
					return { ...item, disabled: false };
				}
				return item;
			});
			setMenuItem(newMenuItem);
			if (result?.vam?.products?.length === 0) {
				const newMenuItem = menuItem.map((item) => {
					if (item.title === "Extras") {
						return { ...item, disabled: true };
					}
					return item;
				});
				setMenuItem(newMenuItem);
				setNoVamData(true);
			}
		} else {
			const newMenuItem = menuItem.map((item) => {
				if (item.title === "Extras") {
					return { ...item, disabled: true };
				}
				return item;
			});
			setMenuItem(newMenuItem);
			setNoVamData(true);
		}
	};

	const onPreview = (metadata: any) => {
		setMetadata(metadata);

		const crewMember = filterRole(metadata?.crewMembers, "Director") ?? filterRole(metadata?.crew, "Director");
		setDirector(crewMember);

		const portraitImage = metadata.images?.filter((val: any) => val.width === 600 && val.height === 900)?.map((val: any) => val.url)[0];
		setPackshot(portraitImage);
		setRatingReason(metadataTextSeparator(metadata));
		setOwnProduct(false);
		setAvailabilityRent(false);
		setAvailabilityPurchase(false);
		setAvailabilityRedeem(false);
		setSelectedProductRedeemed({ owned: true });
		getVam({});
	};

	const getMetadata = async (result: any) => {
		if (result?.metadata) {
			if (preview) {
				onPreview(result?.metadata);
				return;
			}

			await onAvailability(result);
			setMetadata(result?.metadata);
			progressVideo(result?.metadata.duration);

			const crewMember = filterRole(result?.metadata?.crewMembers, "Director") ?? filterRole(result?.metadata?.crew, "Director");
			setDirector(crewMember);

			const portraitImage = result?.metadata.images
				?.filter((val: any) => val.width === 600 && val.height === 900)
				?.map((val: any) => val.url)[0];
			setPackshot(portraitImage);
			setRatingReason(metadataTextSeparator(result?.metadata));
		}
	};

	const getOwnedStatus = async (result: any) => {
		if (result?.ownedstatus) {
			const owned = result?.ownedstatus?.filter((val: any) => val.owned === true).sort((a: any, b: any) => a - b);
			fetchAudioLanguages(owned);

			if (owned?.length === 0) {
				setSelectedProductRedeemed(result?.ownedstatus);
				setProgressBar(0);
			} else {
				setOwnedTransaction(owned.find((val: any) => val.owned === true));
				const hasRented = owned?.find((val: any) => val.transactionType === TRANSACTION_TYPES.TVOD && val.owned === true);
				const hasPurchased = owned?.find((val: any) => val.transactionType !== TRANSACTION_TYPES.TVOD && val.owned === true);

				setSelectedProductRedeemed(result?.ownedstatus);
				setOwnProduct(true);

				setIsRent(!hasPurchased && hasRented);
				if (!hasPurchased && hasRented) {
					const videoQuality = getVideoQualityByDeviceHeight(dimensions.screen.height);
					const expiryDetails: any = await getPlaybackWindow(parentProductId, TRANSACTION_TYPES.TVOD, videoQuality);
					expiryDetails?.utcExpiryDate ? setExpiryDate(expiryDetails) : setIsRent(false);
				}
			}
		}
	};

	const filterRole = (data: any, role: string) => {
		const newRole = data?.filter((val: any) => val.role === role).map((val: any) => val.name);
		return newRole;
	};

	const checkNetwork = async () => {
		const isConnected = await onCheckingNetwork();

		if (!isConnected || isConnected === undefined) {
			setVisible(true);
			setToastMessage(globalAny.language.network_connection_lost);
			setLabelText("");
		}
	};

	const onAvailability = async (result: any) => {
		const availability: any = await getAvailability(parentProductId);
		const hasEST = availability?.transactionTypes?.indexOf(TRANSACTION_TYPES.EST) > -1;
		const hasTVOD = availability?.transactionTypes?.indexOf(TRANSACTION_TYPES.TVOD) > -1;
		const isFree = availability?.transactionTypes?.indexOf(TRANSACTION_TYPES.FVOD) > -1;
		let accountDetails = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.accountDetails, true);
		const [availabilityTransactionType] = availability?.transactionTypes ?? [TRANSACTION_TYPES.EST];

		setAvailableTransactionType(availabilityTransactionType);

		if (!accountDetails) {
			const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), accountSubscription(), purchaseHistory()]);
			// @ts-ignore
			const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

			CacheStorageUtils.setCacheItem(
				AsyncStorageKeys.accountDetails,
				[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
				true
			);

			accountDetails = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.accountDetails, true);
		}

		const [userCredits] = accountDetails;
		const hasAvailableCredit = userCredits.creditsAvailable > 0;
		let hasRedeemed: boolean, hasRented: boolean, hasPurchased: boolean, hasSubscribed: boolean;

		const isOwned = () => {
			return isFree || hasSubscribed || hasRedeemed || hasRented || hasPurchased;
		};

		if (result?.ownedstatus) {
			const owned = result?.ownedstatus?.filter((val: any) => val.owned === true);
			hasSubscribed = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.SVOD && val.owned).length > 0;
			hasRedeemed = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.EST && val.owned).length > 0;
			hasRented = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.TVOD && val.owned).length > 0;
			hasPurchased = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.EST && val.owned).length > 0;
		}

		if (isOwned()) {
			setOwnProduct(true);
			return;
		}

		if (availability?.releaseType === 2) {
			setAvailabilityRent(hasTVOD);
			setAvailabilityPurchase(hasEST);
			setAvailabilityRedeem(false);
			return;
		}

		setHasCredit(hasAvailableCredit);

		if (availability?.releaseType === 0 || !availability?.releaseType) {
			if (hasAvailableCredit) {
				setAvailabilityRedeem(true);
				setAvailabilityRent(hasTVOD);
				setAvailabilityPurchase(false);
				return;
			}

			setAvailabilityRedeem(false);
			setAvailabilityRent(hasTVOD);
			setAvailabilityPurchase(hasEST);
		}
	};

	const progressVideo = async (duration: any) => {
		try {
			setIsLoadingButton(true);
			const result: any = await getProgressVideo(parentProductId);
			if (result?.responseCode === responseOk) {
				const streamProgress: any = (Number(result?.streamProgress) / (convertDuration(duration) * 1000)) * 100 || 0;
				setStreamProgress(result?.streamProgress);
				setProgressBar(streamProgress);
				setIsLoadingButton(false);
			}
		} catch {
			setIsLoadingButton(false);
		}
	};

	const getImages = async () => {
		const result = movieStoreValue.titleDetails.titles[parentProductId];
		let TBG = result?.images.tbg;

		try {
			// @ts-ignore
			let imageToUse = TBG;
			if (imageToUse) {
				setBackgroundImage(`${imageToUse.imageUrl}?width=1600&disablewebp=false`);
			} else {
				setBackgroundImage(NO_IMAGE_RETURNED);
			}
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const exitPreview = () => {
		navigation.navigate(routeLogin);
	};

	const onPlay = async () => {
		await checkNetwork();
		AppLogger.log("ownedTransaction", ownedTransaction);
		const streamLimit: any = await getConcurrency(ownedTransaction?.transactionType ?? 1);

		if (!streamLimit?.globalStreams || streamLimit?.streamsAvailable === 0) {
			setType(ComponentTypeEnum.Secondary);
			setToastMessage(wording.concurrentLimit);
			setLabelText(globalAny.language.ok);
			setVisible(true);
			return;
		}

		const playSessionId = uuidv4();
		setPlaySessionId(playSessionId);
		localStorage.setItem(AsyncStorageKeys.playSessionId, playSessionId);
		await postConcurrency(isRent ? PurchaseTransactionType.Rent : PurchaseTransactionType.Purchase);
		navigation.navigate(routePlayer, { parentProductId, resumeProgress: streamProgress ?? 0 });
	};

	const onTrailer = async () => {
		await checkNetwork();

		const trailer: any = await getTrailer(parentProductId);
		const streamUrl = trailer.url;

		navigation.navigate(routeTrailer, preview ? { parentProductId, streamUrl, preview: true } : { parentProductId, streamUrl });
	};

	const onAssignScreen = () => {
		if (channelData.length > 1) {
			setVamDetails(null);
			navigation.navigate(routeScreenManager, { location: Pages.assignScreen, parentProductId: parentProductId, redirectTo: direction.main });
			return;
		}
		setType(ComponentTypeEnum.Secondary);
		setVisible(true);
		setToastMessage(globalAny.language.assign_single_screen_message);
		setLabelText("");
	};

	const onClickRedeemMovies = () => {
		setShowRedeemModal(true);
	};

	const onClickPurchaseOptions = async () => {
		try {
			const allowUntil = await AsyncStorage.getItem(AsyncStorageKeys.allowUntil);
			const currentTime = new Date().getTime();

			if (allowUntil && parseInt(allowUntil) > currentTime) {
				setToastMessage(globalAny.language.please_try_again_after);
				setVisible(true);
				return;
			}

			if (availabilityPurchase && !availabilityRent) {
				onPurchaseMovie();
				return;
			}

			if (availabilityRent && !availabilityPurchase) {
				onRentMovie();
				return;
			}

			setShowPurchaseModal(true);
		} catch (error) {
			AppLogger.error(error);
		}
	};

	const onClose = () => {
		navigation.navigate(preview ? routeLogin : routeHome);
		setNavigateEvent("");
		setSelectedTabs(0);
	};

	const redeemError = () => {
		if (mistakeCount < 3) {
			setType(ComponentTypeEnum.Secondary);
			setShowRedeemModal(false);
			setVisible(true);
			setToastMessage(wording.errorPurchase);
			setLabelText(globalAny.language.label_retry);
			setMistakeCount(mistakeCount + 1);
			return;
		}
		setLabelText("");
		setType(ComponentTypeEnum.Secondary);
		const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
		AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
		setToastMessage(wording.redeemLimit);
		setVisible(true);
		setShowRedeemModal(false);
	};

	const onRedeemMovies = async () => {
		setIsProcessing(true);
		const result: any = await redeemProductV8(parentProductId, 6);
		if (result?.responseCode === 10006) {
			const details: any = await CacheStorageUtils.getCacheItem("titledetails_" + parentProductId, true);

			if (details) {
				const newOwnedStatus = details["ownedstatus"].map((item: any) => {
					return { ...item, owned: true };
				});

				const newDetails = {
					images: details["images"],
					metadata: details["metadata"],
					ownedstatus: newOwnedStatus,
					vam: details["vam"],
				};

				await CacheStorageUtils.setCacheItem("titledetails_" + parentProductId, newDetails, true);
				setSelectedProductRedeemed(newOwnedStatus);
			}

			const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), accountSubscription(), purchaseHistory()]);
			// @ts-ignore
			const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

			CacheStorageUtils.setCacheItem(
				AsyncStorageKeys.accountDetails,
				[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
				true
			);

			setIsProcessing(false);
			setShowRedeemModal(false);
			setOwnProduct(true);
			setShowRedeemModal(false);
			setType(ComponentTypeEnum.Primary);
			setToastMessage(wording.redeemSuccess.replace("%d", userCreditsData?.creditsAvailable));
			setLabelText("VIEW DETAILS");
			setVisible(true);
			fetchTitleDetails(parentProductId, true);
			return;
		}

		setIsProcessing(false);
		redeemError();
	};

	const onDisabledPayments = () => {
		setVisible(true);
		setType(ComponentTypeEnum.Processing);
		setToastMessage(globalAny.language.feature_available_for_demo);
		setLabelText(globalAny.language.ok.toUpperCase());
	};

	const onPurchaseMovie = () => {
		if (isPaymentsOptions) return onDisabledPayments();
		setSelectedTabs(0);
		setShowPurchaseModal(false);
		navigationPayment.navigate(routePayment, { parentProductId, paymentType: "purchase" });
	};

	const onRentMovie = () => {
		if (isPaymentsOptions) return onDisabledPayments();

		setSelectedTabs(0);
		setShowPurchaseModal(false);
		navigationPayment.navigate(routePayment, { parentProductId, paymentType: "rent" });
	};

	const navigateWithEvent = () => {
		if (navigateEvent === Pages.page) {
			navigation.navigate(routePage, { uniqueId });
			return;
		}
		if (navigateEvent === Pages.search) {
			if (lastSearchData.length > 0) {
				navigation.navigate(routeSearchScreen);
			} else {
				setSelectedTabs(0);
				navigation.navigate(routeSearchScreen);
				setNavigateEvent("");
			}
			return;
		}
		if (navigateEvent === Pages.transactions) {
			setSelectedTabs(0);
			navigation.navigate(routeDataManagementSettings, { location: Pages.transactions });
			setNavigateEvent("");
			return;
		}
		if (navigateEvent === Pages.library) {
			setSelectedTabs(0);
			navigation.navigate(routeMyLibrary);
			setNavigateEvent("");
			return;
		}
		if (navigateEvent === Pages.homeAggregatePage) {
			navigation.navigate(routeAggregatePage, { id: aggregatePageId.id, location: aggregatePageId.location });
			setSelectedTabs(0);
			return;
		}
		setSelectedTabs(0);
		navigation.navigate(routeNewCuration);
		setNavigateEvent("");
	};

	const back = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		if (isTransactionHistory) {
			setIsTransactionHistory(false);
			return;
		}
		if (preview) {
			navigation.navigate(routePreview);
			return;
		}

		if (!navigateEvent || navigateEvent === Pages.main) {
			setSelectedTabs(0);
			setNavigateEvent("");
			navigation.replace(routeHome);
			return;
		}

		navigateWithEvent();
	};

	const dismissRedeemModal = () => {
		setShowRedeemModal(false);
	};

	const dismissPurchaseModal = () => {
		setShowPurchaseModal(false);
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_view_details) {
			checkCacheData();
			return;
		}
		if (command === globalAny.language.label_retry) {
			setVisible(true);
			onRedeemMovies();
			return;
		}

		if (command === globalAny.language.ok.toUpperCase()) {
			setVisible(false);
			dismissPurchaseModal();
			return;
		}
	};

	const checkCacheData = async () => {
		const transactionHistory: any = await purchaseHistory();
		if (transactionHistory.responseCode === responseOk) {
			setTransactionHistory(historyFormat(transactionHistory?.purchases));
			setIsTransactionHistory(true);
			return;
		}

		setType(ComponentTypeEnum.Secondary);
		setToastMessage(globalAny.language.no_transaction_history);
		setVisible(true);
	};

	const calculateGridItemsByWindowWidth = (width: any) => {
		if (width <= 619) return 3;
		return 4;
	};

	const onController = () => {
		if (id === 0) {
			setController(controllerData[1]);
			setId(1);
			return;
		}
		setController(controllerData[0]);
		setId(0);
	};

	const fetchAudioLanguages = async (ownedStatus: any) => {
		const availableLanguages: any = await getAvailableAudioLanguages(
			parentProductId,
			1080,
			ownedStatus[0]?.transactionType ?? availableTransactionType
		);
		if (availableLanguages.responseCode === responseOk) {
			const audio = convertToLanguage(availableLanguages.audioLanguages);
			const subtitle = convertToLanguage(availableLanguages.subtitleLanguages);
			setAudioLang(audio);
			setSubtitleLang(subtitle);
			setMovieAvailableLang(
				`${globalAny.language.available_audio_language} ${audio}<br> ${globalAny.language.available_closed_caption_language} ${subtitle}`
			);
		}
	};

	const checkAvailableTrailer = async () => {
		const trailer: any = await getTrailer(parentProductId);
		const trailerUrl = trailer.url;
		if (trailerUrl && !isCoPassenger()) {
			setIsTrailer(true);
			return;
		}

		setIsTrailer(false);
	};

	const init = async () => {
		try {
			preview ? fetchTitleDetailsPreview(parentProductId, true) : fetchTitleDetails(parentProductId, true);
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const getCurrencySymbol = (currencyCode: any) => {
		const symbol = currencySymbols.find((item: any) => item.code === currencyCode);
		return symbol?.symbol_native;
	};
	//#endregion

	//#region useEffect
	useEffect(() => {
		if (!mqttDevices.length) {
			return;
		}

		setChannelData(mqttDevices);
	}, [mqttDevices]);

	useEffect(() => {
		init();
		checkAvailableTrailer();
	}, []);

	useEffect(() => {
		setShowCarToast(carActivity === CarActivity.INMOTION);
		setMenuItem(menuList);
	}, [carActivity]);

	useEffect(() => {
		if (preview) {
			setMenuItem(menuListPreview);
			return;
		}
		setMenuItem(menuList);
	}, [preview]);

	useEffect(() => {
		(async () => {
			const result = await movieStoreValue.titleDetails.titles[parentProductId];
			if (!preview) await fetchPrice();

			if (result?.metadata?.responseCode === 40058 || isNaN(parentProductId)) {
				setVisible(true);
				setToastMessage(wording.movieNotAvailable);
				setTimeout(() => {
					setSelectedTabs(0);
					location.replace("/landing");
				}, 3000);
				return;
			}

			if (result?.images) {
				getImages();
			}

			getMetadata(result);

			if (!preview) {
				const promiseResult = await Promise.allSettled([
					accountCredits(),
					accountCreditsExpiration(),
					accountSubscription(),
					purchaseHistory(),
				]);
				// @ts-ignore
				const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

				CacheStorageUtils.setCacheItem(
					AsyncStorageKeys.accountDetails,
					[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
					true
				);

				getVam(result);
				getOwnedStatus(result);
			}
		})();
	}, [route.params, movieStoreValue, isRenderTDP]);

	useEffect(() => {
		if (isSuccess.result) {
			setType(ComponentTypeEnum.Primary);
			setLabelText(globalAny.language.label_view_details);

			if (isSuccess.isRented) {
				setToastMessage(globalAny.language.movie_rented);
			} else {
				setToastMessage(globalAny.language.movie_purchased);
			}

			setVisible(true);
			setTimeout(() => {
				setIsSuccess(false, false);
			}, 6000);
		}
	}, [isSuccess]);

	//#endregion

	const renderPackshot = () => {
		if (isNarrow() && !backgroundImage) {
			return (
				//@ts-ignore
				<StyledPackshotNarrow>
					<Image source={{ uri: packshot }} width={"100%"} height={"100%"} style={{ borderRadius: "0.75rem", position: "absolute" }} />
				</StyledPackshotNarrow>
			);
		}

		return (
			//@ts-ignore
			<StyledImage>
				{!backgroundImage && (
					<>
						{/* @ts-ignores */}
						<StyledPackshot>
							<Image source={{ uri: packshot }} width={"100%"} height={"100%"} style={{ borderRadius: 16, position: "absolute" }} />
						</StyledPackshot>
					</>
				)}
			</StyledImage>
		);
	};

	const renderBackgroundNarrow = () => {
		if (backgroundImage && isNarrow()) {
			return (
				//@ts-ignores
				<StyledMainNarrow height={styleHelper?.tdp?.height}>
					<StyledMaskGroupNarrow height={styleHelper?.tdp?.height} bgImage={backgroundImage} />
					<GradientLeftSide isNarrow={isNarrow()} height={styleHelper?.tdp?.height} />
				</StyledMainNarrow>
			);
		}

		return <></>;
	};

	const renderBackground = () => {
		if (backgroundImage && !isNarrow()) {
			return (
				//@ts-ignores
				<StyledMain>
					<StyledMaskGroup height={styleHelper?.tdp?.height} bgImage={backgroundImage} />
					<GradientLeftSide height={styleHelper?.tdp?.height} />
				</StyledMain>
			);
		}

		return <></>;
	};

	return (
		<>
			{/* @ts-ignores */}
			<StyledContainer isFord={isFord()}>
				<TopAppBar
					leftIcon={closeIcon}
					leftTitleIcon={backIcon}
					leftIconWidth={92}
					screenTitle={isTransactionHistory ? "Transaction History" : metadata?.title}
					onPressLeftIcon={onClose}
					onPressLeftTitleIcon={back}
					isShowClose={false}
				/>

				{!isTransactionHistory && (
					<TabsContainer screenDimensions={screenDimensions}>
						{!metadata?.title && <TitleDetailsLoader />}
						{metadata?.title && selectedProductRedeemed && (
							<TabsView
								isTDP={true}
								tabsToRender={menuItem}
								paddingLeft={styleHelper?.tdp?.paddingLeft}
								disabled={!(metadata && selectedProductRedeemed)}
							>
								{/* @ts-ignores */}
								<StyledTabsContainer isNarrow={isNarrow()}>
									{renderBackgroundNarrow()}
									{/* @ts-ignores */}
									<StyledViewContainer isNarrow={isNarrow()} gap={styleHelper?.tdp?.packshotGap}>
										{renderBackground()}
										{/* @ts-ignores */}
										<ViewDetails
											metadata={metadata}
											genres={metadata?.genres}
											ratingReason={ratingReason}
											rating={metadata?.rating}
											synopsis={metadata?.synopsis}
											parentProductId={parentProductId}
											redeemed={ownProduct}
											selectedProductRedeemed={selectedProductRedeemed}
											streamProgress={progressBar}
											onPlay={() => onPlay()}
											onTrailer={() => onTrailer()}
											onAssignScreen={() => onAssignScreen()}
											onRedeemMovie={() => onClickRedeemMovies()}
											onPurchase={() => onClickPurchaseOptions()}
											hasCredit={hasCredit}
											isRent={isRent}
											expiryDate={expiryDate}
											availabilityRedeem={availabilityRedeem}
											availabilityPurchase={availabilityPurchase}
											availabilityRent={availabilityRent}
											purchaseText={textPurchase}
											rentText={textRent}
											isTrailer={isTrailer}
											isLoadingButton={isLoadingButton}
										/>
										{renderPackshot()}
									</StyledViewContainer>
								</StyledTabsContainer>
								{/* @ts-ignores */}
								<StyledTabsContainer isNarrow={isNarrow()}>
									<Details
										genres={metadata?.genres}
										ratingReason={ratingReason}
										rating={metadata?.rating}
										cast={metadata?.cast}
										synopsis={metadata?.synopsis}
										parentProductId={parentProductId}
										copyright={metadata?.copyright}
										audioLanguage={metadata?.language}
										director={director}
										audioLang={audioLang}
										subtitle={subtitleLang}
									/>
								</StyledTabsContainer>
								{/* @ts-ignores */}
								<StyledTabsContainer isNarrow={isNarrow()}>
									<Extras
										data={vamData?.products}
										parentProductId={parentProductId}
										redeem={ownProduct}
										ribbonTitle={globalAny.language.assign_single_screen_message}
										id={id}
										styledTDP={styledTDP?.extras.mainContainer}
									/>
								</StyledTabsContainer>
							</TabsView>
						)}
					</TabsContainer>
				)}
				{channelData && selectedTabs === 2 && isStaging() && (
					<ControllerContent
						data={controller}
						isTitle={channelData?.length <= 1}
						ribbonTitle={globalAny.language.assign_single_screen_message}
						onPress={() => onController()}
					/>
				)}
				{isTransactionHistory && <PrivacyPolicy transactionHistory={transactionHistory} />}
			</StyledContainer>

			{showRedeemModal && (
				<CustomizeDialogBox
					Header={metadata?.title}
					Body={wording.redeemContent}
					Footer={movieAvailableLang}
					ButtonTextLeft={wording.redeemCredit}
					ButtonTextRight={isProcessing ? wording.processing : wording.cancel}
					SingleButton={!!isProcessing}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Tertiary}
					TypeButtonColorRight={isProcessing ? ComponentTypeEnum.Tertiary : ComponentTypeEnum.Secondary}
					isProcessing={!!isProcessing}
					onPressRight={isProcessing ? () => {} : dismissRedeemModal}
					onPressLeft={onRedeemMovies}
				/>
			)}

			{showPurchaseModal && (
				<CustomizeDialogBox
					Header={metadata?.title}
					Body={hasRent ? wording.purchaseRentContent : wording.purchaseOnlyContent}
					Footer={movieAvailableLang}
					ButtonTextLeft={textPurchase}
					ButtonTextMiddle={hasRent ? textRent : textPurchase}
					ButtonTextRight={isProcessing ? wording.processing : wording.cancel}
					rentText={hasRent ? wording.rentExpiry : ""}
					hasSubTextLeft={false}
					SingleButton={!!isProcessing}
					isProcessing={!!isProcessing}
					HasMiddleButton={availabilityRedeem ? false : availabilityRent}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Primary}
					TypeButtonColorMiddle={ComponentTypeEnum.Primary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onPressRight={isProcessing ? () => {} : dismissPurchaseModal}
					onPressMiddle={hasRent ? onRentMovie : onPurchaseMovie}
					onPressLeft={onPurchaseMovie}
					screenDimensions={screenDimensions}
				/>
			)}
			<Toast
				visible={visible}
				text={toastMessage}
				label={labelText}
				type={type}
				onDismissSnackBar={onDismissSnackBar}
				onPress={() => onPressToast(labelText)}
			/>

			<CarActivityDialog
				title={globalAny.language.car_in_motion_title}
				body={globalAny.language.car_in_motion_body}
				buttonText={globalAny.language.car_in_motion_back_to_movie}
				onPress={() => setSelectedTabs(0)}
				show={carActivity === CarActivity.INMOTION && selectedTabs === 1}
			/>

			<Toast
				visible={showCarToast && selectedTabs !== 1}
				text={globalAny.language.car_in_motion_body}
				label={labelText}
				type={type}
				onDismissSnackBar={() => setShowCarToast(false)}
				onPress={() => onPressToast(labelText)}
			/>

			{preview && metadata?.title ? <ExitPreviewButton onPress={() => exitPreview()} /> : <></>}
		</>
	);
};

export default NewTitleDetails;
