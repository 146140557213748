import { View } from "react-native";
import styled from "styled-components/native";
import ImgProgressBar from "./ImgProgressBar";
import { spacing_m } from "../../StyleHelpers";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import MainRow from "../Layout/MainRow";

interface Props {
	show?: boolean;
	data?: any;
	hideTitle?: boolean;
}

const StyledLoadingContainer = styled.View`
	flex-direction: column;
	margin-bottom: 88px;
`;

const StyledLoading = styled.View<any>`
	margin-right: ${(props) => props.columnGap};
	border-radius: 16px;
	overflow: hidden;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
`;

const StyledTitle = styled.View`
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
	width: 368px;
`;

const IntroText = styled.View`
	margin-top: 15px;
	height: 16px;
	border-radius: 8px;
	overflow: hidden;
	width: 37.66963rem;
`;

const StyledLoaderGrid = styled.View<any>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	width: calc(100% - ${props => props.paddingLeft});
`;

const IntroContainer = styled.View<any>`
	position: relative;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	justify-content: space-between;
	width: ${props => props.width};
	height: ${props => props.height};
`;

const IntroImage = styled.View<any>`
	position: absolute;
	right: 2rem;
	margin-right: ${spacing_m};
	border-radius: 16px;
	overflow: hidden;
	width: 30vw;
	margin-left: 315px;
	height: ${(props) => props.height};
`;

const IntroTextContainer = styled.View<any>`
	width: 37.66963rem;
	height: ${(props) => props.height};
	justify-content: center;
`;

const StyledVamTitle = styled.View<any>`
	margin-top: 0.5rem;
	width: 10rem;
	height: ${(props) => props.height};
	border-radius: 8px;
	overflow: hidden;
`;


const StudioLoader = (props: Props) => {
	const styleHelper = useStyleHelpers();

	if (props.show) {
		return (
			<View style={{ marginLeft: styleHelper?.paddingStart, marginTop: styleHelper?.paddingTop, width: '100%' }}>
					{/* @ts-ignore */}
					<IntroContainer
						height={styleHelper?.shortClipsBanner.height}
						width={styleHelper?.shortClipsBanner.width}
					>
						{/* @ts-ignore */}
						<IntroTextContainer
							height={styleHelper?.shortClipsBanner.height}>
							{/* @ts-ignore */}
							<IntroText>
								<ImgProgressBar />
							</IntroText>
							{/* @ts-ignore */}
							<IntroText>
								<ImgProgressBar />
							</IntroText>
							{/* @ts-ignore */}
							<IntroText style={{ width: 300 }}>
								<ImgProgressBar />
							</IntroText>
						</IntroTextContainer>

						{/* @ts-ignore */}
						<IntroImage 
						height={styleHelper?.shortClipsBanner.height}>
							<ImgProgressBar />
						</IntroImage>
					</IntroContainer>

					{props.data?.map((val: any) => {
						return (
							<MainRow isBanner={false} key={`studio-loader-mainrow-${val.id}`}>
								{!props.hideTitle && (
									// @ts-ignore
									<StyledTitle key={`studio-loader-title-${val.id}`} >
										<ImgProgressBar />
									</StyledTitle>
								)}
								{props.hideTitle && <View style={{ marginBottom: spacing_m }}></View>}
								{/* @ts-ignore  */}
								<StyledLoaderGrid paddingLeft={styleHelper?.paddingStart} key={`studio-loader-grid-${val.id}`} >
									{val?.packshot.map((item: number) => {
										return (
											// @ts-ignore
											<StyledLoadingContainer 
												key={`studio-loader-container-${val.id}-${item}`} >
												{/* @ts-ignore */}
												<StyledLoading 
													width={styleHelper?.vam.width} 
													height={styleHelper?.vam.height} 
													columnGap={styleHelper?.columnGap} 
													key={`studio-grid-${val.id}-${item}`} 
													isLandscape={val?.isLandscape}>
													<ImgProgressBar />
												</StyledLoading>
												{/* @ts-ignore */}
												<StyledVamTitle 
													height={styleHelper?.vamTitle.fontSize}
													key={`studio-title-${val.id}-${item}`}>
													<ImgProgressBar />
												</StyledVamTitle>
											</StyledLoadingContainer>
										);
									})}
								</StyledLoaderGrid>
							</MainRow>
						);
					})}
			</View>
		);
	}
	return null;
};

export default StudioLoader;