import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { TEXT_COLOR_ERROR } from "../../utils/Typography";
import { RegisterModel } from "../../models/RegisterModel";
import { AsyncStorageKeys, Pages, ProfileNameState } from "../../Types";
import { HomeScreenNavigationProp, ProfileNameSelectScreenNavigationProp, routeHome, routeProfileNameSelect } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { ACCOUNT_EXIST, Code_OK, DEVICE_NOT_ENTITLE, getPresignedUrl } from "../../networking/networking";
import { redeemVoucherCode, registerAccountNoVoucherNeeded, validateVoucher } from "../../services/loginService";
import { voucherValidate } from "../../utils/ValidateResponse";
import {
	globalAny,
	pushProfileNameStateToBrowserHistory,
	validateEmail,
	generateDeviceInfo,
	isInCarScreen,
	processToastMessage,
} from "../../utils/Utils";
import { AppLogger } from "../../utils/AppLogger";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import FlexRow from "../../components/Layout/FlexRow";
import StyledFormRow from "../../components/Layout/FormRow";
import Modal from "../../components/Modal";
import IFormInputModel from "../../models/IFormInputModel";
import H4 from "../../components/Typography/H4";
import useLoginStore from "../../store/useLogin.store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useMqttStore from "../../store/useMqtt.store";
import useGenericContentStore from "../../store/genericContent.store";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import { automaticVoucherRedemption } from "../../services/accountService";
import CreateAccount from "./CreateAccount";
import useDimensions from "../../hooks/useDimensions";

const LazyDatePickerWidget = React.lazy(() => import("../../components/DatePickerWidget"));
interface FormModel {
	email: IFormInputModel;
	password: IFormInputModel;
	confirmPassword: IFormInputModel;
	year: IFormInputModel;
	month: IFormInputModel;
	voucher: IFormInputModel;
	birthday: IFormInputModel;
	ageChecked: boolean;
}
interface Props {
	accountInfo: RegisterModel; //consolidate user choices (from legal term acceptance, checkbox, and so on) using this prop
	onPressPrivacy?: () => void;
	onPressBack?: () => void;
	setShowWelcomeSplash: (val: boolean) => void;
}

let saveAccount: any;

const CreateAccountForm = (props: Props) => {
	const [accountToSave, setAccountToSave] = useState<RegisterModel | null>(null);
	const [showDateModal, setShowDateModal] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [lastUsedDate, setLastUsedDate] = useState<MaterialUiPickersDate | null>(null);
	const [errorSummary, setErrorSummary] = useState("");
	const [readyToSaveAccount, setReadyToSaveAccount] = useState<boolean>(false);
	const [email, setEmail] = useState("");
	const [birthday, setBirthday] = useState("");
	const [secureTextEntry, setSecureTextEntry] = useState(true);
	const [loading, setLoading] = useState(false);
	const [passwordValidated, setPasswordValidated] = useState(false);
	const [showVoucherToast, setShowVoucherToast] = useState(false);
	const presignedUrl = useMqttStore((state: any) => state.presignedUrl);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const AGE_ALLOWED_MIN = 18;
	const emailUserPart = '([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)';
	const emailQuoted = '".+"';
	const emailIpAddress = "\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\]";
	const emailDomain = "([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}";
	const emailPattern = new RegExp(`^(${emailUserPart}|${emailQuoted})@(${emailIpAddress}|${emailDomain})$`);
	const birthdayValidated = birthday.length === 7;
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const [automaticVoucherCode, setAutomaticVoucherCode] = useState<any>({});
	const screenDimensions = useDimensions();
	const [form, setForm] = useState<FormModel>({
		voucher: { value: automaticVoucherCode, valid: true },
		email: { value: "", valid: true },
		password: { value: "", valid: true },
		confirmPassword: { value: "", valid: true },
		month: { value: 0, valid: true },
		year: { value: 0, valid: true },
		birthday: { value: "", valid: true },
		ageChecked: false,
	});

	const errorEmail = globalAny.language.enter_valid_email;
	const errorPassword = globalAny.language.password_rules;

	useEffect(() => {
		const emailValidated = validateEmail(email);

		if (emailValidated && passwordValidated && birthdayValidated) {
			setDisableSubmit(false);
			setLoading(false);
			return;
		}

		setDisableSubmit(true);
	}, [email, passwordValidated, birthday]);

	const isEmpty = (str: string) => {
		return !str || str.length === 0;
	};

	const isValidDate = (year: any, month: any) => {
		let numMonth = Number(month);
		let numYear = Number(year);
		let CurrentYear = new Date().getFullYear();
		return numMonth > 0 && numMonth < 13 && numYear > 1900 && numYear < CurrentYear;
	};

	/**
	 * Returns true if the entered birthdate is at least 18 years and 1 month before the current date
	 */
	const validateBirthday = (year: any, month: any) => {
		let CurrentDate = new Date(); // defaults to today
		let CurrentYear = CurrentDate.getFullYear();
		let CurrentMonth = CurrentDate.getMonth();

		let AgeYears = CurrentYear - Number(year);
		let AgeMonths = CurrentMonth - Number(month);

		return AgeYears > AGE_ALLOWED_MIN || (AgeYears == AGE_ALLOWED_MIN && AgeMonths > 0);
	};

	/**
	 *  Returns true if the entered email is in a valid email format
	 */
	const validateEmailAddress = () => {
		if (form.email.value != null && !isEmpty(form.email.value.toString())) {
			return emailPattern.test(form.email.value.toString());
		} else {
			return false;
		}
	};

	const validatePassword = (password: any) => {
		return password.length >= 6;
	};

	const validateVoucherCode = async () => {
		let valid = false;
		let errorMessage = "";

		try {
			const voucherEndpoint = await validateVoucher(form.voucher.value.toString());
			// @ts-ignore
			if (voucherEndpoint.response) {
				valid = true;
			} else {
				// @ts-ignore
				errorMessage = voucherValidate(voucherEndpoint.responseCode);
			}
		} catch (ex) {
			errorMessage = voucherValidate(40004);
		}

		return { valid, errorMessage };
	};

	const onEnterEmail = (event: any) => {
		setEmail(event);
		if (!validateEmail(event)) {
			return setForm({ ...form, email: { ...form.email, valid: false, errorMessage: errorEmail } });
		}
		setForm({ ...form, email: { ...form.email, valid: true, value: event } });
	};

	const onEnterPassword = (event: any) => {
		if (!validatePassword(event)) {
			setPasswordValidated(false);
			return setForm({ ...form, password: { ...form.password, valid: false, errorMessage: errorPassword } });
		}
		setPasswordValidated(true);
		setForm({ ...form, password: { ...form.password, valid: true, value: event } });
	};

	const onEnterBirthday = (event: any) => {
		if (event.length >= 7) {
			return setForm({ ...form, birthday: { ...form.birthday, valid: true, errorMessage: "" } });
		}

		setForm({ ...form, birthday: { ...form.birthday, valid: false, errorMessage: globalAny.language.enter_valid_birthday } });
		AppLogger.log(form);
	};

	// Use this to validate input form
	const validateForm = async () => {
		setForm({ ...form, birthday: { ...form.birthday, value: birthday } });
		const date = birthday.split("/");
		const year = date[0];
		const month = date[1];

		setForm({
			...form,
			year: { ...form.year, value: Number(year) },
			month: { ...form.month, value: Number(month) },
		});

		const emailValid = validateEmailAddress();
		const validatePassword1 = validatePassword(form.password.value.toString());
		const confirmPasswordValid = form.password.value === form.confirmPassword.value;
		let validateBday = isValidDate(year, month) && validateBirthday(year, month);
		let validateBdayLength = true;
		let birthdayValidate = false;
		let birthdayErrorMessage: any;

		if (!validateBday && birthday.length === 7) {
			birthdayErrorMessage = globalAny.language.valid_age;
			validateBday = false;
		}

		if (birthday.length > 0 && birthday.length <= 6) {
			birthdayErrorMessage = globalAny.language.enter_valid_birthday;
			validateBdayLength = false;
		}

		if (validateBdayLength && validateBday) {
			birthdayValidate = true;
		}

		let formToSet = {
			...form,
			email: { ...form.email, valid: emailValid, errorMessage: globalAny.language.invalid_email },
			password: { ...form.password, valid: validatePassword1, errorMessage: globalAny.language.password_rules },
			confirmPassword: { ...form.confirmPassword, valid: confirmPasswordValid, errorMessage: globalAny.language.passwords_dont_match },
			birthday: { ...form.birthday, valid: birthdayValidate, errorMessage: birthdayErrorMessage },
		};

		const hasVoucherCodeInput = form.voucher.value.toString().length > 0;
		let isVoucherValidated = true;

		if (!automaticVoucherCode) {
			isVoucherValidated = false;
			if (hasVoucherCodeInput) {
				//if there's voucher input, set validation
				let voucherCodeValidation = await validateVoucherCode();

				isVoucherValidated = voucherCodeValidation.valid;
				formToSet = {
					...formToSet,
					voucher: { ...form.voucher, valid: voucherCodeValidation.valid, errorMessage: voucherCodeValidation.errorMessage },
				};
			}
		}

		setForm(formToSet);

		if (!hasVoucherCodeInput) {
			if (emailValid && validatePassword1 && validateBday) {
				setReadyToSaveAccount(true);
				return;
			}
			setDisableSubmit(false);
			setLoading(false);
		} else {
			if (isVoucherValidated && emailValid && validatePassword1 && validateBday) {
				setReadyToSaveAccount(true);
				return;
			}
			setDisableSubmit(false);
			setLoading(false);
		}
	};

	const processLogin = async (saveAccount: any) => {
		const presignedService = await getPresignedUrl();
		setPresignedUrl(presignedService);
		AsyncStorage.setItem(AsyncStorageKeys.email, saveAccount.email);
		setShowVoucherToast(false);
		props.setShowWelcomeSplash(true);
	};

	const submitFormAndSaveAccount = async () => {
		setLoading(true);

		const date = birthday.split("/");
		const year = date[0];
		const month = date[1];
		const hwid = localStorage.getItem(AsyncStorageKeys.hwid);

		// @ts-ignore
		saveAccount = {
			...accountToSave,
			email: form.email.value.toString(),
			password: form.password.value.toString(),
			confirmPassword: form.password.value.toString(),
			deviceIdentifier: `${localStorage.getItem(AsyncStorageKeys.deviceIdentifier) ?? "WEB-MERCEDES"}`,
			deviceModel: hwid ? "mercedez-benz" : "Web",
			hardwareId: `${hwid ?? ""}`,
			month: Number(month),
			year: Number(year),
			acceptPrivacy: true,
			acceptTC: true,
			ageChecked: true, //for testing only. Remove once done testing
			isHwidEncrypted: hwid !== null,
		};

		setErrorSummary("");
		try {
			let result: any;

			setDisableSubmit(true);
			result = await registerAccountNoVoucherNeeded(saveAccount);

			if (result.responseCode === Code_OK) {
				const automaticVoucher: any = await automaticVoucherRedemption();
				const code = automaticVoucher?.code;
				if (code) {
					setAutomaticVoucherCode(automaticVoucher);
					const voucherResult: any = await redeemVoucherCode(code, localStorage.getItem(AsyncStorageKeys.session));
					if (voucherResult?.responseCode === Code_OK) {
						setShowVoucherToast(true);
						return;
					}
				}
				await processLogin(saveAccount);
			} else if (result.responseCode === ACCOUNT_EXIST) {
				setForm({ ...form, email: { ...form.email, valid: false, errorMessage: globalAny.language.account_exist } });
				setDisableSubmit(false);
				setLoading(false);
				setReadyToSaveAccount(false);
			} else if (result.responseCode === DEVICE_NOT_ENTITLE) {
				setErrorSummary(`${globalAny.language.hwid_account_exist}`);
				AppLogger.error("An error was found after submitting data to registration endpoint. ResponseCode error: ", result);
				setDisableSubmit(false);
				setLoading(false);
				setReadyToSaveAccount(false);
			} else {
				setErrorSummary(`${globalAny.language.unknown_error}`);
				AppLogger.error("An error was found after submitting data to registration endpoint. ResponseCode error: ", result);
				setDisableSubmit(false);
				setLoading(false);
				setReadyToSaveAccount(false);
			}
		} catch (ex) {
			setErrorSummary(globalAny.language.unknown_error);
			setDisableSubmit(false);
			setLoading(false);

			AppLogger.error("Error saving new account: ", ex);
			setReadyToSaveAccount(false);
		}
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	const onPressEye = () => {
		if (secureTextEntry) {
			setSecureTextEntry(false);
			return;
		}
		setSecureTextEntry(true);
	};

	const formatDOB = (date: any) => {
		if (date.length > 7) return;

		let formattedText = date.replace(/[^0-9, /]/g, "");
		if (date.length > 4 && date.length < 7 && date.indexOf("/") === -1) {
			formattedText = `${formattedText.slice(0, 4)}/${formattedText.slice(4, formattedText.length)}`;
			setBirthday(formattedText);
		} else {
			setBirthday(formattedText);
		}
		onEnterBirthday(formattedText);
	};

	// Form consolidation algorithm
	useEffect(() => {
		setAccountToSave({ ...accountToSave, ...props.accountInfo });
	}, [props.accountInfo]);

	// Once form is successfully validated and all inputs passed, run this effect to register to endpoint
	useEffect(() => {
		if (!readyToSaveAccount) {
			return;
		}

		submitFormAndSaveAccount();
	}, [readyToSaveAccount]);

	// Once welcome splash is shown, initiate splash screen animation effect and navigate to homepage after a few
	useEffect(() => {
		if (!presignedUrl?.topic || isLoggedIn) {
			return;
		}

		setTimeout(() => {
			setTimeout(() => {
				const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";
				pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
				props.setShowWelcomeSplash(false);
				setIsLogin(true);

				if (isInCarScreen) {
					const profileName = AsyncStorage.getItem(AsyncStorageKeys.profileName);
					updateDevice(profileName);
					setIsLoggedIn(true);
					navigationHomeScreen.navigate(routeHome, {});
					return;
				}

				//@ts-ignore
				navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
			}, 4000);
		}, 3000);
	}, [presignedUrl]);

	// Month/year state change
	useEffect(() => {
		const birthdayField = form.month.value === 0 || form.year.value === 0 ? "" : `${form.month.value}/${form.year.value}`;

		setForm({ ...form, birthday: { ...form.birthday, value: birthdayField } });
	}, [form.month, form.year]);

	return (
		<>
			{showDateModal && (
				<Modal onDismiss={() => setShowDateModal(false)}>
					<React.Suspense fallback="">
						<LazyDatePickerWidget
							lastUsedDate={lastUsedDate}
							onCancel={() => setShowDateModal(false)}
							onSubmit={(date) => {
								if (date) {
									setLastUsedDate(date);
									setForm({
										...form,
										year: { ...form.year, value: date.year() },
										month: { ...form.month, value: date.month() + 1 },
									}); //add +1 to month since it begins at 0
								}
								setShowDateModal(false);
							}}
						/>
					</React.Suspense>
				</Modal>
			)}
			{showVoucherToast && (
				<Modal>
					<CustomizeDialogBox
						Header={globalAny.language.voucher_automatically_applied}
						SingleButton={true}
						Body={processToastMessage(automaticVoucherCode)}
						ButtonTextRight="Confirm"
						onPressRight={() => processLogin(saveAccount)}
					/>
				</Modal>
			)}
			<CreateAccount
				emailError={!form.email.valid}
				emailErrorMessage={form.email.errorMessage}
				emailOnChangeText={(val: any) => {
					setForm({ ...form, email: { ...form.email, value: val } });
					onEnterEmail(val);
				}}
				passwordError={!form.password.valid}
				passwordErrorMessage={form.password.errorMessage}
				passowrdOnChangeText={(val: any) => {
					setForm({ ...form, password: { ...form.password, value: val } });
					onEnterPassword(val);
				}}
				onPressInputIcon={() => onPressEye()}
				inputIcon={secureTextEntry ? "eye" : "eye-off"}
				birthdayVal={birthday}
				keyboardType={"numeric"}
				placeholder={"YYYY / MM"}
				birthdayError={!form.birthday.valid}
				birthdayErrorMessage={form.birthday.errorMessage}
				birthdayOnChangeText={(date: any) => {
					if (date.length > 7 || isInCarScreen()) return;

					setBirthday(date);
					formatDOB(date);
				}}
				birthdayOnchange={(e: any) => {
					const date = e.nativeEvent.text;
					if (date.length > 7 || !isInCarScreen()) return;
					setBirthday(date);
				}}
				birthdayOnSubmitEditing={(e: any) => {
					if (!isInCarScreen()) return;
					const date = e.nativeEvent.text;
					formatDOB(date);
				}}
				birthdayOnEndEditing={(e: any) => {
					if (!isInCarScreen()) return;
					const date = e.nativeEvent.text;
					formatDOB(date);
				}}
				onBlur={() => {
					if (!isInCarScreen()) return;
					formatDOB(birthday);
				}}
				onPrivacy={props.onPressPrivacy}
				onBackPress={props.onPressBack}
				onPressSubmit={() => validateForm()}
				disabled={disableSubmit}
				loadingIndicator={loading}
				screenDimensions={screenDimensions}
				secureTextEntry={secureTextEntry}
			/>
			{errorSummary ? (
				<StyledFormRow>
					<View style={{ paddingBottom: "50px" }}>
						<FlexRow>
							<H4 fontColor={TEXT_COLOR_ERROR}>{errorSummary}</H4>
						</FlexRow>
					</View>
				</StyledFormRow>
			) : (
				<></>
			)}
		</>
	);
};

export default CreateAccountForm;
