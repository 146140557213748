import styled, { css } from "styled-components/native";

interface Props {
	isNarrow?: any;
	fontSize?: any;
	lineHeight?: any;
}

export const StyledContainer = styled.TouchableOpacity`
	display: flex;
	height: 2.5rem;
	padding: 0rem 0.5rem 0rem 1rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	align-self: stretch;
`;

export const StyledText = styled.Text<Props>`
	${(props) =>
		css`
			overflow: hidden;
			color: #f2f2f2;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-family: Nunito;
			font-size: ${props?.fontSize};
			font-style: normal;
			font-weight: 500;
			line-height: ${props?.lineHeight};
			flex: 1 0 0;
		`}
`;

export const StyledLabel = styled.View`
	display: flex;
	align-items: center;
	align-self: stretch;
	flex-direction: row;
	gap: 1rem;
`;

export const StyledImage = styled.View`
	width: 3rem;
	height: 3rem;
`;
