import {
	StyledButtonContainer,
	StyledButtonNarrowContainer,
	StyledImageContainer,
	StyledLoginText,
	StyledLoginTextNarrow,
	StyledMainDefault,
	StyledMainNarrow,
	StyledMainWide,
	StyledRowButtonContainer,
	StyledRowButtonNarrowContainer,
} from "./styled";
import { RenderCreateEligibleButton, RenderLandingButton, RenderLogo, RenderTryNowButton } from "./Landing";
import { globalAny } from "../../utils/Utils";

export const LandingDefaultScreen = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledMainDefault>
				<RenderLogo {...props} />
			</StyledMainDefault>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				{/* @ts-ignore */}
				<RenderCreateEligibleButton {...props} />
				{/* <PrivacyPolicyButton removeFixedPosition={true} /> */}
			</StyledButtonContainer>
		</>
	);
};

export const LandingWideScreen = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledMainWide>
				<RenderLogo {...props} />
			</StyledMainWide>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				{/* @ts-ignore */}
				<RenderCreateEligibleButton {...props} />
				{/* <PrivacyPolicyButton removeFixedPosition={true} /> */}
			</StyledButtonContainer>
		</>
	);
};

export const LandingNarrowScreen = (props: any) => {
	return (
		// @ts-ignore
		<StyledMainNarrow>
			{/* @ts-ignore */}
			{/* @ts-ignore */}
			<StyledButtonNarrowContainer>
				<RenderCreateEligibleButton {...props} />
				{/* <PrivacyPolicyButton removeFixedPosition={true} /> */}
			</StyledButtonNarrowContainer>
			{/* @ts-ignore */}
			<StyledImageContainer>
				<RenderLogo {...props} />
			</StyledImageContainer>
		</StyledMainNarrow>
	);
};
