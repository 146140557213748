import styled from "styled-components/native";
import { capitalize, globalAny } from "../../utils/Utils";
import { white_smoke } from "../../StyleHelpers";
import ComponentThemeType from "../../models/ComponentTheme";
import TouchableButton from "../TouchableButton";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";

const StyledExitPreviewButton = styled.View<any>`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 4rem;
	background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.5rem;
    justify-content: space-between;
    align-items: center;
`;

const StyledLabel = styled.Text`
    display: flex;
    width: 25.9375rem;
    height: 2.5rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: ${white_smoke};
    font-family: Nunito;
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
`;

const StyledButtonContainer = styled.View`
    height: 100%;
`;

const ExitPreviewButton = (props: any) => {
  const screenDimension = useDimensions();

  return (
    //@ts-ignore
    <StyledExitPreviewButton isNarrow={screenDimension === ScreenDimensions.narrow}>
        {/* @ts-ignore */}
		<StyledLabel>{globalAny.language.preview_mode}</StyledLabel>
        {/* @ts-ignore */}
        <StyledButtonContainer>
            <TouchableButton 
                componentTheme={ComponentThemeType.VinFast} 
                type={ComponentTypeEnum.Primary} onPress={() => props.onPress()}
                alignCenter={true}
                borderRadius={"0.5rem"}
            >
                {capitalize(globalAny.language.exit_preview)}
            </TouchableButton>
        </StyledButtonContainer>
    </StyledExitPreviewButton>
  )
}

export default ExitPreviewButton