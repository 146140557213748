import { CONTENT_BASEURL_V7, CONTENT_BASEURL_V6, CONTENT_BASEURL_V8, CONTENT_BASEURL_V9 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders, getHeadersWithApplicationJSON } from "../utils/endpoint/header";

export const getMetadata = (parentProductId: any, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V7 + "metadata/" + parentProductId
			,{ 
				headers: getHeaders(),
				signal
			}
		).then((resJson) => resolve(resJson))
		.catch((err) => reject(new Error(err)));
	});
	return promise;
};


export const apiGetSearchResults = (searchTerm: string, transactionFilters: string, pageSize: number, pageNumber: number) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			`${CONTENT_BASEURL_V9}search/${searchTerm}?transactionalFilters=${transactionFilters}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
			{ headers: getHeaders() }
		)
			.then((resJson) => {
				resolve(resJson.results);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};


/**
 * Returns the status of a piece of content that is owned
 * /content/v6/Owned
 * @param parentProductId - string: parentProductId
 * @param transactionTypes - transaction type 1,2,3,4
 * @returns - return redeem status
 */
export const getOwnedStatus = (parentProductId: string, transactionTypes: any) => {
	let body = transactionTypes.map((value: number) => {
		return {
			parentProductId: parseInt(parentProductId),
			streamType: 2,
			transactionType: value,
		};
	});
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "owned/", { method: "POST", headers: getHeadersWithApplicationJSON(), body: JSON.stringify(body) })
			.then((resJson) => resolve(resJson.products))
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};


export const getAvailability = (parentProductId: string,) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(`${CONTENT_BASEURL_V8}availability/${parentProductId}?supportedCodecs=1%2C2&svodCountryId=0&streamType=2`, { headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};