import StyledText from "./StyledText";
import HeaderTextProps from "./HeaderTextProps";

const H6 = (props: HeaderTextProps) => {
	return (
		// @ts-ignores
		<StyledText fontColor={props.fontColor} fontSize={16} disabled={props.disabled} fontWeight={props.fontWeight}>
			{props.children}
		</StyledText>
	);
};

export default H6;
