import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { capitalize, globalAny } from "../../utils/Utils";
import TextInput from "../../components/TextInput";
import { CodeSignInNarrowScreen, CodeSignInScreen,  } from "./CodeSignInScreen";
import { StyledRowButtonContainer } from "./styled";

export const RenderTextInput = (props: any) => (
	<TextInput
		componentTheme={ComponentThemeType.VinFast}
		placeholder={globalAny.language.character_code_input}
		label={globalAny.language.character_code_input}
		onChangeText={(event) => props.onEnterPassCode(event)}
		autoFocus={true}
		error={props.passCodeError}
		value={props.passCode}
		disabled={props.isSigninLoading || props.isLoginSuccess}
		onKeyPress={() => props.handleKeyPress()}
	/>
);

export const RenderButton = (props: any) => (
	//@ts-ignore
	<StyledRowButtonContainer>
		<TouchableButton
			disabled={props.disableSignInBtn || props.isLoginSuccess}
			componentTheme={ComponentThemeType.VinFast}
			type={ComponentTypeEnum.Primary}
			onPress={() => {
				props.loginAccount();
			}}
		>
			{capitalize(globalAny.language.sign_in)}
		</TouchableButton>
	</StyledRowButtonContainer>
);

const RecoverPassword = (props: any) => {
	const renderRecoverScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<CodeSignInNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.default) {
			return (
				<MainDefaultWrapper>
					<CodeSignInScreen {...props} />
				</MainDefaultWrapper>
			);
		}

		return (
			<MainWideWrapper>
				<CodeSignInScreen {...props} />
			</MainWideWrapper>
		);
	};

	return renderRecoverScreenDimensions();
};
export default RecoverPassword;
