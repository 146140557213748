import { globalAny } from "../utils/Utils";
//TODO: Message should include in app language
export const voucherValidate = (responseCode: number) => {
	if (responseCode === 40057) {
		return globalAny.language.voucher_bundle_not_active;
	} else if (responseCode === 40004) {
		return globalAny.language.voucher_supplied_invalid;
	} else if (responseCode === 40017) {
		return globalAny.language.voucher_required;
	} else if (responseCode === 40018) {
		return globalAny.language.voucher_specified_invalid;
	} else if (responseCode === 40019) {
		return globalAny.language.voucher_expired;
	} else if (responseCode === 40020) {
		return globalAny.language.voucher_used;
	} else if (responseCode === 40055) {
		return globalAny.language.voucher_not_active;
	} else if (responseCode === 40067) {
		return globalAny.language.voucher_device_not_valid;
	} else if (responseCode === 40076) {
		return globalAny.language.voucher_promotion;
	} else if (responseCode === 40222) {
		return globalAny.language.voucher_device_not_entitled;
	} else {
		return globalAny.language.invalid_voucher;
	}
};

export const isSessionResponseValid = (responseCode: number) => {
	if (responseCode === 40001 || 
		responseCode === 40011 || 
		responseCode === 20005 || 
		responseCode === 20008 || 
		responseCode === 20014) {
		return false;
	} 

	return true;
};

export const responseOk = 10000;
