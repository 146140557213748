import IMqttClient from "../../models/IMqttClient";
import type IMqttDeviceBroadcast from "../../models/IMqttDeviceBroadcast";
import IMqttRequest from "../../models/IMqttRequest";
import { MqttCommand } from "../../models/mqtt-command";
import { MqttDevice } from "../../models/MqttModel";
import { AppLogger } from "../../utils/AppLogger";
import { MqttBaseService } from "./MqttBaseService";

export enum MqttDeviceManagerKey {
	OnAddScreen = "onAddScreen",
}

// TODO: Move device manager implementation here if necessary. Refer to sony_core_angular_web project
export default class MqttDeviceManager extends MqttBaseService {
	public _activeScreens: MqttDevice[] = [];
	private _availableScreens: IMqttDeviceBroadcast[] = [];
	private _primaryScreen: IMqttRequest; //this is the primary screen the user instance selected (e.g. Left Rear, Front View, etc.)
	private _topic: string;

	constructor(mqttService: IMqttClient, primaryScreen: IMqttDeviceBroadcast, topic: string) {
		super(primaryScreen);
		this._mqttService = mqttService;
		this._topic = topic;
		this._primaryScreen = primaryScreen as IMqttRequest;
		this._eventListeners = {};

		this._mqttService.subscribe(topic);
		this._mqttService.onMessage(async (topic, message) => {
			this.onMessage(message);
		});
		this.processMessageInternal = (e: IMqttRequest) => {
			//TODO: Add implementation details once we transferred some event listeners in DeviceManager
		};
	}

	public addScreen(device: IMqttDeviceBroadcast) {
		let existingDevices = (window as any).devicesAdded as IMqttDeviceBroadcast[];
		let existingDevice = existingDevices.find((f) => f.deviceName === device.deviceName);

		if (device.deviceName !== 'ridevue-undefined') {
			if (!existingDevice || (device.deviceName == this.getPrimaryScreen().deviceName && this._availableScreens.length == 0)) {
				AppLogger.log("Adding screen", device);
				this._availableScreens = this._availableScreens.concat([device]);
				this.emitEventListenersSubscribed(MqttDeviceManagerKey.OnAddScreen, this._availableScreens);
			}
		}
	}

	public removeScreen(device: IMqttDeviceBroadcast) {
		let existingDevices = (window as any).devicesAdded as IMqttDeviceBroadcast[];
		const filtered = existingDevices.filter((devices) => devices.deviceName !== device.deviceName);
		(window as any).devicesAdded = filtered;
	}

	public getScreens(): IMqttDeviceBroadcast[] {
		return this._availableScreens.filter((f) => !f.sendOnly);
	}

	public getPrimaryScreen(): IMqttDeviceBroadcast {
		return this._primaryScreen;
	}

	public updatePrimaryScreen(newProfileName: any) {
		return (this._primaryScreen = newProfileName);
	}

	public setSelectedMoviePlayedForPrimaryScreen(parentProductId: any, mqttCommand: MqttCommand, decodedMessage: any) {
		this._primaryScreen.body = {
			movieDetails: {
				parentProductId: parentProductId,
				mqttCommand: mqttCommand,
				decodedMessage: decodedMessage,
			},
		};
	}

	public updateSelectedMoviePlayedForPrimaryScreen(actionCommand: MqttCommand) {
		if (this._primaryScreen?.body?.movieDetails) {
			this._primaryScreen.body.movieDetails.decodedMessage.body.actionCommand = actionCommand;
		}
	}

	/**
	 * Use to update the current position and screen manager progress bar.
	 * @param streamProgress - any: computed value of position divided by duration.
	 * @param position - any: current position of movie.
	 */
	public updateSelectedMovieProgressBar(streamProgress: any, position: any) {
		if (this._primaryScreen?.body?.movieDetails) {
			this._primaryScreen.body.movieDetails.decodedMessage.body.position = position;
			this._primaryScreen.body.movieDetails.decodedMessage.body.streamProgress = streamProgress;
		}
	}

	public removeGroup(profile: any, contentId: string) {
		if (this._primaryScreen?.screenName === profile) {
			this._primaryScreen.body.movieDetails.decodedMessage.body.contentId = contentId;
			this._primaryScreen.body.movieDetails.decodedMessage.body.groupSync = 0;
			this._primaryScreen.body.movieDetails.decodedMessage.body.linkDevices = "";
			this._primaryScreen.body.movieDetails.decodedMessage.body.link = false;
			this._primaryScreen.body.movieDetails.decodedMessage.body.linkProductId = 0;
			this._primaryScreen.body.movieDetails.decodedMessage.body.linkProduct = "";
		}
	}

	public removeSelectedMoviePlayed() {
		if (this._primaryScreen?.body?.movieDetails) {
			delete this._primaryScreen?.body?.movieDetails;
		}
	}

	//#region EVENT EMITTERS. PUT EVENTS HERE. TODO: Needs refactoring further
	public onAddScreenEvent(fn: (devices: IMqttDeviceBroadcast[]) => void) {
		return this.addEventListener(MqttDeviceManagerKey.OnAddScreen, fn);
	}
	//#endregion
}
