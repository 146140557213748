import { Modal as ModalPaper, Portal, Provider } from "react-native-paper";
import styled from "styled-components/native";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import FlexRow from "../Layout/FlexRow";
import TouchableButton from "../TouchableButton";
import H2 from "../Typography/H2";
import H4 from "../Typography/H4";

interface Props {
	Header?: any;
	Body?: any;
	buttonText?: string;
	onDismiss?: () => void;
	onPress?: () => void;
}

const StyledModal = styled(ModalPaper)`
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(15px);
	justify-content: center;
	align-items: center;
	flex-direction: row;
	position: fixed;
`;

const StyledInnerContent = styled.View`
	justify-content: center;
	align-items: center;
	padding: 50px 20px 50px 20px;
`;

const StyledTextArea = styled.View`
	background-color: #04101b;
	width: auto;
	height: 510px;
	overflow: auto;
	opacity: 60%;
	border-radius: 16px;
	padding: 0px 20px 20px 20px;
`;
const StyledTextAreaOuter = styled.View`
	background-color: #04101b;
	opacity: 60%;
	border-radius: 16px;
	padding: 20px 10px 20px 0px;
`;

const StyledMargin = styled.View`
	margin-bottom: 15px;
`;

const StyledButton = styled.View`
	width: 40%;
`;

const LegalDialog = (props: Props) => {
	const primaryStyle = {
		backgroundColor: "#061624",
		padding: 20,
		width: 960,
		height: 750,
		borderRadius: 16,
		borderColor: "#013566",
		border: "1px solid",
	};

	return (
		<Provider>
			<Portal>
				<StyledModal visible={true} contentContainerStyle={primaryStyle}>
					{/* @ts-ignore */}
					<StyledInnerContent>
						{/* @ts-ignore */}
						<StyledMargin>
							{/* @ts-ignore */}
							<H2>{props.Header}</H2>
						</StyledMargin>
						{/* @ts-ignore */}
						<StyledMargin>
							{/* @ts-ignore */}
							<StyledTextAreaOuter>
								{/* @ts-ignore */}
								<StyledTextArea>
									{/* @ts-ignore */}
									<H4>{props.Body}</H4>
								</StyledTextArea>
							</StyledTextAreaOuter>
						</StyledMargin>
						{/* @ts-ignore */}
						<StyledButton>
							<FlexRow>
								<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={props.onPress}>
									{props.buttonText}
								</TouchableButton>
							</FlexRow>
						</StyledButton>
					</StyledInnerContent>
				</StyledModal>
			</Portal>
		</Provider>
	);
};

export default LegalDialog;
