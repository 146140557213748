import PrivacyPolicyButton from "../PrivacyPolicyButton";
import {
	StyledButtonMain,
	StyledDataContainer,
	StyledNarrowButtonContainer,
	StyledNarrowButtonMain,
	StyledNarrowMain,
	StyledSections,
	StyledTextArea,
	StyledTextTemplate,
} from "./styled";
import Typography from "../../components/Typography";
import ButtonToggle from "../ButtonToggle";
import SectionButton from "../SectionButton";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";
import { RenderButton } from "./OtherData";

export const OtherDataNarrowScreen = (props: any) => {
	const styleHelper = useStyleHelpers();
	return (
		/* @ts-ignore */
		<StyledNarrowMain>
			{/* @ts-ignore */}
			<StyledNarrowButtonMain>
				{/* @ts-ignore */}
				<StyledNarrowButtonContainer>
					<RenderButton {...props} />
					<PrivacyPolicyButton onPress={props.onPressPrivacy} removeFixedPosition={true} />
				</StyledNarrowButtonContainer>
			</StyledNarrowButtonMain>
			{/* @ts-ignore */}
			<StyledDataContainer>
				{/* @ts-ignore */}
				<StyledTextTemplate>
					<ButtonToggle defaultValue={props.defaultValue} onPress={props.onPress} />
					{/* @ts-ignore */}
					<StyledTextArea isNarrow={styleHelper?.layout === ScreenDimensions.narrow} gap={styleHelper?.textArea?.gap}>
						{/* @ts-ignore */}
						<Typography title={props.title} description={props.description} />
						{/* @ts-ignore */}
						<StyledSections isNarrow={styleHelper?.layout === ScreenDimensions.narrow} gap={styleHelper?.sectionButton?.gap}>
							{props.sectionButtons.map((button: any) => {
								return (
									<SectionButton
										key={button.title}
										title={button.title}
										onPress={button.onPress}
										onPressToggle={button.onPressToggle}
										isToggle={button.isToggle}
										hasToggleButton={true}
									/>
								);
							})}
						</StyledSections>
					</StyledTextArea>
				</StyledTextTemplate>
			</StyledDataContainer>
		</StyledNarrowMain>
	);
};

export const OtherDataWideScreen = (props: any) => {
	const styleHelper = useStyleHelpers();
	return (
		<>
			{/* @ts-ignore */}
			<StyledDataContainer>
				{/* @ts-ignore */}
				<StyledTextTemplate>
					<ButtonToggle defaultValue={props.defaultValue} onPress={props.onPress} />
					{/* @ts-ignore */}
					<StyledTextArea isNarrow={styleHelper?.layout === ScreenDimensions.narrow} gap={styleHelper?.textArea?.gap}>
						{/* @ts-ignore */}
						<Typography title={props.title} description={props.description} />
						{/* @ts-ignore */}
						<StyledSections isNarrow={styleHelper?.layout === ScreenDimensions.narrow} gap={styleHelper?.sectionButton?.gap}>
							{props.sectionButtons.map((button: any) => {
								return (
									<SectionButton
										key={button.title}
										title={button.title}
										onPress={button.onPress}
										onPressToggle={button.onPressToggle}
										isToggle={button.isToggle}
										hasToggleButton={true}
									/>
								);
							})}
						</StyledSections>
					</StyledTextArea>
				</StyledTextTemplate>
			</StyledDataContainer>
			{/* @ts-ignore */}
			<StyledButtonMain>
				<RenderButton {...props} />
				<PrivacyPolicyButton onPress={props.onPressPrivacy} removeFixedPosition={true} />
			</StyledButtonMain>
		</>
	);
};
