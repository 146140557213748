import styled, { css } from "styled-components/native";
import FontWeightType from "../../models/FontWeightType";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";

interface StyledTextProps {
	fontSize: number;
	disabled?: boolean | null;
	fontWeight?: FontWeightType;
	fontColor?: string;
	textCenter?: boolean;
}

const StyledText = styled.Text<StyledTextProps>`
	font-size: 1.3125rem;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-family: ${FONT_FAMILY_DEFAULT};

	${(props) =>
		props.textCenter &&
		css`
			text-align: center;
		`}

	${(props) => {
		if (props.disabled) {
			return css`
				color: RGBA(255, 255, 255, 0.5);
			`;
		}
		const color = props.fontColor ? props.fontColor : "#FFF";

		return css`
			color: ${color};
		`;
	}}

	${(props) => {
		switch (props.fontWeight) {
			case FontWeightType.Light:
				return css`
					font-weight: 300;
				`;
			case FontWeightType.Regular:
				return css`
					font-weight: 400;
				`;
			case FontWeightType.Medium:
				return css`
					font-weight: 500;
				`;
			case FontWeightType.Bold:
				return css`
					font-weight: 700;
				`;
			default:
				return css`font-weight: 400;`;
		}
	}}
`;

export default StyledText;
