import { TouchableWithoutFeedback } from "react-native";
import AppBarWrapper from "../../components/Layout/MainLayout/AppBarWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { ScreenDimensions } from "../../utils/enum";
import { capitalize, globalAny } from "../../utils/Utils";
import { StyledRowButtonContainer } from "./styled";
import Image from "../../components/Image/Image";
import ridevueLogo from "../../assets/Logo/logos.svg";
import { LandingDefaultScreen, LandingNarrowScreen, LandingWideScreen } from "./LandingScreen";
import MainDefaultLandingWrapper from "../../components/Layout/MainLayout/MainLandingWrapper";

export const RenderCreateEligibleButton = (props: any) => {
	if (!props.isVoucherAvailable) {
		return (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={() => props.signIn()}>
					{capitalize(globalAny.language.sign_in)}
				</TouchableButton>
			</StyledRowButtonContainer>
		);
	}

	return (
		//@ts-ignore
		<StyledRowButtonContainer dimensions={props.screenDimensions}>
			<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={() => props.createAccount()}>
				{capitalize(globalAny.language.create_account)}
			</TouchableButton>
			<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={() => props.signIn()}>
				{capitalize(globalAny.language.sign_in)}
			</TouchableButton>
		</StyledRowButtonContainer>
	);
};

export const RenderLandingButton = (props: any) => (
	<>
		<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={() => props.signIn()}>
			{capitalize(globalAny.language.sign_in_with_email)}
		</TouchableButton>
		<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={() => props.codeSignIn()}>
			{capitalize(globalAny.language.sign_in_with_code)}
		</TouchableButton>
	</>
);

export const RenderTryNowButton = (props: any) => (
	//@ts-ignore
	<StyledRowButtonContainer dimensions={props.screenDimensions}>
		<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Secondary} onPress={() => props.onPressTryItNow()}>
			{capitalize(globalAny.language.try_it_now)}
		</TouchableButton>
	</StyledRowButtonContainer>
);

export const RenderLogo = (props: any) => (
	<TouchableWithoutFeedback onPressIn={() => props.handleLogoPressIn()}>
		{props.screenDimensions === ScreenDimensions.narrow ? (
			<Image
				source={{ uri: ridevueLogo }}
				windowDimensions={props.windowDimensions}
				width={"21.5rem"}
				height={"12.5rem"}
				resizeMode="contain"
			/>
		) : (
			<Image
				source={{ uri: ridevueLogo }}
				windowDimensions={props.windowDimensions}
				style={{ width: props.styledLanding?.logo?.width, height:props.styledLanding?.logo?.height }}
				resizeMode="contain"
			/>
		)}
	</TouchableWithoutFeedback>
);

const Landing = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<>
					<AppBarWrapper dimensions={ScreenDimensions.narrow} />
					<MainNarrowWrapper>
						<LandingNarrowScreen {...props} />
					</MainNarrowWrapper>
				</>
			);
		}

		if (props.screenDimensions === ScreenDimensions.default) {
			return (
				<>
					<AppBarWrapper />
					<MainDefaultLandingWrapper dimensions={props.screenDimensions}>
						<LandingDefaultScreen {...props} />
					</MainDefaultLandingWrapper>
				</>
			);
		}

		return (
			<>
				<AppBarWrapper />
				<MainDefaultLandingWrapper dimensions={props.screenDimensions}>
					<LandingWideScreen {...props} />
				</MainDefaultLandingWrapper>
			</>
		);
	};

	return renderScreenDimensions();
};
export default Landing;
