import CardGridPlaylist from "../CardGridPlaylist";

interface Props {
	isVam?: boolean;
	slice?: number;
}
const AggregatePageLoader = (props: Props) => {
	const data = [
		{ parentProductId: 1, packshots: null, title: null },
		{ parentProductId: 2, packshots: null, title: null },
		{ parentProductId: 3, packshots: null, title: null },
		{ parentProductId: 4, packshots: null, title: null },
		{ parentProductId: 5, packshots: null, title: null },
		{ parentProductId: 6, packshots: null, title: null },
		{ parentProductId: 7, packshots: null, title: null },
		{ parentProductId: 8, packshots: null, title: null },
		{ parentProductId: 9, packshots: null, title: null },
		{ parentProductId: 10, packshots: null, title: null },
		{ parentProductId: 11, packshots: null, title: null },
		{ parentProductId: 12, packshots: null, title: null },
		{ parentProductId: 13, packshots: null, title: null },
		{ parentProductId: 14, packshots: null, title: null },
		{ parentProductId: 15, packshots: null, title: null },
		{ parentProductId: 16, packshots: null, title: null },
		{ parentProductId: 17, packshots: null, title: null },
		{ parentProductId: 18, packshots: null, title: null },
		{ parentProductId: 19, packshots: null, title: null },
		{ parentProductId: 20, packshots: null, title: null },
	];

	return (
		<CardGridPlaylist
			data={data.slice(0, props.slice ?? data.length)}
			hideBorder={true}
			isAggregate={true}
			isClips={props.isVam}
			disabled={true}
		/>
	);
};

export default AggregatePageLoader;
