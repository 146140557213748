import { StyledMainSettingsNarrow } from "./Styled";

const MainSettingsNarrowWrapper = ({ children }: any) => {
	return (
		//@ts-ignore
		<StyledMainSettingsNarrow>
			{children}
		</StyledMainSettingsNarrow>
	);
};

export default MainSettingsNarrowWrapper;
