import { light_black, spacing_l, spacing_s, white } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import { globalAny } from "../../utils/Utils";
import useStyleHelpers from "../../hooks/useStyleHelpers";


const StyledStudioAccessBanner = styled.View<any>`
	position: relative;
	height: ${(props) => props.height};
	top: ${(props) => props.paddingTop};
	left: ${(props) => props.paddingLeft};
`;

const StyledTextContainer = styled.View<any>`
	display: flex;
	width: 37.66963rem;
	padding-left: ${(props) => props.paddingLeft};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	height: 100%;
	z-index: 10;
`;

const StyledText = styled.Text<any>`
	width: 35rem;
	color: ${white};
	font-family: ${FONT_FAMILY_DEFAULT};
	font-size: ${(props) => props.fontSize};
	font-weight: 500;
	line-height:${(props) => props.lineHeight};
`;

const StyledImage = styled.View<any>`
	position: absolute;
	right: 0;
	bottom: 0;
	height: ${(props) => props.height};
	width: ${(props) => props.width};
	background-image: linear-gradient(94deg, #131313 55.88%, rgba(19, 19, 19, 0.00) 88.62%);
	z-index: 9;
`;

const StyledGradient = styled.View<any>`
	position: absolute;
	right: 0;
	bottom: 0;
	height: 5.625rem;
	width: ${(props) => props.width};
	background-image: linear-gradient(to bottom, transparent, ${light_black});
	z-index: 9;
`;

const StyledMask = styled.View<any>`
	right: 0;
	z-index: 8;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	flex-shrink: 0;
	background-image: url(${(props) => props.banner});
	background-repeat: no-repeat;
	background-size: cover;
`;

const StyledMaskGroup = styled.View<any>`
	position: fixed;
	right: 0;
`;

const Banner = (props: any) => {
	const styleHelper = useStyleHelpers();

	return (
		//@ts-ignore
		<StyledStudioAccessBanner 
			height={styleHelper?.shortClipsBanner.height}
			width={styleHelper?.shortClipsBanner.width}
			paddingLeft={styleHelper?.shortClipsBanner.paddingLeft}
			paddingTop={styleHelper?.paddingTop}
		>
			{/* @ts-ignore */}
			<StyledTextContainer
				paddingLeft={styleHelper?.shortClipsBanner.text.paddingLeft}
			>
				{/* @ts-ignore */}
				<StyledText
					fontSize={styleHelper?.shortClipsBanner.text.fontSize}
					lineHeight={styleHelper?.shortClipsBanner.text.lineHeight}
				>
					{globalAny.language.banner_text}
				</StyledText>
			</StyledTextContainer>
			<StyledMaskGroup>
				<StyledMask 
					banner={props.banner}
					height={styleHelper?.shortClipsBanner.height}
					width={styleHelper?.shortClipsBanner.gradient.width}
				/>
				{/* @ts-ignore */}
				<StyledImage 
					paddingTop={styleHelper?.paddingTop}
					height={styleHelper?.shortClipsBanner.height}
					width={styleHelper?.shortClipsBanner.gradient.width}
				/>
				<StyledGradient 
					width={styleHelper?.shortClipsBanner.gradient.width}
				/>
			</StyledMaskGroup>
		</StyledStudioAccessBanner>
	);
};

export default Banner;
