import { useEffect, useState } from "react";
import {
	LoginScreenNavigationProp,
	routeLogin,
	ProfileNameSelectScreenNavigationProp,
	routeProfileNameSelect,
	HomeScreenNavigationProp,
	routeHome,
} from "../../Routes";
import { AsyncStorageKeys, Pages, ProfileNameState } from "../../Types";
import { useNavigation } from "@react-navigation/native";
import { globalAny, pushProfileNameStateToBrowserHistory, generateDeviceInfo, codeLogin } from "../../utils/Utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import Toast from "../../components/Toast";
import useGenericContentStore from "../../store/genericContent.store";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import useLoginStore from "../../store/useLogin.store";
import useSessionStore from "../../store/useSession.store";
import { getPresignedUrl } from "../../networking/networking";
import useMqttStore from "../../store/useMqtt.store";
import { infoEmail } from "../../services/accountService";
import CodeSignIn from "./CodeSignIn";
import Main from "../../components/Layout/MainLayout/Main";
import AppBarWrapper from "../../components/Layout/MainLayout/AppBarWrapper";
import LoaderSignIn from "../../components/Loaders/SignInLoader";
import useDimensions from "../../hooks/useDimensions";

const CodeSignInScreen = () => {
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const [passCode, setPassCode] = useState("");
	const [passCodeError, setPassCodeError] = useState(false);
	const [signInLoading, setSignInLoading] = useState(false);
	const [disableSignInBtn, setDisableSignInBtn] = useState(true);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState<any>();
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setLoginEmail = useGenericContentStore((state: any) => state.setLoginEmail);
	const onDismissSnackBar = () => setVisible(false);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const isSigninLoading = useSessionStore((state: any) => state.isSigninLoading);
	const setIsSigninLoading = useSessionStore((state: any) => state.setIsSigninLoading);
	const setIsLoginSuccess = useSessionStore((state: any) => state.setIsLoginSuccess);
	const isLoginSuccess = useSessionStore((state: any) => state.isLoginSuccess);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const currentLayout = useGenericContentStore((state: any) => state.currentLayout);
	const screenDimensions = useDimensions();

	useEffect(() => {
		if (isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
		}
	}, [isLoggedIn, globalAny.profileName]);

	useEffect(() => {
		validateMistakeCount();
	}, [signInLoading]);

	const onBackPress = () => {
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const onEnterPassCode = (event: any) => {
		setPassCode(event);
		if (event.length > 0) {
			setDisableSignInBtn(false); //remove once shortcode response is standardized.
			setPassCodeError(false);
			return;
		}
		setDisableSignInBtn(true);
	};

	const networkConnectionLost = () => {
		setLabelText(globalAny.language.label_retry);
		setIsSigninLoading(false);
		setToastMessage(globalAny.language.error_signin);
		setVisible(true);
	};

	const invalidSignInCode = () => {
		setLabelText(globalAny.language.label_retry);
		setToastMessage(globalAny.language.connection_unauthorized);
		setIsSigninLoading(false);
		setVisible(true);
	};

	const validateMistakeCount = () => {
		setLabelText(globalAny.language.label_retry);
		if (mistakeCount > 2) {
			const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
			AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
			setLabelText(null);
			setToastMessage(globalAny.language.maximum_attempt);
			setPassCode("");
		}
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_retry) {
			setPassCode("");
		}
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	const validateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";
		const profileName = AsyncStorage.getItem(AsyncStorageKeys.profileName);

		if (!isInCarScreen) return;

		setIsLoggedIn(true);
		updateDevice(profileName);
	};

	const navigateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";

		if (isInCarScreen) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
			return;
		}

		navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
	};

	const loginAccount = async () => {
		setIsSigninLoading(true);
		if (!passCode || passCodeError) {
			setToastMessage(globalAny.language.connection_unauthorized);
			setPassCodeError(true);
		} else {
			setLoginEmail("");
			let initialized = await codeLogin(
				passCode,
				setIsLoggedIn,
				() => invalidSignInCode(),
				() => networkConnectionLost()
			);
			if (initialized?.proceed) {
				const userInfo: any = await infoEmail();
				setLoginEmail(userInfo.email);
				const presignedService = await getPresignedUrl();
				setPresignedUrl(presignedService);
				setIsLogin(true);
				setIsLoginSuccess(true);
				setIsSigninLoading(false);
				pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
				AsyncStorage.setItem(AsyncStorageKeys.email, userInfo.email);
				setMistakeCount(0);
				setNavigateEvent(Pages.codeSignIn);
				validateDeviceScreen();
				setTimeout(() => {
					setDisableSignInBtn(false);
					setIsLoginSuccess(false);
					navigateDeviceScreen();
				}, 5000);
			}
		}
	};

	const handleKeyPress = ({ nativeEvent: { key: keyValue } }: any) => {
		if (keyValue === "Enter") {
			loginAccount();
		}
	};

	const onClose = () => {
		navigationLoginScreen.navigate(routeLogin, {});
	};

	useEffect(() => {
		setSignInLoading(true);
		setTimeout(() => {
			setSignInLoading(false);
		}, 1000);
	}, []);

	// if (signInLoading) {
	// 	return <LoaderSignIn screenDimensions={screenDimensions} isCodeSignIn={true}/>;
	// }

	return (
		<Main>
			<AppBarWrapper dimensions={screenDimensions}>
				<TopAppbar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={globalAny.language.sign_in_with_code}
					rightLogo={ridevueLogo}
					onPressLeftTitleIcon={onBackPress}
					onPressLeftIcon={onClose}
					isSingleLogo={true}
				/>
			</AppBarWrapper>
			<CodeSignIn
				screenDimensions={screenDimensions}
				isLoginSuccess={isLoginSuccess}
				isSigninLoading={isSigninLoading}
				passCode={passCode}
				passCodeError={passCodeError}
				disableSignInBtn={disableSignInBtn}
				handleKeyPress={() => handleKeyPress}
				onEnterPassCode={(event: any) => onEnterPassCode(event)}
				loginAccount={() => loginAccount()}
			/>

			{visible ? (
				<Toast
					visible={visible}
					text={toastMessage}
					label={labelText}
					onDismissSnackBar={onDismissSnackBar}
					onPress={() => onPressToast(labelText)}
				/>
			) : (
				<></>
			)}
		</Main>
	);
};

export default CodeSignInScreen;
