import { useEffect, useState } from "react";
import {
	LoginScreenNavigationProp,
	routeLogin,
	ProfileNameSelectScreenNavigationProp,
	routeProfileNameSelect,
	RecoverPasswordScreenNavigationProp,
	routeRecoverPassword,
	HomeScreenNavigationProp,
	routeHome,
} from "../../Routes";
import { AsyncStorageKeys, Pages, ProfileNameState } from "../../Types";
import { useNavigation } from "@react-navigation/native";
import { globalAny, capitalize, login, pushProfileNameStateToBrowserHistory, validateEmail, generateDeviceInfo } from "../../utils/Utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import Toast from "../../components/Toast";
import useGenericContentStore from "../../store/genericContent.store";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import LoaderSignIn from "../../components/Loaders/SignInLoader/index";
import useLoginStore from "../../store/useLogin.store";
import useSessionStore from "../../store/useSession.store";
import { getPresignedUrl } from "../../networking/networking";
import useMqttStore from "../../store/useMqtt.store";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import useLegalContentStore from "../../store/useLegalContent.store";
import Main from "../../components/Layout/MainLayout/Main";
import SignIn from "./SignIn";
import AppBarWrapper from "../../components/Layout/MainLayout/AppBarWrapper";
import useDimensions from "../../hooks/useDimensions";
import usePrivacyPolicy from "../../hooks/usePrivacyPolicy";

const SignInScreen = () => {
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const navigationRecoverPasswordScreen = useNavigation<RecoverPasswordScreenNavigationProp>();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [emailMessage, setEmailMessage] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [passwordMessage, setPasswordMessage] = useState("");
	const [signInLoading, setSignInLoading] = useState(false);
	const [secureTextEntry, setSecureTextEntry] = useState(true);
	const [disableSignInBtn, setDisableSignInBtn] = useState(true);
	const [visible, setVisible] = useState(false);
	const [privacyPolicyScreen, setPrivacyPolicyScreen] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState<any>();
	const [autoFocusPass, setAutoFocusPass] = useState(false);
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setLoginEmail = useGenericContentStore((state: any) => state.setLoginEmail);
	const onDismissSnackBar = () => setVisible(false);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const isSigninLoading = useSessionStore((state: any) => state.isSigninLoading);
	const setIsSigninLoading = useSessionStore((state: any) => state.setIsSigninLoading);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const legalStoreValue = useLegalContentStore((state) => state.data);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setDeviceName = useMqttStore((state: any) => state.setDeviceName);
	const screenDimensions = useDimensions();
	const privacyPolicyText = usePrivacyPolicy();

	useEffect(() => {
		if (isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
		}
	}, [isLoggedIn, globalAny.profileName]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			const emailValidated = validateEmail(email);
			const passwordValidated = password.length > 0;

			setDisableSignInBtn(!emailValidated || !passwordValidated);
		}
		return () => {
			unmounted = true;
		};
	}, [email, password]);

	useEffect(() => {
		validateMistakeCount();
	}, [signInLoading]);

	const onPressEye = () => {
		if (secureTextEntry) {
			setSecureTextEntry(false);
			return;
		}
		setSecureTextEntry(true);
	};

	const onBackPress = () => {
		if (privacyPolicyScreen) {
			setPrivacyPolicyScreen(false);
			return;
		}
		//@ts-ignore
		navigationLoginScreen.navigate(routeLogin);
	};

	const onEnterEmail = (event: any) => {
		setEmail(event);
		if (validateEmail(event)) {
			setEmailError(false);

			return;
		}

		setEmailMessage(globalAny.language.invalid_email);
		setEmailError(true);
	};

	const onEnterPassword = (event: any) => {
		setPassword(event);
		if (event.length > 0) {
			setPasswordError(false);
			return;
		}

		setPasswordMessage(globalAny.language.invalid_password);
		setPasswordError(true);
	};

	const invalidCredentials = () => {
		setIsSigninLoading(false);
		setToastMessage(globalAny.language.toast_invalid_credential);
		setLabelText("");
		setVisible(true);
		setPassword("");
		setDisableSignInBtn(true);
		setAutoFocusPass(false);
		setMistakeCount(mistakeCount + 1);
	};

	const networkConnectionLost = () => {
		setLabelText(globalAny.language.label_retry);
		setIsSigninLoading(false);
		setToastMessage(globalAny.language.error_signin);
		setVisible(true);
	};

	const RecoverPassword = () => {
		navigationRecoverPasswordScreen.navigate(routeRecoverPassword, { settings: "recover" });
	};

	const validateMistakeCount = () => {
		setLabelText(globalAny.language.label_retry);
		if (mistakeCount > 2) {
			const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
			AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
			setLabelText(null);
			setToastMessage(globalAny.language.maximum_attempt);
			setEmail("");
		}
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_retry && password && !passwordError) {
			setVisible(true);
			setIsSigninLoading(true);
			loginAccount();
		}
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
		setDeviceName(profileName);
	};

	const validateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";

		if (isInCarScreen) {
			const profileName = AsyncStorage.getItem(AsyncStorageKeys.profileName);
			setIsLoggedIn(true);
			updateDevice(profileName);
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
			return;
		}

		navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
	};

	const processLogin = async () => {
		const presignedService = await getPresignedUrl();
		setPresignedUrl(presignedService);
		setIsLogin(true);
		setIsSigninLoading(false);
		pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
		setMistakeCount(0);
		AsyncStorage.setItem(AsyncStorageKeys.email, email);
		validateDeviceScreen();
	};

	const loginAccount = async () => {
		setNavigateEvent("");
		setIsSigninLoading(true);
		if (!email || emailError) {
			setEmailMessage(globalAny.language.invalid_email);
			setEmailError(true);
		} else if (!password || passwordError || password.length === 0) {
			setPasswordMessage(globalAny.language.invalid_password);
			setPasswordError(true);
		} else {
			setLoginEmail(email);
			let initialized = await login(
				email,
				password,
				setIsLoggedIn,
				() => invalidCredentials(),
				() => networkConnectionLost()
			);
			if (initialized?.proceed) {
				await processLogin();
			}
		}
	};

	const handleKeyPress = ({ nativeEvent: { key: keyValue } }: any) => {
		if (keyValue === "Enter") {
			setIsSigninLoading(false);
			loginAccount();
		}
	};

	const onClose = () => {
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const onPrivacy = () => {
		setPrivacyPolicyScreen(true);
	};

	useEffect(() => {
		setSignInLoading(true);
		setTimeout(() => {
			setSignInLoading(false);
		}, 1000);
	}, []);

	if (signInLoading) {
		return <LoaderSignIn screenDimensions={screenDimensions} />;
	}

	return (
		<Main>
			<AppBarWrapper dimensions={screenDimensions}>
				<TopAppbar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={privacyPolicyScreen ? capitalize(globalAny.language.privacy_policy) : capitalize(globalAny.language.sign_in)}
					rightLogo={ridevueLogo}
					onPressLeftTitleIcon={onBackPress}
					onPressLeftIcon={onClose}
					isSingleLogo={true}
				/>
			</AppBarWrapper>

			{privacyPolicyScreen ? <PrivacyPolicy text={privacyPolicyText} /> : <></>}

			<SignIn
				privacyPolicyScreen={privacyPolicyScreen}
				onPressLogin={() => loginAccount()}
				onPressRecoverPassword={() => RecoverPassword()}
				onEnterEmail={(event: any) => onEnterEmail(event)}
				onEnterPassword={(event: any) => onEnterPassword(event)}
				onPressInputIcon={() => onPressEye()}
				inputIcon={secureTextEntry ? "eye" : "eye-off"}
				emailError={emailError}
				emailErrorMessage={emailMessage}
				passwordError={passwordError}
				passwordField={secureTextEntry}
				passwordErrorMessage={passwordMessage}
				autoFocus={autoFocusPass}
				emailValue={email}
				passwordValue={password}
				onKeyPress={handleKeyPress}
				onPrivacy={onPrivacy}
				disabled={isSigninLoading}
				disableSignInBtn={disableSignInBtn}
				screenDimensions={screenDimensions}
			/>

			{visible ? (
				<Toast
					visible={visible}
					text={toastMessage}
					label={labelText}
					onDismissSnackBar={onDismissSnackBar}
					onPress={() => onPressToast(labelText)}
				/>
			) : (
				<></>
			)}
		</Main>
	);
};

export default SignInScreen;
