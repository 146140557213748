import * as React from "react";
import { TouchableOpacity } from "react-native";
import { useEffect, useState } from "react";
import { calculateDynamicHeight, calculateDynamicWidth } from "../../utils/Utils";

export default (props: any) => {
	const [width, setWidth] = useState(calculateDynamicWidth(props.width));
	const [height, setHeight] = useState(calculateDynamicHeight(props.height, props.width));
	const [borderWidth, setBorderWidth] = useState(calculateDynamicWidth(props.borderWidth));

	/**
	 * Dynamic Resizing Function - Updates the dimensions of items when the screen size changes without having to reload
	 */
	useEffect(() => {
		setWidth(calculateDynamicWidth(props.width));
		setHeight(calculateDynamicHeight(props.height, props.width));
		setBorderWidth(calculateDynamicWidth(props.borderWidth));
	}, [props.windowDimensions]);

	const defaultStyle = { width: width, height: height, borderWidth: borderWidth ? borderWidth : 0 };
	const incomingStyle = Array.isArray(props.style) ? props.style : [props.style];
	return <TouchableOpacity {...props} style={[defaultStyle, ...incomingStyle]} />;
};
