import { useNavigation } from "@react-navigation/native";
import { SettingsDataManagementNavigationProp, routeDataManagementSettings } from "../../Routes";
import SettingsContainer from "../../components/Layout/SettingsContainer";
import iconProceed from "../../assets/TopNavBarIcon/icon_nav_proceed.png";
import { Linking, View } from "react-native";
import { AsyncStorageKeys, Pages } from "../../Types";
import YourPrivacyChoice from "../../assets/Icons/YourPrivacyChoices.png";
import { globalAny, isSkipDataManagement } from "../../utils/Utils";
import LabelContainer from "../../components/SettingsComponent/SettingsLabelContainer";
import SettingsLegalButton from "../../components/SettingsComponent/SettingsLegalButton";
import { AppLogger } from "../../utils/AppLogger";
import { useEffect } from "react";
import useLegalContentStore from "../../store/useLegalContent.store";

const SettingsLegal = (props: any) => {
	const skipDataManagement = isSkipDataManagement();
	const navigationSettingsDataManagement = useNavigation<SettingsDataManagementNavigationProp>();
	const legalStoreValue = useLegalContentStore((state: any) => state.data);

	useEffect(() => {
		(async () => {
			const resultLegal = await legalStoreValue.legalData[AsyncStorageKeys.legals];
			const resultAcceptOtherData = await legalStoreValue.acceptOtherData[AsyncStorageKeys.acceptOtherDataSettings];
			if (resultLegal && resultAcceptOtherData) {
				AppLogger.log('resultLegal', resultLegal);
				localStorage.setItem('cacheLegalData', JSON.stringify(resultLegal));
				localStorage.setItem('cacheAcceptOtherData', JSON.stringify(resultAcceptOtherData));
			} 
		})();
	}, [legalStoreValue.legalData, legalStoreValue.acceptOtherData, legalStoreValue]);

	const onLegal = () => {
		navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.legal });
	};

	const onPrivacy = () => {
		navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.privacy });
	};

	const onData = () => {
		if (skipDataManagement) return;
		navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.acceptData });
	};

	return (
		<View>
			<SettingsContainer styledSettings={props.styledSettings}>
				<LabelContainer
					header={globalAny.language.your_privacy_choices}
					body={globalAny.language.privacy_spiel}
					onPressBody={() => Linking.openURL("https://www.sonypicturescore.com/")}
					onClickableBody={false}
					icon={YourPrivacyChoice}
					headerIcon={true}
					iconWidth={59}
					iconHeight={26}
					iconGap={"1rem"}
					onClickableHeader={false}
					{...props}
				/>
				<SettingsLegalButton {...props} onPress={onLegal} title={globalAny.language.legal_terms} rightIcon={iconProceed} />
				<SettingsLegalButton
					{...props}
					onPress={onData}
					title={globalAny.language.data_management}
					rightIcon={iconProceed}
					disabled={skipDataManagement}
				/>
				<SettingsLegalButton {...props} onPress={onPrivacy} title={globalAny.language.privacy_policy} rightIcon={iconProceed} />
			</SettingsContainer>
		</View>
	);
};

export default SettingsLegal;
