import { StyleSheet, ActivityIndicator, View } from "react-native";

interface Props {
	show: Boolean;
	size?: any;
	color?: any;
	isStyle: Boolean;
}

const Spinner = ({ show, size, color, isStyle }: Props) => {
	if (show) {
		return (
			<View style={isStyle ? [styles.margin] : styles.container}>
				<View style={[styles.container, styles.horizontal]}>
					<ActivityIndicator size={size || "large"} color={color || "white"} />
				</View>
			</View>
		);
	}
	return show;
};

export default Spinner;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
	},
	margin: {
		top: "5px",
		marginRight: "10px",
	},
	horizontal: {
		flexDirection: "row",
		justifyContent: "space-around",
	},
});
