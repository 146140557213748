import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useLoginStore = create(
	devtools((set) => ({
		isInitialLoading: false,
		setIsInitialLoading: (isInitialLoading: boolean) => set({ isInitialLoading: isInitialLoading }),
		isLoggedIn: false,
		setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn: isLoggedIn }),
		isProfileSelected: false,
		setIsProfileSelected: (isProfileSelected: boolean) => set({ isProfileSelected: isProfileSelected }),
		checkingLoginInfo: true,
		setCheckingLoginInfo: (checkingLoginInfo: boolean) => set({ checkingLoginInfo: checkingLoginInfo })
	}))
);

export default useLoginStore;