import CardGridPlaylist from "../CardGridPlaylist";
interface Props {
	title?: any;
	hideTitle?: boolean;
	slice?: number;
}

const SearchLoader = (props: Props) => {
	const load = 20;
	return (
		<CardGridPlaylist
			data={[...Array(load)].slice(0, props.slice ?? load).map((index) => {
				return { parentProductId: index, packshots: null };
			})}
			title={props.title}
			hideBorder={true}
			hideTitle={props.hideTitle}
			disabled={true}
		/>
	);
};

export default SearchLoader;
