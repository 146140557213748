import CustomizeDialogBox from "../DialogMessageBox/CustomizeDialogBox";

interface CarActivityDailogProps {
    title: string;
    body: string;
    subText?: string;
    buttonText?: string;
    onPress?: () => void;
    show: boolean;
};

const CarActivityDialog = (props: CarActivityDailogProps) => {

    return (
        props.show ? 
        (<CustomizeDialogBox
            Header={props.title}
            Body={props.body}
            Footer={props?.subText}
            Width={680}
            Height={"auto"}
            SingleButton={true}
            HideButton={!props?.buttonText}
            ButtonTextRight={props?.buttonText}
            onPressRight={props?.onPress} />) 
        : <></>);
};

export default CarActivityDialog;