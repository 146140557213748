import styled from "styled-components/native";

export const StyledWideMain = styled.View`
	display: flex;
	justify-content: space-between;
	height: 39rem;
`;

export const StyledButtonMain = styled.View`
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;
export const StyledButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

export const StyledFormContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

//Narrow
export const StyledNarrowMain = styled.View`
	display: flex;
	padding: 1.5rem 1.5rem;
	flex-direction: row;
	gap: 1rem;
`;

export const StyledNarrowButtonMain = styled.View`
	display: flex;
	width: 31.25rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	top: 0.38rem;
`;

export const StyledNarrowButtonContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledNarrowFormContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	gap: 1rem;
	align-self: stretch;
	top: 0.38rem;
`;

export const StyledNarrowButton = styled.View`
	display: flex;
	height: 4rem;
	padding: 0rem 1rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.625rem;
	align-self: stretch;
	border-radius: 0.75rem;
	background: rgba(178, 178, 178, 0.2);
`;

export const StyledRowButtonContainer = styled.View`
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	flex-direction: row;
`;

export const StyledButton = styled.View`
	display: flex;
	height: 4.5rem;
	padding: 0rem 1.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	flex: 1 0 0;
	border-radius: 1rem;
	background: rgba(178, 178, 178, 0.2);
`;

export const StyledTextInput = styled.View`
	display: flex;
	height: 4rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 0.75rem;
	background: rgba(178, 178, 178, 0.2);
`;

export const StyledPrivacy = styled.View`
	display: flex;
	height: 2rem;
	padding: 0rem 1rem 0rem 0.5rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	align-self: stretch;
	border-radius: 0.125rem;
	background: rgba(178, 178, 178, 0.2);
`;
