import Image from "../Image/Image";
import viewLegalDocButton from "../../assets/Icons/read_more.png";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";
import ButtonToggle from "../ButtonToggle";
import { StyledContainer, StyledImage, StyledLabel, StyledText } from "./styled";

interface Props {
	title?: string;
	onPress?: () => void;
	onPressToggle?: () => void;
	hasToggleButton?: any;
	isToggle?: any;
}

const SectionButton = (props: Props) => {
	const styleHelper = useStyleHelpers();
	return (
		// @ts-ignore

		<StyledContainer onPress={props.onPress}>
			{/* @ts-ignore */}
			<StyledLabel>
				{props.hasToggleButton && <ButtonToggle onPress={props.onPressToggle} defaultValue={props.isToggle} />}
				{/* @ts-ignore */}
				<StyledText
					isNarrow={styleHelper?.layout === ScreenDimensions.narrow}
					fontSize={styleHelper?.typographyBody?.fontSize}
					lineHeight={styleHelper?.typographyBody?.lineHeight}
				>
					{props.title}
				</StyledText>
				{/* @ts-ignore */}
				<StyledImage>
					<Image source={{ uri: viewLegalDocButton }} resizeMode={"contain"} width={"100%"} height={"100%"} />
				</StyledImage>
			</StyledLabel>
		</StyledContainer>
	);
};

export default SectionButton;
