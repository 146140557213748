import TabsView from "../../components/TabsView";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { globalAny } from "../../utils/Utils";
import SettingAccount from "./SettingsAccount";
import SettingsAddScreen from "./SettingsAddScreen";
import SettingsAdvance from "./SettingsAdvance";
import SettingsHelpAndAbout from "./SettingsHelpAndAbout";
import SettingsLegal from "./SettingsLegal";

export const filterMenu = (menuList: any, isHide: boolean, isMHU: boolean) => {
	let filteredMenu = menuList;

	if (isHide) {
		filteredMenu = filteredMenu.filter((menu: any) => menu.title !== globalAny.language.advanced);
	}

	if (!isMHU) {
		filteredMenu = filteredMenu.filter((menu: any) => menu.title !== globalAny.language.add_screen);
	}

	return filteredMenu;
};

export const RenderLogoutButton = (props: any) => (
	<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Secondary} onPress={() => props.LogOutAccount()}>
		{globalAny.language.logout}
	</TouchableButton>
);

export const TabsViewLayout = (props: any) => {
	return (
		<>
			<TabsView tabsToRender={filterMenu(props.menuList, props.isHide, props.isMHU)} isSettings={true}>
				<SettingAccount {...props} />
				<SettingsHelpAndAbout {...props} />
				<SettingsLegal {...props} />
				{props.isMHU ? <SettingsAddScreen {...props} /> : <SettingsAdvance {...props} />}
				<SettingsAdvance {...props} />
			</TabsView>
		</>
	);
};
