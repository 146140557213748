import { CONTENT_BASEURL_V7, CONTENT_BASEURL_V8 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

/**
 *
 * @param parentProductId - string parentProductId
 * @returns - progress details
 */
export const getProgressVideo = (parentProductId: string, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V7 + "progress/" + parentProductId
			,{ 
				headers: getHeaders(true),
				signal 
			}
		).then((resJson) => {
			resolve(resJson);
		}).catch((err) => reject(err));
	});

	return promise;
};

/**
 *
 * @param parentProductId - The parent product ID
 * @param streamProgress - Stream current seconds of video time
 * @param type  - Stream type (stream, download)
 * @param totalDuration - Stream total video runtime in seconds
 * @param event - The device event
 * @param trackingId - The tracking id taken from the video endpoint after requesting the video
 * @param transactionType - EST, SVOD, TVOD, FVOD
 * @returns
 */
export const setProgressVideo = (
	parentProductId: number,
	streamProgress: number,
	type: number,
	totalDuration: number,
	event: string,
	trackingId: string,
	transactionType: number
) => {
	const body = {
		parentProductId,
		streamProgress,
		type,
		totalDuration,
		event,
		trackingId,
		transactionType,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V8 + "progress", { method: "POST", headers: getHeaders(true), body: JSON.stringify(body) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err));
	});
	return promise;
};
