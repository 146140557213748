import styled, { css } from "styled-components/native";
import { white_smoke } from "../../StyleHelpers";
import { Modal as ModalPaper } from "react-native-paper";

export const StyledModal = styled(ModalPaper)`
	background-color: rgba(0, 0, 0, 0.9);
	align-items: center;
	position: fixed;
	z-index: 999;
`;

export const StyledInnerContent = styled.View`
	justify-content: center;
	align-items: center;
`;

export const StyledCloseContent = styled.View`
	align-items: end;
	margin-top: 10px;
	margin-right: 10px;
`;

export const StyledTextContainer = styled.View`
	text-align: left;
	opacity: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const StyledMargin = styled.Text`
	margin-bottom: 30px;
`;

export const StyledBottomContainer = styled.View`
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
	margin: 5px 0px 10px 0px;
`;

export const StyledBottomInnerContainer = styled.View`
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
`;

export const StyledBottomText = styled.Text`
	text-align: center;
	flex: 1;
	color: white;
`;

export const StyledText = styled.Text<any>`
	text-align: center;
	font-size: 25px;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	${(props) =>
		css`
			color: ${props.fontColor};
		`}
`;

export const StyledSubText = styled.Text`
	text-align: center;
	font-size: 18px;
	line-height: 20px;
`;

export const StyledTextLoadingContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const StyledSpinner = styled.View`
	margin-bottom: 12;
`;

export const StyledNarrowMain = styled.View`
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;
	flex-direction: row;
`;

export const StyledNarrowTextContainer = styled.View`
	display: flex;
	width: 31.25rem;
	text-align: center;
	gap: 1.5rem;
`;

export const StyledNarrowButtonContainer = styled.View`
	display: flex;
	width: 31.25rem;
`;

export const StyledTextTitle = styled.Text`
	font-family: "Nunito";
	color: ${white_smoke};
	font-size: 2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 2.5rem;
`;

export const StyledRentFrame = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const StyledRentText = styled.Text`
	color: #f2f2f2;
	text-align: center;
	font-family: Nunito;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem;
`;

export const StyledButtonContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

export const StyledButtonContainerNarrow = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	flex: 1 0 0;
`;
