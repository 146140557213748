import "./index.css";
import { Provider as PaperProvider } from "react-native-paper";
import { Platform } from "react-native";
import { FONT_FAMILY_DEFAULT } from "./utils/Typography";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

	ReactDOM.render(
		<PaperProvider>
			{Platform.OS === "web" ? (
				<React.Fragment>
					<style type="text/css">
						{`
							@font-face {
								font-family: 'MaterialCommunityIcons';
								src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf").default}) format('truetype');
							}
							@font-face {
								font-family: 'FontAwesome5_Regular';
								src: url(${require("react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf").default}) format('truetype');
							@font-face {
								font-family: 'FontAwesome5_Solid';
								src: url(${require("react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf").default}) format('truetype');
							}
						`}
					</style>
					<style>
						{`input {
								font-family: ${FONT_FAMILY_DEFAULT} !important;
							}`}
					</style>
				</React.Fragment>
			) : null}
			<App />
		</PaperProvider>,
	document.getElementById("root")
);
