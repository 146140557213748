import { StyledMainDefault, StyledMainWrapper, StyledMainContainer } from "./Styled";

const MainDefaultWrapper = ({ children }: any) => {
	return (
		//@ts-ignore
		<StyledMainWrapper>
			{/* @ts-ignore */}
			<StyledMainContainer>
				{/* @ts-ignore */}
				<StyledMainDefault>
					{children}
				</StyledMainDefault>
			</StyledMainContainer>
		</StyledMainWrapper>
	);
};

export default MainDefaultWrapper;
