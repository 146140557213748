import styled, { css } from "styled-components/native";
import { spacing_m, white_smoke } from "../../StyleHelpers";
import CardPackshot from "../CardPackshot";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import useStyleHelpers from "../../hooks/useStyleHelpers";

interface Props {
	title?: any;
	hideTitle?: boolean;
	data?: any;
	hideBorder?: boolean;
	isClips?: boolean;
	isAssignToScreen?: boolean;
	parentProductId?: any;
	isRedeem?: boolean;
	isAggregate?: boolean;
	isStudioAccess?: boolean;
	isBorderClips?: boolean;
	isExtras?: boolean;
	disabled?: boolean;
	isPreview?: boolean;
	isHome?: boolean;
}

interface GridProps {
	gridSize?: number;
}

const StyledContainer = styled.View`
	width: 100%;
	gap: 0.5rem;
`;

const StyledTitle = styled.Text<any>`
	font-size: 25px;
	color: ${white_smoke};
	width: 100%;
	padding-right: ${props => props.extras.paddingRight};
	margin-bottom: 0.313rem;
`;

const StyledProgressTitle = styled.Text`
	width: 368px;
	height: 1.75rem;
	border-radius: 0.875rem;
	overflow: hidden;
	margin-bottom: 0.313rem;
`;

const StyledGridCenter = styled.View<Props>`
	${(props) =>
		props.isExtras &&
		css`
			margin-bottom: 1.2%;
		`}
`;

const StyledGrid = styled.View<any>`
	display: grid;
  justify-items: center;

	${(props) =>
		props?.gridSize &&
		css`
			grid-template-columns: repeat(${props.gridSize}, 1fr);
			column-gap: ${spacing_m};
			row-gap: ${spacing_m};
		`}

`;

const StyledImage = styled.View<any>`
	${(props) =>
		props?.isClips && css`margin: 0 auto;`
	}
`;

const CardGridPlaylist = (props: Props) => {
	const styleHelper = useStyleHelpers();

	const getGridSizeByType = () => {
		if (props.isClips) return styleHelper?.gridSize.hka;

		return styleHelper?.gridSize.packshot;
	};

	return (
		//@ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			{props.title && !props.hideTitle && !props.isAggregate ? <StyledTitle extras={styleHelper?.tdp.extras}>{props.title}</StyledTitle> : <></>}
			{!props.title && !props.hideTitle && !props.isAggregate ? (
				// @ts-ignore
				<StyledProgressTitle extras={styleHelper?.tdp.extras}>
					<ImgProgressBar />
				</StyledProgressTitle>
			) : <></>}
			{props.data?.length !== 0 ? (
				//@ts-ignore
				<StyledGridCenter isExtras={props.isExtras}>
					{/* @ts-ignore */}
					<StyledGrid extras={styleHelper?.tdp.extras} gridSize={getGridSizeByType()}>
						{props.data?.map((item: any) => {
							return (
								// @ts-ignore
								<StyledImage isClips={props.isClips} key={`grid-products-${item.parentProductId ?? item.id}`} >
									{!props.isClips ? (
										<CardPackshot
											key={item.parentProductId}
											productId={item.parentProductId}
											image={item.packshots}
											hideBorder={props.hideBorder}
											isProgressBar={item.isProgressBar}
											isRedeem={props.isRedeem}
											disabled={props.disabled}
											isPreview={props?.isPreview}
											isHome={props?.isHome}
										/>
									) : <></>}
									{props.isClips ? (
										<CardPackshot
											key={item.id}
											productId={props.isAggregate ? item.productId : props.parentProductId}
											vamId={item.id}
											image={item.imageUrl}
											title={item.title}
											isAssignToScreen={props.isAssignToScreen}
											isRedeem={item?.vamStatus === 1 || item?.vamStatus === 3 || props.isRedeem}
											isLandscape={props.isClips}
											isClips={props.isClips}
											isStudioAccess={props.isStudioAccess}
											isVamAggregate={props.isAggregate}
											hideBorder={props.hideBorder}
											vamStatus={item.vamStatus}
											disabled={props.disabled}
											isPreview={props?.isPreview}
											isHome={props?.isHome}
										/>
									) : <></>}
								</StyledImage>
							);
						})}
					</StyledGrid>
				</StyledGridCenter>
			) : <></>}
		</StyledContainer>
	);
};

export default CardGridPlaylist;
