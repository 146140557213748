import { light_black } from "../../StyleHelpers";
import { useEffect, useState } from "react";
import { SettingsNavigationProp, routeNewSettings, routeHome, routeDataManagementSettingsDetails, routeDataManagementSettings } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import OtherDataComponent from "../../components/OtherDataComponent";
import LegalTermsConditions from "../../components/LegalTermsConditions";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import TopAppbar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import styled from "styled-components/native";
import AcceptDataComponent from "../../components/AcceptDataComponent";
import { Legals } from "../../models/Legals";
import { AsyncStorageKeys, Pages, SubPages, Type } from "../../Types";
import useGenericContentStore from "../../store/genericContent.store";
import { AcceptOtherData } from "../../models/AcceptOtherData";
import { globalAny } from "../../utils/Utils";
import useLegalContentStore from "../../store/useLegalContent.store";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
import AppBarWrapper from "../../components/Layout/MainLayout/AppBarWrapper";
import useDimensions from "../../hooks/useDimensions";
import usePrivacyPolicy from "../../hooks/usePrivacyPolicy";

const StyledContainer = styled.View`
	flex: 1;
	background-color: ${light_black};
`;

const SettingsDataManagement = (props: any) => {
	const { location, subLocation } = props.route.params;
	const [state, setState] = useState("");
	const [legal, setLegal] = useState(false);
	const [googleScreen, setGoogleScreen] = useState(false);
	const [videoAnalyticsScreen, setVideoAnalyticsScreen] = useState(false);
	const [otherData, setOtherData] = useState(false);
	const [googleData, setGoogleAnalyticsData] = useState(false);
	const [videoAnalyticsData, setVideoAnalyticsData] = useState(false);
	const [transactionsValue, setTransactionsValue] = useState(false);
	const [legalText, setLegalText] = useState<Legals>();
	const navigationSettingsScreen = useNavigation<SettingsNavigationProp>();
	const transactionHistory = usePaymentTransactionStore((state: any) => state.transactionHistory);
	const navigation = useNavigation();
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const [acceptOtherData, setAcceptOtherData] = useState<AcceptOtherData>();
	const [googleAnalytics, setGoogleAnalytics] = useState("");
	const [videoAnalytics, setVideoAnalytics] = useState("");
	const [sonyPictures, setSonyPictures] = useState("");
	const [sonyCompany, setSonyCompany] = useState("");
	const privacyPolicy= usePrivacyPolicy();
	const [acceptDataTitle, setAcceptDataTitle] = useState("");
	const [acceptDataDescription, setAcceptDataDescription] = useState("");
	const [otherDataTitle, setOtherDataTitle] = useState("");
	const [otherDataDescription, setOtherDataDescription] = useState("");
	const [legalData, setLegalData] = useState<Legals>();
	const screenDimensions = useDimensions();

	useEffect(() => {
		if (location === Pages.data) setSelectedTabs(2);
	}, []);

	useEffect(() => {
		if (transactionHistory) {
			setTransactionsValue(transactionHistory);
		}

		if (transactionHistory.length === undefined && location === Pages.transactions) {
			navigationSettingsScreen.navigate(routeNewSettings, {});
		}
	}, [transactionHistory]);

	useEffect(() => {
		if (!googleData && !videoAnalyticsData) {
			setOtherData(false);
		}

		if (googleData && videoAnalyticsData) {
			setOtherData(true);
		}
	}, [googleData, videoAnalyticsData]);

	useEffect(() => {
		setGoogleAnalyticsData(otherData);
		setVideoAnalyticsData(otherData);
	}, [otherData]);



	const fetchAcceptData = () => {
		let titleData = acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title");
		let titleDescription = acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description");
		if (!titleData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			titleData = cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title");
		}
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			titleDescription = cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description");
		}
		setAcceptDataTitle(titleData?.textValue);
		setAcceptDataDescription(titleDescription?.textValue)
	}

	const fetchOtherData = () => {
		let titleData = acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title");
		let titleDescription = acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-description");
		if (!titleData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			titleData = cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title");
		}
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			titleDescription = cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-description");
		}

		setOtherDataTitle(titleData?.textValue);
		setOtherDataDescription(titleDescription?.textValue)
	}

	useEffect(() => {
		switch (subLocation) {
			case SubPages.termsOfUse:
				onLegal(globalAny.language.terms_of_use, 6);
				return;
			case SubPages.termsOfService:
				onLegal(globalAny.language.terms_of_service, 3);
				return;
			case SubPages.sonyPictures:
				onPressSonyPictures();
				return;
			case SubPages.sonyGroup:
				onPressSonyCompany();
				return;
			case SubPages.googleAnalytics:
				onPressGoogle();
				return;
			case SubPages.videoAnalytics:
				onPressVideoAnalytics();
				return;
		}

		switch (location) {
			case Pages.legal:
				setState(globalAny.language.legal_terms);
				break;
			case Pages.acceptData:
				fetchAcceptData();
				setState("");
				setState(globalAny.language.data_management);
				break;
			case Pages.otherData:
				fetchOtherData();
				setState("");
				setState(globalAny.language.data_management);
				break;
			case Pages.privacy:
				setState(globalAny.language.privacy_policy);
				break;
			case Pages.transactions:
				setState(globalAny.language.transaction_history);
				break;
		}
	}, [location, subLocation]);

	const onSubmit = () => {
		navigationSettingsScreen.push(routeNewSettings, {});
	};

	const onBackPress = () => {
		if(location === Pages.privacy) {
			navigationSettingsScreen.navigate(routeNewSettings, {});
			return;
		}
		
		if(navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		navigationSettingsScreen.navigate(routeNewSettings, {});
	};

	const onLegal = async (keyValue: string, type: number) => {
		let data = legalData?.legalContents?.filter((val: any) => val?.type === type);
		if (!data) {
			const cacheLegalData = JSON.parse(localStorage.getItem('cacheLegalData') ?? '{}');
			data = cacheLegalData?.legalContents?.filter((val: any) => val?.type === type);
		}
		if (data) {
			switch (keyValue) {
				case globalAny.language.promotional_terms:
					setState(globalAny.language.promotional_terms);
					setLegalText(data[0]?.htmlContent);
					setLegal(true);
					break;
				case globalAny.language.terms_of_use:
					setState(globalAny.language.terms_of_use);
					setLegalText(data[0]?.htmlContent);
					setLegal(true);
					break;
				case globalAny.language.terms_of_service:
					setState(globalAny.language.terms_of_service);
					setLegalText(data[0]?.htmlContent);
					setLegal(true);
					break;
			}
		}
	};

	const onNext = () => {
		setGoogleScreen(false);
		setVideoAnalyticsScreen(false);
		//@ts-ignore
		navigation.push(routeDataManagementSettings, {location: Pages.otherData});
	};

	const onPressSonyPictures = () => {
		let data = acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-1");
		if (!data) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			data = cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-1");
		}
		setSonyPictures(data[0]?.textValue);
		setState("Sony Pictures");
	};

	const onPressSonyCompany = () => {
		let data = acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-2");
		if (!data) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			data = cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-2");
		}
		setSonyCompany(`
		  <span style="display: flex; align-items: center;">
			<label target="_blank" style="display: flex; align-items: center; margin-right: 500px; margin-top:-100px;">
			  ${data[0]?.textValue}
			</label>
		  </span>
		`);
		setState("Sony Group Companies");
	};

	const onPressGoogle = () => {
		let initialData = acceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Google Analytics");
		if (!initialData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			initialData = cacheAcceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Google Analytics");
		}
		const data = initialData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-read-more-1");
		setGoogleAnalytics(data[0]?.textValue);
		setState("Google Analytics");
		setGoogleScreen(true);
	};

	const onPressVideoAnalytics = () => {
		let initialData = acceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Video Analytics");
		if (!initialData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem('cacheAcceptOtherData') ?? '{}');
			initialData = cacheAcceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Video Analytics");
		}
		const data = initialData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-read-more-1");
		setVideoAnalytics(data[0]?.textValue);

		setState("Video Analytics");
		setVideoAnalyticsScreen(true);
	};

	const onClose = () => {
		//@ts-ignore
		navigation.navigate(routeHome);
	};

	return (
		// @ts-ignores
		<StyledContainer>
			{/* @ts-ignore */}
			<AppBarWrapper dimensions={screenDimensions}>
				<TopAppbar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={state}
					rightLogo={ridevueLogo}
					onPressLeftIcon={onClose}
					onPressLeftTitleIcon={onBackPress}
					isSingleLogo={true}
				/>
			</AppBarWrapper>
			{location === Pages.legal && !legal && (
				<LegalTermsConditions
					isSettings={true}
					onPressBack={() => onBackPress()}
					onPressNext={() => onBackPress()}
					// onPressPromotionalTerms={() => onLegal(globalAny.language.promotional_terms, 7)}
					//@ts-ignore
					onPressTermsOfUse={() => navigation.push(routeDataManagementSettingsDetails, {location: Pages.legal, subLocation: SubPages.termsOfUse})}
					//@ts-ignore
					onPressTermsOfService={() => navigation.push(routeDataManagementSettingsDetails, {location: Pages.legal, subLocation: SubPages.termsOfService})}
				/>
			)}

			{location === Pages.legal && legal && <PrivacyPolicy text={legalText}></PrivacyPolicy>}

			{location === Pages.acceptData && !subLocation && (
				<AcceptDataComponent
					onPressPrimaryButton={onNext}
					onPressSecondaryButton={onBackPress}
					//@ts-ignore
					onPressSonyCompany={() => navigation.push(routeDataManagementSettingsDetails, {location: Pages.acceptData, subLocation: SubPages.sonyGroup})}
					//@ts-ignore
					onPressSonyPictures={() => navigation.push(routeDataManagementSettingsDetails, {location: Pages.acceptData, subLocation: SubPages.sonyPictures})}
					type={Type.Settings}
					title={acceptDataTitle}
					description={acceptDataDescription}
				/>
			)}

			{location === Pages.otherData && !subLocation && (
				<OtherDataComponent
					onSubmit={(val) => setOtherData(val)}
					onChangeGoogleData={(val) => setGoogleAnalyticsData(val)}
					onChangeVideoAnalyticsData={(val) => setVideoAnalyticsData(val)}
					onPressPrimaryButton={onSubmit}
					onPressSecondaryButton={() => onBackPress()}
					//@ts-ignore
					onPressGoogle={() => navigation.push(routeDataManagementSettingsDetails, {location: Pages.otherData, subLocation: SubPages.googleAnalytics})}
					//@ts-ignore
					onPressVideoAnalytics={() => navigation.push(routeDataManagementSettingsDetails, {location: Pages.otherData, subLocation: SubPages.videoAnalytics})}
					isSettings={true}
					title={otherDataTitle}
					description={otherDataDescription}
				/>
			)}

			{location === Pages.otherData && googleScreen && <PrivacyPolicy text={googleAnalytics}></PrivacyPolicy>}

			{location === Pages.otherData && videoAnalyticsScreen && <PrivacyPolicy text={videoAnalytics}></PrivacyPolicy>}

			{location === Pages.acceptData && subLocation === SubPages.sonyPictures && <PrivacyPolicy text={sonyPictures}></PrivacyPolicy>}

			{location === Pages.acceptData  && subLocation === SubPages.sonyGroup && <PrivacyPolicy text={sonyCompany}></PrivacyPolicy>}

			{location === Pages.privacy && <PrivacyPolicy text={privacyPolicy}></PrivacyPolicy>}

			{location === Pages.transactions && <PrivacyPolicy transactionHistory={transactionsValue}></PrivacyPolicy>}
		</StyledContainer>
	);
};

export default SettingsDataManagement;
