import { Portal, Provider } from "react-native-paper";
import { TouchableOpacity } from "react-native";
import H5 from "../Typography/H5";
import H4 from "../Typography/H4";
import Image from "../Image/Image";
import TouchableButton from "../TouchableButton";
import closeIcon from "../../assets/Icons/close_icon.png";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import H6 from "../Typography/H6";
import parse from "html-react-parser";
import Spinner from "../Spinner";
import { ScreenDimensions } from "../../utils/enum";
import {
	StyledBottomContainer,
	StyledBottomInnerContainer,
	StyledCloseContent,
	StyledInnerContent,
	StyledMargin,
	StyledModal,
	StyledNarrowMain,
	StyledNarrowTextContainer,
	StyledSpinner,
	StyledText,
	StyledTextTitle,
	StyledTextContainer,
	StyledTextLoadingContainer,
	StyledSubText,
	StyledRentText,
	StyledButtonContainer,
	StyledButtonContainerNarrow,
	StyledRentFrame,
} from "./styled";

interface CustomDialogProps {
	Header?: any;
	Body?: any;
	Footer?: any;
	ButtonTextLeft?: string;
	ButtonTextMiddle?: string;
	ButtonTextRight?: string;
	ButtonSubTextLeft?: string;
	ButtonSubTextMiddle?: string;
	ButtonSubTextRight?: string;
	hasSubTextLeft?: boolean;
	hasSubTextMiddle?: boolean;
	hasSubTextRight?: boolean;
	TypeButtonColorLeft?: ComponentTypeEnum;
	TypeButtonColorMiddle?: ComponentTypeEnum;
	TypeButtonColorRight?: ComponentTypeEnum;
	BottomTextLeft?: string;
	BottomTextRight?: string;
	CloseButton?: boolean;
	SingleButton?: boolean;
	HasMiddleButton?: boolean;
	Width?: any;
	Height?: any;
	HideButton?: boolean;
	isProcessing?: boolean;
	screenDimensions?: string;
	onDismiss?: () => void;
	onPressRight?: () => void;
	onPressMiddle?: () => void;
	onPressLeft?: () => void;
	rentText?: string;
}

const CustomizeDialogBox = (props: CustomDialogProps) => {
	const customizeStyle = {
		background: "#1f262b",
		borderRadius: 16,
		padding: props.screenDimensions === ScreenDimensions.narrow ? "2rem" : "2.5rem",
		width: props.screenDimensions === ScreenDimensions.narrow ? "68rem" : props.Width,
		height: props.screenDimensions === ScreenDimensions.narrow ? "auto" : props.Height,
		zIndex: 1001,
	};

	const renderMiddleButton = () => {
		return (
			<>
				{!props.SingleButton && props.HasMiddleButton ? (
					<TouchableButton
						style={{ width: "100%" }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorMiddle}
						onPress={props.onPressMiddle}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorMiddle === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{props.ButtonTextMiddle}
							{props.hasSubTextMiddle ? (
								//@ts-ignore
								<StyledSubText>{props.ButtonSubTextMiddle}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderButtons = () => {
		return (
			<>
				{!props.SingleButton ? (
					<TouchableButton
						style={{ width: "100%" }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorLeft}
						onPress={props.onPressLeft}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorLeft === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{props.ButtonTextLeft}
							{props.hasSubTextLeft ? (
								// @ts-ignore
								<StyledSubText>{props.ButtonSubTextLeft}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
				{renderMiddleButton()}
				{props.rentText ? (
					// @ts-ignore
					<StyledRentFrame>
						{/* @ts-ignore */}
						<StyledRentText>{props.rentText}</StyledRentText>
					</StyledRentFrame>
				) : (
					<></>
				)}
				{!props.HideButton ? (
					<TouchableButton
						style={{ width: "100%" }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorRight}
						onPress={props.onPressRight}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorRight === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{/**@ts-ignore */}
							<StyledTextLoadingContainer>
								{/**@ts-ignore */}
								<StyledSpinner>
									{props.isProcessing ? (
										<Spinner
											show={true}
											size="small"
											color={props.TypeButtonColorRight === ComponentTypeEnum.Tertiary ? "black" : "white"}
											isStyle={true}
										/>
									) : (
										<></>
									)}
								</StyledSpinner>
								{props.ButtonTextRight}
							</StyledTextLoadingContainer>
							{props.hasSubTextRight ? (
								//@ts-ignore
								<StyledSubText>{props.ButtonSubTextRight}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderNarrow = () => {
		return (
			<>
				{/* @ts-ignore */}
				<StyledCloseContent>
					{props.CloseButton && (
						<TouchableOpacity onPress={props.onDismiss}>
							<Image source={{ uri: closeIcon }} width={30} height={30} />
						</TouchableOpacity>
					)}
				</StyledCloseContent>
				{/* @ts-ignore */}
				<StyledNarrowMain>
					{/* @ts-ignore */}
					<StyledButtonContainerNarrow>
						{renderButtons()}

						{/* @ts-ignore */}
						<StyledBottomContainer>
							{props.BottomTextLeft ? (
								// @ts-ignore
								<StyledBottomInnerContainer>
									{/* @ts-ignore */}
									<StyledBottomText>{props.BottomTextLeft}</StyledBottomText>
									{/* @ts-ignore */}
									<StyledBottomText>{props.BottomTextRight}</StyledBottomText>
								</StyledBottomInnerContainer>
							) : (
								<></>
							)}
						</StyledBottomContainer>
					</StyledButtonContainerNarrow>

					{/* @ts-ignore */}
					<StyledNarrowTextContainer>
						{/* @ts-ignore */}
						<StyledTextTitle>{props.Header}</StyledTextTitle>
						{props.SingleButton ? <H4>{props.Body}</H4> : <H5>{props.Body}</H5>}

						{props.Footer && (
							// @ts-ignore
							<H6>{parse(props.Footer)}</H6>
						)}
					</StyledNarrowTextContainer>
				</StyledNarrowMain>
			</>
		);
	};

	const renderDialog = () => {
		return (
			<>
				{/* @ts-ignore */}
				<StyledCloseContent>
					{props.CloseButton && (
						<TouchableOpacity onPress={props.onDismiss}>
							<Image source={{ uri: closeIcon }} width={30} height={30} />
						</TouchableOpacity>
					)}
				</StyledCloseContent>
				{/* @ts-ignore */}
				<StyledInnerContent>
					{/* @ts-ignore */}
					<StyledTextContainer>
						{/* @ts-ignore */}
						<StyledMargin>
							{/* @ts-ignore */}
							<StyledTextTitle>{props.Header}</StyledTextTitle>
						</StyledMargin>
						{/* @ts-ignore */}
						<StyledMargin>
							{/* @ts-ignore */}
							{props.SingleButton ? <H4>{props.Body}</H4> : <H5>{props.Body}</H5>}
						</StyledMargin>
						{props.Footer && (
							// @ts-ignore
							<StyledMargin>
								<H6>{parse(props.Footer)}</H6>
							</StyledMargin>
						)}
					</StyledTextContainer>
					{/* @ts-ignore */}
					<StyledButtonContainer>{renderButtons()}</StyledButtonContainer>
				</StyledInnerContent>
				{/* @ts-ignore */}
				<StyledBottomContainer>
					{props.BottomTextLeft ? (
						// @ts-ignore
						<StyledBottomInnerContainer>
							{/* @ts-ignore */}
							<StyledBottomText>{props.BottomTextLeft}</StyledBottomText>
							{/* @ts-ignore */}
							<StyledBottomText>{props.BottomTextRight}</StyledBottomText>
						</StyledBottomInnerContainer>
					) : (
						<></>
					)}
				</StyledBottomContainer>
			</>
		);
	};
	return (
		<Provider>
			<Portal>
				<StyledModal visible={true} contentContainerStyle={customizeStyle}>
					{props.screenDimensions === ScreenDimensions.narrow ? renderNarrow() : renderDialog()}
				</StyledModal>
			</Portal>
		</Provider>
	);
};

export default CustomizeDialogBox;
