import { dark_gray, white_smoke } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import { View, Text } from "react-native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { useNavigation } from "@react-navigation/native";
import { routeNewCuration } from "../../Routes";
import { globalAny } from "../../utils/Utils";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";
import { StyleSheet } from "react-native";

interface Props {
	banner?: any;
	borderEvent?: boolean;
}

interface StyledProps {
	banner?: any;
	borderEvent?: boolean;
	width?: any;
	height?: any;
}

const StyledContainer = styled.View<StyledProps>`
	background-color: ${dark_gray};
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-repeat: no-repeat;
			background-size: cover;
		`}

	border-radius: 1rem;
	height: auto;
	aspectRatio: 4/1;
	width: ${props => props.width};
	border-width: 2px;
	margin-bottom: 2rem;

	outline: 2px solid rgba(0, 0, 0, 0.30);
`;

const StyledButton = styled.View`
	width: 368px;
`;

const FeatureText = styled.View<any>`
	top: 1;
	left: 1;
	display: flex;
	width: 50%;
	height: 100%;
	padding: 2.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1.5rem;
	flex-shrink: 0;
	border-radius: 16px;
	border-color: transparent;
	background-image: linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.00) 100%);
	z-index: 9;
`;

const FeatureLineTwo = styled.Text<any>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${(props) => props?.clamp};
	align-self: stretch;
	overflow: hidden;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30);
	font-family: 'Nunito';
	font-size: ${(props) => props?.fontSize};
	font-weight: 500;
	font-style: normal;
	line-height: ${(props) => props?.lineHeight};
	color: #fff;
`;

const TextContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

const FooterBanner = (props: Props) => {
	const navigation = useNavigation();
	const styleHelper = useStyleHelpers();
	const isNarrow = () => styleHelper?.layout === ScreenDimensions.narrow;

	const goToStudioAccessHandler = () => {
		//@ts-ignore
		navigation.navigate(routeNewCuration);
	};

	return (
		<View style={{width: '100%', alignItems: isNarrow() ? 'center' : 'flex-start', paddingLeft: isNarrow() ? '' : styleHelper?.paddingStart}}>
			{/* @ts-ignore */}
			<StyledContainer 
				height={styleHelper?.banner.height}
				width={styleHelper?.banner.width}
				banner={props.banner}
				style={styles.onLeave}
			>
				<FeatureText height={styleHelper?.banner.height}>
					{/* @ts-ignore */}
					<TextContainer>
						<FeatureLineTwo 
							clamp={styleHelper?.banner.lineTwo.clamp}
							fontSize={styleHelper?.banner.lineTwo.fontSize}
							lineHeight={styleHelper?.banner.lineTwo.lineHeight}
						>
							{globalAny.language.banner_text}
						</FeatureLineTwo> 
					</TextContainer>
					{/* @ts-ignore */}
					<StyledButton>
						<TouchableButton
							addMarginRight={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={goToStudioAccessHandler}
						>
							{/* @ts-ignore */}
							<Text style={{ color: "white", fontFamily: FONT_FAMILY_DEFAULT, fontSize: 25 }}>
								{globalAny.language.go_behind_the_scenes}
							</Text>
						</TouchableButton>
					</StyledButton>
				</FeatureText>
			</StyledContainer>
		</View>
	);
};

export default FooterBanner;

const styles = StyleSheet.create({
	stt: {
		width: '31.0625rem',
		height: '5.0625rem',
		flexShrink: 0
	},
	onHover: {
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray
	},
	onLeave: {
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray
	},
});
