import ComponentThemeType from "../../models/ComponentTheme";
import ITextInputProps from "../../models/ITextInputProps";
import TextInputLegacy from "./TextInputLegacy";
import TextInputVinfast from "./TextInputVinfast";

const TextInput = (props: ITextInputProps) => {
	if (props.componentTheme === ComponentThemeType.VinFast) {
		return <TextInputVinfast {...props} />;
	}

	return <TextInputLegacy {...props} />;
};

export default TextInput;
