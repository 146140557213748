import styled from "styled-components/native";

export const StyledContainer = styled.View`
	height: 100%;
`;

export const StyleTextContainer = styled.View`
	padding-top: 30vh;
	text-align: center;
`;

export const StyleText = styled.Text`
	font-size: 37px;
	line-height: 44px;
	font-family: "Nunito";
	font-weight: 300;
	color: rgba(242, 242, 242, 1);
`;

export const StyleSearchContainer = styled.View`
	margin-top: 5px;
`;
