import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { CreateAccountState } from "../Types";

const useCreateAccount = create(
	devtools((set) => ({
		createAccountState: CreateAccountState.legalOne,
		setCreateAccountState: (createAccountState: string) => set({ createAccountState: createAccountState }),
		automaticVoucherCode: {},
		setAutomaticVoucherCode: (automaticVoucherCode: any) => set({automaticVoucherCode: automaticVoucherCode})
	}))
);

export default useCreateAccount;
