import styled, { css } from "styled-components/native";
import { ScreenDimensions } from "../../utils/enum";

interface Props {
	dimensions: string;
}

export const StyledMainDefault = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-items: center;
	width: 100%;
`;

export const StyledButtonContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

export const StyledRowButtonContainer = styled.View<Props>`
	${(props) =>
		props.dimensions === ScreenDimensions.narrow &&
		css`
			flex-direction: column;
			gap: 0.625rem;
		`}
	${(props) =>
		props.dimensions != ScreenDimensions.narrow &&
		css`
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 1.5rem;
			align-self: stretch;
		`}
`;

export const StyledMainWide = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-items: center;
	width: 100%;
`;

export const StyledMainNarrow = styled.View`
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
	padding: 1.5rem;
`;

export const StyledImageContainer = styled.View`
	display: flex;
`;

export const StyledButtonNarrowContainer = styled.View`
	gap: 0.625rem;
	width: 29.3rem;
`;

export const StyledRowButtonNarrowContainer = styled.View`
	flex-direction: column;
	gap: 0.625rem;
`;

export const StyledLoginText = styled.View`
	align-self: stretch;
	color: #F2F2F2;
	text-align: center;
	font-family: Nunito;
	font-size: 1.3125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4375rem;
`;

export const StyledLoginTextNarrow = styled.View`
	width: 59.5rem;
	position: fixed;
	left: 8rem;
	top: 1.3125rem;
	align-self: stretch;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.1875rem; 
`;