import { useEffect, useRef, useState } from "react";
import { Dimensions, SafeAreaView, ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { routeHome } from "../../Routes";
import { apiGetSearchResults } from "../../services/metadataService";
import { getPlaylistSummary } from "../../services/apiMoviesContentService";
import { getItem, getPackshot, getTransactionFilters, globalAny } from "../../utils/Utils";
import TopAppBar from "../../components/TopAppBar";
import searchIcon from "../../assets/TopNavBarIcon/icon_nav_search.png";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import GalleryContainer from "../../components/Layout/GalleryContainer";
import CardGridPlaylist from "../../components/CardGridPlaylist";
import SearchLoader from "../../components/Loaders/SearchLoader";
import useGenericContentStore from "../../store/genericContent.store";
import { Pages } from "../../Types";
import useSearchDataStore from "../../store/useSearchData.store";
import { AppLogger } from "../../utils/AppLogger";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";
import { StyledContainer, StyleTextContainer, StyleSearchContainer, StyleText } from "./styled";
import useStyleHelpers from "../../hooks/useStyleHelpers";

const transactionFilters = getTransactionFilters();
let currentPage = 1;
let searchTimeout: NodeJS.Timeout | null = null;

const SearchScreen = () => {
	const screenDimensions = useDimensions();
	const isNarrow = screenDimensions === ScreenDimensions.narrow;
	const isDefault = screenDimensions === ScreenDimensions.default;
	const pageSize = isNarrow ? 20 : 12;
	const navigation = useNavigation();
	const [searchTerm, setSearchTerm] = useState("");
	const [searchLoader, setSearchLoader] = useState(false);
	const [popular, setPopular] = useState([]);
	const [title, setTitle] = useState("");
	const [focus, setFocus] = useState(true);
	const [isSearching, setIsSearching] = useState(false);
	const [windowWidth, setWindowWidth] = useState<any>();
	const [gridSize, setGridSize] = useState<any>(6);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const lastSearchQuery = useSearchDataStore((state: any) => state.lastSearchQuery);
	const setLastSearchQuery = useSearchDataStore((state: any) => state.setLastSearchQuery);
	const searchResults = useSearchDataStore((state: any) => state.searchResults);
	const setSearchResults = useSearchDataStore((state: any) => state.setSearchResults);
	const lastNext = useSearchDataStore((state: any) => state.lastNext);
	const setLastNext = useSearchDataStore((state: any) => state.setLastNext);
	const [search, setSearch] = useState(searchResults ?? []);
	const bottomScroll = useRef(null);
	const [hasPopular, setHasPopular] = useState(false);
	const [next, setNext] = useState(lastNext ?? pageSize);
	const styleHelper = useStyleHelpers();
	const styledSearch = styleHelper?.aggregate;

	const Init = async () => {
		if (searchTerm.length === 0) {
			const playlistResult: any = await getPlaylistSummary(Pages.search, getTransactionFilters());

			if (playlistResult?.products?.length !== 0) {
				setHasPopular(false);
				const [result] = getItem(playlistResult);
				setPopular(result.products);
				return;
			}

			setHasPopular(true);
		}
	};

	const calculateGridItemsByWindowWidth = () => {
		if (isDefault) {
			return 4;
		} else if (isNarrow) {
			return 10;
		}
		return 6;
	};

	const onBackPress = () => {
		setLastSearchQuery("");
		setSearchResults([]);
		setLastNext(pageSize);
		//@ts-ignore
		navigation.replace(routeHome);
	};

	const onClose = () => {
		setLastSearchQuery("");
		setNavigateEvent("");
		setSearchResults([]);
		setLastNext(pageSize);
		//@ts-ignore
		navigation.replace(routeHome);
	};

	const doSearch = async (searchQuery: string) => {
		if (searchResults.length) return;
		setNavigateEvent(Pages.search);
		if (searchQuery.length > 0) {
			setHasPopular(false);
			onFocus();
			try {
				const searchResult: any = await apiGetSearchResults(searchQuery, transactionFilters, pageSize, currentPage);
				if (searchResult) {
					setSearch(getPackshot(searchResult));
					setSearchResults(getPackshot(searchResult));
				}
				setIsSearching(false);
			} catch (err) {
				AppLogger.log(err);
			}
		}
		onFocus();
	};

	const onFocus = () => {
		setFocus(true);
		setTimeout(() => {
			setFocus(false);
		}, 2);
	};

	const onSearchKey = async (searchText: any) => {
		if (!searchText) {
			setIsSearching(false);
		} else {
			setIsSearching(true);
		}

		setNext(pageSize);
		setLastNext(pageSize);
		setPopular([]);
		setSearch([]);
		setSearchResults([]);
		setSearchTerm(searchText);
		const title = `${globalAny.language.search_results_for} “${searchText}”`;
		setTitle(title);
		// @ts-ignore
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			if (searchText.length > 0) {
				doSearch(searchText);
				setLastSearchQuery(searchText);
			} else {
				setLastSearchQuery("");
			}
		}, 3000);
	};

	const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
		const paddingBottom = 100;
		return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingBottom;
	};

	const onLazyLoading = async () => {
		if (isSearching || searchResults?.length < pageSize) return;

		setSearchLoader(true);
		const nextPageNumber = Math.ceil(search.length / pageSize) + 1;
		try {
			const nextPageResults: any = await apiGetSearchResults(searchTerm, transactionFilters, pageSize, nextPageNumber);
			if (Array.isArray(nextPageResults) && nextPageResults?.length) {
				setSearch((prevSearch: any) => [...prevSearch, ...getPackshot(nextPageResults)]);
				setSearchResults(search);
				setNext(next + pageSize);
				setLastNext(next + pageSize);
			}
		} catch (err) {
			AppLogger.log(err);
		} finally {
			setSearchLoader(false);
		}
	};

	/**
	 * Return search data sliced based on the last pagination length (next)
	 */
	const getSearchData = () => {
		if (!search?.length) return [];

		return search.slice(0, next);
	};

	const sliceSearchLoader = () => {
		if (isDefault) {
			return 8;
		} else if (isNarrow) {
			return 20;
		}
		return 12;
	};

	const renderSearch = () => {
		return (
			<>
				{!searchTerm && popular.length === 0 && search.length === 0 && !isSearching && !hasPopular && (
					<SearchLoader title={""} slice={sliceSearchLoader()} />
				)}

				{!searchTerm && popular.length === 0 && search.length === 0 && !isSearching && hasPopular && (
					// @ts-ignore
					<StyleTextContainer>
						{/* @ts-ignore */}
						<StyleText>{globalAny.language.no_popular}</StyleText>
					</StyleTextContainer>
				)}

				{searchTerm ? <CardGridPlaylist data={getSearchData()} title={title} /> : <></>}
				{searchTerm && !search.length && isSearching ? (
					//@ts-ignore
					<StyleSearchContainer>
						<SearchLoader hideTitle={true} title={globalAny.language.loading} slice={sliceSearchLoader()} />
					</StyleSearchContainer>
				) : (
					<></>
				)}
				{popular.length !== 0 && !searchTerm && <CardGridPlaylist data={popular} title={globalAny.language.popular_search} />}
				{searchTerm && search.length === 0 && searchResults.length === 0 && !isSearching ? (
					//@ts-ignore
					<StyleTextContainer>
						{/* @ts-ignore */}
						<StyleText>{globalAny?.language?.no_matches_found}</StyleText>
					</StyleTextContainer>
				) : (
					<></>
				)}
				{searchLoader && (
					<View style={{ marginTop: 20 }} ref={bottomScroll}>
						<SearchLoader hideTitle={true} title={globalAny.language.loading} slice={gridSize} />
					</View>
				)}
			</>
		);
	};

	useEffect(() => {
		const lastTitleSearch = `${globalAny.language.search_results_for} “${lastSearchQuery}”`;
		setTitle(lastTitleSearch);
		setSearchTerm(lastSearchQuery || "");
		setNavigateEvent(Pages.search);
		if (lastSearchQuery) {
			doSearch(lastSearchQuery);
		}
		return () => {
			setSearchTerm(lastSearchQuery);
		};
	}, []);

	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
			setGridSize(calculateGridItemsByWindowWidth());
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth, screenDimensions]);

	useEffect(() => {
		Init();
		return () => {
			setHasPopular(false);
			setPopular([]);
		};
	}, [searchTerm]);

	return (
		<SafeAreaView style={isNarrow ? { flex: 1, marginTop: -50 } : { flex: 1 }}>
			{/* @ts-ignore */}
			<StyledContainer>
				<TopAppBar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					rightIcon={searchIcon}
					onPressLeftTitleIcon={onBackPress}
					onPressLeftIcon={onClose}
					search={true}
					placeholder={globalAny.language.search_keyword}
					onChangeText={onSearchKey}
					onPressInputIcon={() => {
						setLastSearchQuery("");
						setSearchTerm("");
						setSearch([]);
					}}
					onPressRightIcon={() => doSearch(searchTerm)}
					inputValue={searchTerm}
					autoFocus={focus}
					isShowClose={false}
				/>

				<GalleryContainer>
					<ScrollView
						onScroll={({ nativeEvent }) => {
							if (!search.length || !searchTerm.length) return;

							if (isCloseToBottom(nativeEvent) && !searchLoader) {
								onLazyLoading();
							}
						}}
						scrollEventThrottle={400}
						style={{
							paddingTop: 153,
							paddingBottom: 50,
							paddingLeft: styledSearch?.paddingLeft,
							paddingRight: styledSearch?.paddingRight,
						}}
					>
						{renderSearch()}
					</ScrollView>
				</GalleryContainer>
			</StyledContainer>
		</SafeAreaView>
	);
};

export default SearchScreen;
