import { Text, StyleSheet } from "react-native";
import { black, white } from "../../StyleHelpers";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import styled, { css } from "styled-components/native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import PlayIcon from "../../assets/Icons/Play.png";
import AssignIcon from "../../assets/Icons/ScreenManager.png";
import Image from "../../components/Image/Image";

import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import GenresComponent from "../../components/GenresComponent";
import { useEffect, useState } from "react";
import ImgProgressBar from "../../components/Loaders/ImgProgressBar";
import { globalAny, isStaging } from "../../utils/Utils";
import useGenericContentStore from "../../store/genericContent.store";
import { CarActivity } from "../../Types";
import useDrivingStore from "../../store/useDriving.store";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";

const StyledFrame = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
`;

const StyledButtonLoader = styled.View`
	height: 4.5rem;
	width: 100%;
	border-radius: 16px;
	overflow: hidden;
`;

const StyledButtonContainer = styled.View<any>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	width: 100%;
	${(props) =>
		!props.isButtonVisible
			? css`
					display: auto;
			  `
			: css`
					display: none;
			  `}
`;

const StyledButtonContainerNarrow = styled.View`
	display: flex;
	width: 31.25rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	flex-shrink: 0;
`;

const StyledButton = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
`;

const StyledTextTemplate = styled.View<any>`
	display: flex;
	width: ${(props) => props.width};
	flex-direction: column;
	align-items: flex-start;
`;

const StyledTextTemplateNarrow = styled.View<any>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
`;

const StyledTextFrame = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

const StyledRatings = styled.Text<any>`
	width: ${(props) => (props.width ? props.width : "auto")};
	align-self: stretch;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.ratings.lineHeight};
	text-shadow: rgba(0,0,0,.3) 2px 2px 2px;
`;

const StyledSynopsis = styled.Text<any>`
	height: ${(props) => props.synopsis.height};
	width: ${(props) => (props.width ? props.width : "auto")};
	align-self: stretch;
	overflow: hidden;
	color: #F2F2F2;
	text-overflow: ellipsis;
	font-family: Nunito;
	font-size: ${(props) => props.synopsis.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.synopsis.lineHeight};
	text-shadow: rgba(0,0,0,.3) 2px 2px 2px;
`;

const wording = {
	play: "Play",
	resume: "Resume",
	redeem: "Redeem for 1 Credit",
	purchase: "Purchase Options",
	assign: "Assign to Screens",
	trailer: "Trailer",
	hd: "HD",
};

const StyledFrameNarrow = styled.View`
	display: flex;
	flex-direction: row;
	width: 57.5rem;
	justify-content: center;
	align-items: flex-start;
	gap: 1.5rem;
`;

interface Buttons {
	text: string;
	icon: string;
	theme: ComponentThemeType;
	type: ComponentTypeEnum;
	onPress: () => void;
}

const ViewDetails = (props: any) => {
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const isNarrow = () => screenDimensions === ScreenDimensions.narrow;
	const buttonDataDefault = [
		{
			text: props.streamProgress ? globalAny.language.resume : globalAny.language.play,
			icon: PlayIcon,
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPlay,
			redeem: true,
		},
		{
			text: globalAny.language.redeem_for_one,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Tertiary,
			onPress: props.onRedeemMovie,
			redeem: false,
		},
		{
			text: globalAny.language.purchase,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
		},
		{
			text: props.rentText,
			icon: "",
			options: "rent",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
		},
		{
			text: props.purchaseText,
			options: "purchase",
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
		},
		isStaging() && {
			text: globalAny.language.assign_to_screens,
			icon: AssignIcon,
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onAssignScreen,
			redeem: true,
		},
		props.isTrailer && {
			text: globalAny.language.trailer,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onTrailer,
			redeem: false,
		},
	];

	const [isButtonVisible, setIsButtonVisible] = useState(false);
	const [data, setData] = useState({});
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const isRenderTDP = useGenericContentStore((state: any) => state.isRenderTDP);
	const goToDetails = () => {
		setSelectedTabs(1);
	};

	useEffect(() => {
		 setIsButtonVisible(false);
	}, [isRenderTDP]);
	
	useEffect(() => {
		setIsButtonVisible(false);
		if (props.isLoadingButton) {
			setIsButtonVisible(false);
			return;
		}
		setIsButtonVisible(true);
	}, [props.isLoadingButton]);

	useEffect(() => {
		let buttonData = buttonDataDefault
			.filter((val) => val.redeem === props.redeemed || val.text === globalAny.language.trailer)
			.map((item) => {
				if (item.text === globalAny.language.redeem_for_one && !props.availabilityRedeem) return;
				if (availabilityChecking(item)) return;
				return { ...item };
			});
		setData(buttonData);
	}, [props.redeemed, props?.streamProgress, props?.isTrailer]);

	const availabilityChecking = (item: any) => {
		if ((item.options === "rent" && !props.availabilityRent) || (item.options === "rent" && props.availabilityPurchase && props.availabilityRent))
			return true;

		if (
			(item.options === "purchase" && !props.availabilityPurchase) ||
			(item.options === "purchase" && props.availabilityPurchase && props.availabilityRent)
		)
			return true;

		if (item.text === globalAny.language.purchase && (!props.availabilityRent || !props.availabilityPurchase)) return true;
	};

	const renderButtons = (item: Buttons, id: number) => {
		return (
			<TouchableButton
				key={`tdp-button-${id}`}
				fontColor={item.type === ComponentTypeEnum.Tertiary ? black : white}
				componentTheme={item.theme}
				type={item.type}
				isStreamProgress={props.streamProgress && item.text === wording.resume ? props.streamProgress : 0}
				onPress={item.onPress}
				style={{ width: styleHelper?.tdp?.textTemplateWidth }}
				disabled={item.text !== wording.assign && carActivity === CarActivity.INMOTION}
			>
				{/* @ts-ignore */}
				<StyledButton>
					{item.icon ? <Image source={{ uri: item.icon }} width={56} height={56} /> : <></>}
					<Text style={styles.button}>{item.text}</Text>
				</StyledButton>
			</TouchableButton>
		);
	};

	const renderTextTemplateDefault = () => {
		if (props.metadata) {
			return (
				//@ts-ignore
				<StyledTextTemplate width={styleHelper?.tdp?.textTemplateWidth}>
					{/* @ts-ignore */}
					<StyledTextFrame>
						<GenresComponent
							rating={props?.rating}
							genres={props?.genres}
							expiryDate={props?.expiryDate}
							isRent={props.streamProgress !== null && props.streamProgress === undefined ? false : props?.isRent}
						/>
						{/* @ts-ignore */}
						<StyledRatings ratings={styleHelper?.tdp.ratings} width={styleHelper?.tdp?.textTemplateWidth}>
							{props?.ratingReason}
						</StyledRatings>

						{/* @ts-ignore */}
						<TouchableOpacity onPress={goToDetails}>
							{/* @ts-ignore */}
							<StyledSynopsis
								synopsis={styleHelper?.tdp.synopsis}
								width={styleHelper?.tdp?.textTemplateWidth}
								numberOfLines={3}
								ellipsizeMode="tail"
							>
								{props?.synopsis}
							</StyledSynopsis>
						</TouchableOpacity>
					</StyledTextFrame>
				</StyledTextTemplate>
			);
		}

		return <></>;
	};

	const renderTextTemplateNarrow = () => {
		if (props.metadata) {
			return (
				//@ts-ignore
				<StyledTextTemplateNarrow>
					{/* @ts-ignore */}
					<StyledTextFrame>
						<GenresComponent
							rating={props?.rating}
							genres={props?.genres}
							expiryDate={props?.expiryDate}
							isRent={props.streamProgress !== null && props.streamProgress === undefined ? false : props?.isRent}
						/>
						{/* @ts-ignore */}
						<StyledRatings ratings={styleHelper?.tdp.ratings}>{props?.ratingReason}</StyledRatings>

						{/* @ts-ignore */}
						<TouchableOpacity onPress={goToDetails}>
							{/* @ts-ignore */}
							<StyledSynopsis synopsis={styleHelper?.tdp.synopsis} numberOfLines={3} ellipsizeMode="tail">
								{props?.synopsis}
							</StyledSynopsis>
						</TouchableOpacity>
					</StyledTextFrame>
				</StyledTextTemplateNarrow>
			);
		}

		return <></>;
	};

	const renderButtonLoaderDefault = () => {
			return (
				// @ts-ignore
				<StyledButtonContainer isButtonVisible={isButtonVisible}>
					{/* @ts-ignore */}
					<StyledButtonLoader>
						<ImgProgressBar />
					</StyledButtonLoader>

					{/* @ts-ignore */}
					<StyledButtonLoader>
						<ImgProgressBar />
					</StyledButtonLoader>

					{/* @ts-ignore */}
					<StyledButtonLoader>
						<ImgProgressBar />
					</StyledButtonLoader>
				</StyledButtonContainer>
			);
			
	};

	const renderButtonDefault = () => {
		if (isButtonVisible) {
			return (
				//  @ts-ignore
				<StyledButtonContainer>{data.map((item: any, index: number) => (item ? renderButtons({ ...item }, index) : <></>))}</StyledButtonContainer>
			);
		}

		return <></>;
	};

	const renderButtonLoaderNarrow = () => {
		if (!isButtonVisible)
			return (
				// @ts-ignore
				<StyledButtonContainerNarrow >
					{/* @ts-ignore */}
					<StyledButtonLoader>
						<ImgProgressBar />
					</StyledButtonLoader>

					{/* @ts-ignore */}
					<StyledButtonLoader>
						<ImgProgressBar />
					</StyledButtonLoader>

					{/* @ts-ignore */}
					<StyledButtonLoader>
						<ImgProgressBar />
					</StyledButtonLoader>
				</StyledButtonContainerNarrow>
			);
	};

	const renderButtonNarrow = () => {
		if (isButtonVisible) {
			return (
				//  @ts-ignore
				<StyledButtonContainerNarrow>{data.map((item: any, index: number) => (item ? renderButtons({ ...item }, index) : <></>))}</StyledButtonContainerNarrow>
			);
		}

		return <></>;
	};

	const renderViewDetails = () => {
		if (isNarrow()) {
			return (
				//@ts-ignore
				<StyledFrameNarrow>
					{renderButtonLoaderNarrow()}
					{renderButtonNarrow()}
					{renderTextTemplateNarrow()}
				</StyledFrameNarrow>
			);
		}

		return (
			//@ts-ignore
			<StyledFrame>
				{renderTextTemplateDefault()}
				{renderButtonLoaderDefault()}
				{renderButtonDefault()}
			</StyledFrame>
		);
	};

	return renderViewDetails();
};

export default ViewDetails;

const styles = StyleSheet.create({
	metadata: {
		fontFamily: FONT_FAMILY_DEFAULT,
		color: "white",
	},
	button: {
		fontFamily: FONT_FAMILY_DEFAULT,
		fontSize: 25,
	},
});
