import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import Image from "../Image/Image";
import iconChevron from "../../assets/Icons/icon_chevron-right.svg";
import styled from "styled-components/native";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import useStyleHelpers from "../../hooks/useStyleHelpers";

interface Props {
	title?: any;
	noSpacing?: any;
	removeCursor?: boolean;
	isContinueWatching?: any;
	onPress?: () => void;
	addPaddingStart?: boolean;
}

const StyledContainer = styled.View`
	flex-direction: row;
	align-items: center;
`;

const StyledText = styled.Text`
	flex-direction: row;
	align-items: center;
`;

const StyledLoader = styled.View`
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
	width: 368px;
`;

const StyledTitle = styled.Text`
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
`;

const PlaylistTitle = (props: Props) => {
	const styleHelper = useStyleHelpers();
	const style = {
		fontFamily: FONT_FAMILY_DEFAULT,
		color: "white",
		paddingStart: props?.addPaddingStart ? styleHelper?.paddingStart : 0,
		...styleHelper?.playlistTitle
	};
	return (
		// @ts-ignore
		<StyledText
			style={style}
		>
			{props.title && !props?.isContinueWatching && (
				<TouchableOpacity onPress={props.onPress} style={props.removeCursor ? { cursor: "default" } : ""}>
					{/* @ts-ignore */}
					<StyledContainer>
						{/* @ts-ignore */}
						<StyledTitle>{props.title}</StyledTitle>
						<Image source={{ uri: iconChevron }} width={24} height={24} />
					</StyledContainer>
				</TouchableOpacity>
			)}

			{props.title && props?.isContinueWatching && (
				//@ts-ignore
				<StyledContainer>
					{/* @ts-ignore */}
					<StyledTitle>{props.title}</StyledTitle>
				</StyledContainer>
			)}

			{!props.title && (
				// @ts-ignore
				<StyledLoader>
					<ImgProgressBar></ImgProgressBar>
				</StyledLoader>
			)}
		</StyledText>
	);
};

export default PlaylistTitle;
