import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useSearchDataStore = create(
	devtools((set) => ({
		searchResults: [],
		setSearchResults: (searchResults: any) => set({ searchResults }),
		lastSearchData: [],
		setLastSearchData: (lastSearchData: any) => set({ lastSearchData }),
		lastSearchQuery: "", // Initialize last search query
		setLastSearchQuery: (query: string) => set({ lastSearchQuery: query }),
		lastNext: 12,
		setLastNext: (lastNext: number) => set({ lastNext }),
	}))
);

export default useSearchDataStore;
