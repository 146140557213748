import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";
import { RenderButton, RenderTextInput } from "./SignIn";
import {
	StyledButtonMain,
	StyledFormContainer,
	StyledNarrowButtonContainer,
	StyledNarrowFormContainer,
	StyledNarrowMain,
	StyledNarrowButtonMain,
} from "./styled";

export const SignInNarrowScreen = (props: any) => {
	return (
		/* @ts-ignore */
		<StyledNarrowMain>
			{/* @ts-ignore */}
			<StyledNarrowButtonMain>
				{/* @ts-ignore */}
				<StyledNarrowButtonContainer>
					<RenderButton {...props} />
					<PrivacyPolicyButton onPress={props.onPrivacy} removeFixedPosition={true} />
				</StyledNarrowButtonContainer>
			</StyledNarrowButtonMain>

			{/* @ts-ignore */}
			<StyledNarrowFormContainer>
				<RenderTextInput {...props} />
			</StyledNarrowFormContainer>
		</StyledNarrowMain>
	);
};

export const SignInWideScreen = (props: any) => {
	return (
		<>
			{!props.privacyPolicyScreen && (
				<>
					{/* @ts-ignore */}
					<StyledFormContainer dimensionDefault={props.dimensionDefault}>
						<RenderTextInput {...props} />
					</StyledFormContainer>
					{/* @ts-ignore */}
					<StyledButtonMain>
						<RenderButton {...props} />
						<PrivacyPolicyButton onPress={props.onPrivacy} removeFixedPosition={true} />
					</StyledButtonMain>
				</>
			)}
		</>
	);
};
