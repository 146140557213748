import styled from "rn-css/.";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import { useEffect, useState } from "react";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import { ScrollView, TouchableOpacity } from "react-native";
import Image from "../Image/Image";
import ENVIRONMENT from "../../config/ENVIRONMENT";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";

interface Props {
	playerVariant?: any;
	playerStat?: any;
	bufferedInfo?: any;
	manifestUrl?: any;
	alpha?: any;
	videoFile?: string;
	audioFile?: string;
	videoStat?: any;
	onPress?: () => void;
}

const StyledContainer = styled.View`
	padding: 1rem 1rem;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 999;
`;
const StyledInfoContainer = styled.View`
	background-color: transparent;
	gap: 10px;
	width: 100%;
`;
const StyledStreamContainer = styled.View`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 2rem;
`;
const StyledText = styled.Text`
	color: white;
	font-size: 16px;
	font-family: ${FONT_FAMILY_DEFAULT};
	font-weight: 300;
	display: flex;
	flex-direction: column;
`;
const StyledStreamText = styled.Text`
	color: white;
	font-size: 16px;
	font-family: ${FONT_FAMILY_DEFAULT};
	font-weight: 300;
	display: flex;
	flex-direction: column;
	@media (max-height: 500px) {
		margin-top: -10px;
	}
`;

const StyledTitle = styled.Text`
	font-size: 19px;
	font-weight: 600;
`;

const StyledButton = styled.View`
	top: 30;
	right: 30;
	display: flex;
	flex-direction: row-reverse;
	z-index: 999999;
`;
const Nerds = (props: Props) => {
	const resolution = `${props.videoStat.width}x${props.videoStat.height}`;
	const audioBandwidth = props.playerVariant.audioBandwidth / 1000;
	const audioCodec = `${props.playerVariant.audioCodec}`;
	const videoCodec = props.playerVariant.videoCodec;
	const channels = props.playerVariant.channelsCount;
	const language = props.playerVariant.language;
	const playingBitrate = Math.round(props.playerStat.streamBandwidth / 1000000);
	const estimatedBandwidth = Math.round(props.playerStat.estimatedBandwidth / 1000000);
	const [buffered] = props.bufferedInfo?.total ?? [];
	const bufferedStart = buffered?.start;
	const bufferedEnd = buffered?.end;
	const bufferedTotal = Math.round(bufferedEnd - bufferedStart);
	const [dateTime, setDateTime] = useState(new Date());
	const currentTime = dateTime.toLocaleString("en-US", {
		year: "numeric",
		month: "long",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: true,
	});
	const domain = (url: any) => {
		const http = url.split("/");
		const splitUrl = url.split("/");
		return http[0] + "//" + splitUrl[2];
	};

	const videoStat = {
		title: "Video",
		data: [
			{
				title: "Video File:",
				value: props.videoFile,
			},
			{
				title: "Codec:",
				value: videoCodec,
			},
			{
				title: "Playing Bitrate:",
				value: `${playingBitrate} Mbps`,
			},
			{
				title: "Avg Video Render Rate:",
				value: "1ms",
			},
			{
				title: "Resolution:",
				value: resolution,
			},
			{
				title: "Domain:",
				value: domain(props.manifestUrl),
			},
			{
				title: "Tunneled Buffer Processed:",
				value: domain(props.manifestUrl),
			},
		],
	};

	const auidioStat = {
		title: "Audio",
		data: [
			{
				title: "Audio File:",
				value: props.audioFile,
			},
			{
				title: "Codec:",
				value: audioCodec,
			},
			{
				title: "Bitrate:",
				value: `${audioBandwidth} Kbps`,
			},
			{
				title: "Avg Audio Render Rate:",
				value: "2ms",
			},
			{
				title: "Language:",
				value: language,
			},
			{
				title: "Channel:",
				value: channels,
			},
		],
	};

	const debug = {
		title: "Debug",
		data: [
			{
				title: "Audio Underrun Count:",
				value: "0",
			},
			{
				title: "Audio Performance Issue Counter:",
				value: "0",
			},
			{
				title: "Dropped Buffers:",
				value: "0",
			},
			{
				title: "Blocked Bitrates:",
				value: "none",
			},
			{
				title: "Adv Blocked Bitrates:",
				value: "none",
			},
			{
				title: "Recovery In:",
				value: "N/A",
			},
		],
	};

	const streamHealth = {
		title: "Stream Health",
		data: [
			{
				title: "Total Time Buffering:",
				value: "0s",
			},
			{
				title: "Available Buffer Duration:",
				value: `${bufferedTotal}s`,
			},
			{
				title: "Playing Bitrate:",
				value: `${playingBitrate} Mbps`,
			},
			{
				title: "Estimated Bandwidth:",
				value: `${estimatedBandwidth} Mbps`,
			},
			{
				title: "Average Bitrate:",
				value: `${estimatedBandwidth} Mbps`,
			},
			{
				title: "Speed Test Bitrate:",
				value: "N/A",
			},
		],
	};
	const renderStreamContent = (content: any) => {
		return (
			<StyledStreamText>
				<StyledTitle>{content.title}</StyledTitle>
				{content.data.map((item: any) => {
					return (
						<span key={item.title}>
							<b>{item.title}</b> {item.value}
						</span>
					);
				})}
			</StyledStreamText>
		);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setDateTime(new Date());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		
		<StyledContainer>
			<StyledButton>
				<TouchableOpacity onPress={props.onPress}>
					<Image source={{ uri: closeIcon }} width={35} height={35} />
				</TouchableOpacity>
			</StyledButton>
			<ScrollView showsHorizontalScrollIndicator={true}>
				<StyledInfoContainer>
					<StyledText>
						<StyledTitle>Info</StyledTitle>
						<span>
							<b>Alpha:</b> {props.alpha}
						</span>
						<span>
							<b>Ultra HD Support:</b>
						</span>
						<span>
							<b>Date (UTC):</b> {currentTime}
						</span>
					</StyledText>
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						<StyledText>
							<StyledTitle>Media Info:</StyledTitle>
							<span>
								<b>Manifest Url:</b> {props.manifestUrl}
							</span>
						</StyledText>
					) : (
						<></>
					)}
				</StyledInfoContainer>
				<StyledStreamContainer>
					{renderStreamContent(videoStat)}
					{renderStreamContent(auidioStat)}
				</StyledStreamContainer>
				<StyledStreamContainer>
					{renderStreamContent(debug)}
					{renderStreamContent(streamHealth)}
				</StyledStreamContainer>
			</ScrollView>
		</StyledContainer>
	);
};

export default Nerds;
