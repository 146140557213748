import { useEffect, useState } from "react";
import { globalAny, isSkipDataManagement, pushCreateAccountStateToBrowserHistory } from "../../utils/Utils";
import { AsyncStorageKeys, CreateAccountState, Pages, SubPages, Type } from "../../Types";
import { useNavigation } from "@react-navigation/native";
import { LoginScreenNavigationProp, routeCreateAccount, routeCreateAccountDetails, routeLogin } from "../../Routes";
import { Legals } from "../../models/Legals";
import { AcceptOtherData } from "../../models/AcceptOtherData";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import CreateAccountForm from "../CreateAccountScreenComponents/CreateAccountForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import LegalTermsConditions from "../../components/LegalTermsConditions";
import OtherDataComponent from "../../components/OtherDataComponent";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import AcceptDataComponent from "../../components/AcceptDataComponent";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import styled from "rn-css";
import useLegalContentStore from "../../store/useLegalContent.store";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";
import { AppLogger } from "../../utils/AppLogger";
import Main from "../../components/Layout/MainLayout/Main";
import AppBarWrapper from "../../components/Layout/MainLayout/AppBarWrapper";
import useDimensions from "../../hooks/useDimensions";
import LegalData from "../Legals";
import LandingScreenLoader from "../../components/Loaders/LandingLoader";
import usePrivacyPolicy from "../../hooks/usePrivacyPolicy";

const StyledLegal = styled.View`
	margin: 0 14.1% 0 14.1%;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	@media (max-height: 610px) and (min-height: 500px) {
		bottom: 15%;
	}
	@media (min-width: 1600px) and (max-width: 1600px) {
		flex-direction: row;
		bottom: 2%;
		width: 50%;
		right: 0%;
	}
`;

const CreateAccountScreen = (props: any) => {
	const navigation = useNavigation<any>();
	const { location, subLocation } = props.route.params;
	const [showWelcomeSplash, setShowWelcomeSplash] = useState(false);
	const [showActivityIndicator, setShowActivityIndicator] = useState(true);
	const [otherData, setOtherData] = useState(false);
	const [googleData, setGoogleData] = useState<any>({});
	const [videoAnalyticsData, setVideoAnalyticsData] = useState(false);
	const [state, setState] = useState(globalAny.language.create_account);
	const [acceptOtherData, setAcceptOtherData] = useState<AcceptOtherData>();
	const [legalText, setLegalText] = useState<Legals>();
	const [privacyPolicyScreen, setPrivacyPolicyScreen] = useState(false);
	const [googleAnalytics, setGoogleAnalytics] = useState("");
	const [videoAnalytics, setVideoAnalytics] = useState("");
	const [sonyPictures, setSonyPictures] = useState("");
	const [sonyCompany, setSonyCompany] = useState("");
	const [acceptDataTitle, setAcceptDataTitle] = useState("");
	const [acceptDataDescription, setAcceptDataDescription] = useState("");
	const [otherDataTitle, setOtherDataTitle] = useState("");
	const [otherDataDescription, setOtherDataDescription] = useState("");
	const [legalDescription, setLegalDescription] = useState("");
	const [legalData, setLegalData] = useState<Legals>();
	const legalStoreValue = useLegalContentStore((state) => state.data);
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const [createAccountState, setCreateAccountState] = useState("");
	const screenDimensions = useDimensions();
	const skipDataManagement = isSkipDataManagement();
	const privacyPolicyText = usePrivacyPolicy();

	useEffect(() => {
		(async () => {
			const resultLegal = await legalStoreValue.legalData[AsyncStorageKeys.legals];
			const resultAcceptOtherData = await legalStoreValue.acceptOtherData[AsyncStorageKeys.acceptOtherDataSignUp];
			if (resultLegal && resultAcceptOtherData) {
				setLegalData(resultLegal);
				setAcceptOtherData(resultAcceptOtherData);
				AppLogger.log("resultLegal", resultLegal);
				localStorage.setItem("cacheLegalDataCreate", JSON.stringify(resultLegal));
				localStorage.setItem("cacheAcceptOtherDataCreate", JSON.stringify(resultAcceptOtherData));
				fetchLegalData();
				setShowActivityIndicator(false);
			}
		})();
	}, [legalStoreValue.legalData, legalStoreValue.acceptOtherData, legalStoreValue, privacyPolicyScreen]);

	const onNextButtonPress = () => {
		if (skipDataManagement) {
			return navigation.push(routeCreateAccount, { location: Pages.createAccount });
		}
		switch (createAccountState) {
			case CreateAccountState.legalOne:
				navigation.push(routeCreateAccount, { location: Pages.acceptData });
				break;
			case CreateAccountState.legalTwo:
				navigation.push(routeCreateAccount, { location: Pages.otherData });
				break;
			case CreateAccountState.legalThree:
				navigation.push(routeCreateAccount, { location: Pages.legalTerms });
				break;
			case CreateAccountState.legalFour:
				navigation.push(routeCreateAccount, { location: Pages.createAccount });
				break;
		}
	};

	const onPrivacy = () => {
		navigation.push(routeCreateAccount, { location: Pages.privacy });
	};

	const onBackPress = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		navigation.navigate(routeLogin);
	};

	const onPressSonyPictures = () => {
		let data = acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-1");
		if (!data) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			data = cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-1");
		}
		setSonyPictures(data[0]?.textValue);
		setState("Sony Pictures");
	};

	const onPressSonyCompany = () => {
		let data = acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-2");
		if (!data) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			data = cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-2");
		}
		setSonyCompany(`
		  <span style="display: flex align-items: center">
			<label target="_blank" style="display: flex align-items: center margin-right: 500px margin-top:-100px">
			  ${data[0]?.textValue}
			</label>
		  </span>
		`);
		setState("Sony Group Companies");
	};

	const onPressGoogle = () => {
		let initialData = acceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Google Analytics");
		if (!initialData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			initialData = cacheAcceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Google Analytics");
		}
		const data = initialData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-read-more-1");

		setGoogleAnalytics(data[0]?.textValue);
		setState("Google Analytics");
	};

	const onPressVideoAnalytics = () => {
		let initialData = acceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Video Analytics");
		if (!initialData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			initialData = cacheAcceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === "Video Analytics");
		}
		const data = initialData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-read-more-1");
		setVideoAnalytics(data[0]?.textValue);
		setState("Video Analytics");
	};

	const onLegal = async (keyValue: string, type: number) => {
		let data = legalData?.legalContents?.filter((val: any) => val?.type === type);
		if (!data) {
			const cacheLegalData = JSON.parse(localStorage.getItem("cacheLegalDataCreate") ?? "{}");
			data = cacheLegalData?.legalContents?.filter((val: any) => val?.type === type);
		}
		if (data) {
			switch (keyValue) {
				case globalAny.language.promotional_terms:
					setState(globalAny.language.promotional_terms);
					setLegalText(data[0]?.htmlContent);
					break;
				case globalAny.language.terms_of_use:
					setState(globalAny.language.terms_of_use);
					setLegalText(data[0]?.htmlContent);
					break;

				case globalAny.language.terms_of_service:
					setState(globalAny.language.terms_of_service);
					setLegalText(data[0]?.htmlContent);
					break;
			}
		}
	};

	const onClose = () => {
		setCreateAccountState(CreateAccountState.legalOne);
		pushCreateAccountStateToBrowserHistory(CreateAccountState.legalOne);
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const fetchLegalData = () => {
		let titleDescription = acceptOtherData?.sections[0]?.sectionWording?.find((val: any) => val?.key === "section-description");
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			titleDescription = cacheAcceptOtherData?.sections[0]?.sectionWording?.find((val: any) => val?.key === "section-description");
		}
		setLegalDescription(titleDescription?.textValue);
	}

	const fetchAcceptData = () => {
		let titleData = acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title");
		let titleDescription = acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description");
		if (!titleData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			titleData = cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title");
		}
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			titleDescription = cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description");
		}
		setAcceptDataTitle(titleData?.textValue);
		setAcceptDataDescription(titleDescription?.textValue)
	}

	const fetchOtherData = () => {
		let titleData = acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title");
		let titleDescription = acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-description");
		if (!titleData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			titleData = cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title");
		}
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			titleDescription = cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find(
				(val: any) => val?.key === "section-description"
			);
		}

		setOtherDataTitle(titleData?.textValue);
		setOtherDataDescription(titleDescription?.textValue)
	}

	useEffect(() => {
		if (!googleData && !videoAnalyticsData) {
			setOtherData(false);
		}

		if (googleData && videoAnalyticsData) {
			setOtherData(true);
		}
	}, [googleData, videoAnalyticsData]);

	useEffect(() => {
		setGoogleData(otherData);
		setVideoAnalyticsData(otherData);
	}, [otherData]);

	useEffect(() => {
		switch (subLocation) {
			case SubPages.termsOfUse:
				onLegal(globalAny.language.terms_of_use, 6);
				return;
			case SubPages.termsOfService:
				onLegal(globalAny.language.terms_of_service, 3);
				return;
			case SubPages.sonyPictures:
				onPressSonyPictures();
				return;
			case SubPages.sonyGroup:
				onPressSonyCompany();
				return;
			case SubPages.googleAnalytics:
				onPressGoogle();
				return;
			case SubPages.videoAnalytics:
				onPressVideoAnalytics();
				return;
		}

		switch (location) {
			case Pages.legal:
				setCreateAccountState(CreateAccountState.legalOne);
				break;
			case Pages.acceptData:
				fetchAcceptData();
				setCreateAccountState(CreateAccountState.legalTwo);
				break;
			case Pages.otherData:
				fetchOtherData();
				setCreateAccountState(CreateAccountState.legalThree);
				break;
			case Pages.legalTerms:
				setCreateAccountState(CreateAccountState.legalFour);
				break;
			case Pages.createAccount:
				setCreateAccountState(CreateAccountState.newCreateAccountForm);
				break;
			case Pages.privacy:
				setPrivacyPolicyScreen(true);
				setState(globalAny.language.privacy_policy);
				break;
		}
	}, [location, subLocation]);

	if (showWelcomeSplash) {
		return (
			<Main>
				<LandingScreenLoader title={globalAny.language.account_created} />
			</Main>
		);
	}

	if (showActivityIndicator) {
		return (
			<Main>
				<LandingScreenLoader />
			</Main>
		);
	}

	return (
		//@ts-ignore
		<Main isScrollable={false}>
			{/* @ts-ignore */}
			<AppBarWrapper dimensions={screenDimensions}>
				<TopAppbar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={state}
					rightLogo={ridevueLogo}
					onPressLeftIcon={onClose}
					onPressLeftTitleIcon={onBackPress}
					isSingleLogo={true}
					removeFixedPosition={false}
				/>
			</AppBarWrapper>
			{createAccountState === CreateAccountState.newCreateAccountForm && !subLocation && (
				<CreateAccountForm
					accountInfo={{
						ageChecked: false,
						acceptTC: true,
						acceptPrivacy: true,
						email: "",
						password: "",
						confirmPassword: "",
						month: 0,
						year: 0,
						deviceIdentifier: `WEB-MERCEDES`,
						deviceModel: "WEB",
						softwareVersion: "6",
					}}
					setShowWelcomeSplash={setShowWelcomeSplash}
					onPressPrivacy={onPrivacy}
					onPressBack={() => onBackPress()}
				/>
			)}
			{createAccountState == CreateAccountState.legalOne &&
				!subLocation &&
				(skipDataManagement ? (
					<LegalTermsConditions
						bodyText={globalAny.language.i_read_agree}
						showButton={true}
						isSkipDataManagement={true}
						onPressNext={() => onNextButtonPress()}
						// onPressPromotionalTerms={() => onLegal(globalAny.language.promotional_terms, 7)}
						onPressTermsOfUse={() =>
							navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfUse })
						}
						onPressTermsOfService={() =>
							navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfService })
						}
						onPressPrivacy={onPrivacy}
					/>
				) : (
					<LegalData
						onPressNext={onNextButtonPress}
						onPrivacy={onPrivacy}
						legalTitle={globalAny.language.legal_data}
						legalDescription={legalDescription ?? globalAny.language.legal_one_body}
						screenDimensions={screenDimensions}
					/>
				))}
			{createAccountState == CreateAccountState.legalTwo && !subLocation && (
				//@ts-ignore
				<AcceptDataComponent
					onPressSecondaryButton={() => onBackPress()}
					onPressPrimaryButton={() => onNextButtonPress()}
					onPressSonyCompany={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.acceptData, subLocation: SubPages.sonyGroup })
					}
					onPressSonyPictures={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.acceptData, subLocation: SubPages.sonyPictures })
					}
					onPressPrivacy={() => onPrivacy()}
					showPrivacyLink={true}
					type={Type.CreateAccount}
					title={acceptDataTitle}
					description={acceptDataDescription}
				/>
			)}
			{subLocation === SubPages.sonyPictures && (
				//@ts-ignore
				<PrivacyPolicy text={sonyPictures} />
			)}

			{subLocation === SubPages.sonyGroup && (
				//@ts-ignore
				<PrivacyPolicy text={sonyCompany} />
			)}

			{/* Other data */}
			{createAccountState == CreateAccountState.legalThree && !subLocation && (
				<OtherDataComponent
					onSubmit={(val) => setOtherData(val)}
					onChangeGoogleData={(val) => setGoogleData(val)}
					onChangeVideoAnalyticsData={(val) => setVideoAnalyticsData(val)}
					onPressSecondaryButton={onBackPress}
					onPressPrimaryButton={onNextButtonPress}
					onPressGoogle={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.otherData, subLocation: SubPages.googleAnalytics })
					}
					onPressVideoAnalytics={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.otherData, subLocation: SubPages.videoAnalytics })
					}
					onPressPrivacy={onPrivacy}
					showPrivacyLink={true}
					title={otherDataTitle}
					description={otherDataDescription}
				/>
			)}

			{subLocation === SubPages.googleAnalytics && (
				//@ts-ignore
				<PrivacyPolicy text={googleAnalytics} />
			)}

			{subLocation === SubPages.videoAnalytics && (
				//@ts-ignore
				<PrivacyPolicy text={videoAnalytics} />
			)}

			{createAccountState == CreateAccountState.legalFour && !subLocation && (
				<LegalTermsConditions
					bodyText={globalAny.language.i_read_agree}
					showButton={true}
					onPressBack={() => onBackPress()}
					onPressNext={() => onNextButtonPress()}
					// onPressPromotionalTerms={() => onLegal(globalAny.language.promotional_terms, 7)}
					onPressTermsOfUse={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfUse })
					}
					onPressTermsOfService={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfService })
					}
					onPressPrivacy={onPrivacy}
				/>
			)}

			{privacyPolicyScreen && <PrivacyPolicy text={privacyPolicyText} />}

			{(subLocation === SubPages.termsOfUse || subLocation === SubPages.termsOfService) && (
				//@ts-ignore
				<PrivacyPolicy text={legalText} />
			)}

			{(createAccountState == CreateAccountState.validateVoucher || createAccountState == CreateAccountState.termsAndConditions) &&
				!privacyPolicyScreen && (
					// @ts-ignore

					<StyledLegal>
						<TouchableButton componentTheme={ComponentThemeType.VinFast} onPress={() => onNextButtonPress()}>
							{globalAny.language.next}
						</TouchableButton>
						<PrivacyPolicyButton onPress={() => onPrivacy()} />
					</StyledLegal>
				)}
		</Main>
	);
};

export default CreateAccountScreen;
