import { ScrollView } from "react-native";
import { StyledMain } from "./Styled";

//@ts-ignore
const Main = ({ children, isScrollable = true} : any ) => {
	return (
		//@ts-ignore
		<StyledMain>
			{
				isScrollable === true
				?	<ScrollView style={{maxWidth: '100%'}}>{children}</ScrollView>
				:	children
			}
		</StyledMain>
	);
};

export default Main;
