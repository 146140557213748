import { useEffect, useState } from "react";
import { AsyncStorageKeys, CarActivity } from "../../Types";
import MainLayoutWithSideNav from "../../components/Layout/MainLayoutWithSideNav";
import { useNavigation } from "@react-navigation/native";
import { routeHome, routeSearchScreen } from "../../Routes";
import { globalAny } from "../../utils/Utils";
import CarActivityDialog from "../../components/CarActivityDialog";
import useGenericContentStore from "../../store/genericContent.store";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";
import ENVIRONMENT from "../../config/ENVIRONMENT";
import useDrivingStore from "../../store/useDriving.store";
import Main from "../../components/Layout/MainLayout/Main";
import { TabsViewLayout } from "./SettingsLayout";
import MainSideTopWrapper from "../../components/Layout/MainLayout/MainSideTopWrapper";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import useDimensions from "../../hooks/useDimensions";

const SettingsScreen = () => {
	const navigation = useNavigation();
	const isHide = useGenericContentStore((state: any) => state.isHide);
	const setIsHide = useGenericContentStore((state: any) => state.setIsHide);
	const [modalLogout, setModalLogout] = useState(false);
	const [isMHU, setIsMHU] = useState(false);
	const menuList = [
		{ title: globalAny.language.account, disabled: false },
		{ title: globalAny.language.help_and_about, disabled: false },
		{ title: globalAny.language.legal, disabled: false },
		{ title: globalAny.language.add_screen, disabled: false },
		{ title: globalAny.language.advanced, disabled: false },
	];
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const selectedTabs = useGenericContentStore((state: any) => state.selectedTabs);
	const isEnabledFloatingButton = useGenericContentStore((state: any) => state.isEnabledFloatingButton);
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const styledTabsView = styleHelper?.tabsView;
	const styledSettings = styleHelper?.settings;

	useEffect(() => {
		setIsHide(DEFAULT_ENV === ENVIRONMENT.PRODUCTION);
		setIsMHU(localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true");
	}, []);

	const LogOutAccount = () => {
		setModalLogout(true);

		setTimeout(() => {
			setModalLogout(false);
		}, 1000);
	};

	const onSearch = () => {
		//@ts-ignore
		navigation.navigate(routeSearchScreen);
	};

	return (
		<Main isScrollable={false}>
			<MainLayoutWithSideNav
				screenTitle={globalAny.language.header_settings}
				navTitle={globalAny.language.header_settings}
				showLogout={modalLogout}
				searchIcon={true}
				onPressRightIcon={onSearch}
				isSettings={true}
				screenDimensions={screenDimensions}
			>
				<MainSideTopWrapper styleHelper={styledTabsView}>
					<TabsViewLayout
						isHide={isHide}
						isMHU={isMHU}
						menuList={menuList}
						LogOutAccount={() => LogOutAccount()}
						styledSettings={styledSettings}
						styledTabsView={styledTabsView}
					/>
				</MainSideTopWrapper>
			</MainLayoutWithSideNav>

			<CarActivityDialog
				title={globalAny.language.car_in_motion_title}
				body={globalAny.language.car_in_motion_body}
				buttonText={globalAny.language.car_in_motion_back_to_home}
				subText={globalAny.language.car_in_motion_subtext}
				onPress={() => {
					//@ts-ignore
					navigation.navigate(routeHome);
				}}
				show={carActivity === CarActivity.INMOTION && isEnabledFloatingButton && selectedTabs !== 3}
			/>
		</Main>
	);
};

export default SettingsScreen;
