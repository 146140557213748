import { StyleSheet } from "react-native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import styled from "styled-components/native";
import GenresComponent from "../../components/GenresComponent";
import { globalAny } from "../../utils/Utils";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";
import useStyleHelpers from "../../hooks/useStyleHelpers";

const StyledFrame = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

const StyledTextTemplate = styled.View`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;

const StyledTextFrame = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

const StyledRatings = styled.Text<any>`
	align-self: stretch;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.ratings.lineHeight};
`;

const StyledSynopsis = styled.Text<any>`
	width: 100%;
	align-self: stretch;
	overflow: hidden;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: 1.5625rem;
	font-size: ${(props) => props.synopsis.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.synopsis.lineHeight};
`

const StyledDetailsFrame = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	align-self: stretch;
`;

const StyledText = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

const StyledColumn1 = styled.Text<any>`
	width: 10.75rem;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 700;
	line-height: ${(props) => props.ratings.lineHeight};
`;

const StyledColumn2 = styled.View<any>`
	flex: 1 0 0;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.ratings.lineHeight};
`;

const StyledRating = styled.View<any>`
	width: 100%;
	color: #F2F2F2;
	font-family: Nunito;
	font-size: ${(props) => props.ratings.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.ratings.lineHeight};
`;

const StyleDetailsContainer = styled.View`
	display: flex;
	flex-direction: column;
	top: 0;
	padding: 2rem 6rem 0rem 6rem;
	align-items: flex-start;
	gap: 10rem;
	flex: 1 0 0;
	align-self: stretch;
`;

const StyleDetailsContainerNarrow = styled.View`
	display: flex;
	width: 57.5rem;
	padding: 2rem 1.5rem;
	justify-content: center;
	align-items: flex-start;
	gap: 1.5rem;
`;

const Details = (props: any) => {
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const isNarrow = () => screenDimensions === ScreenDimensions.narrow;

	const data = [
		globalAny.language.directed_by,
		globalAny.language.starring,
		globalAny.language.audio_languages,
		globalAny.language.available_subtitles,
	];

	const renderTextTemplate = () => {
		return (
			//@ts-ignore
			<StyledTextTemplate>
				{/* @ts-ignore */}
				<StyledFrame>
					{/* @ts-ignore */}
					<StyledTextFrame>
						<GenresComponent rating={props.rating} genres={props.genres} />
						{/* @ts-ignore */}
						<StyledRatings ratings={styleHelper?.tdp.ratings}>{props.ratingReason}</StyledRatings>
						{/* @ts-ignore */}
						<StyledSynopsis synopsis={styleHelper?.tdp.synopsis}>{props?.synopsis}</StyledSynopsis>
						
					</StyledTextFrame>
					{/* @ts-ignore */}
					<StyledTextFrame>
						{/* @ts-ignore */}
						<StyledDetailsFrame>
							{data.map((item: any, index: any) => {
								return (
									// @ts-ignore
									<StyledText key={`metadata-content-${item}`}>
										{/* @ts-ignore */}
										<StyledColumn1 ratings={styleHelper?.tdp.ratings}>{item}</StyledColumn1>
										{index === 0 ? (
											//@ts-ignore
											<StyledColumn2 ratings={styleHelper?.tdp.ratings}  key={`metadata-director-1`}>
												{props.director.join(", ")}
											</StyledColumn2>
										) : (
											""
										)}

										{index === 1 ? (
											// @ts-ignore
											<StyledColumn2 ratings={styleHelper?.tdp.ratings} key={`metadata-cast-1}`}>
													{props.cast.join(", ")}
											</StyledColumn2>
										) : (
											""
										)}
										{/* @ts-ignore */}
										{index === 2 ? <StyledColumn2 ratings={styleHelper?.tdp.ratings}>{props.audioLang}</StyledColumn2> : ""}
										{/* @ts-ignore */}
										{index === 3 ? <StyledColumn2 ratings={styleHelper?.tdp.ratings}>{props.subtitle}</StyledColumn2> : ""}
									</StyledText>
								);
							})}
						</StyledDetailsFrame>
					</StyledTextFrame>
					{/* @ts-ignore */}
					<StyledRating ratings={styleHelper?.tdp.ratings}>{props.copyright}</StyledRating>
				</StyledFrame>
			</StyledTextTemplate>
		);
	}

	const renderDetails = () => {
		if (isNarrow()) {
			return (
				//@ts-ignore
				<StyleDetailsContainerNarrow>
					{renderTextTemplate()}
				</StyleDetailsContainerNarrow>
			);
		}

		return (
			// @ts-ignore
			<StyleDetailsContainer>
				{renderTextTemplate()}
			</StyleDetailsContainer>
		);
	}

	return renderDetails();
};

export default Details;

const styles = StyleSheet.create({
	metadata: {
		fontFamily: FONT_FAMILY_DEFAULT,
		color: "white",
	},
	button: {
		fontFamily: FONT_FAMILY_DEFAULT,
		fontSize: 25,
		color: "white",
	},
});
