import React, { useEffect, useMemo, useState } from 'react'
import Main from '../../components/Layout/MainLayout/Main'
import AppBarWrapper from '../../components/Layout/MainLayout/AppBarWrapper'
import TopAppBar from '../../components/TopAppBar'
import { useNavigation } from '@react-navigation/native'
import { HomeScreenNavigationProp, ProfileNameSelectScreenNavigationProp, routeHome, routeLogin } from '../../Routes'
import useGenericContentStore from '../../store/genericContent.store'
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import ridevueLogo from "../../assets/Logo/ridevuLogo.svg";
import { addNewJSONStringsAsKeysIfNeeded, generateDeviceInfo, getTransactionFilters, globalAny, pushProfileNameStateToBrowserHistory, validateCustomerZone } from '../../utils/Utils'
import { getCategoriesShared } from '../../services/categoriesService'
import { AppLogger } from '../../utils/AppLogger'
import { AsyncStorageKeys, Categories, Pages, ProfileNameState } from '../../Types'
import PlaylistContainer from '../../components/Layout/PlaylistContainer'
import { StyledServicePreviewMain } from './styled'
import ContentArea from '../../components/Layout/ContentArea'
import ExitPreviewButton from '../../components/ExitPreviewButton'
import useDimensions from '../../hooks/useDimensions'
import useLoginStore from '../../store/useLogin.store'
import useMqttStore from '../../store/useMqtt.store'
import { Code_OK, getPresignedUrl } from '../../networking/networking'
import { linkedDevice } from '../../services/loginService'
import { infoEmail } from '../../services/accountService'
import { getLanguage as getLanguageApi } from "../../services/appWordingService";
import CustomizeLoader from '../../components/Loaders/CustomizeLoader'
import LandingLoader from '../../components/Loaders/LandingLoader'
import {EN} from '../../utils/Strings'

const TRANSACTIONAL_FILTERS = getTransactionFilters();
const defaultItem = [
	{
		id: 1,
		isLandscape: true,
		packshot: [1, 2, 3, 4, 5],
	},
	{
		id: 2,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7],
	},
	{
		id: 3,
		isLandscape: false,
		packshot: [1, 2, 3],
	},
];

const ServicePreview = (props: any) => {
	const navigation = useNavigation<any>();
	const screenDimension = useDimensions();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const { 
		customerzone, 
		language, 
		wheelpos, 
		hwid, 
		masteruser, 
		uniqueId, 
		enableLogs, 
		enableMQTT } = props?.route?.params ?? "";

  	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const isLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isInitialLoading = useLoginStore((state: any) => state.isInitialLoading);
	const setIsInitialLoading = useLoginStore((state: any) => state.setIsInitialLoading);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const [categories, setCategories] = useState([]);
	const [startIndex, setStartIndex] = useState(5);
	const [isShowLoader, setIsShowLoader] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		setNavigateEvent("");
		navigation.navigate(routeLogin);
	};

	const renderPlaylist = useMemo(
		() => (
			<PlaylistContainer
				headerBanner={null}
				categories={categories}
				isSeeMore={true}
				location={Categories.home}
				transactionalFilters={TRANSACTIONAL_FILTERS}
				setIsShowLoader={setIsShowLoader}
				setIsAggregate={() => {}}
				setStartIndex={setStartIndex}
				startIndex={startIndex}
				isPreview={true}
			/>
		),
		[categories, startIndex]
	);

	const exitPreview = () => {
		navigation.navigate(routeLogin);
	};
  
	const isCustomerZoneParamExist = () => customerzone;

	useEffect(() => {
		if (props?.route?.params)
			localStorage.setItem(
				AsyncStorageKeys.dataExchange,
				JSON.stringify(Object.keys(props?.route?.params).filter((keys) => keys !== "uniqueId"))
			);
	}, [props?.route?.params]);

  	useEffect(() => {
		if (isInitialLoading) setIsShowLoader(true);
	}, [isInitialLoading]);

	useEffect(() => {
		if (!uniqueId && isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.navigate(routeHome, {});
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isCustomerZoneParamExist()) {
			validateCustomerZone(customerzone);
		}
	}, [customerzone]);

	useEffect(() => {
		if (masteruser) {
			localStorage.setItem(AsyncStorageKeys.masteruser, masteruser);
		}
	}, [masteruser]);

	useEffect(() => {
		(async () => {
			if (language) {
				setIsInitialLoading(true);
				const splittedLanguage = language.split("_");

				if (splittedLanguage.length == 2) {
					localStorage.setItem(AsyncStorageKeys.LanguageOverride, splittedLanguage[0].toUpperCase() ?? "EN");
					localStorage.setItem(AsyncStorageKeys.RegionOverride, splittedLanguage[1].toUpperCase() ?? "US");
					localStorage.removeItem(AsyncStorageKeys.languageJson);
					let languageJSON = await getLanguageApi();
					if (languageJSON) {
						// @ts-ignore
						globalAny.language = { ...EN.Strings, ...languageJSON, pass_rules: EN.Strings["pass_rules"] };

						// add keys if they dont exist on server, but do locally
						addNewJSONStringsAsKeysIfNeeded();
						localStorage.setItem(AsyncStorageKeys.languageJson, JSON.stringify(languageJSON));
					}
				}
				setIsInitialLoading(false);
			}
		})();
	}, [language]);

	useEffect(() => {
		if (wheelpos) {
			localStorage.setItem(AsyncStorageKeys.wheelPosition, wheelpos);
		}
	}, [wheelpos]);

	const setProfileName = async (deviceInfo: any) => {
		localStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	useEffect(() => {

		if (hwid) {
			setIsLoading(true);
			localStorage.setItem(AsyncStorageKeys.hwid, hwid);

			(async () => {
				const autoLogin: any = await linkedDevice();
				if (!autoLogin) {
					setIsLoading(false);
					return;
				};

				if (autoLogin.responseCode === Code_OK) {
					const presignedService = await getPresignedUrl();
					setPresignedUrl(presignedService);
					const userInfo: any = await infoEmail();
					localStorage.setItem(AsyncStorageKeys.email, userInfo.email);
					const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";
					pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
					setIsLogin(true);
					setIsLoading(false);
					if (!isInCarScreen) {
						//@ts-ignore
						navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
					}
					if (isInCarScreen) {
						const profileName = localStorage.getItem(AsyncStorageKeys.profileName);
						updateDevice(profileName);
						setIsLoggedIn(true);
						navigationHomeScreen.navigate(routeHome, {});
					}
					return;
				}
				setIsLoading(false);
			})();
		}
	}, [hwid]);

	useEffect(() => {
		localStorage.removeItem(AsyncStorageKeys.enableLogs);
		if (enableLogs) {
			localStorage.setItem(AsyncStorageKeys.enableLogs, enableLogs);
		}
	}, [enableLogs]);

	useEffect(() => {
		localStorage.removeItem(AsyncStorageKeys.enableMQTT);
		if (enableMQTT) {
			localStorage.setItem(AsyncStorageKeys.enableMQTT, enableMQTT);
		}
	}, [enableMQTT]);

  	useEffect(() => {
		const controller = new AbortController();

		(async () => {
			try {
				let categories: any = (await getCategoriesShared(Pages.preview, TRANSACTIONAL_FILTERS, controller.signal)) ?? [];
				setCategories(categories);
			} catch (err) {
				AppLogger.log(err);
			}
		})();

		return () => {
			setCategories([]);
		};
	}, []);

	if (isLoading) {
		return <LandingLoader />;
	}

  return (
    <Main isScrollable={false}>
        <AppBarWrapper dimensions={screenDimension}>
            <TopAppBar
              leftIcon={backIcon}
              leftIconWidth={92}
              leftTitleIcon={""}
              isHideLeftIcon={false}
              screenTitle={""}
              titleLogo={ridevueLogo}
              onPressLeftTitleIcon={() => {}}
              onPressLeftIcon={onClose}
              isPreview={true}
              removeFixedPosition={false}
            />
        </AppBarWrapper>
        {/* @ts-ignore */}
        <StyledServicePreviewMain>
            <ContentArea>
				{isShowLoader && (
					<CustomizeLoader show={isShowLoader} isHeroBanner={true} isPlaylist={true} data={defaultItem} />
				)}
              	{renderPlaylist}
            </ContentArea>
        </StyledServicePreviewMain>
        <ExitPreviewButton onPress={() => exitPreview()} />
    </Main>
  )
}

export default ServicePreview;
