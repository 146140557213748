import { StyledMainNarrowWrapper } from "./Styled";

const MainNarrowWrapper = ({ children }: any) => {
	return (
		//@ts-ignore
		<StyledMainNarrowWrapper>{children}</StyledMainNarrowWrapper>
	);
};

export default MainNarrowWrapper;
