import { useEffect, useState } from "react";
import { globalAny } from "../../utils/Utils";
import useAcceptDataStore from "../../store/useAcceptData.store";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import { AsyncStorageKeys, Type } from "../../Types";
import AcceptData from "./AcceptData";
import useDimensions from "../../hooks/useDimensions";

interface Props {
	onPressPrimaryButton?: () => void;
	onPressSecondaryButton?: () => void;
	onPressSonyPictures?: () => void;
	onPressSonyCompany?: () => void;
	onPressPrivacy?: () => void;
	showPrivacyLink?: boolean;
	type: any;
	title?: string;
	description?: string;
}

const AcceptDataComponent = (props: Props) => {
	const screenDimensions = useDimensions();
	const { acceptData, setAcceptData } = useAcceptDataStore();
	const [optionDescription, setOptionDescription] = useState("");
	const acceptDataSectionButtons = [
		{
			title: globalAny.language.sony_pictures,
			onPress: props.onPressSonyPictures,
		},
		{ title: globalAny.language.sony_group, onPress: props.onPressSonyCompany },
	];

	const asyncAcceptData = async () => {
		if (props.type === Type.Settings) {
			const acceptData = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.acceptOtherDataSettings, true);
			const [sections] = acceptData?.sections ?? [];
			const [options] = sections?.options ?? [];
			const optionDesc = options?.optionsWordings?.find((val: any) => val?.key === "option-description");
			return setOptionDescription(optionDesc?.textValue);
		}

		if (props.type === Type.CreateAccount) {
			const acceptData = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.acceptOtherDataSignUp, true);
			const [sections] = acceptData?.sections ?? [];
			const [options] = sections?.options ?? [];
			const optionDesc = options?.optionsWordings?.find((val: any) => val?.key === "option-description");
			return setOptionDescription(optionDesc?.textValue);
		}
	};

	useEffect(() => {
		// Retrieve saved state from Zustand store when component mounts
		const savedAcceptData = acceptData;

		if (savedAcceptData) {
			setAcceptData(savedAcceptData);
		}
	}, []);

	useEffect(() => {
		// Save state to Zustand store whenever it changes
		setAcceptData(acceptData);
	}, [acceptData]);

	useEffect(() => {
		// Get acceptData in Local Storage
		asyncAcceptData();
	}, []);

	return (
		<AcceptData
			title={props.title ?? globalAny.language.accept_data}
			screenDimensions={screenDimensions}
			onBackPress={props.onPressSecondaryButton}
			onPressNext={props.onPressPrimaryButton}
			onPressPrivacy={props.onPressPrivacy}
			defaultValue={acceptData}
			description={props.description ?? optionDescription}
			showPrivacyLink={props.showPrivacyLink}
			onPress={(state: any) => setAcceptData(state)}
			sectionButtons={acceptDataSectionButtons}
		/>
	);
};

export default AcceptDataComponent;
