import StyledText from "./StyledText";
import HeaderTextProps from "./HeaderTextProps";

const H1 = (props: HeaderTextProps) => {
	return (
		// @ts-ignores
		<StyledText fontSize={50} fontWeight={props.fontWeight}>
			{props.children}
		</StyledText>
	);
};

export default H1;
