import styled, { css } from "styled-components/native";

interface Props {
	dimensions?: any;
	isNarrow?: any;
	fontSize?: any;
	lineHeight?: any;
	gap?:any;
}
export const StyledTitle = styled.Text<Props>`
	${(props) =>
		css`
			color: #f2f2f2;
			font-family: Nunito;
			font-size: ${props?.fontSize};
			font-style: normal;
			font-weight: 500;
			line-height: ${props?.lineHeight};
		`}
`;
export const StyledBody = styled.Text<Props>`
	${(props) =>
		css`
			color: #f2f2f2;
			font-family: Nunito;
			font-size: ${props?.fontSize};
			font-style: normal;
			font-weight: 400;
			line-height: ${props?.lineHeight};
		`}
`;

export const StyledMain = styled.View<Props>`
	${(props) =>
		css`
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: ${props?.gap};
			align-self: stretch;
		`}
`;
