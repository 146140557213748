import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import ComponentThemeType from "../../models/ComponentTheme";
import { StyledBottomInnerContainer, StyledSettingsButtonText, StyledSettingsLegalButton } from "./styled";
import icon from "../../assets/TopNavBarIcon/icon_nav_proceed.png";
import Image from "../Image/Image";

const SettingsLegalButton = (props: any) => {
	return (
		<StyledSettingsLegalButton
			{...props}
			componentTheme={ComponentThemeType.VinFast}
			type={ComponentTypeEnum.Secondary}
			onPress={() => props.onPress()}
			disabled={props.disabled}
		>
			{/* @ts-ignore */}
			<StyledBottomInnerContainer>
				<StyledSettingsButtonText {...props}>{props.title}</StyledSettingsButtonText>

				<Image source={{ uri: props.rightIcon || icon }} resizeMode={"contain"} width={24} height={24} />
			</StyledBottomInnerContainer>
		</StyledSettingsLegalButton>
	);
};

export default SettingsLegalButton;
