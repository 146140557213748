import { StyledMainContainer, StyledMainLanding, StyledMainWrapper } from "./Styled";

const MainDefaultLandingWrapper = ({ children, dimensions }: any) => {
	return (
		//@ts-ignore
		<StyledMainWrapper>
			{/* @ts-ignore */}
			<StyledMainContainer>
				{/* @ts-ignore */}
				<StyledMainLanding dimensions={dimensions}>{children}</StyledMainLanding>
			</StyledMainContainer>
		</StyledMainWrapper>
	);
};

export default MainDefaultLandingWrapper;
