import styled from "styled-components/native";

export const StyledMainWide = styled.View`
	display: flex;
	width: 100%;
	height: 39rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
`;

export const StyledButtonContainer = styled.View`
	display: flex;
	gap: 1.5rem;
	width: 100%;
`;

export const StyledTextContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;


export const StyledMainNarrow = styled.View`
	display: flex;
	padding: 1.5rem 1.5rem;
	flex-direction: row;
	gap: 1rem;
`;

export const StyledButtonNarrowContainer = styled.View`
	display: flex;
	width: 31.25rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	top: 0.38rem;
`;

export const StyledTextNarrowContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledButton = styled.View`
	display: flex;
	width: 100%;
`;

export const StyledRowButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

