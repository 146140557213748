import styled from "styled-components/native";

export const StyledContentArea = styled.View`
	display: flex;
	padding-bottom: 5rem;
    flex-direction: column;
    align-items: flex-start;
	width: 100%;
`;

// @ts-ignore
const ContentAreaWithoutPadding = ({ children }) => {
	return (
		// @ts-ignores
		<StyledContentArea>{children}</StyledContentArea>
	);
};

export default ContentAreaWithoutPadding;
