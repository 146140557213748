import styled from "styled-components/native";
import { light_black } from "../../StyleHelpers";

const StyledGallery = styled.View`
	z-index: -1;
	background-color: ${light_black};
	width: 100%;
	height: 100%;
`;

// @ts-ignore
const GalleryContainer = ({ children }) => {
	return (
		// @ts-ignores
		<StyledGallery>{children}</StyledGallery>
	);
};

export default GalleryContainer;
