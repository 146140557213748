import type IMqttClient from "../../models/IMqttClient";
import type IMqttDeviceBroadcast from "../../models/IMqttDeviceBroadcast";
import IMqttRequest from "../../models/IMqttRequest";
import { MqttCommand } from "../../models/mqtt-command";
import MqttDeviceManager from "./MqttDeviceManager";
import { MqttBaseService } from "./MqttBaseService";
import IDisposable from "../../models/IDisposable";
import { IMediaPlaybackOptions } from "../../models/IMediaPlaybackOptions";
import { AppLogger } from "../../utils/AppLogger";

export enum MqttReceiverEventListenersKey {
	OnTestSendEvent = "onTestSendEvent",
	OnDeviceBroadcast = "onDeviceBroadcast",
	OnDiscoverAccount = "onDiscoverAccount",
}

export default class MqttReceiverService extends MqttBaseService implements IDisposable {
	private _deviceManager: MqttDeviceManager;
    private _topic: string;
    private _enabled: boolean;

	constructor(mqttService: IMqttClient, mqttDeviceBroadcast: IMqttDeviceBroadcast, topic: string, deviceManager: MqttDeviceManager, enabled: boolean) {
		super(mqttDeviceBroadcast);
		this._mqttService = mqttService;
		this._topic = topic;
		this._mqttDeviceBroadcast = mqttDeviceBroadcast;
		this._deviceManager = deviceManager;
		this._enabled = enabled;
		this._eventListeners = {};

		this._mqttService.subscribe(topic);
		this._mqttService.onMessage(async (topic, message) => {
			if (!this._enabled) {return;}
			this.onMessage(message);
		});
		this.processMessageInternal = (e) => {
			this.processMessage(e);
		};
	}
	
	public dispose() {
		this.deleteAllEventListeners();

		return null;
	}

	// DEV NOTE: interpret message / decode message here when adding new mqtt functionality (see command)
	private processMessage(decodedMessage: IMqttRequest) {
		switch (decodedMessage.command) {
			case MqttCommand.DiscoverAccount:
				this.emitOnDiscoverAccountEvents(decodedMessage);
				break;
			case MqttCommand.TestCommand:
				this.emitOnTestSendEvents(decodedMessage);
				break;
			case MqttCommand.SendFromTitleDetails:
				AppLogger.log("received command from title details successfully!", decodedMessage);
				break;
			case MqttCommand.DeviceBroadcast:
				this.receiveDevice(decodedMessage as IMqttDeviceBroadcast);
				break;
			case MqttCommand.PlayVam:
				this.emitOnVamSendEvents(decodedMessage);
				break;
			case MqttCommand.MediaAudio:
				this.emitOnAudioChangeEvents(decodedMessage);
				break;
			case MqttCommand.MediaSubtitle:
				this.emitOnSubTitleChangeEvents(decodedMessage);
				break;
			case MqttCommand.PlayMovie:
				this.emitOnPlayMovieSendEvents(decodedMessage);
				break;
			case MqttCommand.PlayTrailer:
				this.emitOnTrailerSendEvents(decodedMessage);
				break;
			case MqttCommand.MediaPosition:
				this.emitOnMediaPositionEvents(decodedMessage);
				break;
			case MqttCommand.MediaPlay:
				this.emitMediaPlayListenerEvents(decodedMessage);
				break;
			case MqttCommand.MediaRestart:
				this.emitMediaRestartListenerEvents(decodedMessage);
				break;
			case MqttCommand.MediaSeek:
				this.emitOnMediaSeekEvents(decodedMessage);
				break;
			case MqttCommand.MediaSync:
				this.emitOnMediaSyncEvents(decodedMessage);
				break;
			case MqttCommand.MediaStop:
				this.emitOnMediaStopEvents(decodedMessage);
				break;
			case MqttCommand.RemoveScreen:
				this.emitOnRemoveScreenEvents(decodedMessage);
				break;
			case MqttCommand.MediaPause:
				this.emitMediaPauseListenerEvents(decodedMessage);;
				break;
			case MqttCommand.BroadcastUpdatedDeviceInfo:
				this.emitOnBroadcastUpdatedDeviceInfo(decodedMessage);;
				break;
			case MqttCommand.Ping:
				this.emitEventListenersSubscribed(MqttCommand.Ping, decodedMessage);
				break;
			case MqttCommand.PingBack:
				this.emitEventListenersSubscribed(MqttCommand.PingBack, decodedMessage);
				break;
			case MqttCommand.DisconnectDevice:
				this.emitOnDisconnectDeviceEvents(decodedMessage);
				break;
			case MqttCommand.SyncDevice:
				this.emitEventListenersSubscribed(MqttCommand.SyncDevice, decodedMessage);
				break;
			case MqttCommand.MediaPlaybackOptions:
				this.emitOnMediaPlaybackOptionsEvents(decodedMessage);
				break;
			case MqttCommand.MediaProgressBar:
				this.emitOnMediaProgressBarEvents(decodedMessage);
				break;	
			case MqttCommand.SyncDeviceDone:
				this.emitOnAddSyncDeviceDone(decodedMessage);
				break;
			default:
				break;
		}
	}

	receiveDevice(device: IMqttDeviceBroadcast) {
		this.emitEventListenersSubscribed(MqttCommand.DeviceBroadcast, device as IMqttRequest);
	}

	//#region Event Listeners

	public onDeviceBroadcastListener(fn: (decodedMessage: IMqttDeviceBroadcast) => void) {
		return this.addEventListener(MqttCommand.DeviceBroadcast, fn);
	}
	// syncs devices connected to mqtt
	public onDeviceSyncListener(fn: (decodedMessage: IMqttDeviceBroadcast) => void) {
		return this.addEventListener(MqttCommand.SyncDevice, fn);
	}

	public addOnPingReceivedListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.Ping, fn);
	}

	public addOnPingBackReceivedListener(fn: (decodedMessage: IMqttRequest) => void): number {
		return this.addEventListener(MqttCommand.PingBack, fn);
	}

	public addOnMediaPlayListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaPlay, fn);
	}

	public addOnMediaRestartListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaRestart, fn);
	}

	public addOnMediaPauseListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaPause, fn);
	}

	public onBroadcastUpdatedDeviceInfo(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.BroadcastUpdatedDeviceInfo, fn);
	}

	public addOnPlayMovieSendEvent(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.PlayMovie, fn);
	}

	public addOnVamSendEvent(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.PlayVam, fn);
	}

	public addOnTrailerSendEvent(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.PlayTrailer, fn);
	}

	public addOnMediaPositionListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaPosition, fn);
	}

	public addOnMediaSeekListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaSeek, fn);
	}

	public addOnMediaStopListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaStop, fn);
	}

	public addOnMediaSyncListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaSync, fn);
	}

	public addOnMediaProgressBarListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaProgressBar, fn);
	}

	
	/**
	 * onMediaPlaybackOptions
	 */
	public onMediaPlaybackOptions(fn: (decodedMessage: IMediaPlaybackOptions) => void) {
		this.addEventListener(MqttCommand.MediaPlaybackOptions, fn);
	}

	/**
	 * TODO: May be subject to deprecation as it can't be determined whether this function works as intended
	 * @param fn
	 * @returns 
	 */
	public addSyncDeviceDoneListener(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.SyncDeviceDone, fn);
	}

	public addOnSubTitleChange(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.MediaSubtitle, fn);
	}

	public addOnRemoveScreenEvents(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.DisconnectDevice, fn);
	}

	public addOnDisconnectDeviceEvents(fn: (decodedMessage: IMqttRequest) => void) {
		return this.addEventListener(MqttCommand.DisconnectDevice, fn);
	}

	public addOnDiscoverAccountEvents(fn: (decodedMessage: IMqttDeviceBroadcast) => void) {
		return this.addEventListener(MqttCommand.DiscoverAccount, fn);
	}

	public addOnAudioChange(fn: (decodedMessage: IMqttRequest) => void): number {
		return this.addEventListener(MqttCommand.MediaAudio, fn);
	}

	//#endregion

	//#region EVENT EMITTERS. PUT EVENTS HERE
	public emitOnTestSendEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttReceiverEventListenersKey.OnTestSendEvent, decodedMessage);
	}

	private emitMediaPlayListenerEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaPlay, decodedMessage);
	}

	private emitMediaPauseListenerEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaPause, decodedMessage);
	}

	private emitMediaRestartListenerEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaRestart, decodedMessage);
	}

	public emitOnBroadcastUpdatedDeviceInfo(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.BroadcastUpdatedDeviceInfo, decodedMessage);
	}

	public emitOnPlayMovieSendEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.PlayMovie, decodedMessage);
	}

	public emitOnVamSendEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.PlayVam, decodedMessage);
	}

	public emitOnTrailerSendEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.PlayTrailer, decodedMessage);
	}

	public emitOnMediaPositionEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaPosition, decodedMessage);
	}

	public emitOnMediaSeekEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaSeek, decodedMessage);
	}

	public emitOnMediaStopEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaStop, decodedMessage);
	}

	public emitOnAddSyncDeviceDone(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.SyncDeviceDone, decodedMessage);
	}

	public emitOnMediaSyncEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaSync, decodedMessage);
	}

	public emitOnAudioChangeEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaAudio, decodedMessage);
	}

	public emitOnSubTitleChangeEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaSubtitle, decodedMessage);
	}

	public emitOnDisconnectDeviceEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.DisconnectDevice, decodedMessage);
	}

	public emitOnRemoveScreenEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.DisconnectDevice, decodedMessage);
	}

	public emitOnDiscoverAccountEvents(decodedMessage: IMqttDeviceBroadcast) {
		this.emitEventListenersSubscribed(MqttCommand.DiscoverAccount, decodedMessage);
	}

	public emitOnMediaPlaybackOptionsEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaPlaybackOptions, decodedMessage);
	}

	public emitOnMediaProgressBarEvents(decodedMessage: IMqttRequest) {
		this.emitEventListenersSubscribed(MqttCommand.MediaProgressBar, decodedMessage);
	}

	//#endregion
}
