import { injectable } from "inversify";
import { MqttClient } from "mqtt";
import mitt, { Emitter } from 'mitt';
import "reflect-metadata";
import MqttConnectionStatus from "../../../models/MqttConnectionStatus";
import { AppLogger } from "../../../utils/AppLogger";

type Events = {
    connect: void;
    disconnect: void;
    reconnect: void;
    close: void;
    offline: void;
};

const EventListener: Emitter<Events> = mitt(); //Note: need to use event emitters to support better event handlers as well as to simulate scenarios (e.g. faking offline / disconnect events)

@injectable()
export class BaseMqttDriver {
    private MqttInstance: MqttClient | undefined;
    public ConnectionStatus: MqttConnectionStatus = MqttConnectionStatus.Offline;

    /**
     * determines if new mqtt connection is coming from reconnection
     */
    public IsConnectionReconnect: boolean = false;
    ClientId?: string;
    ClientTopic: string;

    constructor() {
        this.ClientTopic = "";
    }
    
    // note: call this to initialize mqtt client
    async initializeMqtt(_mqttInstance: MqttClient) {
        try {
            AppLogger.log("Initializing MqttClient..");
            this.MqttInstance = _mqttInstance;
            
            this.bindMqttEventListeners();
        } catch (ex) {
            AppLogger.error('an error found: ', ex);
        }
    }

    // Connect to MQTT broker
    async connect(presignedUrl: any, onConnected: () => void) {
        // Don't instantiate anything here. IGNORE
    }

    public forceClose = () => {  
        AppLogger.log('force close connection... ', this.MqttInstance);
        this.MqttInstance?.end(true);
    }

    // Publish a message to a topic
    public publish = (message: string) => {
        this.MqttInstance?.publish(this.ClientTopic, message);
    }

    // Publish a message to a topic
    public unmarshalledPublish = (topic: string, message: string) => {
        this.MqttInstance?.publish(topic, message);
    }

    // Subscribe to a topic
    public subscribe = (topic: string) => {
        this.MqttInstance?.subscribe(topic);
    }

    // Unsubscribe from a topic
    public unsubscribe = (topic: string) => {
        this.MqttInstance?.unsubscribe(topic);
    }

    // End the connection
    public end = (force: boolean | undefined | null) => {
        this.MqttInstance?.publish(this.ClientTopic, "end");
        this.MqttInstance?.end(force ?? false);
    }

    public unmarshalledEnd = (force: boolean | undefined | null, topic: string) => {
        this.MqttInstance?.publish(topic, "end");
        this.MqttInstance?.end(force ?? false);
    }

    // Listen to a topic
    public onMessage = (callback: (topic: string ,message: string) => void) => {
        this.MqttInstance?.on('message', (topic, message) => {
            callback(topic, message.toString());
        });
    }

    // Handle an event
    public handleEvent = (topic: string, callback: () => void) => {
        this.MqttInstance?.on('message', (_topic, _message) => {
            if (`${this.ClientId}-${topic}` == _topic) {
                callback();
            }
        });
    }

    public bindMqttEventListeners() {
        if (!this.MqttInstance) {
            return;
        }

        this.MqttInstance.on('connect', () => {
            if (!this.IsConnectionReconnect) {
                AppLogger.log("[MQTT] Connected to MQTT broker!");
            } else {
                AppLogger.log("[MQTT] Successfully reconnected to MQTT broker!");
            }
            this.ConnectionStatus = MqttConnectionStatus.Connected;

            EventListener.emit('connect');
        });
        this.MqttInstance.on('disconnect', () => {
            AppLogger.log("[MQTT] Disconnected from MQTT broker");
            this.ConnectionStatus = MqttConnectionStatus.Offline;

            this.MqttInstance?.end(true);
            EventListener.emit('disconnect');
        });
        this.MqttInstance.on('reconnect', () => {
            this.IsConnectionReconnect = true;
            AppLogger.log("[MQTT] Reconnecting to MQTT broker");

            EventListener.emit('reconnect');
        });
        this.MqttInstance.on('close', () => {
            this.ConnectionStatus = MqttConnectionStatus.Connected;

            EventListener.emit('close');
            AppLogger.log('[MQTT] Connection closed...');
        });
        this.MqttInstance.on('offline', () => {
            this.ConnectionStatus = MqttConnectionStatus.Offline;

            EventListener.emit('offline');
            AppLogger.log('[MQTT] Connection lost...');
        });
    }

    // On connection established
    /**
     * NOTE: Use onConnect to bind all event listeners if applicable(such as onReconnect, onDisconnect, onConnectionLost) since MqttInstance is instantiated once this event listener is called
     * 
     * 
     * @param callback: triggers a callback function
     */
    public onConnect = (callback: (topic: string) => void) => {
        EventListener.on('connect', () => callback(this.ClientTopic));
    }

    // On connection lost
    public onDisconnect (callback: () => void) {
        EventListener.on('disconnect', () => callback());
    }

    public onClose = (callback: () => void) => {
        EventListener.on('close', () => callback());
    }

    public onConnectionLost = (callback: () => void) => {
        EventListener.on('offline', () => callback());
    }

    // On connection re-connecting
    public onReconnect = (callback: () => void) => {
        EventListener.on('reconnect', () => callback());
    }


}