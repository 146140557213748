import styled from "styled-components/native";

export const StyledContainer = styled.View`
	height: 100%;
`;

export const StyledGridPackshots = styled.View<any>`
	margin-bottom: 3%;
	padding: ${(props) => props.paddingTop} ${(props) => props.styledAggregate.paddingLeft} 20px ${(props) => props.styledAggregate.paddingRight};
`;
