export enum ScreenDimensions {
	narrow = "narrow",
	wide = "wide",
	default = "default",
	dimensions = "screenDimensions",
	sync = "sync"
}

export enum SelectedNav {
	Home = "Home",
	ScreenManager = "ScreenManager",
	Library = "MyLibrary",
	Curation = "StudioAccess",
	Settings = "Settings",
}