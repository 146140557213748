import { useEffect, useState } from "react";
import {
	globalAny,
	pushRecoverPasswordStateToBrowserHistory,
	pushProfileNameStateToBrowserHistory,
	capitalize,
	validateEmail,
	login,
	generateDeviceInfo,
} from "../../utils/Utils";
import { Code_OK, INVALID_PASSWORD, TEMPORARY_PASSWORD_OK, getPresignedUrl } from "../../networking/networking";
import { resetCurrentPassword, resetTemporaryPassword, sendTemporaryPassword } from "../../services/loginService";
import { RecoverPasswordState, AsyncStorageKeys, ProfileNameState, Pages } from "../../Types";
import { useNavigation } from "@react-navigation/native";
import {
	SignInScreenNavigationProp,
	LoginScreenNavigationProp,
	routeSignIn,
	ProfileNameSelectScreenNavigationProp,
	routeProfileNameSelect,
	SettingsNavigationProp,
	routeNewSettings,
	routeHome,
	routeLogin,
	HomeScreenNavigationProp,
} from "../../Routes";
import { PasswordReset, PasswordChange } from "../../models/PasswordReset";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SplashWelcome from "../../components/SplashWelcome";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import Toast from "../../components/Toast";
import useMqttStore from "../../store/useMqtt.store";
import useGenericContentStore from "../../store/genericContent.store";
import useLoginStore from "../../store/useLogin.store";
import Main from "../../components/Layout/MainLayout/Main";
import AppBarWrapper from "../../components/Layout/MainLayout/AppBarWrapper";
import RecoverPassword from "./RecoverPassword";
import ChangePassword from "./ChangePassword";
import useDimensions from "../../hooks/useDimensions";
import LandingScreenLoader from "../../components/Loaders/LandingLoader";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import usePrivacyPolicy from "../../hooks/usePrivacyPolicy";

const RecoverPasswordScreen = (props: any) => {
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const [recoverPasswordState, setRecoverPasswordState] = useState(RecoverPasswordState.recover);
	const navigationSignInScreen = useNavigation<SignInScreenNavigationProp>();
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const navigation = useNavigation();
	const navigationSettingsScreen = useNavigation<SettingsNavigationProp>();
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const { settings } = props.route.params;
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [tempPassword, setTempPassword] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");
	const [retypePassword, setRetypePassword] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [emailMessage, setEmailMessage] = useState("");
	const [modalHeader, setModalHeader] = useState("");
	const [modalBody, setModalBody] = useState("");
	const [secureTextEntryTemp, setSecureTextEntryTemp] = useState(true);
	const [secureTextEntryPass, setSecureTextEntryPass] = useState(true);
	const [secureTextEntryRepass, setSecureTextEntryRepass] = useState(true);
	const [passwordError, setPasswordError] = useState(false);
	const [passwordMessage, setPasswordMessage] = useState("");
	const [tempPasswordError, setTempPasswordError] = useState(false);
	const [tempPasswordMessage, setTempPasswordMessage] = useState("");
	const [currentPasswordError, setCurrentPasswordError] = useState(false);
	const [currentPasswordMessage, setCurrentPasswordMessage] = useState("");
	const [rePasswordError, setRePasswordError] = useState(false);
	const [rePasswordMessage, setRePasswordMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [splashWelcome, setSplashWelcome] = useState(false);
	const [customizeModal, setCustomizeModal] = useState(false);
	const [disableBtn, setDisableBtn] = useState(true);
	const [disableChangePassBtn, setDisableChangePassBtn] = useState(true);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [privacyPolicyScreen, setPrivacyPolicyScreen] = useState(false);
	const onDismissSnackBar = () => setVisible(false);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const screenDimensions = useDimensions();
	const privacyPolicyText = usePrivacyPolicy();

	let result: any;
	let reset: any;
	let initialized: any;

	const onSubmit = async () => {
		if (!email || emailError) {
			setEmailMessage(globalAny.language.invalid_email);
			setEmailError(true);
			return;
		}

		if (recoverPasswordState === RecoverPasswordState.recover) {
			result = await sendTemporaryPassword(email);
			if (result.responseCode === Code_OK) {
				setModalHeader(globalAny.language.temporary_password_modal_header);
				setModalBody(globalAny.language.temporary_password_modal_body);
				setCustomizeModal(true);
			} else {
				setToastMessage(globalAny.language.error_temporary_password);
				setLabelText(globalAny.language.label_retry);
				setVisible(true);
			}
		}
	};

	const resetState = () => {
		setDisableChangePassBtn(true);
		setTempPassword("");
		setCurrentPassword("");
		setPassword("");
		setRetypePassword("");
		setTempPasswordError(false);
		setCurrentPasswordError(false);
		setPasswordError(false);
		setRePasswordError(false);
	};

	const onError = () => {
		setLoading(false);
		setToastMessage(globalAny.language.error_change_password);
		setLabelText(globalAny.language.label_retry);
		setVisible(true);
		resetState();
	};

	const onBackPress = () => {
		if (privacyPolicyScreen) {
			setPrivacyPolicyScreen(false);
			return;
		}

		if (settings === Pages.reset) {
			navigationSettingsScreen.navigate(routeNewSettings, {});
			return;
		}

		switch (recoverPasswordState) {
			case RecoverPasswordState.recover:
				navigationSignInScreen.navigate(routeSignIn, {});
				break;
			case RecoverPasswordState.changePassword:
				setRecoverPasswordState(RecoverPasswordState.recover);
				pushRecoverPasswordStateToBrowserHistory(RecoverPasswordState.recover);
				break;
		}
		setPasswordError(false);
		setRePasswordError(false);
		setTempPasswordError(false);
		setDisableBtn(true);
	};

	const onClose = () => {
		if (settings === "reset") {
			//@ts-ignore
			navigation.navigate(routeHome);
		} else {
			navigationLoginScreen.navigate(routeLogin, {});
		}
	};

	const dismissCustomizeModal = () => {
		setCustomizeModal(false);
	};

	const onEnterEmail = (event: any) => {
		setEmail(event);
		if (validateEmail(event)) {
			setEmailError(false);
			return;
		}

		setEmailMessage(globalAny.language.invalid_email);
		setEmailError(true);
	};

	const onEnterPassword = (event: any) => {
		setPassword(event);

		if (event.length > 0 && event !== retypePassword && retypePassword.length != 0) {
			setRePasswordMessage(globalAny.language.passwords_dont_match);
			setRePasswordError(true);
		} else {
			setRePasswordError(false);
		}

		if (validatePassword(event)) {
			setPasswordError(false);
			return;
		}

		setPasswordMessage(globalAny.language.password_rules);
		setPasswordError(true);
	};

	const onEnterTempPassword = (event: any) => {
		setTempPassword(event);
		if (event.length > 0) {
			setTempPasswordError(false);
			return;
		}

		setTempPasswordMessage(globalAny.language.invalid_temporary_password);
		setTempPasswordError(true);
	};

	const onEnterCurrentPassword = (event: any) => {
		setCurrentPassword(event);
		if (event.length > 0) {
			setCurrentPasswordError(false);
			return;
		}
		setCurrentPasswordMessage(globalAny.language.fill_up_current_password);
		setCurrentPasswordError(true);
	};

	const onEnterRetypePassword = (event: any) => {
		setRetypePassword(event);
		if (event === password || event.length === 0) {
			setRePasswordError(false);
			return;
		}

		setRePasswordMessage(globalAny.language.passwords_dont_match);
		setRePasswordError(true);
	};

	const onPressEye = (event: number) => {
		if (event === 2) {
			if (secureTextEntryPass) {
				setSecureTextEntryPass(false);
				return;
			}
			setSecureTextEntryPass(true);
		} else if (event === 3) {
			if (secureTextEntryRepass) {
				setSecureTextEntryRepass(false);
				return;
			}
			setSecureTextEntryRepass(true);
		} else {
			if (secureTextEntryTemp) {
				setSecureTextEntryTemp(false);
				return;
			}
			setSecureTextEntryTemp(true);
		}
	};

	const successSent = () => {
		setCustomizeModal(false);
		setRecoverPasswordState(RecoverPasswordState.changePassword);
		pushRecoverPasswordStateToBrowserHistory(RecoverPasswordState.changePassword);
	};

	const validatePassword = (password: string) => {
		return password.length >= 6;
	};

	const returnToSettings = () => {
		setShowModal(false);
		navigationSettingsScreen.navigate(routeNewSettings, {});
	};

	const onChangeCurrentPassword = async () => {
		if (currentPassword.length === 0) {
			setCurrentPasswordMessage(globalAny.language.fill_up_current_password);
			setCurrentPasswordError(true);
			return;
		}

		if (password.length === 0 || passwordError) {
			setPasswordMessage(globalAny.language.password_rules);
			setPasswordError(true);
			return;
		}

		if (retypePassword.length === 0 || rePasswordError) {
			setRePasswordMessage(globalAny.language.passwords_dont_match);
			setRePasswordError(true);
			return;
		}

		if (recoverPasswordState === RecoverPasswordState.changePassword) {
			setLoading(true);

			const passwordChange: PasswordChange = {
				newPassword: password,
				confirmNewPassword: retypePassword,
				oldPassword: currentPassword,
			};

			reset = await resetCurrentPassword(passwordChange);

			if (reset.responseCode === Code_OK || reset.responseCode === TEMPORARY_PASSWORD_OK) {
				await AsyncStorage.setItem(AsyncStorageKeys.email, email);
				setLoading(false);
				setShowModal(true);
			} else if (reset.responseCode === INVALID_PASSWORD || currentPasswordError) {
				setToastMessage(globalAny.language.invalid_current_password);
				setLabelText(globalAny.language.label_retry);
				setLoading(false);
				setVisible(true);
				setDisableChangePassBtn(true);
			} else {
				setLoading(false);
				setToastMessage(globalAny.language.error_change_password);
				setLabelText(globalAny.language.label_retry);
				setVisible(true);
				setDisableChangePassBtn(true);
			}
		}
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	const validateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";

		if (isInCarScreen) {
			const profileName = AsyncStorage.getItem(AsyncStorageKeys.profileName);
			setIsLoggedIn(true);
			updateDevice(profileName);
			navigationHomeScreen.navigate(routeHome, {});
			return;
		}

		navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
	};

	const onPrivacy = () => {
		setPrivacyPolicyScreen(true);
	};

	const title = () => {
		if (privacyPolicyScreen) {
			return capitalize(globalAny.language.privacy_policy);
		}
		return settings === Pages.reset ? capitalize(globalAny.language.change_password) : capitalize(globalAny.language.recover_password);
	};

	const onChangePassword = async () => {
		if (tempPassword.length === 0 || tempPasswordError) {
			setTempPasswordMessage(globalAny.language.invalid_temporary_password);
			setTempPasswordError(true);
			return;
		}

		if (password.length === 0 || passwordError) {
			setPasswordMessage(globalAny.language.invalid_password);
			setPasswordError(true);
			return;
		}

		if (retypePassword.length === 0 || rePasswordError) {
			setRePasswordMessage(globalAny.language.passwords_dont_match);
			setRePasswordError(true);
			return;
		}

		if (recoverPasswordState === RecoverPasswordState.changePassword) {
			setLoading(true);

			initialized = await login(
				email,
				tempPassword,
				() => {},
				() => onError(),
				() => onError()
			);

			if (initialized?.proceed) {
				const passwordReset: PasswordReset = {
					newPassword: password,
					confirmNewPassword: retypePassword,
					temporaryPasswordCode: initialized.response.temporaryPasswordCode,
				};

				reset = await resetTemporaryPassword(passwordReset);
				if (reset.responseCode === Code_OK || reset.responseCode === TEMPORARY_PASSWORD_OK) {
					await AsyncStorage.setItem(AsyncStorageKeys.email, email);
					const presignedService = await getPresignedUrl();
					setPresignedUrl(presignedService);
					setLoading(false);
					setSplashWelcome(true);
					pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
					AsyncStorage.setItem(AsyncStorageKeys.email, email);
					setTimeout(() => {
						setIsLogin(true);
						setSplashWelcome(false);
						validateDeviceScreen();
					}, 2000);
				} else {
					setLoading(false);
					setToastMessage(globalAny.language.error_change_password);
					setLabelText(globalAny.language.label_retry);
					setVisible(true);
				}
			}
		}
	};

	// Recover password logic
	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			if (settings === Pages.reset) {
				return;
			}
			const emailValidated = validateEmail(email);
			setDisableBtn(!emailValidated);
		}

		return () => {
			unmounted = true;
		};
	}, [email]);

	// Change password logic
	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			const tempPasswordValidated = tempPassword.length > 0;
			const currentPasswordValidated = currentPassword.length > 0;
			const passwordValidated = password.length > 0 && validatePassword(password);
			const retypePasswordValidated = retypePassword.length > 0 && password === retypePassword;

			if (settings === Pages.reset) {
				setDisableChangePassBtn(!currentPasswordValidated || !passwordValidated || !retypePasswordValidated);
				return;
			}

			setDisableChangePassBtn(!tempPasswordValidated || !passwordValidated || !retypePasswordValidated);
		}

		return () => {
			unmounted = true;
		};
	}, [currentPassword, tempPassword, password, retypePassword]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			if (settings === Pages.reset) {
				setRecoverPasswordState(RecoverPasswordState.changePassword);
				return;
			}
			setRecoverPasswordState(RecoverPasswordState.recover);
		}

		return () => {
			unmounted = true;
		};
	}, []);

	if (loading) {
		return (
			<Main>
				<LandingScreenLoader />
			</Main>
		);
	}

	if (splashWelcome) {
		return (
			<Main>
				<LandingScreenLoader title={globalAny.language.welcome_only} />
			</Main>
		);
	}

	return (
		<Main isScrollable={false}>
			<AppBarWrapper dimensions={screenDimensions}>
				<TopAppbar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={title()}
					rightLogo={ridevueLogo}
					onPressLeftTitleIcon={onBackPress}
					onPressLeftIcon={onClose}
					isSingleLogo={true}
					removeFixedPosition={false}
				/>
			</AppBarWrapper>

			{privacyPolicyScreen ? <PrivacyPolicy text={privacyPolicyText} /> : <></>}

			{recoverPasswordState === RecoverPasswordState.recover && !privacyPolicyScreen && (
				<RecoverPassword
					onSubmit={() => onSubmit()}
					onEnterEmail={(event: any) => onEnterEmail(event)}
					emailError={emailError}
					emailMessage={emailMessage}
					disableBtn={disableBtn}
					screenDimensions={screenDimensions}
					onPrivacy={() => onPrivacy()}
				/>
			)}

			{recoverPasswordState === RecoverPasswordState.changePassword && !privacyPolicyScreen && (
				<ChangePassword
					screenDimensions={screenDimensions}
					settings={settings}
					secureTextEntryTemp={secureTextEntryTemp}
					currentPasswordError={currentPasswordError}
					tempPasswordError={tempPasswordError}
					currentPasswordMessage={currentPasswordMessage}
					tempPasswordMessage={tempPasswordMessage}
					secureTextEntryPass={secureTextEntryPass}
					passwordError={passwordError}
					passwordMessage={passwordMessage}
					secureTextEntryRepass={secureTextEntryRepass}
					disableChangePassBtn={disableChangePassBtn}
					rePasswordError={rePasswordError}
					rePasswordMessage={rePasswordMessage}
					onEnterCurrentPassword={(event: any) => onEnterCurrentPassword(event)}
					onEnterTempPassword={(event: any) => onEnterTempPassword(event)}
					onPressEye={(event: any) => onPressEye(event)}
					onEnterPassword={(event: any) => onEnterPassword(event)}
					onEnterRetypePassword={(event: any) => onEnterRetypePassword(event)}
					onChangeCurrentPassword={() => onChangeCurrentPassword()}
					onChangePassword={() => onChangePassword()}
					onPrivacy={() => onPrivacy()}
				/>
			)}

			<Toast visible={visible} text={toastMessage} label={labelText} onDismissSnackBar={onDismissSnackBar}></Toast>

			{customizeModal && (
				<CustomizeDialogBox
					Header={modalHeader}
					Body={modalBody}
					ButtonTextRight={
						settings === Pages.reset ? capitalize(globalAny.language.reset_password) : capitalize(globalAny.language.recover_password)
					}
					SingleButton={true}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorRight={ComponentTypeEnum.Primary}
					onDismiss={dismissCustomizeModal}
					onPressRight={successSent}
					screenDimensions={screenDimensions}
				></CustomizeDialogBox>
			)}

			{showModal && (
				<CustomizeDialogBox
					Header={globalAny.language.success_change_pass}
					Body={globalAny.language.change_pass_modal}
					ButtonTextRight={globalAny.language.return_to_settings}
					SingleButton={true}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorRight={ComponentTypeEnum.Primary}
					onPressRight={returnToSettings}
				/>
			)}
		</Main>
	);
};
export default RecoverPasswordScreen;
