import React, { useState } from "react";
import { View, ScrollView, Text } from "react-native";
import styled from "styled-components/native";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import LegalDialog from "../../components/DialogMessageBox/LegalDialogBox";
import ButtonToggle from "../../components/ButtonToggle";
import FlexRow from "../../components/Layout/FlexRow";
import FormRow from "../../components/Layout/FormRow";
import ScreenContainer from "../../components/Layout/ScreenManagerContainer";
import TabsView from "../../components/TabsView";
import TextInput from "../../components/TextInput";
import TouchableButton from "../../components/TouchableButton";
import H1 from "../../components/Typography/H1";
import H2 from "../../components/Typography/H2";
import H3 from "../../components/Typography/H3";
import H4 from "../../components/Typography/H4";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import Toast from "../../components/Toast";

const StyledView = styled.View`
	background-color: #08447c;
	padding: 30px;
	font-size: 24px;
`;

const VinfastComponentsScreen = () => {
	const [txtInputShowError, setTxtInputShowError] = useState("");
	const [modalLegal, setModalLegal] = useState(false);
	const [customizeModal, setCustomizeModal] = useState(false);
	const [visible, setVisible] = useState(false);

	// sample
	const SampleBtnToggle = () => {
		const [btnDisabled, setBtnDisabled] = useState(false);
		return <ButtonToggle onPress={(state) => setBtnDisabled(state)}>{btnDisabled ? "OFF" : "ON"}</ButtonToggle>;
	};

	const onPressLegal = () => {
		setModalLegal(true);
	};

	const onPressCustomizeModal = () => {
		setCustomizeModal(true);
	};

	const dismissCustomizeModal = () => {
		setCustomizeModal(false);
	};

	const pressCustomizeModal = () => {
		setCustomizeModal(false);
	};

	const pressModalLegal = () => {
		setModalLegal(false);
	};

	const onDismissSnackBar = () => setVisible(false);

	return (
		<View style={{ backgroundColor: "#000", height: "100%" }}>
			<ScreenContainer>
				<ScrollView stickyHeaderIndices={[0]} showsVerticalScrollIndicator={false}>
					<br />
					<br />
					<br />
					{/* @ts-ignore */}
					<StyledView>
						<H2>Vinfast style guide</H2>
					</StyledView>
					<br />
					<br />

					<H4>This page would serve as style guide for using UI components using vinfast design system</H4>

					<br />
					<br />
					<br />
					{/* @ts-ignore */}
					<StyledView>
						<H3>Button components</H3>
					</StyledView>

					<br />
					<br />
					<SampleBtnToggle />

					{/* @ts-ignore */}
					<FormRow>
						{/* @ts-ignore */}
						<FlexRow>
							<TouchableButton
								componentTheme={ComponentThemeType.VinFast}
								type={ComponentTypeEnum.Primary}
								onPress={() => alert("I am a primary component")}
							>
								Primary
							</TouchableButton>
							<TouchableButton
								componentTheme={ComponentThemeType.VinFast}
								type={ComponentTypeEnum.Secondary}
								onPress={() => alert("I am a secondary component")}
							>
								Secondary
							</TouchableButton>
						</FlexRow>
					</FormRow>
					{/* @ts-ignore */}
					<FlexRow>
						<TouchableButton
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => alert("I am a primary component")}
						>
							Primary button but wider
						</TouchableButton>
					</FlexRow>

					<br />
					<br />
					<br />

					{/* @ts-ignore */}
					<StyledView>
						<H3>Other reusable components</H3>
					</StyledView>

					<br />
					<br />
					<br />

					{/* NOTE: DO NOT USE FLEXROW COMPONENT ON TABSVIEW */}
					<TabsView tabsToRender={["Account", "Help and About", "Legal", "Another tab"]}>
						<View>
							<Text style={{ color: "#FFF" }}>
								This is a content related to account. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsa delectus possimus
								nostrum magnam accusantium pariatur voluptatem, quasi expedita sed unde natus atque adipisci. Quisquam repellat ut
								veniam laboriosam quasi quod!
							</Text>
						</View>
						<View>
							<Text style={{ color: "#FFF" }}>Help and about content</Text>
						</View>
						<View style={{ flexDirection: "row", borderColor: "#FFF", borderWidth: 2 }}>
							<Text style={{ color: "#FFF", flexWrap: "wrap", flexDirection: "row" }}>
								Anim nulla tempor laboris esse reprehenderit excepteur consectetur mollit adipisicing est id pariatur elit. Ex dolore
								mollit laboris velit et exercitation ullamco voluptate ad id ut occaecat excepteur. Nulla id dolore tempor elit non
								eu. Do non ullamco laborum ad et nulla nostrud in commodo ut occaecat culpa anim. Culpa incididunt fugiat
								reprehenderit exercitation cupidatat veniam sit. Laboris eu mollit mollit nostrud. Ullamco aliquip ea laborum sit
								tempor aute quis excepteur dolor ex voluptate.
							</Text>
						</View>
					</TabsView>

					<br />
					<br />
					<br />

					{/* @ts-ignore */}
					<StyledView>
						<H3>Typography</H3>
					</StyledView>

					<br />
					<br />

					<H1>Lorem ipsum dolor sit amet consectetur adipisicing elit</H1>
					<br />
					<H2>Lorem ipsum dolor sit amet consectetur adipisicing elit</H2>
					<br />
					<H3>Lorem ipsum dolor sit amet consectetur adipisicing elit</H3>
					<br />
					<H4>Lorem ipsum dolor sit amet consectetur adipisicing elit</H4>

					<br />
					{/* @ts-ignore */}
					<StyledView>
						<H3>Inputs</H3>
					</StyledView>
					{/* @ts-ignore */}
					<FlexRow>
						<TextInput componentTheme={ComponentThemeType.VinFast} label={"Regular input"} />
					</FlexRow>
					<FlexRow>
						<TextInput
							componentTheme={ComponentThemeType.VinFast}
							error={true}
							errorMessage={"I have a problem so I show an error"}
							label={"Input with error"}
						/>
					</FlexRow>
					<FlexRow>
						<TextInput
							componentTheme={ComponentThemeType.VinFast}
							onChangeText={(val) => setTxtInputShowError(val)}
							error={txtInputShowError !== ""}
							errorMessage={"you typed something so I showed an error"}
							label={"Type something and I will show an error"}
						/>
					</FlexRow>

					{/* @ts-ignore */}
					<FormRow>
						{/* @ts-ignore */}
						<FlexRow>
							<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={onPressLegal}>
								Legal Modal
							</TouchableButton>

							<TouchableButton
								componentTheme={ComponentThemeType.VinFast}
								type={ComponentTypeEnum.Primary}
								onPress={onPressCustomizeModal}
							>
								Customize Modal
							</TouchableButton>
						</FlexRow>
					</FormRow>
					<FlexRow>
						<TouchableButton
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => setVisible(true)}
						>
							Toast or Snackbar
						</TouchableButton>
					</FlexRow>
					<br />
					<br />
					<br />
					<br />
				</ScrollView>
			</ScreenContainer>
			{/* @ts-ignore */}
			<Toast visible={visible} text={"Hey there! I'm a Snackbar."} label={"RETRY"} onDismissSnackBar={onDismissSnackBar}></Toast>

			{customizeModal && (
				<CustomizeDialogBox
					Header={"Sync Movie"}
					Body={"Front Screen wants to sync the Rear Left “Jumanji: The Next Level” on your screen."}
					ButtonTextRight={"Accept (5s)"}
					ButtonTextLeft={"Cancel"}
					BottomTextLeft={"(Expires in 30 days)"}
					BottomTextRight={"(Keep it forever)"}
					SingleButton={false}
					CloseButton={true}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Tertiary}
					TypeButtonColorRight={ComponentTypeEnum.Tertiary}
					onDismiss={dismissCustomizeModal}
					onPressRight={pressCustomizeModal}
					onPressLeft={pressCustomizeModal}
				></CustomizeDialogBox>
			)}

			{modalLegal && (
				<LegalDialog
					Header={"Privacy Policy"}
					Body={
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat suscipit pretium. Nam ultricies mi ipsum, eget rutrum leo condimentum vitae. Curabitur elit enim, viverra vel imperdiet non, tristique eget risus. Quisque consequat leo volutpat nulla pretium, at consectetur dolor facilisis. Praesent efficitur massa non sollicitudin ullamcorper. Nulla facilisi. Pellentesque vel libero non tellus condimentum congue. Quisque pretium pulvinar mauris, sit amet sagittis enim congue vitae. Nulla facilisi. Sed a risus laoreet, mattis elit eu, tincidunt orci. Duis a nisl luctus, placerat est vitae, porta arcu. Mauris porttitor turpis ligula, id maximus sem vulputate quis. Phasellus dui erat, sagittis at suscipit non, semper sit amet tellus. Curabitur nec neque nulla." +
						"Duis ultricies vestibulum blandit. In cursus purus felis, quis porta nisl hendrerit et. Nunc quis nibh eu nisi commodo finibus et sit amet augue. Sed elementum egestas justo, sit amet varius lacus iaculis id. In feugiat diam vel imperdiet cursus. Donec quis nibh in erat tincidunt gravida. Curabitur a nunc tincidunt nisl pulvinar sodales a ut enim. Phasellus ac odio magna. Duis dapibus pharetra pulvinar. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas." +
						"In venenatis libero sed nisi vulputate auctor. Ut posuere ut ante nec rhoncus. Donec non nisl viverra, fringilla libero eu, euismod est. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed urna diam, accumsan vitae dapibus vitae, vulputate vitae lectus. Fusce cursus a nisl nec facilisis. Donec facilisis urna orci. Nam in ex ut est malesuada bibendum eleifend vitae orci. Praesent lacinia finibus purus, id mattis quam luctus id. Duis varius finibus sapien, euismod bibendum velit. Ut hendrerit elementum urna eu tincidunt. Morbi facilisis gravida est, vitae congue tortor pellentesque ut." +
						"Morbi dictum non nisl sed consectetur. Nunc nec cursus orci. Vestibulum in rhoncus velit, quis blandit felis. Maecenas tincidunt scelerisque orci non volutpat. Donec quis semper erat. Nullam fringilla interdum cursus. Sed ac bibendum felis. Maecenas at felis quis elit lacinia laoreet non eu elit. Praesent pellentesque magna vel ullamcorper placerat. Quisque molestie sollicitudin semper. Integer magna enim, scelerisque condimentum blandit ac, aliquet et risus. Proin sed condimentum urna, vel lobortis erat." +
						"Quisque et mollis ex. Integer libero dolor, tincidunt bibendum pellentesque eget, lacinia ac risus. Nulla id tincidunt magna. Ut sit amet nibh tellus. Donec aliquam ultrices dolor, in euismod dolor sodales mollis. Ut dictum pellentesque velit, quis ullamcorper sapien tincidunt nec. Etiam ut mollis dui, ac tempus ligula. Suspendisse augue sem, malesuada id nunc a, maximus rhoncus sem. Integer ut quam ligula. Praesent in arcu nisi. Quisque vel diam risus. Sed finibus, odio eu cursus luctus, dolor eros vehicula diam, in aliquet nisl enim eget enim."
					}
					buttonText={"I Agree"}
					onPress={pressModalLegal}
				></LegalDialog>
			)}
		</View>
	);
};

export default VinfastComponentsScreen;
