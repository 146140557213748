import { View } from "react-native";
import styled, { css } from "styled-components/native";
import ImgProgressBar from "./ImgProgressBar";
import { light_black, spacing_l, spacing_m, spacing_s } from "../../StyleHelpers";

interface Props {
	marginRight: boolean;
}

const StyledContainer = styled.View`
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: ${light_black};
`;

const Title = styled.View`
	width: 439px;
	height: 64px;
	border-radius: 16px;
	left: 4.8%;
	top: 24px;
	overflow: hidden;
`;

const SubTitle = styled.View`
	height: 24px;
	width: 80%;
	top: 35%;
	border-radius: 16px;
	overflow: hidden;
`;

const BodyContainer = styled.View`
	margin: 0 10% 0 10%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BodyContent = styled.View`
	width: 100%;
	height: 72px;
	top: 50%;
	margin-bottom: 24px;
	border-radius: 16px;
	overflow: hidden;
`;

const ButtonContainer = styled.View`
	display: flex;
	top: 90%;
	flex-direction: row;
	width: 100%;
`;

const ButtonContent = styled.View<Props>`
	width: 50%;
	height: 72px;
	border-radius: 16px;
	overflow: hidden;
	${(props) =>
		props?.marginRight &&
		css`
			width: 49%;
			margin-right: 24px;
		`}
	${(props) =>
		!props?.marginRight &&
		css`
			width: 49%;
		`}
`;

const PaymentLoader = () => {
	const bodyCount = [{ id: 1 }, { id: 2 }, { id: 3 }];
	const buttonCount = [
		{ id: 1, marginRight: true },
		{ id: 2, marginRight: false },
	];
	const renderBodyContent = (id: any) => {
		return (
			// @ts-ignore
			<BodyContent key={`shimmer-${id}`}>
				<ImgProgressBar />
			</BodyContent>
		);
	};

	const renderButtonContent = ({ id, marginRight }: any) => {
		return (
			// @ts-ignore
			<ButtonContent key={`shimmer-button-${id}`} marginRight={marginRight}>
				<ImgProgressBar />
			</ButtonContent>
		);
	};

	return (
		// @ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			<Title>
				<ImgProgressBar />
			</Title>
			{/* @ts-ignore */}
			<BodyContainer>
				{/* @ts-ignore */}
				<SubTitle>
					<ImgProgressBar />
				</SubTitle>
				{bodyCount.map((item) => {
					return renderBodyContent(item.id);
				})}
				{/* @ts-ignore */}
				<ButtonContainer>
					{buttonCount.map((item) => {
						return renderButtonContent(item);
					})}
				</ButtonContainer>
			</BodyContainer>
		</StyledContainer>
	);
};

export default PaymentLoader;
