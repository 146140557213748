import MainLayoutWithSideNav from "../../components/Layout/MainLayoutWithSideNav";
import { Categories } from "../../Types";
import { useEffect, useState, useMemo } from "react";
import Banner from "./Banner";
import defaultStudioAccessBanner from "../../assets/Images/StudioAccess.webp";
import { useNavigation } from "@react-navigation/native";
import { ScreenManagerNavigationProp, routeSearchScreen } from "../../Routes";
import ControllerContent from "../../components/ControllerContent";
import ScreenIcon from "../../assets/ControllerIcon/Screen-Icon.svg";
import { AppLogger } from "../../utils/AppLogger";
import { getTransactionFilters, globalAny, isStaging, uniqueChannel } from "../../utils/Utils";
import useMqttStore from "../../store/useMqtt.store";
import { getCategories } from "../../services/categoriesService";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import StudioLoader from "../../components/Loaders/StudioLoader";
import { getPage } from "../../services/pageService";
import ContentAreaWithoutPadding from "../../components/Layout/ContentAreaWithoutPadding";

const transactionalFilters = getTransactionFilters();
const defaultItem = [
	{ id: 1, isLandscape: true, packshot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
	{
		id: 2,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
	},
];

const StudioAccess = () => {
	const navigation = useNavigation<ScreenManagerNavigationProp>();
	const ribbonText = globalAny.language.assign_single_screen_message;
	const controllerData = [
		{ id: 0, icon: ScreenIcon, buttonText: globalAny.language.assign_to_screens, textDescription: globalAny.language.assign_media_to_screen },
		{ id: 1, icon: "", buttonText: globalAny.language.cancel, textDescription: globalAny.language.select_media_to_assign },
	];
	const [channelData, setChannelData] = useState([]);
	const [controller, setController] = useState(controllerData[0]);
	const mqttDevices = useMqttStore((state: any) => state.mqttDevices);
	const [id, setId] = useState(0);
	const [categories, setCategories] = useState([]);
	const [isShowLoader, setIsShowLoader] = useState(true);
	const [startIndex, setStartIndex] = useState(4);
	const [studioAccessBanner, setStudioAccessBanner] = useState(defaultStudioAccessBanner);

	const onController = () => {
		if (id === 0) {
			setController(controllerData[1]);
			setId(1);
			return;
		}
		setController(controllerData[0]);
		setId(0);
	};

	const onSearch = () => {
		//@ts-ignore
		navigation.navigate(routeSearchScreen);
	};

	const renderStudioAccessPlaylist = useMemo(() => 
		<PlaylistContainer 
			headerBanner={null}
			categories={categories} 
			isSeeMore={true} 
			location={Categories.studioTwo}
			transactionalFilters={transactionalFilters} 
			setIsShowLoader={setIsShowLoader}
			isStudioAccess={true}
			isAssignToScreen={id === 1}
			setStartIndex={setStartIndex}
			startIndex={startIndex}
		/>
	, [categories, id, startIndex, studioAccessBanner]);

	useEffect(() => {
		if (!mqttDevices.length) {
			return;
		}

		const unique = uniqueChannel(mqttDevices);
		setChannelData(unique);
	}, [mqttDevices]);

	useEffect(() => {
		const controller = new AbortController();
		(async () => {
			try {
				const pages: any = await getPage(Categories.studioTwo, controller.signal);
				const [page] = pages.pages;
				const [pageImage] = page.pageImages;
				setStudioAccessBanner(pageImage.imageUrl);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		(async () => {
			try {
				let categories: any = await getCategories(Categories.studioTwo, transactionalFilters) ?? [];
				setCategories(categories);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		return () => { 
			setCategories([]);
		};
	}, []);

	return (
		<MainLayoutWithSideNav
			searchIcon={true}
			screenTitle={globalAny.language.short_clips}
			navTitle={"StudioAccess"}
			onPressRightIcon={onSearch}
		>
			<StudioLoader show={isShowLoader} data={defaultItem} />
			<ContentAreaWithoutPadding>
				{!isShowLoader ? <Banner banner={studioAccessBanner} /> : <></>}
				<ContentAreaWithoutPadding>
					{renderStudioAccessPlaylist}
					{ isStaging() && <ControllerContent
						data={controller}
						isTitle={channelData?.length <= 1}
						ribbonTitle={ribbonText}
						isShortClips={true}
						onPress={() => onController()}
					/> }
				</ContentAreaWithoutPadding>
			</ContentAreaWithoutPadding>
		</MainLayoutWithSideNav>
	);
};

export default StudioAccess;
