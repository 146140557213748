import { logoHeight, logoWidth, pageTitleFontSize, white_smoke } from "../../StyleHelpers";
import { TouchableWithoutFeedback, View } from "react-native";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import Image from "../Image/Image";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import searchIcon from "../../assets/TopNavBarIcon/icon_nav_search.png";
import Text from "../Text/Text";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import { TextInput } from "react-native-paper";
import closeIcon from "../../assets/Icons/x.svg";
import { useEffect, useRef, useState } from "react";
import useGenericContentStore from "../../store/genericContent.store";
import { AsyncStorageKeys, WheelPosition } from "../../Types";
import {
	StyledCenterImage,
	StyledCloseContainer,
	StyledContainer,
	StyledIconContainer,
	StyledIconRenderer,
	StyledInnerContainer,
	StyledLoading,
	StyledText,
	StyledTextContainer,
	styles,
} from "./styled";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";
import ENVIRONMENT from "../../config/ENVIRONMENT";

interface Props {
	demoOptions?: any;
	screenTitle?: any;
	screenTitleBody?: any;
	leftIcon?: any;
	leftTitleIcon?: any;
	titleLogo?: any;
	rightIcon?: any;
	rightLogo?: any;
	rightImage?: boolean;
	rightImageLandscape?: any;
	rightImagePortrait?: any;
	addTitleMarginLeft?: boolean;
	leftIconWidth?: any;
	search?: boolean;
	placeholder?: string;
	onChangeText?: any;
	inputValue?: string;
	autoFocus?: boolean;
	isNav?: boolean;
	isSingleLogo?: boolean;
	isSettings?: boolean;
	isHideLeftIcon?: boolean;
	isShowClose?: boolean;
	isPreview?: boolean;
	hasSideBar?: boolean;
	removeFixedPosition?: boolean;
	removeBackground?: boolean;
	onPressLeftIcon?: () => void;
	onPressLeftTitleIcon?: () => void;
	onPressRightIcon?: () => void;
	onPressInputIcon?: () => void;
}

let pressCount = 0;

const TopAppBar = (props: Props) => {
	const textRef: any = useRef();
	const [clickCount, setClickCount] = useState(1);
	const setIsHide = useGenericContentStore((state: any) => state.setIsHide);
	const setIsDebugVisible = useGenericContentStore((state: any) => state.setIsDebugVisible);
	const wheelPosition = localStorage.getItem(AsyncStorageKeys.wheelPosition);
	const styleHelper = useStyleHelpers();
	const styledTopBar = styleHelper?.topBar;

	const iconRender = () => (
		<StyledIconRenderer
			styledTopBar={styledTopBar}
			leftIconWidth={props.leftIconWidth}
			source={{ uri: props.leftIcon ? props.leftIcon : backIcon }}
		/>
	);

	const titleRender = () =>
		!props?.isHideLeftIcon ? (
			// @ts-ignores
			<StyledCenterImage>
				<TouchableOpacity onPress={props.onPressLeftTitleIcon}>
					{/* @ts-ignores */}
					<StyledIconContainer leftIconWidth={props.leftIconWidth} styledTopBar={styledTopBar}>
						{/* @ts-ignores */}
						<StyledIconRenderer
							styledTopBar={styledTopBar}
							leftIconWidth={props.leftIconWidth}
							source={{ uri: props.leftTitleIcon ? props.leftTitleIcon : backIcon }}
						/>
					</StyledIconContainer>
				</TouchableOpacity>
			</StyledCenterImage>
		) : (
			<StyledCloseContainer styledTopBar={styledTopBar} />
		);

	const showAdvanceSettings = () => {
		const newCount = clickCount + 1;
		setClickCount(newCount);
		if (clickCount === 10) {
			setIsHide(false);
			return setClickCount(1);
		}
	};

	const renderTitleLogo = () => {
		return props.titleLogo ? (
			<View style={props.isPreview ? styles.stylesPreview : {}}>
				<TouchableWithoutFeedback onPressIn={handleLogoPressIn}>
					<Image
						source={{ uri: props.titleLogo ? props.titleLogo : ridevueLogo }}
						resizeMode={"contain"}
						style={{ width: styledTopBar?.titleLogo?.width, height: styledTopBar?.titleLogo?.height }}
					/>
				</TouchableWithoutFeedback>
			</View>
		) : (
			<></>
		);
	};

	const renderTitle = () => {
		return (
			//@ts-ignore
			<StyledTextContainer addTitleMarginLeft={props.addTitleMarginLeft}>
				{!props.screenTitle && !props.titleLogo && !props.search ? (
					// @ts-ignores
					<StyledLoading styledTopBar={styledTopBar}>
						<ImgProgressBar />
					</StyledLoading>
				) : (
					<></>
				)}

				{props.screenTitle ? (
					<View>
						{/* @ts-ignores */}
						<StyledText>
							{props.isSettings && DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
								<Text
									onPress={showAdvanceSettings}
									fontSize={styledTopBar?.title.fontSize}
									style={[
										styles.screenLabel,
										props.screenTitleBody
											? { fontSize: styledTopBar?.assignToScreenTitle?.fontSize }
											: {
													fontSize: styledTopBar?.title?.fontSize,
													fontWeight: styledTopBar?.title?.fontWeight,
													lineHeight: styledTopBar?.title?.lineHeight,
											  },
									]}
									numberOfLines={1}
									ellipsizeMode="tail"
								>
									{props.screenTitle}
								</Text>
							) : (
								<Text
									fontSize={styledTopBar?.title.fontSize}
									style={[
										styles.screenLabel,
										props.screenTitleBody
											? { fontSize: styledTopBar?.assignToScreenTitle?.fontSize }
											: {
													fontSize: styledTopBar?.title?.fontSize,
													fontWeight: styledTopBar?.title?.fontWeight,
													lineHeight: styledTopBar?.title?.lineHeight,
											  },
									]}
									numberOfLines={1}
									ellipsizeMode="tail"
								>
									{props.screenTitle}
								</Text>
							)}

							<Text
								fontSize={styledTopBar?.title.fontSize}
								style={(styles.titleBody, { fontSize: styledTopBar?.titleBody?.fontSize })}
								numberOfLines={1}
								ellipsizeMode=""
							>
								{props.screenTitleBody}
							</Text>
						</StyledText>
					</View>
				) : (
					<></>
				)}
				{renderTitleLogo()}
				{props.search ? (
					// @ts-ignores
					<TextInput
						mode="outlined"
						style={(styledTopBar?.searchTextInput)}
						placeholder={props.placeholder}
						onChangeText={props.onChangeText}
						outlineColor={"gray"}
						activeOutlineColor={white_smoke}
						textColor={white_smoke}
						outlineStyle={styles.outlineStyle}
						placeholderTextColor={"gray"}
						value={props.inputValue}
						focusable={props.autoFocus}
						ref={textRef}
						right={
							props.inputValue ? (
								// @ts-ignores
								<TextInput.Icon
									icon={closeIcon}
									onPress={props.onPressInputIcon}
									style={{ marginTop: 22, marginRight: 50 }}
									iconColor={white_smoke}
									size={30}
								/>
							) : (
								<></>
							)
						}
					/>
				) : (
					<></>
				)}
			</StyledTextContainer>
		);
	};

	const renderWheelPositionRightLogo = () => {
		return (
			<>
				{props.rightImage && wheelPosition !== WheelPosition.RIGHT ? (
					<Image
						source={props.rightImageLandscape ? { uri: props.rightImageLandscape } : { uri: props.rightImagePortrait }}
						style={
							(styles.rightImage,
							props.rightImageLandscape
								? {
										width: styledTopBar?.landscapeImage?.width,
										height: styledTopBar?.landscapeImage?.height,
										marginRight: 122,
								  }
								: { width: styledTopBar?.portraitImage?.width, height: styledTopBar?.portraitImage?.height })
						}
					/>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderRightLogo = () => {
		return (
			// @ts-ignores
			<StyledCenterImage>
				{/* @ts-ignores */}
				{props.rightIcon ? (
					<TouchableOpacity onPress={props.onPressRightIcon}>
						<Image
							source={{ uri: props.rightIcon ? props.rightIcon : searchIcon }}
							style={(styles.backIcon, { width: styledTopBar?.searchIcon?.width, height: styledTopBar?.searchIcon?.height })}
						/>
					</TouchableOpacity>
				) : (
					<></>
				)}
				{props.rightLogo ? (
					//@ts-ignores
					<TouchableWithoutFeedback onPressIn={handleLogoPressIn}>
						<Image
							source={{ uri: props.rightLogo ? props.rightLogo : ridevueLogo }}
							resizeMode={"contain"}
							style={{ width: props.isSingleLogo ? 46 : logoWidth, height: props.isSingleLogo ? 46 : logoHeight }}
						/>
					</TouchableWithoutFeedback>
				) : (
					<></>
				)}
				{renderWheelPositionRightLogo()}
			</StyledCenterImage>
		);
	};

	const renderRightPackshot = () => {
		if (!props.rightImage) return;

		return (
			// @ts-ignores
			<StyledCenterImage>
				{/* @ts-ignores */}
				{props.rightImage ? (
					<Image
						source={props.rightImageLandscape ? { uri: props.rightImageLandscape } : { uri: props.rightImagePortrait }}
						style={[
							styles.rightImage,
							props.rightImageLandscape
								? {
										width: styledTopBar?.landscapeImage?.width,
										height: styledTopBar?.landscapeImage?.height,
										marginRight: 55,
								  }
								: { width: styledTopBar?.portraitImage?.width, height: styledTopBar?.portraitImage?.height },
						]}
					/>
				) : (
					<></>
				)}
			</StyledCenterImage>
		);
	};

	/**
	 * Set setTimeout to 5 seconds after timeout it will
	 * set the `setDialogVisible` to true
	 */
	const handleLogoPressIn = () => {
		pressCount = pressCount + 1;
		if (pressCount === 10) {
			setIsDebugVisible(true);
			pressCount = 0;
		}
	};

	const renderCloseContainer = () => {
		return !props.screenTitle && !props.titleLogo && !props.search ? <StyledCloseContainer styledTopBar={styledTopBar} /> : <></>;
	};

	const renderTopAppBarRight = () => {
		return (
			// @ts-ignores
			<StyledInnerContainer>
				{renderRightLogo()}
				{renderTitle()}
				{renderRightPackshot()}
				{props.search || (!props.isNav && props.screenTitle) ? titleRender() : <></>}
				{props.isShowClose ? (
					// @ts-ignore
					<StyledCenterImage hasSideBar={props.hasSideBar} styledTopBar={styledTopBar}>
						{/* @ts-ignore */}
						<TouchableWithoutFeedback onPress={props.onPressLeftIcon} onPressIn={handleLogoPressIn}>
							{/* @ts-ignores */}
							<StyledIconContainer leftIconWidth={!props.screenTitle && !props.search && props.leftIconWidth}>
								{/* @ts-ignores */}
								{props.screenTitle || props.titleLogo || props.search ? iconRender() : <></>}
							</StyledIconContainer>
						</TouchableWithoutFeedback>
					</StyledCenterImage>
				) : (
					<></>
				)}
			</StyledInnerContainer>
		);
	};

	const renderTopAppBarLeft = () => {
		return (
			// @ts-ignores
			<StyledInnerContainer>
				{props.isShowClose ? (
					// @ts-ignores
					<StyledCenterImage hasSideBar={props.hasSideBar} styledTopBar={styledTopBar}>
						{/* @ts-ignore */}
						<TouchableWithoutFeedback onPress={props.onPressLeftIcon} onPressIn={handleLogoPressIn}>
							{/* @ts-ignores */}
							<StyledIconContainer leftIconWidth={!props.screenTitle && !props.search && props.leftIconWidth}>
								{/* @ts-ignores */}
								{props.screenTitle || props.titleLogo || props.search ? iconRender() : <></>}
							</StyledIconContainer>
						</TouchableWithoutFeedback>
					</StyledCenterImage>
				) : (
					renderCloseContainer()
				)}
				{props.search || (!props.isNav && props.screenTitle) ? titleRender() : <></>}
				{renderTitle()}
				{renderRightLogo()}
			</StyledInnerContainer>
		);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted && props.autoFocus === true) {
			textRef.current.focus();
		}

		return () => {
			mounted = false;
		};
	}, [props.autoFocus]);

	return (
		// @ts-ignores
		<StyledContainer
			removeFixedPosition={props.removeFixedPosition}
			screenTitle={props.screenTitle}
			titleLogo={props.titleLogo}
			search={props.search}
			hasSideBar={props.hasSideBar}
			removeBackground={props.removeBackground}
			styledTopBar={styledTopBar}
			wheelPosition={wheelPosition}
		>
			{wheelPosition === WheelPosition.RIGHT ? renderTopAppBarRight() : renderTopAppBarLeft()}
		</StyledContainer>
	);
};
export default TopAppBar;
