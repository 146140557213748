import { useState, useEffect } from "react";
import useGenericContentStore from "../store/genericContent.store";
import { helpersByLayout } from "../StyleHelpers";
import { ScreenDimensions } from "../utils/enum";
import { AsyncStorageKeys } from "../Types";

function useStyleHelpers() {
	const defaultStyle = helpersByLayout.find((helper: any) => helper.screen === localStorage.getItem(ScreenDimensions.dimensions));
	const [currentStyleHelper, setCurrentStyleHelper] = useState(defaultStyle);
	const currentLayout = useGenericContentStore((state: any) => state.currentLayout);
	
	useEffect(() => {
		if (currentLayout) {
			let helpers: any = helpersByLayout.find((helper: any) => helper.screen === currentLayout);
			const session = localStorage.getItem(AsyncStorageKeys.session);
			if (session) {
				helpers = {...helpers, 
					paddingStart: helpers?.mainWithNav.paddingStart,
					paddingTop: helpers?.mainWithNav.paddingTop
				};
			}

			setCurrentStyleHelper(helpers);
		}
	}, [currentLayout]);

	return currentStyleHelper;
}

export default useStyleHelpers;
