import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import { globalAny } from "../../utils/Utils";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import { StyledRowButtonContainer } from "../../screens/Legals/styled";
import { LegalTermsNarrowScreen, LegalTermsWideScreen } from "./LegalTermScreen";

export const RenderButton = (props: any) => (
	<>
		{props.screenDimensions === ScreenDimensions.narrow ? (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				{!props?.isSettings ? (
					<TouchableButton
						componentTheme={ComponentThemeType.VinFast}
						type={ComponentTypeEnum.Primary}
						onPress={props.onPressNext}
						style={{ width: "100%" }}
					>
						{globalAny.language.agree}
					</TouchableButton>
				) : (
					<></>
				)}
				{props?.isSkipDataManagement ? (
					<></>
				) : (
					<TouchableButton
						componentTheme={ComponentThemeType.VinFast}
						type={ComponentTypeEnum.Secondary}
						onPress={props.onBackPress}
						style={{ width: "100%" }}
					>
						{globalAny.language.back}
					</TouchableButton>
				)}
			</StyledRowButtonContainer>
		) : (
			//@ts-ignore
			<StyledRowButtonContainer dimensions={props.screenDimensions}>
				{props?.isSkipDataManagement ? (
					<></>
				) : (
					<TouchableButton
						componentTheme={ComponentThemeType.VinFast}
						type={ComponentTypeEnum.Secondary}
						onPress={props.onBackPress}
						style={{ width: "100%" }}
					>
						{globalAny.language.back}
					</TouchableButton>
				)}
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={props.onPressNext}
					style={{ width: "100%" }}
				>
					{globalAny.language.agree}
				</TouchableButton>
			</StyledRowButtonContainer>
		)}
	</>
);

const LegalTerms = (props: any) => {
	const renderScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<LegalTermsNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.wide) {
			return (
				<MainWideWrapper>
					<LegalTermsWideScreen {...props} />
				</MainWideWrapper>
			);
		}

		return (
			<MainDefaultWrapper>
				<LegalTermsWideScreen {...props} />
			</MainDefaultWrapper>
		);
	};

	if (!props.screenDimensions) return <></>;

	return renderScreenDimensions();
};
export default LegalTerms;
