import { Snackbar } from "react-native-paper";
import { peacock_blue, toastError, toastSuccess, toastWarning, white, scorpion } from "../../StyleHelpers";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import styled, { css } from "styled-components/native";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { useEffect, useRef, useState } from "react";
import { Animated, PanResponder, Dimensions } from "react-native";

interface Props {
	type?: ComponentTypeEnum;
	visible: boolean;
	onDismissSnackBar?: any;
	text?: string;
	label?: any;
	duration?: any;
	onPress?: any;
}

let hideToast: any;

const StyledContainer = styled.View`
	bottom: 24px;
	left: 13.5%;
	z-index: 1;
	position: fixed;
	width: 74%;
	transition: all 0.3s ease;
`;

const StyledFont = styled.View<Props>`
	user-select: none;
	${(props) =>
		!props.label &&
		css`
			text-align: center;
			align-items: center;
			justify-content: center;
		`}
`;

const StyledText = styled.Text`
	font-family: ${FONT_FAMILY_DEFAULT};
	font-size: 25px;
	font-weight: 300;
	color: white;
`;

const Toast = ({ visible, onDismissSnackBar, text, label, onPress, type, duration }: Props) => {
	const [toastVisibility, setToastVisibility] = useState(visible);
	const [position, setPosition] = useState("bottom");
	const [range, setRange] = useState(-300);

	const showSnackbar = () => {
		clearTimeout(hideToast);
		setPosition("bottom");
		Animated.timing(animatedValue.current, {
			toValue: 1,
			useNativeDriver: true,
		}).start();
	};

	const hideSnackbar = () => {
		setPosition("right");
		setRange(-2500);
		hideToast = setTimeout(() => {
			onDismissSnackBar();
		}, 500);

		Animated.timing(animatedValue.current, {
			toValue: 0,
			useNativeDriver: true,
		}).start();
	};

	const snackBarStyle = () => {
		if (type === ComponentTypeEnum.Primary) {
			return { backgroundColor: toastSuccess, borderRadius: 16 };
		} else if (type === ComponentTypeEnum.Secondary) {
			return { backgroundColor: toastWarning, borderRadius: 16 };
		} else if (type === ComponentTypeEnum.Tertiary) {
			return { backgroundColor: peacock_blue, borderRadius: 16 };
		} else if (type === ComponentTypeEnum.Processing) {
			return { backgroundColor: scorpion, borderRadius: 16 };
		} else if (type === ComponentTypeEnum.Error) {
			return { backgroundColor: toastError, borderRadius: 16 };
		} else {
			return { backgroundColor: toastError, borderRadius: 16 };
		}
	};

	const [snackbarPosition, setSnackbarPosition] = useState(new Animated.ValueXY());
	const SCREEN_WIDTH = Dimensions.get("window").width;
	const panResponder = useRef(
		PanResponder.create({
			onMoveShouldSetPanResponder: () => true,
			onPanResponderMove: (event, gestureState) => {
				setSnackbarPosition(new Animated.ValueXY({ x: gestureState.dx, y: 0 }));
			},
			onPanResponderRelease: (event, gestureState) => {
				if (gestureState.dx > SCREEN_WIDTH * 0.5) {
					hideSnackbar();
				} else {
					setSnackbarPosition(new Animated.ValueXY({ x: 0, y: 0 }));
					Animated.spring(snackbarPosition, {
						toValue: { x: 0, y: 0 },
						useNativeDriver: true,
					}).start();
				}
			},
		})
	).current;

	useEffect(() => {
		if (visible) {
			setSnackbarPosition(new Animated.ValueXY({ x: 0, y: 0 }));
			showSnackbar();
			hideToast = setTimeout(() => {
				showSnackbar();
				hideSnackbar();
			}, duration || Snackbar.DURATION_SHORT);
		}
		setToastVisibility(visible);
	}, [visible]);

	const animatedValue = useRef(new Animated.Value(0));
	return (
		//@ts-ignores
		<StyledContainer>
			<Animated.View
				style={{
					transform: [{ translateX: snackbarPosition.x }, { translateY: snackbarPosition.y }],
				}}
				{...panResponder.panHandlers}
			>
				<Animated.View
					style={{
						[position]: animatedValue.current.interpolate({ inputRange: [0, 1], outputRange: [range, 0] }),
					}}
				>
					<Snackbar
						wrapperStyle={{ bottom: 0, position: "absolute" }}
						visible={toastVisibility}
						onDismiss={()=> {}}
						action={{
							label: label,
							onPress: onPress,
							textColor: white,
							labelStyle: { fontSize: 25, fontWeight: "300" },
						}}
						style={snackBarStyle()}
					>
						{/* @ts-ignores  */}
						<StyledFont label={label}>
							{/* @ts-ignores */}
							<StyledText>{text}</StyledText>
						</StyledFont>
					</Snackbar>
				</Animated.View>
			</Animated.View>
		</StyledContainer>
	);
};

export default Toast;
