import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import { ScreenDimensions } from "../../utils/enum";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { capitalize, globalAny } from "../../utils/Utils";
import TextInput from "../../components/TextInput";
import { RecoverPassDefaultScreen, RecoverPassNarrowScreen, RecoverPassWideScreen } from "./RecoverPasswordLayout";
import { StyledRowButtonContainer } from "./styled";

export const RenderTextInput = (props: any) => (
	<TextInput
		componentTheme={ComponentThemeType.VinFast}
		label={globalAny.language.enter_email_ca}
		placeholder="your@email.com"
		onChangeText={(event) => props.onEnterEmail(event)}
		error={props.emailError}
		errorMessage={props.emailMessage}
		autoFocus={true}
	/>
);

export const RenderButton = (props: any) => (
	//@ts-ignore
	<StyledRowButtonContainer>
		<TouchableButton
			disabled={props.disableBtn}
			componentTheme={ComponentThemeType.VinFast}
			type={ComponentTypeEnum.Primary}
			onPress={() => props.onSubmit()}
		>
			{capitalize(globalAny.language.send_temporary_password)}
		</TouchableButton>
	</StyledRowButtonContainer>
);

const RecoverPassword = (props: any) => {
	const renderRecoverScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<RecoverPassNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.default) {
			return (
				<MainDefaultWrapper>
					<RecoverPassDefaultScreen {...props} />
				</MainDefaultWrapper>
			);
		}

		return (
			<MainWideWrapper>
				<RecoverPassWideScreen {...props} />
			</MainWideWrapper>
		);
	};

	return renderRecoverScreenDimensions();
};
export default RecoverPassword;
