import {
	StyledButton,
	StyledButtonContainer,
	StyledButtonNarrowContainer,
	StyledMainNarrow,
	StyledMainWide,
	StyledTextContainer,
	StyledTextNarrowContainer,
} from "./styled";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";
import { RenderButton, RenderTextInput } from "./ChangePassword";

export const ChangePassWideScreen = (props: any) => {
	return (
		//@ts-ignore
		<StyledMainWide>
			{/* @ts-ignore */}
			<StyledTextContainer>
				<RenderTextInput {...props} />
			</StyledTextContainer>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				<RenderButton {...props} />
				<PrivacyPolicyButton removeFixedPosition={true} onPress={() => props.onPrivacy()} />
			</StyledButtonContainer>
		</StyledMainWide>
	);
};

export const ChangePassNarrowScreen = (props: any) => {
	return (
		//@ts-ignore
		<StyledMainNarrow>
			{/* @ts-ignore */}
			<StyledButtonNarrowContainer>
				{/* @ts-ignore */}
				<StyledButton>
					<RenderButton {...props} />
				</StyledButton>
				<PrivacyPolicyButton removeFixedPosition={true} onPress={() => props.onPrivacy()} />
			</StyledButtonNarrowContainer>
			{/* @ts-ignore */}
			<StyledTextNarrowContainer>
				<RenderTextInput {...props} />
			</StyledTextNarrowContainer>
		</StyledMainNarrow>
	);
};

export const ChangePassDefaultScreen = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledTextContainer>
				<RenderTextInput {...props} />
			</StyledTextContainer>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				<RenderButton {...props} />
				<PrivacyPolicyButton removeFixedPosition={true} onPress={() => props.onPrivacy()} />
			</StyledButtonContainer>
		</>
	);
};
