import { AsyncStorageKeys } from "../Types";
import ENVIRONMENT from "../config/ENVIRONMENT";
import { DEFAULT_ENV } from "../config/ENV_CONFIG";

export class AppLogger {

    static _isProduction = DEFAULT_ENV === ENVIRONMENT.PRODUCTION;

    /**
     * Same as console.log
     * @param message - error message
     * @param optional - optional parameters
     */
    static log (message: any, ...optional: any) {
        if (!AppLogger._isProduction || localStorage.getItem(AsyncStorageKeys.enableLogs) == 'true') console.log(message, ...optional);
    };

    /**
     * Same as console.error
     * @param message - error message
     * @param optional - optional parameters
     */
    static error (message: any, ...optional: any) {
        console.error(message, ...optional);
    };

    /**
     * Same as console.warn
     * @param message - error message
     * @param optional - optional parameters
     */
    static warn (message: any, ...optional: any) {
        console.warn(message, ...optional);
    };

    /**
     * Same as console.table
     * @param data - The data to display. This must be either an array or an object.
     * @param columns - An array containing the names of columns to include in the output.
     */
    static table (data: any, columns?: any) {
        !AppLogger._isProduction && console.table(data, columns);
    };
};