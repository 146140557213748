import { create } from "zustand";
import { devtools } from "zustand/middleware";
import * as CacheStorageUtils from "../utils/CacheStorageUtils";
import { AppLogger } from "../utils/AppLogger";
import { featureContent, playlistContent, vamPlaylistContent, metadataContent, metadataContentPreview } from "../services/movieContentService";

export interface MovieContentState {
	categoriesPlaylist: any;
	vamCategoriesPlaylist: any;
	featuredPlaylists: { data: []; status: string };
	titleDetails: any;
	status: "idle" | "loading" | "failed" | "success";
}

export interface ModelMovieContentStore {
	data: MovieContentState;
	fetchVamCategoriesPlaylist: (playlistId: string, transactionalFilters: any) => void;
	fetchCategoriesPlaylist: (playlistId: string, transactionalFilters: any) => void;
	fetchFeaturedPlaylists: (categoryId: any, transactionalFilters: any) => void;
	fetchTitleDetails: (playlistId: string, reload: boolean) => void;
	fetchTitleDetailsPreview: (playlistId: string, reload: boolean) => any;
}

const initialState: MovieContentState = {
	categoriesPlaylist: {},
	vamCategoriesPlaylist: {},
	titleDetails: { status: "idle", titles: {} },
	featuredPlaylists: { data: [], status: "idle" },
	status: "idle",
};

const useMovieContentStore = create<ModelMovieContentStore>()(
	devtools((set, get) => ({
		data: initialState,
		fetchFeaturedPlaylists: async (categoryId, transactionalFilters) => {
			const stateMovieContent = get().data;
			set(
				(state) => ({
					data: { ...state.data, status: "loading", featuredPlaylists: { ...stateMovieContent.featuredPlaylists, status: "loading" } },
				})
			);

			let result: any = await CacheStorageUtils.getCacheItem(`featuredPlaylists`, true);

			try {
				if (!result) {
					result = await featureContent("home", transactionalFilters);
				}

				set(
					(state) => ({ data: { ...state.data, status: "success", featuredPlaylists: { data: result, status: "success" } } })
				);
				await CacheStorageUtils.setCacheItem(`featuredPlaylists`, result, true);
			} catch (ex) {
				AppLogger.error("failed to fetch featured playlists: ", ex);
				set(
					(state) => ({
						data: { ...state.data, status: "failed", featuredPlaylists: { ...stateMovieContent.featuredPlaylists, status: "failed" } },
					})
				);
			}
		},
		fetchCategoriesPlaylist: async (playlistId, transactionalFilters) => {
			const stateMovieContent = get().data;

			set((state) => ({ data: { ...state.data, status: "loading" } }));

			if (stateMovieContent.categoriesPlaylist[playlistId]) {
				return;
			}

			let playlistResult: any = await CacheStorageUtils.getCacheItem(`playlist_${playlistId}`, true);

			try {
				if (!playlistResult) {
					playlistResult = await playlistContent(playlistId, transactionalFilters);
				}

				set(
					(state) => ({
						data: {
							...state.data,
							status: "success",
							categoriesPlaylist: { [playlistId]: playlistResult },
						},
					})
				);
				await CacheStorageUtils.setCacheItem(`playlist_${playlistId}`, playlistResult, true);
			} catch (ex) {
				AppLogger.error("failed to fetch categories playlists: ", ex);
				set((state) => ({ data: { ...state.data, status: "failed" } }));
			}
		},
		fetchVamCategoriesPlaylist: async (playlistId, transactionalFilters) => {
			const stateMovieContent = get().data;

			set((state) => ({ data: { ...state.data, status: "loading" } }));

			if (stateMovieContent.vamCategoriesPlaylist[playlistId]) {
				return;
			}

			let playlistResult: any = await CacheStorageUtils.getCacheItem(`playlist_${playlistId}`, true);

			try {
				if (!playlistResult) {
					playlistResult = await vamPlaylistContent(playlistId, transactionalFilters);
				}

				set(
					(state) => ({
						data: {
							...state.data,
							status: "success",
							vamCategoriesPlaylist: { [playlistId]: playlistResult },
						},
					})
				);

				await CacheStorageUtils.setCacheItem(`playlist_${playlistId}`, playlistResult, true);
			} catch (ex) {
				AppLogger.error("failed to fetch categories playlists: ", ex);
				set((state) => ({ data: { ...state.data, status: "failed" } }));
			}
		},
		fetchTitleDetails: async (parentProductId, reload) => {
			const stateMovieContent = get().data;
			set((state) => ({ data: { ...state.data, status: "loading" } }));
			if (!reload) {
				if (
					stateMovieContent.titleDetails.titles[parentProductId]?.images ||
					stateMovieContent.titleDetails.titles[parentProductId]?.metadata ||
					stateMovieContent.titleDetails.titles[parentProductId]?.ownedstatus ||
					stateMovieContent.titleDetails.titles[parentProductId]?.vam
				) {
					return;
				}
			}

			let result: any = await CacheStorageUtils.getCacheItem(`titledetails_${parentProductId}`, true);

			try {
				if (!result) {
					result = await metadataContent(parentProductId);
				}
				set(
					(state) => ({
						data: {
							...state.data,
							status: "success",
							titleDetails: { ...stateMovieContent.titleDetails, status: "success", titles: { [parentProductId]: result } },
						},
					})
				);
				// Uncomment this if TDP needs to be cached.
				// await CacheStorageUtils.setCacheItem(`titledetails_${parentProductId}`, result, true);
			} catch (ex) {
				AppLogger.error("failed to fetch categories playlists: ", ex);
				set((state) => ({ data: { ...state.data, status: "failed" } }));
			}
		},
		fetchTitleDetailsPreview: async (parentProductId, reload) => {
			const stateMovieContent = get().data;
			set((state) => ({ data: { ...state.data, status: "loading" } }));
			if (!reload) {
				if (
					stateMovieContent.titleDetails.titles[parentProductId]?.images ||
					stateMovieContent.titleDetails.titles[parentProductId]?.metadata ||
					stateMovieContent.titleDetails.titles[parentProductId]?.ownedstatus ||
					stateMovieContent.titleDetails.titles[parentProductId]?.vam
				) {
					return;
				}
			}

			let result: any = await CacheStorageUtils.getCacheItem(`titledetails_${parentProductId}_preview`, true);

			try {
				if (!result) {
					result = await metadataContentPreview(parentProductId);
				}
				set(
					(state) => ({
						data: {
							...state.data,
							status: "success",
							titleDetails: { ...stateMovieContent.titleDetails, status: "success", titles: { [parentProductId]: result } },
						},
					})
				);
			} catch (ex) {
				AppLogger.error("failed to fetch categories playlists: ", ex);
				set((state) => ({ data: { ...state.data, status: "failed" } }));
			}
		},
	}))
);

export default useMovieContentStore;
