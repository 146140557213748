import { light_black, spacing_l, spacing_s, spacing_xs } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import { View, Text } from "react-native";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import { globalAny } from "../../utils/Utils";

interface Props {
	data?: any;
	banner?: any;
}

const StyledContainer = styled.View<Props>`
	flex-direction: row;
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-repeat: no-repeat;
			background-size: cover;
		`}
	${(props) =>
		!props.banner &&
		css`
			background-color: ${light_black};
		`}
	height: 288px;
`;

const StyledText = styled.View`
	padding: 55px 0 0 ${spacing_l};
	background-color: ${light_black};
	width: 100%;
	height: 100%;
`;

const StyledSecondaryText = styled.View`
	padding: 0 0 0 ${spacing_l};
	background-color: ${light_black};
	width: 40%;
`;

const StyledImage = styled.View`
	width: 60%;
	height: 100%;
	background-image: linear-gradient(to left, transparent, #141414);
`;

const StyledGradient = styled.View`
	width: 100%;
	height: 90px;
	background-image: linear-gradient(to bottom, transparent, #141414);
	position: absolute;
	bottom: 0;
`;

const StyledGridPackshots = styled.View`
	width: 100%;
	padding-top: ${spacing_xs};
	margin-left: -115px;
`;

const Banner = (props: Props) => {
	return (
		<View>
			{/* @ts-ignore */}
			<StyledContainer banner={props.banner}>
				{/* @ts-ignore */}
				<StyledText>
					<Text
						numberOfLines={1}
						ellipsizeMode="tail"
						style={{ width: 470, color: "white", fontFamily: FONT_FAMILY_DEFAULT, fontSize: 30, marginBottom: spacing_s }}
					>
						{globalAny.language.empty_library}
					</Text>
					<Text
						numberOfLines={2}
						ellipsizeMode="tail"
						style={{ width: 470, color: "white", fontFamily: FONT_FAMILY_DEFAULT, fontSize: 25 }}
					>
						{globalAny.language.bookmarks_content}
					</Text>
				</StyledText>
				{/* @ts-ignore */}
				<StyledImage>
					<StyledGradient></StyledGradient>
				</StyledImage>
			</StyledContainer>
			{/* @ts-ignore */}
			<StyledSecondaryText>
				{props?.data && props?.data.length > 0 ? (
					<Text style={{ width: 495, color: "white", fontFamily: FONT_FAMILY_DEFAULT, fontSize: 25 }}>
						{globalAny.language.popular_titles}
					</Text>
				) : (
					<></>
				)}
				{/* @ts-ignore */}
			</StyledSecondaryText>
			{props?.data && (
				// @ts-ignore
				<StyledGridPackshots>
					{props?.data?.length > 0 ? (
						<PlaylistContainer startIndex={0} setStartIndex={""} setIsShowLoader={""} categories={props?.data} hideTitle={true} />
					) : (
						<></>
					)}
				</StyledGridPackshots>
			)}
		</View>
	);
};

export default Banner;
