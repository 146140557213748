import { useEffect, useMemo, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { routeHome, routeMyLibrary, routeNewSettings, routeScreenManager, routeNewCuration } from "../../Routes";
import homeIcon from "../../assets/SideBarIcon/home.svg";
import screenManagerIcon from "../../assets/SideBarIcon/screen-manager.svg";
import libraryIcon from "../../assets/SideBarIcon/library.svg";
import curationIcon from "../../assets/SideBarIcon/curation.svg";
import settingsIcon from "../../assets/SideBarIcon/settings.svg";
import Image from "../Image/Image";
import { FlatList } from "react-native";
import { AsyncStorageKeys, Pages } from "../../Types";
import useGenericContentStore from "../../store/genericContent.store";
import { globalAny, isStaging } from "../../utils/Utils";
import { StyledContainer, StyledMain } from "./styled";
import { SelectedNav } from "../../utils/enum";
import useStyleHelpers from "../../hooks/useStyleHelpers";

const SideNav = (props: any) => {
	const [highlightHome, setHighlightHome] = useState(false);
	const [highlightScreenManager, setHighlightScreenManager] = useState(false);
	const [highlightLibrary, setHighlightLibrary] = useState(false);
	const [highlightCuration, setHighlightCuration] = useState(false);
	const [highlightSettings, setHighlightSettings] = useState(false);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const wheelPosition = localStorage.getItem(AsyncStorageKeys.wheelPosition);
	const styleHelper = useStyleHelpers();
	const styledSideBar = styleHelper?.sideBar;

	const navigation = useNavigation();
	const item = isStaging()
		? [
				{ name: SelectedNav.Home, icon: homeIcon, highlight: highlightHome },
				{ name: SelectedNav.ScreenManager, icon: screenManagerIcon, highlight: highlightScreenManager },
				{ name: SelectedNav.Library, icon: libraryIcon, highlight: highlightLibrary },
				{ name: SelectedNav.Curation, icon: curationIcon, highlight: highlightCuration },
				{ name: SelectedNav.Settings, icon: settingsIcon, highlight: highlightSettings },
		  ]
		: [
				{ name: SelectedNav.Home, icon: homeIcon, highlight: highlightHome },
				{ name: SelectedNav.Library, icon: libraryIcon, highlight: highlightLibrary },
				{ name: SelectedNav.Curation, icon: curationIcon, highlight: highlightCuration },
				{ name: SelectedNav.Settings, icon: settingsIcon, highlight: highlightSettings },
		  ];

	useEffect(() => {
		let mounted = true;

		if (mounted) onSelectedHighlight(props.currentSelected);

		return () => {
			mounted = false;
		};
	}, []);

	const onSelectedHighlight = (value: SelectedNav | string) => {
		setHighlightHome(value === globalAny.language.header_home);
		setHighlightScreenManager(value === SelectedNav.ScreenManager);
		setHighlightLibrary(value === SelectedNav.Library);
		setHighlightCuration(value === SelectedNav.Curation);
		setHighlightSettings(value === globalAny.language.header_settings);
	};

	const onSelectedNav = (name: any) => {
		switch (name) {
			case SelectedNav.Home:
				//@ts-ignore
				navigation.navigate(routeHome);
				setSelectedTabs(0);
				break;

			case SelectedNav.ScreenManager:
				//@ts-ignore
				navigation.navigate(routeScreenManager, { location: Pages.main, parentProductId: Pages.controller, redirectTo: "screen" });
				setSelectedTabs(0);
				break;

			case SelectedNav.Settings:
				//@ts-ignore
				navigation.navigate(routeNewSettings);
				setSelectedTabs(0);
				break;

			case SelectedNav.Curation:
				//@ts-ignore
				navigation.navigate(routeNewCuration);
				setSelectedTabs(0);
				break;

			case SelectedNav.Library:
				//@ts-ignore
				navigation.navigate(routeMyLibrary);
				setSelectedTabs(0);
				break;

			default:
				break;
		}
	};

	const renderIcon = ({ item }: any) => {
		return (
			//@ts-ignore
			<StyledContainer key={`sidenav-list-${item.name}`} styledSideBar={styledSideBar} highlight={item.highlight} onPress={() => onSelectedNav(item.name)}>
				<Image
					source={{ uri: item.icon }}
					style={{ width: styledSideBar?.imageIcon?.width, height: styledSideBar?.imageIcon?.height }}
					key={`sidenav-icon-${item.name}`}
				/>
			</StyledContainer>
		);
	};

	const renderNav = useMemo(
		() => (
			<FlatList
				showsVerticalScrollIndicator={false}
				data={item}
				extraData={item}
				renderItem={renderIcon}
				keyExtractor={(item: any) => item.name}
				style={{ width: "100%" }}
			/>
		),
		[item]
	);

	return (
		<StyledMain wheelPosition={wheelPosition} styledSideBar={styledSideBar}>
			{renderNav}
		</StyledMain>
	);
};

export default SideNav;
