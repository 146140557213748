import { ScreenDimensions } from "./utils/enum";

export const PRESSED_BUTTON_COLOR = "#313131";

//Global Color
export const wisteria = "#9575cd";
export const deep_aqua = "#0a8470";
export const deep_brown = "#360000";
export const mid_blue = "#2362be";
export const palladium = "#b2b2b2";
export const light_peach = "#f4d1d1";
export const cocoa = "#804848";
export const light_black = "#141414";
export const tealish = "#1bbc9b";
export const white = "#fff";
export const black = "#000";
export const peacock_blue = "#0045ac";
export const navy_blue = "#0A1D39";
export const royal_blue = "rgba(39, 86, 156, 0.3)";
export const salmon = "#ef877f";
export const dull_red = "#c4443a";
export const white_smoke = "#f2f2f2";
export const macaroni_and_cheese = "#fec32d";
export const muted_pink = "#e16490";
export const lipstick = "#d32b6c";
export const underpass_shrine = "#cc431f";
export const void_color = "rgba(10, 29, 57, 0.95)";
export const gray = "#1e1e1e";
export const separator_gray = "#3f3f3f";
export const toastSuccess = "#008131";
export const toastWarning = "#D07000";
export const toastError = "#FF0000";
export const shakaPlayColor = "#1e4cad";
export const highlightColor = "#008BEF";
export const silver = "#c0c0c0";
export const gray_one = "#030303";
export const royal_blue_hex = "#002356";
export const scorpion = "#5A5A5A";
export const dark_gray = "#363636";
export const dark = "rgba(51, 51, 51, 1)";

//Sync group color
export const purple_blue = "#8c2bd3";
export const aloha = "#1bbc9b";
export const love = "#e16490";

//Global Spacing
export const spacing_xxs = "4px";
export const spacing_xs = "8px";
export const spacing_s = "16px";
export const spacing_m = "24px";
export const spacing_l = "32px";
export const spacing_xl = "40px";
export const spacing_xxl = "48px";

//Global Spacing vw
export const spacing_xxs_vw = "0.20vw";
export const spacing_xs_vw = "0.41vw";
export const spacing_s_vw = "0.83vw";
export const spacing_m_vw = "1.25vw";
export const spacing_l_vw = "1.66vw";
export const spacing_xl_vw = "2.08vw";
export const spacing_xxl_vw = "2.5vw";

//Global Spacing vh
export const spacing_xxs_vh = "0.43vh";
export const spacing_xs_vh = "0.86vh";
export const spacing_s_vh = "1.72vh";
export const spacing_m_vh = "2.58vh";
export const spacing_l_vh = "3.44vh";
export const spacing_xl_vh = "4.30vh";
export const spacing_xxl_vh = "6.24vh";

//Media Query Breakpoint
export const sm = "640px";
export const md = "768px";
export const lg = "1024px";
export const xl = "1280px";
export const x2l = "1536px";

//Global Minimum Width
export const minWidth = "1024px";

// TextInputWithButtons component base sizes
export const textInputWithButtonsFontSize = 36;
export const textInputWithButtonsButtonWidth = 512;
export const textInputWithButtonsButtonHeight = 128;
export const textInputWithButtonsSmallIconWidth = 191;
export const textInputWithButtonsSmallIconHeight = 128;
export const textInputWithButtonsLargeIconWidth = 439;
export const textInputWithButtonsLargeIconHeight = 135;
export const textInputWithButtonsBoxWidth = 904;
export const textInputWithButtonsBoxHeight = 128;
export const textInputWithButtonsButtonUnderlay = "#121212";

//CreateAccount base sizes
export const textInputWidth = 904;
export const textInputHeight = 128;
export const subLabelWidth = 904;
export const subLabelHeight = 0;
export const topLabelWidth = 904;
export const topLabelHeight = 128;
export const subLabelFontSize = 16;
export const subLabelFontsize = 15;
export const labelMargin = 21;
export const legalTitleFontSize = 32;
export const legalBodyFontSize = 25;
export const displayLegalDocIconHeight = 76;
export const displayLegalDocIconWidth = 76;

//Create Account Styles
export const legalTextOpacity = 0.6;

// header base sizes
export const pageTitleFontSize = 36;
export const logoHeight = 46;
export const logoWidth = 297.26;

// back button
export const backButtonWidth = 17.2;
export const backButtonHeight = 30;
export const backButtonFontSize = 24;

// Eye Button
export const eyeWidth = 60;
export const eyeHight = 60;

export const breakPointsByLayout = [
	{
		screen: "wide",
		layout: ScreenDimensions.wide,
		aspectRation: "16:9",
		dimensions: [
			{ screenName: "Central 17.7", width: 3088, height: 1365, scale: 1.0 },
			{ screenName: "Central 12.8", width: 2504, height: 1162, scale: 1.0 },
			{ screenName: "Rear seats 13.1", width: 1920, height: 960, scale: 1.0 },
			{ screenName: "Rear seats 11.6", width: 1280, height: 600, scale: 0.7 },
			{ screenName: "wide", width: 1920, height: 792, scale: 1.0 },
		],
	},
	{
		screen: "sync",
		aspectRation: "4:3",
		layout: ScreenDimensions.sync,
		dimensions: [
			{ screenName: "SYNC 4", width: 800, height: 765, zoom: 0.6 },
		],
	},
	{
		screen: "default",
		aspectRation: "4:3",
		layout: ScreenDimensions.default,
		dimensions: [
			{ screenName: "Central 12.8", width: 1888, height: 1365, scale: 1.0 },
			{ screenName: "Central 11.9", width: 1624, height: 1365, scale: 1.0 },
			{ screenName: "default", width: 1336, height: 792, scale: 1.0 },
		],
	},
	{
		screen: "narrow",
		aspectRation: "4:1",
		layout: ScreenDimensions.narrow,
		dimensions: [
			{ screenName: "Central 12.3", width: 1920, height: 720, scale: 1.0 },
			{ screenName: "Central 10.25", width: 1920, height: 720, scale: 1.0 },
			{ screenName: "Co-Driver 12.3", width: 2400, height: 780, scale: 1.8 },
			{ screenName: "narrow", width: 1600, height: 411, scale: 1.0 },
		],
	},
];

export const helpersByLayout = [
	{
		screen: "sync",
		layout: ScreenDimensions.sync,
		columnGap: "1.5rem",
		rowGap: "2rem",
		paddingTop: 0,
		paddingStart: "5.75rem",
		paddingEnd: "11.75rem",
		fullHeight: "calc(100vh + calc(100vh * 0.6))",
		playlistTitle: {
			fontSize: 25,
			lineHeight: 28,
		},
		hka: {
			width: "29.125rem",
			height: "16.375rem",
		},
		packshot: {
			width: "16.875rem",
			height: "25.3125rem",
		},
		banner: {
			height: "18.0625rem",
			width: "calc(100vw + calc(100vw * 0.6) - 8rem)",
			lineOne: {
				fontSize: "2.3125rem",
				lineHeight: "2.75rem",
				clamp: 2,
			},
			lineTwo: {
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
				clamp: 3,
			},
		},
		button: {
			borderRadius: "1rem",
			fontSize: "1.5625rem",
			lineHeight: "1.75rem",
		},
		mainWithNav: {
			paddingStart: "9.5rem",
			paddingTop: "7rem",
		},
		landing: {
			logo: {
				width: "24rem",
				height: "13.9375rem",
			},
		},
		sideBar: {
			top: "7rem",
			main: {
				width: "7.5rem",
			},
			container: {
				width: "7.5rem",
				height: "7rem",
			},
			imageIcon: {
				width: "3.5rem",
				height: "3.5rem",
			},
		},
		topBar: {
			container: {
				height: "7rem",
				paddingLeft: 0,
				iconHeight: "7rem",
				iconRenderer: "3.5rem",
				iconWidth: "7.5rem",
			},
			titleBody: {
				fontSize: "1.125rem",
			},
			title: {
				fontSize: "3.125rem",
				fontWeight: 500,
				lineHeight: "3.75rem",
			},
			assignToScreenTitle: {
				fontSize: "1.875rem",
			},
			searchTextInput: {
				height: "4rem",
				fontSize: 30,
				lineHeight: 33,
				backgroundColor: light_black,
			},
			titleLogo: {
				width: "18.57875rem",
				height: "2.875rem",
			},
			searchIcon: {
				width: "7.5rem",
				height: "7rem",
			},
			portraitImage: {
				width: "3.5rem",
				height: "5.5rem",
			},
			landscapeImage: {
				width: "9.75rem",
				height: "5.5rem",
			},
		},
		shortClipsBanner: {
			height: "18rem",
			width: "calc(100% - 7rem)",
			paddingLeft: "7rem",
			gradient: {
				width: "81.852rem",
			},
			text: {
				paddingLeft: "2rem",
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
			},
		},
		vamTitle: {
			fontSize: "1.3125rem",
			lineHeight: "1.4375rem",
		},
		tabsView: {
			top: "7rem",
			paddingTop: "7rem",
			paddingLeft: "7.5rem",
			font: {
				fontFamily: "Nunito",
				size: "1.5625rem",
				lineHeight: "1.75rem",
			},
		},
		settings: {
			container: "2.5rem",
			gap: "1.5rem",
			textWidth: "44rem",
			loading: {
				height: "18.0625rem",
				gap: "2em",
			},
			font: {
				fontFamily: "Nunito",
				buttonSize: "1.5625rem",
				headerSize: "1.875rem",
				headerLineHeight: "2.0625rem",
				subTextSize: "1.5625rem",
				subTextLineHeight: "1.75rem",
			},
		},
		gridSize: {
			hka: 3,
			packshot: 4,
		},
		vam: {
			width: "23rem",
			height: "14.9rem",
		},
		typographyTitle: {
			fontSize: "2.3125rem",
			lineHeight: "2.75rem",
			gap: "1rem",
		},
		typographyBody: {
			fontSize: "1.5625rem",
			lineHeight: "1.75rem",
		},
		textArea: {
			gap: "2.5rem",
		},
		sectionButton: {
			gap: "1rem",
		},
		textError: {
			fontSize: "1.125rem",
			lineHeight: "1.25rem",
		},
		tdp: {
			textTemplateWidth: "32.9rem",
			packshotGap: "10rem",
			height: "calc(100% - 182px)",
			paddingLeft: "5.75rem",
			ratings: {
				fontSize: "1.125rem",
				lineHeight: "1.25rem",
			},
			synopsis: {
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
				height: "5.5rem",
			},
			extras: {
				paddingLeft: "6rem",
				paddingRight: "6rem",
				mainContainer: {
					paddingRight: "5.75rem",
					paddingLeft: "5.75rem",
				}
			},
		},
		aggregate: {
			paddingRight: "5.75rem",
			paddingLeft: "5.75rem",
		},
	},
	{
		screen: "default",
		layout: ScreenDimensions.default,
		columnGap: "1.5rem",
		rowGap: "2rem",
		paddingTop: 0,
		paddingStart: "5.75rem",
		paddingEnd: "11.75rem",
		playlistTitle: {
			fontSize: 25,
			lineHeight: 28,
		},
		hka: {
			width: "29.125rem",
			height: "16.375rem",
		},
		packshot: {
			width: "16.875rem",
			height: "25.3125rem",
		},
		banner: {
			height: "18.0625rem",
			width: "calc(100vw - 11.75rem)",
			lineOne: {
				fontSize: "2.3125rem",
				lineHeight: "2.75rem",
				clamp: 2,
			},
			lineTwo: {
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
				clamp: 3,
			},
		},
		button: {
			borderRadius: "1rem",
			fontSize: "1.5625rem",
			lineHeight: "1.75rem",
		},
		mainWithNav: {
			paddingStart: "9.5rem",
			paddingTop: "7rem",
		},
		landing: {
			logo: {
				width: "24rem",
				height: "13.9375rem",
			},
		},
		sideBar: {
			top: "7rem",
			main: {
				width: "7.5rem",
			},
			container: {
				width: "7.5rem",
				height: "7rem",
			},
			imageIcon: {
				width: "3.5rem",
				height: "3.5rem",
			},
		},
		topBar: {
			container: {
				height: "7rem",
				paddingLeft: 0,
				iconHeight: "7rem",
				iconRenderer: "3.5rem",
				iconWidth: "7.5rem",
			},
			titleBody: {
				fontSize: "1.125rem",
			},
			title: {
				fontSize: "3.125rem",
				fontWeight: 500,
				lineHeight: "3.75rem",
			},
			assignToScreenTitle: {
				fontSize: "1.875rem",
			},
			searchTextInput: {
				height: "4rem",
				fontSize: 30,
				lineHeight: 33,
				backgroundColor: light_black,
			},
			titleLogo: {
				width: "18.57875rem",
				height: "2.875rem",
			},
			searchIcon: {
				width: "7.5rem",
				height: "7rem",
			},
			portraitImage: {
				width: "3.5rem",
				height: "5.5rem",
			},
			landscapeImage: {
				width: "9.75rem",
				height: "5.5rem",
			},
		},
		shortClipsBanner: {
			height: "18rem",
			width: "calc(100% - 7rem)",
			paddingLeft: "7rem",
			gradient: {
				width: "81.852rem",
			},
			text: {
				paddingLeft: "2rem",
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
			},
		},
		vamTitle: {
			fontSize: "1.3125rem",
			lineHeight: "1.4375rem",
		},
		tabsView: {
			top: "7rem",
			paddingTop: "7rem",
			paddingLeft: "7.5rem",
			font: {
				fontFamily: "Nunito",
				size: "1.5625rem",
				lineHeight: "1.75rem",
			},
		},
		settings: {
			container: "2.5rem",
			gap: "1.5rem",
			textWidth: "44rem",
			loading: {
				height: "18.0625rem",
				gap: "2em",
			},
			font: {
				fontFamily: "Nunito",
				buttonSize: "1.5625rem",
				headerSize: "1.875rem",
				headerLineHeight: "2.0625rem",
				subTextSize: "1.5625rem",
				subTextLineHeight: "1.75rem",
			},
		},
		gridSize: {
			hka: 3,
			packshot: 4,
		},
		vam: {
			width: "23rem",
			height: "14.9rem",
		},
		typographyTitle: {
			fontSize: "2.3125rem",
			lineHeight: "2.75rem",
			gap: "1rem",
		},
		typographyBody: {
			fontSize: "1.5625rem",
			lineHeight: "1.75rem",
		},
		textArea: {
			gap: "2.5rem",
		},
		sectionButton: {
			gap: "1rem",
		},
		textError: {
			fontSize: "1.125rem",
			lineHeight: "1.25rem",
		},
		tdp: {
			textTemplateWidth: "32.9rem",
			packshotGap: "10rem",
			height: "calc(100vw - 182px)",
			paddingLeft: "5.75rem",
			ratings: {
				fontSize: "1.125rem",
				lineHeight: "1.25rem",
			},
			synopsis: {
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
				height: "5.5rem",
			},
			extras: {
				paddingLeft: "6rem",
				paddingRight: "6rem",
				mainContainer: {
					paddingRight: "5.75rem",
					paddingLeft: "5.75rem",
				}
			},
		},
		aggregate: {
			paddingRight: "5.75rem",
			paddingLeft: "5.75rem",
		},
	},
	{
		screen: "wide",
		layout: ScreenDimensions.wide,
		columnGap: "1.5rem",
		rowGap: "2rem",
		paddingTop: 0,
		paddingStart: "5.75rem",
		paddingEnd: "11.75rem",
		playlistTitle: {
			fontSize: 25,
			lineHeight: 28,
		},
		hka: {
			width: "35rem",
			height: "19.67813rem",
		},
		packshot: {
			width: "16.75rem",
			height: "25.125rem",
		},
		banner: {
			height: "27rem",
			width: "calc(100vw - 11.75rem)",
			lineOne: {
				fontSize: "2.3125rem",
				lineHeight: "2.75rem",
				clamp: 2,
			},
			lineTwo: {
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
				clamp: 3,
			},
		},
		button: {
			borderRadius: "1rem",
			fontSize: "1.5625rem",
			lineHeight: "1.75rem",
		},
		mainWithNav: {
			paddingStart: "9.5rem",
			paddingTop: "7rem",
		},
		landing: {
			logo: {
				width: "24rem",
				height: "13.9375rem",
			},
		},
		sideBar: {
			top: "7rem",
			main: {
				width: "7.5rem",
			},
			container: {
				width: "7.5rem",
				height: "7rem",
			},
			imageIcon: {
				width: "3.5rem",
				height: "3.5rem",
			},
		},
		topBar: {
			container: {
				height: "7rem",
				paddingLeft: 0,
				iconHeight: "7rem",
				iconRenderer: "3.5rem",
				iconWidth: "7.5rem",
			},
			titleBody: {
				fontSize: "1.125rem",
			},
			title: {
				fontSize: "3.125rem",
				fontWeight: 500,
				lineHeight: "3.75rem",
			},
			assignToScreenTitle: {
				fontSize: "1.875rem",
			},
			searchTextInput: {
				height: "4rem",
				fontSize: 30,
				lineHeight: 33,
				backgroundColor: light_black,
			},
			titleLogo: {
				width: "18.57875rem",
				height: "2.875rem",
			},
			searchIcon: {
				width: "7.5rem",
				height: "7rem",
			},
			portraitImage: {
				width: "3.5rem",
				height: "5.5rem",
			},
			landscapeImage: {
				width: "9.75rem",
				height: "5.5rem",
			},
		},
		shortClipsBanner: {
			height: "19rem",
			width: "calc(100% - 7rem)",
			paddingLeft: "7rem",
			gradient: {
				width: "81.852rem",
			},
			text: {
				paddingLeft: "2rem",
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
			},
		},
		vamTitle: {
			fontSize: "1.3125rem",
			lineHeight: "1.4375rem",
		},
		tabsView: {
			top: "7rem",
			paddingTop: "7rem",
			paddingLeft: "7.5rem",
			font: {
				fontFamily: "Nunito",
				size: "1.5625rem",
				lineHeight: "1.75rem",
			},
		},
		settings: {
			container: "2.5rem",
			gap: "1.5rem",
			textWidth: "44rem",
			loading: {
				height: "18.0625rem",
				gap: "2rem",
			},
			font: {
				fontFamily: "Nunito",
				buttonSize: "1.5625rem",
				headerSize: "1.875rem",
				headerLineHeight: "2.0625rem",
				subTextSize: "1.5625rem",
				subTextLineHeight: "1.75rem",
			},
		},
		gridSize: {
			hka: 4,
			packshot: 6,
		},
		vam: {
			width: "23rem",
			height: "14.9rem",
		},
		typographyTitle: {
			fontSize: "2.3125rem",
			lineHeight: "2.75rem",
			gap: "1rem",
		},
		typographyBody: {
			fontSize: "1.5625rem",
			lineHeight: "1.75rem",
		},
		textArea: {
			gap: "2.5rem",
		},
		sectionButton: {
			gap: "1rem",
		},
		textError: {
			fontSize: "1.125rem",
			lineHeight: "1.25rem",
		},
		tdp: {
			textTemplateWidth: "44.1rem",
			packshotGap: "28.75rem",
			height: "calc(100% - 182px)",
			paddingLeft: "5.75rem",
			ratings: {
				fontSize: "1.125rem",
				lineHeight: "1.25rem",
			},
			synopsis: {
				fontSize: "1.5625rem",
				lineHeight: "1.75rem",
				height: "5.5rem",
			},
			extras: {
				paddingLeft: "6rem",
				paddingRight: "6rem",
				mainContainer: {
					paddingRight: "6rem",
					paddingLeft: "6rem",
				}
			},
		},
		aggregate: {
			paddingRight: "6rem",
			paddingLeft: "6rem",
		},
	},
	{
		screen: "narrow",
		layout: ScreenDimensions.narrow,
		columnGap: "1rem",
		rowGap: "1.5rem",
		paddingTop: 0,
		paddingStart: "4rem",
		paddingEnd: "8rem",
		playlistTitle: {
			fontSize: 21,
			lineHeight: 25,
		},
		hka: {
			width: "17.125rem",
			height: "9.625rem",
		},
		packshot: {
			width: "8.1875rem",
			height: "12.3125rem",
		},
		banner: {
			height: "13.5rem",
			width: "54rem",
			widthInPixel: 864,
			lineOne: {
				fontSize: "2rem",
				lineHeight: "2.5rem",
				clamp: 2,
			},
			lineTwo: {
				fontSize: "1.3125rem",
				lineHeight: "1.5625rem",
				clamp: 3,
			},
		},
		button: {
			borderRadius: "0.75rem",
			fontSize: "1.3125rem",
			lineHeight: "1.5625rem",
		},
		mainWithNav: {
			paddingStart: "6.5rem",
			paddingTop: "4.5rem",
		},
		landing: {
			logo: {
				width: "21.52469rem",
				height: "12.5rem",
			},
		},
		sideBar: {
			top: "0rem",
			main: {
				width: "5rem",
			},
			container: {
				width: "5rem",
				height: "5rem",
			},
			imageIcon: {
				width: "3rem",
				height: "3rem",
			},
		},
		topBar: {
			container: {
				height: "5rem",
				paddingLeft: "5rem",
				iconHeight: "5rem",
				iconRenderer: "3rem",
			},
			titleBody: {
				fontSize: "0.875rem",
			},
			title: {
				fontSize: "2.5rem",
				fontWeight: 500,
				lineHeight: "3rem",
			},
			assignToScreenTitle: {
				fontSize: "1.625rem",
			},
			searchTextInput: {
				height: "2.0625rem",
				fontSize: 26,
				lineHeight: 35,
				backgroundColor: light_black,
			},
			titleLogo: {
				width: "13rem",
				height: "2rem",
			},
			searchIcon: {
				width: "5rem",
				height: "5rem",
			},
			portraitImage: {
				width: "2.5rem",
				height: "4rem",
			},
			landscapeImage: {
				width: "6.125rem",
				height: "3.4375rem",
			},
		},
		shortClipsBanner: {
			height: "18rem",
			width: "100%",
			paddingLeft: "5rem",
			gradient: {
				width: "81.852rem",
			},
			text: {
				paddingLeft: "1.5rem",
				fontSize: "1.3125rem",
				lineHeight: "1.5625rem",
			},
		},
		vamTitle: {
			fontSize: "1.3125rem",
			lineHeight: "1.4375rem",
		},
		tabsView: {
			top: "5rem",
			paddingTop: "5rem",
			paddingLeft: "5rem",
			font: {
				fontFamily: "Nunito",
				size: "1.3125rem",
				lineHeight: "1.5625rem",
			},
		},
		settings: {
			container: "1.5rem",
			gap: "1rem",
			textWidth: "47.25rem",
			loading: {
				height: "17rem",
				gap: "1.5rem",
			},
			font: {
				fontFamily: "Nunito",
				buttonSize: "1.3125rem",
				headerSize: "1.625rem",
				headerLineHeight: "2.1875rem",
				subTextSize: "1.3125rem",
				subTextLineHeight: "1.5625rem",
			},
		},
		gridSize: {
			hka: 5,
			packshot: 10,
		},
		vam: {
			width: "13.8rem",
			height: "7.69rem",
		},
		typographyTitle: {
			fontSize: "2rem",
			lineHeight: " 2.5rem",
			gap: "0.75rem",
		},
		typographyBody: {
			fontSize: "1.3125rem",
			lineHeight: "1.5625rem",
		},
		textArea: {
			gap: "0.75rem",
		},
		sectionButton: {
			gap: "0.75rem",
		},
		textError: {
			fontSize: "0.875rem",
			lineHeight: "1rem",
		},
		tdp: {
			textTemplateWidth: "31.25rem",
			height: "calc(100% - 5rem)",
			ratings: {
				fontSize: "0.875rem",
				lineHeight: "1rem",
			},
			synopsis: {
				fontSize: "1.3125rem",
				lineHeight: "1.5625rem",
				height: "auto",
			},
			extras: {
				paddingLeft: "1.5rem",
				paddingRight: "1.5rem",
				mainContainer: {
					paddingRight: "1.5rem",
					paddingLeft: "1.5rem",
				}
			},
		},
		aggregate: {
			paddingRight: "6rem",
			paddingLeft: "1.5rem",
		},
	},
];
