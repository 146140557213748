import { useEffect } from "react";
import { Modal as ModalPaper, Portal } from "react-native-paper";
import styled from "styled-components/native";

interface Props {
	children: any;
	onDismiss?: () => void;
}

const StyledModal = styled(ModalPaper)`
	justify-content: center;
	flex-direction: row;
`;

const Modal = (props: Props) => {
	const containerStyle = { width: "auto", height: "100%" };

	useEffect(() => {
		// @ts-ignore
		document.body.style = "overflow-y: hidden"; // [React Native]: Workaround for web to hide the scrollbar when opening modal

		return () => {
			// @ts-ignore
			document.body.style = "overflow-y: auto"; // [React Native]: Workaround for web to show the scrollbar when opening modal
		};
	}, []);

	return (
		<Portal>
			<StyledModal visible={true} onDismiss={props.onDismiss} contentContainerStyle={containerStyle}>
				{props.children}
			</StyledModal>
		</Portal>
	);
};

export default Modal;
