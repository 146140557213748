import { useState, useEffect } from "react";
import { useWindowDimensions } from "react-native";
import { globalAny, pushCreateAccountStateToBrowserHistory } from "../../utils/Utils";
import {
	CodeSignInScreenNavigationProp,
	CreateAccountScreenNavigationProp,
	HomeScreenNavigationProp,
	SignInScreenNavigationProp,
	routeCodeSignIn,
	routeCreateAccount,
	routeHome,
	routePreview,
	routeSignIn,
} from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { automaticVoucherRedemption } from "../../services/accountService";
import { AsyncStorageKeys, Pages } from "../../Types";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import LandingScreenLoader from "../../components/Loaders/LandingLoader";
import useLoginStore from "../../store/useLogin.store";
import useCreateAccount from "../../store/useCreateAccount.store";
import useGenericContentStore from "../../store/genericContent.store";
import Toast from "../../components/Toast";
import Main from "../../components/Layout/MainLayout/Main";
import Landing from "./Landing";
import useDimensions from "../../hooks/useDimensions";
import useStyleHelpers from "../../hooks/useStyleHelpers";

let pressCount = 0;
const LandingScreen = () => {
	const windowDimensions = useWindowDimensions();
	const navigationSignInScreen = useNavigation<SignInScreenNavigationProp>();
	const navigationCodeSignInScreen = useNavigation<CodeSignInScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const navigation = useNavigation<any>();
	const navigationCreateAccountScreen = useNavigation<CreateAccountScreenNavigationProp>();
	const createAccountState = useCreateAccount((state: any) => state.createAccountState);
	const isLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isInitialLoading = useLoginStore((state: any) => state.isInitialLoading);
	const setIsDebugVisible = useGenericContentStore((state: any) => state.setIsDebugVisible);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const [loading, setLoading] = useState(true);
	const [isCreateAccountEligible, setIsCreateAccountEligible] = useState(true);
	const [isVoucherAvailable, setIsVoucherAvailable] = useState(false);
	const showHWIDError = useGenericContentStore((state: any) => state.showHWIDError);
	const setShowHWIDError = useGenericContentStore((state: any) => state.setShowHWIDError);
	const screenDimensions = useDimensions();
	const styleHelper = useStyleHelpers();
	const styledLanding = styleHelper?.landing;

	const SignIn = () => {
		navigationSignInScreen.navigate(routeSignIn, {});
	};

	const CodeSignIn = () => {
		navigationCodeSignInScreen.navigate(routeCodeSignIn, {});
	};

	const CreateAccount = () => {
		navigationCreateAccountScreen.navigate(routeCreateAccount, { location: Pages.legal });
		pushCreateAccountStateToBrowserHistory(createAccountState);
	};

	/**
	 * Set setTimeout to 5 seconds after timeout it will
	 * set the `setDialogVisible` to true
	 */
	const handleLogoPressIn = () => {
		pressCount = pressCount + 1;
		if (pressCount === 10) {
			setIsDebugVisible(true);
			pressCount = 0;
		}
	};

	const onPressTryItNow = () => {
		setNavigateEvent(Pages.preview);
		navigation.navigate(routePreview);
	};

	useEffect(() => {
		const isMaster = localStorage.getItem(AsyncStorageKeys.masteruser) == "true";

		setIsCreateAccountEligible(isMaster);
		setLoading(false);
	}, [isInitialLoading]);

	useEffect(() => {
		if (isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.navigate(routeHome, {});
		}
	}, [isLoggedIn]);

	useEffect(() => {
		setLoading(true);
		setIsVoucherAvailable(true);
		setLoading(false);
	}, []);

	if (loading) {
		return (
			<Main>
				<LandingScreenLoader />
			</Main>
		);
	}

	return (
		<Main>
			{
				<Landing
					screenDimensions={screenDimensions}
					isVoucherAvailable={isVoucherAvailable}
					isCreateAccountEligible={isCreateAccountEligible}
					windowDimensions={windowDimensions}
					signIn={() => SignIn()}
					createAccount={() => CreateAccount()}
					codeSignIn={() => CodeSignIn()}
					onPressTryItNow={() => onPressTryItNow()}
					handleLogoPressIn={() => handleLogoPressIn()}
					styledLanding={styledLanding}
				/>
			}
			{showHWIDError ? (
				<Toast
					visible={showHWIDError}
					text={globalAny.language.hwid_error}
					type={ComponentTypeEnum.Secondary}
					onDismissSnackBar={() => setShowHWIDError(false)}
				/>
			) : (
				<></>
			)}
		</Main>
	);
};

export default LandingScreen;
