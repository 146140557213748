import { Carousel, CategoryTextTranslation, VamList } from "../Types";
import { AppLogger } from "../utils/AppLogger";
import { getCategories, getFeatures, getFeaturesShared } from "./categoriesService";
import { getImage } from "./imageService";
import { getLegals } from "./legalService";
import { getPlaylist, getCustomerPlaylist, getVamPlaylist, getPlaylistWithPage } from "./playlistService";

export const getPlaylistSummary = async (playlistId: string, transactionalFilters: any, pageSize?: number, pageNumber?: number) => {
	let categories: any = (await getCategories(playlistId, transactionalFilters)) ?? [];

	const playlistPromises = categories.map(
		(
			cat: {
				id: any;
				playlistId: any;
				customListId: any;
				categoryTemplateType: any;
				categoryTextTranslations: any;
				name: any;
				consumerPlaylistId: any;
				categoryImages: any;
			},
			idx: number
		) =>
			new Promise(async (resolve, reject) => {
				try {
					let isVam = cat.categoryTemplateType === "vam";
					let isLandscape = cat.categoryTemplateType === "hka";
					let isPortrait = cat.categoryTemplateType === "std";
					let isBanner = cat.categoryTemplateType === "banner";
					let playlistInitial: any;
					if (cat.playlistId) {
						if (cat.name !== "Hero Banners") {
							playlistInitial =
								pageSize && pageNumber
									? await getPlaylistWithPage(cat.playlistId ?? cat.customListId, cat.customListId != null, pageSize, pageNumber)
									: await getPlaylist(cat.playlistId ?? cat.customListId, cat.customListId != null);
						}
					} else if (cat.customListId) {
						playlistInitial = await getVamPlaylist(cat.customListId, 15, 1);
					} else if (cat.consumerPlaylistId) {
						playlistInitial = await getCustomerPlaylist(cat.consumerPlaylistId, transactionalFilters, pageSize, pageNumber);
					}

					let translatedTitle = cat.categoryTextTranslations.find((translation: CategoryTextTranslation) => {
						if (translation.name == "Title") return true;
					});

					let title = translatedTitle?.text === "My Library" ? cat.name : translatedTitle?.text || cat.name;
					let items = playlistInitial?.products;

					let playlist: Carousel = {
						isLandscape: cat.consumerPlaylistId ? false : isLandscape,
						isPortrait: isPortrait,
						isProgressBar: false,
						playListCarousel: true,
						playlistId: cat.playlistId || cat.consumerPlaylistId,
						customListId: cat.customListId,
						categoryName: cat.name,
						title: title,
						isVam: isVam,
						isBanner: isBanner,
						categoryImages: cat?.categoryImages,
						uri: null,
						products: items,
						id: cat.id,
					};

					resolve({ index: idx, data: playlist });
				} catch (ex) {
					reject(ex);
				}
			})
	);

	const parallelResults = await Promise.all(playlistPromises);

	// Filters items with null or empty results
	const mappedResults = parallelResults.filter((i) => i).map((item: any) => item.data);
	return mappedResults;
};

export const getHeroBanner = async (page: any, transactionalFilters: any) => {
	const result: any = await getCategories(page, transactionalFilters);

	if (!result) return [];

	return result.filter((val: any) => (val.name = "Hero Banners"));
};

export const getFeaturedLists = async (categoryId: any, transactionalFilters: any, signal?: any, isPreview?: boolean) => {
	const result = !isPreview
		? await getFeatures(categoryId, transactionalFilters, signal)
		: await getFeaturesShared(categoryId, transactionalFilters, signal);
	return result;
};

export const getTitleDetailsImages = async (parentProductId: string) => {
	const apiImageTBG = getImage("TBG", parentProductId);
	const apiImageSTT = getImage("STT", parentProductId);

	const [imgTBG, imgSTT]: [any, any] = await Promise.all([apiImageTBG, apiImageSTT]);

	return { tbg: imgTBG?.images[0], stt: imgSTT?.images[0] };
};

export const fetchLegals = async () => {
	const result = await getLegals();
	return result;
};

/**
 *
 * @param playlistId {string}
 * @param transactionalFilters
 * @returns Filter vams by customListId
 */
export const getVamPlaylistSummary = async (playlistId: string, transactionalFilters: any, pageSize?: number, pageNumber?: number) => {
	let categories: any = await getCategories(playlistId, transactionalFilters);
	const playlistPromises = categories.map(
		(
			cat: {
				customListId: any;
				name: any;
				id: any;
			},
			idx: number
		) =>
			new Promise(async (resolve, reject) => {
				try {
					let playlistInitial: any;

					if (cat.customListId) {
						playlistInitial = await getVamPlaylist(cat.customListId, pageSize, pageNumber);
					} else {
						AppLogger.log("error");
					}

					let items = playlistInitial?.products;

					let vamlist: VamList = {
						title: cat.name,
						customListId: cat.customListId,
						products: items,
						id: cat.id,
					};

					resolve({ index: idx, data: vamlist });
				} catch (ex) {
					reject(ex);
				}
			})
	);

	const parallelResults = await Promise.all(playlistPromises);

	// Filters items with null or empty results
	const mappedResults = parallelResults.filter((i) => i).map((item: any) => item.data);
	return mappedResults;
};
