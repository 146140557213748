import { Categories, Pages } from "../../Types";
import { useEffect, useState, useMemo } from "react";
import { useNavigation } from "@react-navigation/native";
import { routeHome, routePage } from "../../Routes";
import { AppLogger } from "../../utils/AppLogger";
import { getCategories } from "../../services/categoriesService";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import { getPage } from "../../services/pageService";
import TopAppBar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import styled from "styled-components/native";
import { light_black } from "../../StyleHelpers";
import AggregatePageLoader from "../../components/Loaders/AggregatePageLoader";
import Banner from "./Banner";
import useGenericContentStore from "../../store/genericContent.store";
import { getTransactionFilters } from "../../utils/Utils";
import useLoginStore from "../../store/useLogin.store";

const transactionalFilters = getTransactionFilters();

const StyledContainer = styled.View`
	height: 100%;
	background-color: ${light_black};
`;

const TopSpacer = styled.View`
	margin-top: 3%;
`;

const StyledGridPackshots = styled.View`
	margin-bottom: 3%;
	padding-top: 115px;
	padding-bottom: 20px;
`;

const Page = (props: any) => {
	const navigation = useNavigation<any>();
	const { uniqueId} = props?.route?.params ?? "";
	const [categories, setCategories] = useState([]);
	const [pageDetails, setPageDetails] = useState<any>();
	const [title, setTitle] = useState("");
	const [banner, setBanner] = useState("");
	const [isShowLoader, setIsShowLoader] = useState(true);

	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	const setUniqueId = useGenericContentStore((state: any) => state.setUniqueId);
	const isInitialLoading = useLoginStore((state: any) => state.isInitialLoading);
	
	const [startIndex, setStartIndex] = useState(4);

	const renderPlaylist = useMemo(
		() => (
			<PlaylistContainer
				headerBanner={banner ? <Banner banner={banner} /> : null}
				categories={categories}
				isSeeMore={true}
				location={Categories.home}
				transactionalFilters={transactionalFilters}
				setIsShowLoader={setIsShowLoader}
				setIsAggregate={() => {}}
				setStartIndex={setStartIndex}
				startIndex={startIndex}
				isPreview={navigateEvent === Pages.preview}
			/>
		),
		[categories, startIndex, banner]
	);

	const onClose = () => {
		if (navigateEvent === Pages.preview) {
			setNavigateEvent("");
			navigation.navigate(routePage, { uniqueId: Pages.preview });
			return;
		}

		setNavigateEvent("");
		navigation.navigate(routeHome);
	};

	const onBackPress = () => {
		onClose();
	};

	useEffect(() => {
		if (uniqueId) setUniqueId(uniqueId);
		if (navigateEvent !== Pages.preview) setNavigateEvent(Pages.page);
	}, [uniqueId]);

	useEffect(() => {
		if (pageDetails) {
			const [pageTitle] = pageDetails.pageTranslations.filter((translation: any) => translation.name.toLowerCase() === "title");
			setTitle(pageTitle.text);

			if (pageDetails?.pageImages) {
				const [pageImage] = pageDetails.pageImages;
				setBanner(pageImage?.imageUrl);
			}
		}
	}, [pageDetails]);

	useEffect(() => {
		if (isInitialLoading) setIsShowLoader(true);
	}, [isInitialLoading]);

	useEffect(() => {
		(async () => {
			try {
				const pages: any = await getPage(uniqueId);
				const [page] = pages.pages;
				setPageDetails(page);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		(async () => {
			try {
				let categories: any = (await getCategories(uniqueId, transactionalFilters)) ?? [];
				setCategories(categories);
			} catch (err) {
				AppLogger.log(err);
			}
		})();

		return () => {
			setCategories([]);
		};
	}, []);

	return (
		// @ts-ignore
		<StyledContainer>
			<TopAppBar
				leftIcon={backIcon}
				leftIconWidth={92}
				leftTitleIcon={ backIcon}
				isHideLeftIcon={false}
				screenTitle={title}
				titleLogo={null}
				onPressLeftTitleIcon={onBackPress}
				onPressLeftIcon={onClose}
			/>
			{isShowLoader && (
				// @ts-ignore
				<StyledGridPackshots>
					<TopSpacer />
					<AggregatePageLoader />
				</StyledGridPackshots>
			)}
			{renderPlaylist}
			
		</StyledContainer>
	);
};

export default Page;
