import { ProductPreverify } from "../models/ProductPreverify";
import { ProductCheckout } from "../models/ProductCheckout";
import ProductRedemption from "../models/ProductRedemption";
import { PAYMENT_BASEURL_V6, PAYMENT_LOCAL_V6, USER_BASEURL_V6, USER_BASEURL_V7, USER_BASEURL_V8 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

/**
 * Purchasing products
 * @param parentProductId - number: parentProductId
 * @returns
 */
export const redeemProduct = async (parentProductId: any) => {
	const redemptionRequest: ProductRedemption = {
		parentProductIds: parentProductId + "-6",
		forcedId: 0,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "ProductRedemption", { method: "POST", headers: getHeaders(true), body: JSON.stringify(redemptionRequest) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Purchasing products
 * @param parentProductId - number: parentProductId
 * @returns
 */
export const redeemProductV7 = async (parentProductId: any, definition: any) => {
	const redemptionRequest: any = {
		parentProductId,
		definition,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V7 + "ProductRedemption", { method: "POST", headers: getHeaders(true), body: JSON.stringify(redemptionRequest) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Purchasing products
 * @param parentProductId - number: parentProductId
 * @returns
 */
export const redeemProductV8 = async (parentProductId: any, definition: any) => {
	const redemptionRequest: any = {
		parentProductId,
		definition,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V8 + "ProductRedemption", { method: "POST", headers: getHeaders(true), body: JSON.stringify(redemptionRequest) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Use for Purchase and Rent Movie
 * @param transactionId - string transaction id
 * @param checkoutRequest - model product checkout request body
 * @returns - success purchase or rent
 */
export const checkout = async (transactionId: string, checkoutRequest: ProductCheckout) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "cybersource/purchase/" + transactionId + "/checkout", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(checkoutRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Use for Local environment Purchase and Rent Movie
 * @param transactionId - string transaction id
 * @param checkoutRequest - model product checkout request body
 * @returns - success purchase or rent
 */
export const checkoutLocal = async (transactionId: string, checkoutRequest: ProductCheckout) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_LOCAL_V6 + "cybersource/purchase/" + transactionId + "/checkout", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(checkoutRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Use for cyberSource Preverify
 * @param parentProductId - number
 * @param product - contain product details
 * @returns - transactionId
 */
export const cyberSourcePurchasePreverify = async (parentProductId: number, product: any) => {
	const productPreverifyRequest: ProductPreverify = {
		parentProductId: parentProductId,
		productSku: product[0].sku,
		transactionTypeId: product[0].transactionTypeId,
		videoType: product[0].videoType,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "cybersource/purchase/preverify", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(productPreverifyRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Use for cyberSource Preverify in local environment
 * @param parentProductId - number
 * @param product - contain product details
 * @returns - transactionId
 */
export const cyberSourcePurchasePreverifyLocal = async (parentProductId: number, product: any) => {
	const productPreverifyRequest: ProductPreverify = {
		parentProductId: parentProductId,
		productSku: product[0].sku,
		transactionTypeId: product[0].transactionTypeId,
		videoType: product[0].videoType,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_LOCAL_V6 + "cybersource/purchase/preverify", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(productPreverifyRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});

	return promise;
};

/**
 * Use this function for running flexmicro
 * @param transactionId - string
 * @returns
 */
export const cyberSourcePurchasePublicKey = async (transactionId: string) => {
	const contextRequest = {
		transactionId,
		flexType: 1,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "cybersource/context", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(contextRequest),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Use this function for running flexmicro in local environment
 * @param transactionId - string
 * @returns
 */
export const cyberSourcePurchasePublicKeyLocal = async (transactionId: string) => {
	const contextRequest = {
		transactionId,
		flexType: 1,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_LOCAL_V6 + "cybersource/context", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(contextRequest),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};
