import { Dimensions, SafeAreaView, ScrollView, View } from "react-native";
import { useEffect, useState } from "react";
import { routeHome, routeMyLibrary, routeNewCuration } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { getPlaylistSummary, getVamPlaylistSummary } from "../../services/apiMoviesContentService";
import { AppLogger } from "../../utils/AppLogger";
import { getItem, getTransactionFilters } from "../../utils/Utils";
import { Categories, Pages } from "../../Types";
import AggregatePageLoader from "../../components/Loaders/AggregatePageLoader";
import TopAppBar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import CardGridPlaylist from "../../components/CardGridPlaylist";
import styled from "styled-components/native";
import useGenericContentStore from "../../store/genericContent.store";
import GalleryContainer from "../../components/Layout/GalleryContainer";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { StyledContainer, StyledGridPackshots } from "./styled";

const myLibrary = Categories.myLibrary;
const studioTwo = Categories.studioTwo;

const AggregatePage = (props: any) => {
	const styleHelper = useStyleHelpers();
	const screenDimensions = useDimensions();
	const isNarrow = screenDimensions === ScreenDimensions.narrow;
	const isDefault = screenDimensions === ScreenDimensions.default;
	const isWide = screenDimensions === ScreenDimensions.wide;
	const { id, customListId, location } = props.route.params;
	const aggregateTitle = useGenericContentStore((state: any) => state.aggregateTitle);
	const [title, setTitle] = useState(aggregateTitle);
	const [playList, setPlayList] = useState<any>([]);
	const [vamList, setVamList] = useState<any>([]);
	const [next, setNext] = useState(0);
	const [endPage, setEndPage] = useState(false);
	const [searchLoader, setSearchLoader] = useState(false);
	const [windowWidth, setWindowWidth] = useState<any>();
	const [gridSize, setGridSize] = useState<any>(id ? 6 : 4);
	const [currentPage, setCurrentPage] = useState(1);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setAggregatePageId = useGenericContentStore((state: any) => state.setAggregatePageId);
	const navigation = useNavigation<any>();
	const styledAggregate = styleHelper?.aggregate;

	const fetchData = async (pageSize?: number, nextPage?: number) => {
		return id
			? await getPlaylistSummary(location, getTransactionFilters(), pageSize, nextPage)
			: await getVamPlaylistSummary(studioTwo, getTransactionFilters(), pageSize, nextPage);
	};

	const filterData = (page: any) => {
		const filterCriteria = (item: any) => (id ? item.id === Number(id) : item.customListId === customListId);
		const isLandscape = !id && customListId;

		return page
			.filter((item: any) => filterCriteria(item))
			.map((val: any) => {
				return { ...val, isLandscape };
			});
	};

	const Init = async () => {
		try {
			const page = await fetchData(getPageSize(), currentPage);
			const [data]: any = id ? getItem(filterData(page)) : filterData(page);

			if (id) {
				setPlayList(data);
				setTitle(aggregateTitle || data.title);
				return;
			}

			setVamList(data);
			setTitle(aggregateTitle || data.title);
		} catch (err) {
			AppLogger.log(err);
		}
	};

	useEffect(() => {
		setAggregatePageId(id || customListId, location);
		setNavigateEvent(id ? Pages.homeAggregatePage : Pages.vamAggregatePage);
	}, []);

	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
			setGridSize(getPageSize());
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth]);

	useEffect(() => {
		setCurrentPage(1);
		setGridSize(getPageSize());
		setNext(0);
		Init();
	}, [screenDimensions]);

	const getPageSize = () => {
		if (isNarrow) {
			return 20;
		}
		return 12;
	};

	const onBackPress = () => {
		if (id) {
			navigation.navigate(location === myLibrary ? routeMyLibrary : routeHome);
		} else {
			navigation.navigate(routeNewCuration);
		}
		setNavigateEvent("");
	};

	const onClose = () => {
		setNavigateEvent("");
		navigation.navigate(routeHome);
	};

	const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
		const paddingBottom = 100;
		return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingBottom;
	};

	const onLazyLoading = async () => {
		AppLogger.log(`Next Page Count: ${next} Playlist Count: ${playList?.products?.length}`);

		if (next >= playList?.products?.length || next >= vamList?.products?.length || endPage) return;
		setSearchLoader(true);

		const nextPageNumber = currentPage + 1;
		setCurrentPage(nextPageNumber);

		try {
			const page = await fetchData(getPageSize(), nextPageNumber);
			const [nextPageResults]: any = id ? getItem(filterData(page)) : filterData(page);
			if (!nextPageResults.products.length) {
				setEndPage(true);
				return;
			}
			if (nextPageResults) {
				id
					? setPlayList({ ...playList, products: [...playList.products, ...nextPageResults.products] })
					: setPlayList({ ...vamList, products: [...vamList.products, ...nextPageResults.products] });
				setSearchLoader(false);
				setNext(next + gridSize);
			}
		} catch (err) {
			AppLogger.log(err);
		} finally {
			setSearchLoader(false);
		}
	};
	const slicePortraitLoader = () => {
		if (isDefault && id) {
			return 12;
		}
		if (isNarrow && id) {
			return 20;
		}
		if (isWide && id) {
			return 18;
		}
	};
	const sliceLandscapeLoader = () => {
		if (isDefault && !id) {
			return 9;
		}
		if (isNarrow && !id) {
			return 10;
		}
		if (isWide && !id) {
			return 12;
		}
	};

	return (
		<SafeAreaView>
			{/* @ts-ignore */}
			<StyledContainer>
				<TopAppBar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={title}
					onPressLeftTitleIcon={onBackPress}
					onPressLeftIcon={onClose}
					isShowClose={false}
				/>
				<ScrollView>
					<GalleryContainer>
						<ScrollView
							onScroll={({ nativeEvent }) => {
								if (isCloseToBottom(nativeEvent) && !searchLoader && (vamList.products || playList.products)) {
									onLazyLoading();
								}
							}}
							scrollEventThrottle={400}
							style={{ paddingTop: 30, height: "100%" }}
						>
							{/* @ts-ignore */}
							<StyledGridPackshots paddingTop={styleHelper.paddingTop} styledAggregate={styledAggregate}>
								{customListId && vamList.length === 0 && <AggregatePageLoader isVam={true} slice={sliceLandscapeLoader()} />}
								{customListId && vamList.length !== 0 && (
									<CardGridPlaylist
										data={vamList.products}
										parentProductId={vamList.products[0].productId}
										isAggregate={true}
										isClips={true}
										isRedeem={true}
										isStudioAccess={true}
										isBorderClips={true}
									/>
								)}
								{id && playList?.length !== 0 && <CardGridPlaylist data={playList?.products} isAggregate={true} />}
								{id && playList?.length === 0 && <AggregatePageLoader isVam={false} slice={slicePortraitLoader()} />}
								{searchLoader && (
									<View style={{ marginTop: 20 }}>
										<AggregatePageLoader isVam={!id} slice={slicePortraitLoader()} />
									</View>
								)}
							</StyledGridPackshots>
						</ScrollView>
					</GalleryContainer>
				</ScrollView>
			</StyledContainer>
		</SafeAreaView>
	);
};

export default AggregatePage;
