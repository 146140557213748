import styled, { css } from "styled-components/native";
import { ScreenDimensions } from "../../utils/enum";

interface Props {
	dimensions?: any;
	isNarrow?: any;
	fontSize?: any;
	lineHeight?: any;
	gap?: any;
}

export const StyledWideMain = styled.View`
	display: flex;
	justify-content: space-between;
	height: 39rem;
`;

export const StyledButtonMain = styled.View`
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;
export const StyledButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

//Narrow
export const StyledNarrowMain = styled.View`
	display: flex;
	padding: 1.5rem 1.5rem;
	flex-direction: row;
	gap: 1rem;
`;

export const StyledNarrowButtonMain = styled.View`
	display: flex;
	width: 31.25rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

export const StyledNarrowButtonContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledNarrowButton = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledDataContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	flex: 1 0 0;
	align-self: stretch;
`;

export const StyledTextTemplate = styled.View`
	display: flex;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
	flex-direction: row;
`;

export const StyledTextArea = styled.View<Props>`
	${(props) =>
		css`
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: ${props?.gap};
			flex: 1 0 0;
		`}
`;

export const StyledSections = styled.View<Props>`
	${(props) =>
		css`
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: ${props?.gap};
			align-self: stretch;
		`}
`;

export const StyledRowButtonContainer = styled.View<Props>`
	${(props) =>
		props.dimensions === ScreenDimensions.narrow &&
		css`
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 1rem;
			align-self: stretch;
		`}
	${(props) =>
		props.dimensions != ScreenDimensions.narrow &&
		css`
			display: flex;
			align-items: flex-start;
			align-self: stretch;
			flex-direction: row;
			gap: 1.5rem;
		`}
`;
