import { ProgressBar } from "react-native-paper";
import { black, palladium, mid_blue, spacing_l, spacing_m, purple_blue, white, aloha, love } from "../../StyleHelpers";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import styled, { css } from "styled-components/native";
import { Text, ActivityIndicator, TouchableOpacity } from "react-native";
import IconActive from "../../assets/ControllerIcon/Signal.svg";
import IconLink1 from "../../assets/ControllerIcon/Link1.svg";
import IconLink2 from "../../assets/ControllerIcon/Link2.svg";
import IconLink3 from "../../assets/ControllerIcon/Link3.svg";
import Image from "../../components/Image/Image";
import React, { useEffect, useState } from "react";
import { GroupSync } from "../../models/GroupSync";
import { globalAny } from "../../utils/Utils";
import Marquee from "../../components/Marquee";

const groupColor = [
	{ group: 1, color: purple_blue, icon: IconLink1 },
	{ group: 2, color: aloha, icon: IconLink2 },
	{ group: 3, color: love, icon: IconLink3 },
];

interface Props {
	headerText?: string;
	actionText?: string;
	title?: string;
	link?: boolean;
	selected?: boolean;
	indicator?: boolean;
	bgImage?: any;
	reserveImage?: any;
	progressPercent?: any;
	groupSync: number;
	onPress?: () => void;
	isHovered?: boolean;
}

const StyledContainer = styled.View<Props>`
	background-color: black;
	width: 100%;
	height: 194px;
	border-radius: 16px;

	border-style: solid;
	z-index: -2;
	${(props) =>
		props.selected &&
		css`
			border-color: ${mid_blue};
			border-width: 2px;
		`}

	${(props) =>
		!props.selected &&
		css`
			border-color: ${palladium};
			border-width: 1px;
		`}
`;

const StyledMain = styled.View<Props>`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-basis: 100%;
	width: 100%;
	border-radius: 16px;
	${(props) =>
		props.selected &&
		css`
			background-color: rgba(35, 98, 190, 0.40);
		`}

	${(props) =>
		!props.selected &&
		css`
			background-color: transparent;
		`}
`;

const StyledPadding = styled.View`
	width: 50%;
	padding: ${spacing_m} ${spacing_m} ${spacing_m} ${spacing_m};
`;

const StyledGradient = styled.View<Props>`
	border-radius: 16px;
	width: 50%;
	height: 100%;
	z-index: -1;
`;

const StyledChannelBg = styled.View<Props>`
	border-radius: 16px;
	right: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	${(props) =>
		props.bgImage &&
		css`
			background-image: url(${props.bgImage});
			background-position: top right;
			background-repeat: no-repeat;
			background-size: contain;
		`}

	${(props) =>
		!props.bgImage &&
		css`
			background-color: #333333;
		`}
`;

const StyledImage = styled.View`
	z-index: 1;
	height: 100%;
	border-radius: 16px;
	flex-shrink: 0;
	background-image: linear-gradient(to right, ${black} 50%, transparent 100%);
`;

const StyledIcon = styled.View`
	height: 100%;
	width: 100%;
	right: 0;
	position: absolute;
	justify-content: center;
	align-items: end;
	margin-right: 30%; 
`;

const StyledProgressBar = styled.View`
	width: 90%;
`;

const StyledHeader = styled.View`
	flex-direction: row;
	align-items: center;
	color: white;
	font-family: ${FONT_FAMILY_DEFAULT};
	font-size: 18;
	margin-bottom: ${spacing_l};
`;

const StyledHeaderText = styled.Text`
	color: white;
	font-family: ${FONT_FAMILY_DEFAULT};
	font-size: 18;
`;

const StyledAction = styled.Text`
	color: white;
	font-family: ${FONT_FAMILY_DEFAULT};
	font-size: 18;
	opacity: 0.6;
`;

const styles = {
	onLeave: {
		transition: "border-color 0.6s ease",
	},
	onHover: {
		backgroundColor: "rgba(35, 98, 190, 0.40)",
		borderRadius: 16,
		elevation: 4,
		shadowColor: "rgba(0, 0, 0, 0.30)",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 1,
		shadowRadius: 4,
	},
};

const ScreenManagerChannelButtons = (props: Props) => {
	const [groupSyncColor, setGroupSyncColor] = useState<GroupSync>();
	const [loadingSpinner, setSetLoadingSpinner] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	
	useEffect(() => {
		if (props.link) {

			groupColor.forEach((item) => {
				if (item.group === props.groupSync) {
					setGroupSyncColor({ ...item, color: item.color, icon: item.icon });
				}
			});
		}

		if (!props.link) {
			setGroupSyncColor({ group: 0, color: mid_blue, icon: "" });
		}
	}, [props.link, props.groupSync]);

	useEffect(() => {
		if (props.actionText === globalAny.language.loading) {
			setSetLoadingSpinner(true);
			return;
		}
		setSetLoadingSpinner(false);
	}, [props.actionText]);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<TouchableOpacity onPress={props.onPress}>
			{/* @ts-ignores */}
			<StyledContainer selected={props.selected}>
				{/* @ts-ignores */}
				<StyledMain selected={props.selected} style={isHovered ? styles.onHover : styles.onLeave} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onTouchEnd={handleMouseLeave}>
					{/* @ts-ignores */}
					<StyledPadding>
						{/* @ts-ignores */}
						<StyledHeader>
							{props.link && <Image source={{ uri: groupSyncColor?.icon }} width={32} height={32}></Image>}
							{/* @ts-ignores */}
							<StyledHeaderText>
								<Text numberOfLines={1} ellipsizeMode="tail">
									{props.headerText}
								</Text>
							</StyledHeaderText>
						</StyledHeader>
						{/* @ts-ignores */}
						<StyledAction>{props.actionText}</StyledAction>
						{/* @ts-ignores */}
						{props.title?.length > 12 && props.selected ? (
							<Marquee title={props.title}/>
						):(	
						<Text
									style={{
										color: "white",
										fontSize: 25,
										fontFamily: FONT_FAMILY_DEFAULT,
										fontWeight: "500",
										marginBottom: spacing_l,
									}}
									numberOfLines={1}
									>
										{props.title}
								</Text>)}
						{/* @ts-ignores */}
						<StyledProgressBar>
							<ProgressBar
								progress={props.progressPercent || 0}
								color={groupSyncColor?.color}
								style={{
									borderRadius: 10,
									height: 8,
									backgroundColor: "rgb(51,51,51)",
								}}
							/>
						</StyledProgressBar>
					</StyledPadding>
					{/* @ts-ignores */}
					<StyledGradient>
						{!props.bgImage && props.title !== globalAny.language.online && props.reserveImage && (
							// @ts-ignores
							<StyledIcon>
								<Image
									source={{ uri: props.reserveImage }}
									color={white}
									width={107.3}
									height={161.5}
									style={{ borderRadius: 8 }}
								/>
							</StyledIcon>
						)}

						{!props.bgImage && props.title === globalAny.language.online && props.actionText !== globalAny.language.loading && !props.indicator && (
							// @ts-ignores
							<StyledIcon>
								<Image source={{ uri: IconActive }} width={32} height={32} />
							</StyledIcon>
						)}

						{loadingSpinner && (
							// @ts-ignores
							<StyledIcon>
								<ActivityIndicator size={"large"} color={"white"} />
							</StyledIcon>
						)}
					</StyledGradient>
				</StyledMain>
				{/* @ts-ignores  */}
				<StyledChannelBg bgImage={props.bgImage}>
					<StyledImage/>
				</StyledChannelBg>
			</StyledContainer>
		</TouchableOpacity>
	);
};

export default ScreenManagerChannelButtons;
