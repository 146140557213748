import ProgressSteps, { Content } from '@joaosousa/react-native-progress-steps';

type StepperProps = {
    marker: any;
    currentStep: number;
    steps: any;
    orientation: any;
};

export const Stepper = (props : StepperProps) => {
    return (
        <ProgressSteps
            marker={props.marker}
            orientation={props.orientation}
            currentStep={props.currentStep}
            steps={props.steps}
        />
    );
};