import styled, { css } from "styled-components/native";
import { ScreenDimensions } from "../../utils/enum";

interface Props {
	dimensions?: any;
	isNarrow?: any;
	fontSize?: any;
	lineHeight?: any;
}

export const StyledWideMain = styled.View`
	display: flex;
	justify-content: space-between;
	height: 39rem;
`;

export const StyledButtonMain = styled.View`
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;
export const StyledButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

export const StyledFormContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

//Narrow
export const StyledNarrowMain = styled.View`
	display: flex;
	padding: 1.5rem 1.5rem;
	flex-direction: row;
	gap: 1rem;
`;

export const StyledNarrowButtonMain = styled.View`
	display: flex;
	width: 31.25rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	top: 0.38rem;
`;

export const StyledNarrowButtonContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledLegalContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledLegalTitle = styled.Text<Props>`
	${(props) =>
		css`
			color: #f2f2f2;
			font-family: Nunito;
			font-size: ${props?.fontSize};
			font-style: normal;
			font-weight: 500;
			line-height: ${props?.lineHeight};
		`}
`;
export const StyledLegalBody = styled.Text<Props>`
	${(props) =>
		css`
			color: #f2f2f2;
			font-family: Nunito;
			font-size: ${props?.fontSize};
			font-style: normal;
			font-weight: 400;
			line-height: ${props?.lineHeight};
		`}
`;
export const StyledNarrowButton = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
`;

export const StyledRowButtonContainer = styled.View<Props>`
	${(props) =>
		props.dimensions === ScreenDimensions.narrow &&
		css`
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 1rem;
			align-self: stretch;
		`}
	${(props) =>
		props.dimensions != ScreenDimensions.narrow &&
		css`
			display: flex;
			align-items: flex-start;
			align-self: stretch;
			flex-direction: row;
			gap: 1.5rem;
		`}
`;
