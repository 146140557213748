import { create } from "zustand";
import { devtools } from "zustand/middleware";
import * as CacheStorageUtils from "../utils/CacheStorageUtils";
import { getLegals, getOptions } from "../services/legalService";
import { AppLogger } from "../utils/AppLogger";
import { Type } from "../Types";

export interface LegalContentState {
	legalData: any;
	acceptOtherData: any;
}

export interface ModelLegalContentStore {
	data: LegalContentState;
	fetchLegals: (legals: string) => void;
	fetchAcceptOtherData: (legals: string) => void;
}

const initialState: LegalContentState = {
	legalData: {},
	acceptOtherData: {},
};

const useLegalContentStore = create<ModelLegalContentStore>()(
	devtools((set, get) => ({
		data: initialState,
		fetchLegals: async (legals: string) => {
			const stateMovieContent = get().data;

			if (stateMovieContent.legalData[legals]) {
				return;
			}

			set((state) => ({ data: { ...state.data, status: "loading" } }));

			let result: any = await CacheStorageUtils.getCacheItem(`${legals}`, true);

			try {
				result = await getLegals();

				set((state) => ({ data: { ...state.data, status: "idle", legalData: { ...stateMovieContent.legalData, [legals]: result } } }));

				await CacheStorageUtils.setCacheItem(`${legals}`, result, true);
			} catch (ex) {
				AppLogger.error("failed to fetch legals: ", ex);
				set((state) => ({ data: { ...state.data, status: "failed" } }));
			}
		},
		fetchAcceptOtherData: async (name: string) => {
			const stateMovieContent = get().data;

			if (stateMovieContent.acceptOtherData[name]) {
				return;
			}

			set((state) => ({ data: { ...state.data, status: "loading" } }));

			let resultSettings: any = await CacheStorageUtils.getCacheItem(`${name}`, true);
			let resultSignUp: any = await CacheStorageUtils.getCacheItem(`${name}`, true);

			try {
				resultSettings = await getOptions(Type.Settings);
				resultSignUp = await getOptions(Type.CreateAccount);

				await CacheStorageUtils.setCacheItem(`${name}-settings`, resultSettings, true);
				await CacheStorageUtils.setCacheItem(`${name}-signUp`, resultSignUp, true);

				set((state) => ({
					data: {
						...state.data,
						status: "idle",
						acceptOtherData: { ...stateMovieContent.acceptOtherData, [`${name}-settings`]: resultSettings, [`${name}-signUp`]: resultSignUp },
					},
				}));
			} catch (ex) {
				AppLogger.error("failed to fetch accept other data: ", ex);
				set((state) => ({ data: { ...state.data, status: "failed" } }));
			}
		},
	}))
);

export default useLegalContentStore;
