import {
	StyledContainer,
	StyledFont,
	StyledTextContent,
	StyledTouchableOpacity,
	StyledTouchableOpacityNarrow,
	TabsNarrowContainer,
	TabsNarrowContentArea,
	TabsNarrowMain,
} from "./styled";
import MainSettingsDefaultWrapper from "../Layout/MainLayout/MainSettingsDefaultWrapper";
import MainSettingsNarrowWrapper from "../Layout/MainLayout/MainSettingsNarrowWrapper";

export const TabsViewNarrow = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<TabsNarrowMain>
				{/* @ts-ignore */}
				<TabsNarrowContainer>
					{props.tabsToRender.map((val: any, idx: any) => (
						//@ts-ignore
						<StyledTouchableOpacityNarrow
							key={`tabs-name-narrow-${val.title}`}
							shouldHighlight={idx === props.selectedTabs}
							onPress={() => {
								props.setSelectedTabs(idx);
							}}
							disabled={val.disabled}
						>
							<StyledFont {...props} disabled={val.disabled}>
								{val.title}
							</StyledFont>
						</StyledTouchableOpacityNarrow>
					))}
				</TabsNarrowContainer>
				<TabsNarrowContentArea {...props}>
					{props?.isTDP 
						? props.children[props.selectedTabs]
						: <MainSettingsNarrowWrapper>{props.children[props.selectedTabs]}</MainSettingsNarrowWrapper>
					}
				</TabsNarrowContentArea>
			</TabsNarrowMain>
		</>
	);
};

export const TabsViewDefault = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledContainer {...props}>
				{props.tabsToRender.map((val: any, idx: any) => (
					// @ts-ignore
					<StyledTouchableOpacity
						key={`tabs-name-${val.title}`}
						shouldHighlight={idx === props.selectedTabs}
						onPress={() => {
							props.setSelectedTabs(idx);
						}}
						disabled={val.disabled}
					>
						{/* @ts-ignore */}
						<StyledTextContent>
							<StyledFont {...props} disabled={val.disabled}>
								{val.title}
							</StyledFont>
						</StyledTextContent>
					</StyledTouchableOpacity>
				))}
			</StyledContainer>

			{props.isSettings ? (
				<MainSettingsDefaultWrapper {...props}>{props.children[props.selectedTabs]}</MainSettingsDefaultWrapper>
			) : (
				props.children[props.selectedTabs]
			)}
		</>
	);
};
