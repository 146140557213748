import MainNarrowWrapper from "../../components/Layout/MainLayout/MainNarrowWrapper";
import MainDefaultWrapper from "../../components/Layout/MainLayout/MainDefaultWrapper";
import MainWideWrapper from "../../components/Layout/MainLayout/MainWideWrapper";
import TextInput from "../../components/TextInput";
import { ScreenDimensions } from "../../utils/enum";
import ComponentThemeType from "../../models/ComponentTheme";
import { capitalize, globalAny } from "../../utils/Utils";
import { Pages } from "../../Types";
import TouchableButton from "../../components/TouchableButton";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { ChangePassDefaultScreen, ChangePassNarrowScreen, ChangePassWideScreen } from "./ChangePasswordLayout";

export const RenderTextInput = (props: any) => (
	<>
		<TextInput
			componentTheme={ComponentThemeType.VinFast}
			label={props.settings === Pages.reset ? globalAny.language.current_password : globalAny.language.temporary_password}
			onChangeText={(event: any) => (props.settings === Pages.reset ? props.onEnterCurrentPassword(event) : props.onEnterTempPassword(event))}
			onPressInputIcon={() => props.onPressEye(1)}
			inputIcon={props.secureTextEntryTemp ? "eye" : "eye-off"}
			passwordField={props.secureTextEntryTemp}
			error={props.settings === Pages.reset ? props.currentPasswordError : props.tempPasswordError}
			errorMessage={props.settings === Pages.reset ? props.currentPasswordMessage : props.tempPasswordMessage}
			autoFocus={true}
		/>

		<TextInput
			componentTheme={ComponentThemeType.VinFast}
			label={globalAny.language.enter_password_ca}
			onChangeText={(event: any) => props.onEnterPassword(event)}
			onPressInputIcon={() => props.onPressEye(2)}
			inputIcon={props.secureTextEntryPass ? "eye" : "eye-off"}
			passwordField={props.secureTextEntryPass}
			error={props.passwordError}
			errorMessage={props.passwordMessage}
		/>

		<TextInput
			componentTheme={ComponentThemeType.VinFast}
			label={globalAny.language.retype_new_password}
			onChangeText={(event: any) => props.onEnterRetypePassword(event)}
			onPressInputIcon={() => props.onPressEye(3)}
			inputIcon={props.secureTextEntryRepass ? "eye" : "eye-off"}
			passwordField={props.secureTextEntryRepass}
			error={props.rePasswordError}
			errorMessage={props.rePasswordMessage}
		/>
	</>
);

export const RenderButton = (props: any) => (
	<TouchableButton
		disabled={props.disableChangePassBtn}
		componentTheme={ComponentThemeType.VinFast}
		type={ComponentTypeEnum.Primary}
		onPress={() => (props.settings === Pages.reset ? props.onChangeCurrentPassword() : props.onChangePassword())}
	>
		{capitalize(globalAny.language.submit)}
	</TouchableButton>
);

const ChangePassword = (props: any) => {
	const renderChangeScreenDimensions = () => {
		if (props.screenDimensions === ScreenDimensions.narrow) {
			return (
				<MainNarrowWrapper>
					<ChangePassNarrowScreen {...props} />
				</MainNarrowWrapper>
			);
		}

		if (props.screenDimensions === ScreenDimensions.default) {
			return (
				<MainDefaultWrapper>
					<ChangePassDefaultScreen {...props} />
				</MainDefaultWrapper>
			);
		}

		return (
			<MainWideWrapper>
				<ChangePassWideScreen {...props} />
			</MainWideWrapper>
		);
	};

	return renderChangeScreenDimensions();
};

export default ChangePassword;
