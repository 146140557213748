import styled from "styled-components/native";

const StyledContainer = styled.View<any>`
	gap: ${(props) => props?.styledSettings?.loading?.gap};
`;

const StyledLoading = styled.View<any>`
	display: flex;
	width: 100%;
	height: ${(props) => props?.height};
	flex-direction: column;
	align-items: flex-start;
	border-radius: 1rem;
	background: rgba(178, 178, 178, 0.2);
`;

const SettingsLoader = (props: any) => {
	return (
		<>
			{/* @ts-ignore */}
			<StyledContainer {...props}>
				<StyledLoading {...props} height={props?.styledSettings?.loading?.height} />
				<StyledLoading {...props} height={"10rem"} />
				<StyledLoading {...props} height={props.styledSettings?.loading?.height} />
			</StyledContainer>
		</>
	);
};

export default SettingsLoader;
