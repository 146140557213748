import styled, { css } from "styled-components/native";
import { palladium, white } from "../../StyleHelpers";
import TouchableButton from "../TouchableButton";

//Group
export const StyledContainer = styled.View`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
`;

export const StyledButton = styled.View`
	width: 21.5rem;
	height: 4.5rem;
`;

export const StyledLoadHeader = styled.View`
	width: 400px;
	height: 24px;
	border-radius: 16px;
	overflow: hidden;
`;

export const StyledLoadBody = styled.View`
	width: 200px;
	height: 18px;
	border-radius: 16px;
	overflow: hidden;
`;

export const StyledTextContainer = styled.View`
	max-width: 85vw;
	word-break: break-word;
`;

export const StyledText = styled.Text<any>`
	color: ${palladium};
	font-family: ${(props) => props?.styledSettings?.font?.fontFamily ?? "Nunito"};
	font-size: ${(props) => props?.styledSettings?.font?.subTextSize ?? "1.5625rem"};
	font-style: normal;
	font-weight: 500;
	line-height: ${(props) => props?.styledSettings?.font?.subTextLineHeight ?? "1.75rem"};
	padding-left: ${(props) => (props?.addMarginChild ? "1.5rem" : 0)};
`;

export const StyledTextMain = styled.View`
	flex: 1;
`;

//Label
export const StyledLabelContainer = styled.View`
	width: 100%;
`;

export const StyledChildMargin = styled.View<any>`
	${(props) =>
		props.addMarginChild &&
		css`
			margin-left: 20px;
		`}
`;

export const StyledTextLabel = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const IconImage = styled.View`
	margin-left: 5px;
	width: 40px;
	height: 40px;
`;

export const StyledFooterContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const StyledMainContainer = styled.View<any>`
	display: flex;
	align-items: center;
	${(props) =>
		props.hasIcon &&
		css`
			flex-direction: row;
		`}
`;

export const StyledSettingsButton = styled(TouchableButton)`
	max-width: 21.5rem;
	height: 4.1rem;
`;

export const StyledSettingsLegalButton = styled(TouchableButton)`
	width: 100%;
	height: 4.1rem;
`;

export const StyledSettingsButtonText = styled.Text<any>`
	font-family: ${(props) => props.styledSettings.font.fontFamily};
	font-size: ${(props) => props.styledSettings.font.buttonSize};
	font-style: normal;
	font-weight: 500;
	line-height: 1.75rem;
`;

export const StyledBreakWord = styled.Text<any>`
	word-break: break-word;
`;

export const StyledHeaderFont = styled.Text<any>`
	font-family: ${(props) => props?.styledSettings?.font?.fontFamily ?? "Nunito"};
	color: ${white};
	font-size: ${(props) => props?.styledSettings?.font?.headerSize};
	font-style: normal;
	font-weight: 500;
	line-height: ${(props) => props?.styledSettings?.font?.headerLineHeight};
`;

export const StyledBottomInnerContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-basis: 100%;
`;

export const StyledClickableContainer = styled.View<any>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${(props) => props?.iconGap};
`;
