import { Text } from "react-native";
import { useEffect, useState } from "react";
import { calculateFontSize } from "../../utils/Utils";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";

export default (props: any) => {
	const baseTextFontSize = props.fontSize ? props.fontSize : 18;
	const [textFontSize, setTextFontSize] = useState(calculateFontSize(baseTextFontSize));

	/**
	 * Dynamic Resizing Function - Updates the dimensions of items when the screen size changes without having to reload
	 */
	useEffect(() => {
		setTextFontSize(calculateFontSize(baseTextFontSize));
	}, [props.windowDimensions]);

	const defaultStyle = { fontFamily: FONT_FAMILY_DEFAULT, color: "white", fontSize: textFontSize };
	const incomingStyle = Array.isArray(props.style) ? props.style : [props.style];
	return <Text {...props} style={[defaultStyle, ...incomingStyle]} />;
};
