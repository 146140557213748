import styled, { css } from "styled-components/native";
import { light_black } from "../../../StyleHelpers";
import { ScreenDimensions } from "../../../utils/enum";

interface AppBarProps {
	dimensions: string;
}

export const StyledMain = styled.View`
	background-color: ${light_black};
	width: 100%;
	height: 100%;
`;

export const StyledMainWrapper = styled.View`
	display: inline-flex;
	justify-content: center;
	align-items: center;
`;

export const StyledMainContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

//** AppBar */
export const StyledAppBarWrapper = styled.View<AppBarProps>`
	display: flex;
	width: 100%;
	align-items: center;
	height: ${(props) => (props.dimensions === ScreenDimensions.narrow ? "5rem" : "7rem")};
	z-index: 1000;
`;

//** Default */
export const StyledMainDefault = styled.View`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 83.5rem;
	height: 49.5rem;
	padding: 7rem 11.9rem 2.8rem 11.9rem;
`;

export const StyledMainLanding = styled.View<any>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 6.3125rem;
	flex-shrink: 0;

	${(props) =>
		props.dimensions === ScreenDimensions.default &&
		css`
			width: 83.5rem;
			// height: 49.5rem;
			padding: 7rem 11.9rem 2.8rem 11.9rem;
		`}

	${(props) =>
		props.dimensions != ScreenDimensions.default &&
		css`
			width: 120rem;
			// height: 49.5rem;
			padding: 7rem 24.25rem 3.5rem 24.25rem;
		`}
`;

export const StyledMainSettingsDefault = styled.View<any>`
	margin-top: 4.125rem;
	display: flex;
	padding: 2rem 2rem;
	flex-direction: column;
	gap: 2rem;
	z-index: -100;
`;

export const MainSideTopContainer = styled.View<any>`
	padding-left: ${(props) => props.styleHelper.paddingLeft};
	padding-top: ${(props) => props.styleHelper.paddingTop};
`;

//** Narrow */
export const StyledMainNarrowWrapper = styled.View`
	display: flex;
	padding: 0rem 6.5rem 0rem 6.5rem;
`;

export const StyledMainNarrowContainer = styled.View`
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
	padding: 1.5rem;
`;

export const StyledMainSettingsNarrow = styled.View`
	display: flex;
	padding: 1.5rem 1.5rem;
	gap: 1.5rem;
	width: 100%;
	z-index: -100;
`;

//** Wide */
export const StyledMainWide = styled.View`
	display: flex;
	width: 120rem;
	height: 49.5rem;
	padding: 7rem 24.25rem 3.5rem 24.25rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
`;
